import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function CalendarIllustration({ className }: Props) {
  return (
    <IconBase name="calendar-illustration" w={60} className={className}>
      <g clipPath="url(#calendarillustration_clip0_2031_5514)">
        <path
          d="M49.9151 11.3851H10.0845C7.03669 11.3851 4.56592 13.8559 4.56592 16.9038V50.4005C4.56592 53.4484 7.03669 55.9191 10.0845 55.9191H49.9151C52.9629 55.9191 55.4337 53.4484 55.4337 50.4005V16.9038C55.4337 13.8559 52.9629 11.3851 49.9151 11.3851Z"
          fill="white"
        />
        <path
          d="M49.9184 56.4852H10.075C8.46172 56.4835 6.91504 55.8419 5.7743 54.7012C4.63356 53.5604 3.99195 52.0137 3.99023 50.4005V16.9005C3.99537 15.2895 4.63849 13.7462 5.77886 12.6082C6.91923 11.4703 8.46395 10.8304 10.075 10.8287H49.9184C51.5306 10.8287 53.077 11.4678 54.2187 12.6059C55.3605 13.7441 56.0045 15.2884 56.0096 16.9005V50.4005C56.0079 52.0149 55.3654 53.5625 54.2233 54.7034C53.0811 55.8444 51.5328 56.4852 49.9184 56.4852ZM10.075 11.9512C8.76229 11.9521 7.50358 12.4737 6.57508 13.4016C5.64658 14.3295 5.12414 15.5879 5.12243 16.9005V50.4005C5.12414 51.7135 5.64648 52.9722 6.57489 53.9006C7.50329 54.829 8.76199 55.3513 10.075 55.353H49.9184C51.2314 55.3513 52.4901 54.829 53.4185 53.9006C54.3469 52.9722 54.8693 51.7135 54.871 50.4005V16.9005C54.8693 15.5879 54.3468 14.3295 53.4183 13.4016C52.4898 12.4737 51.2311 11.9521 49.9184 11.9512H10.075Z"
          fill="currentColor"
        />
        <path
          d="M10.0845 11.3851H49.9183C51.3814 11.386 52.7842 11.9678 53.8185 13.0026C54.8527 14.0375 55.4337 15.4407 55.4337 16.9038V24.318H4.56592V16.9038C4.56592 16.179 4.70866 15.4614 4.986 14.7919C5.26334 14.1223 5.66984 13.514 6.18229 13.0015C7.21723 11.9666 8.62092 11.3851 10.0845 11.3851Z"
          fill="#D3DFF0"
        />
        <path
          d="M55.4372 24.8841H4.57586C4.42572 24.8841 4.28174 24.8245 4.17557 24.7183C4.06941 24.6121 4.00977 24.4681 4.00977 24.318V16.9005C4.01487 15.2929 4.65533 13.7524 5.79151 12.615C6.92769 11.4777 8.46744 10.8356 10.0751 10.8287H49.9186C51.5307 10.8287 53.0771 11.4678 54.2188 12.6059C55.3606 13.7441 56.0046 15.2884 56.0098 16.9005V24.318C56.0098 24.3929 55.9949 24.467 55.966 24.5361C55.9372 24.6053 55.8949 24.6679 55.8417 24.7206C55.7884 24.7732 55.7252 24.8148 55.6558 24.8429C55.5864 24.8709 55.5121 24.885 55.4372 24.8841ZM5.13225 23.7681H54.8711V16.9005C54.8694 15.5879 54.3469 14.3295 53.4184 13.4016C52.4899 12.4737 51.2312 11.9521 49.9186 11.9512H10.0751C8.76241 11.9521 7.50371 12.4737 6.57521 13.4016C5.6467 14.3295 5.12426 15.5879 5.12255 16.9005L5.13225 23.7681Z"
          fill="currentColor"
        />
        <path
          d="M19.0775 39.14H10.0749C9.47438 39.14 8.89843 38.9014 8.47377 38.4768C8.04912 38.0521 7.81055 37.4761 7.81055 36.8756V29.7751C7.81055 29.1746 8.04912 28.5986 8.47377 28.174C8.89843 27.7493 9.47438 27.5107 10.0749 27.5107H19.0904C19.691 27.5107 20.2669 27.7493 20.6916 28.174C21.1162 28.5986 21.3548 29.1746 21.3548 29.7751V36.8756C21.3548 37.174 21.2958 37.4696 21.1812 37.7451C21.0666 38.0207 20.8987 38.2709 20.687 38.4813C20.4754 38.6918 20.2242 38.8583 19.948 38.9713C19.6718 39.0844 19.3759 39.1417 19.0775 39.14ZM10.0749 28.6462C9.77522 28.6462 9.48773 28.765 9.27549 28.9766C9.06326 29.1883 8.9436 29.4754 8.94274 29.7751V36.8756C8.94445 37.1747 9.06449 37.4611 9.27663 37.672C9.48877 37.8829 9.77577 38.0013 10.0749 38.0013H19.0904C19.3901 38.0005 19.6773 37.8808 19.8889 37.6686C20.1005 37.4563 20.2194 37.1688 20.2194 36.8691V29.7751C20.2194 29.4757 20.1004 29.1886 19.8887 28.9768C19.677 28.7651 19.3898 28.6462 19.0904 28.6462H10.0749Z"
          fill="currentColor"
        />
        <path
          d="M34.5953 39.14H25.5798C24.9793 39.14 24.4033 38.9014 23.9787 38.4768C23.554 38.0521 23.3154 37.4761 23.3154 36.8756V29.7751C23.3154 29.1746 23.554 28.5986 23.9787 28.174C24.4033 27.7493 24.9793 27.5107 25.5798 27.5107H34.5953C35.1959 27.5107 35.7718 27.7493 36.1965 28.174C36.6211 28.5986 36.8597 29.1746 36.8597 29.7751V36.8756C36.8597 37.4761 36.6211 38.0521 36.1965 38.4768C35.7718 38.9014 35.1959 39.14 34.5953 39.14ZM25.5798 28.6462C25.2801 28.6462 24.9926 28.765 24.7804 28.9766C24.5681 29.1883 24.4485 29.4754 24.4476 29.7751V36.8756C24.4476 37.1759 24.5669 37.4638 24.7792 37.6762C24.9916 37.8885 25.2795 38.0078 25.5798 38.0078H34.5953C34.8956 38.0078 35.1836 37.8885 35.3959 37.6762C35.6082 37.4638 35.7275 37.1759 35.7275 36.8756V29.7751C35.7266 29.4754 35.607 29.1883 35.3947 28.9766C35.1825 28.765 34.895 28.6462 34.5953 28.6462H25.5798Z"
          fill="currentColor"
        />
        <path
          d="M50.1124 39.14H41.0969C40.4964 39.14 39.9204 38.9014 39.4957 38.4768C39.0711 38.0521 38.8325 37.4761 38.8325 36.8756V29.7751C38.8325 29.1746 39.0711 28.5986 39.4957 28.174C39.9204 27.7493 40.4964 27.5107 41.0969 27.5107H50.1124C50.7129 27.5107 51.2889 27.7493 51.7136 28.174C52.1382 28.5986 52.3768 29.1746 52.3768 29.7751V36.8756C52.3768 37.4761 52.1382 38.0521 51.7136 38.4768C51.2889 38.9014 50.7129 39.14 50.1124 39.14ZM41.0969 28.6462C40.9486 28.6462 40.8018 28.6754 40.6649 28.7321C40.5279 28.7888 40.4034 28.872 40.2986 28.9768C40.1938 29.0817 40.1106 29.2061 40.0539 29.3431C39.9972 29.4801 39.968 29.6269 39.968 29.7751V36.8756C39.9679 37.1753 40.0868 37.4628 40.2984 37.675C40.51 37.8873 40.7972 38.0069 41.0969 38.0078H50.1124C50.4127 38.0078 50.7007 37.8885 50.913 37.6762C51.1253 37.4638 51.2446 37.1759 51.2446 36.8756V29.7751C51.2437 29.4754 51.1241 29.1883 50.9118 28.9766C50.6996 28.765 50.4121 28.6462 50.1124 28.6462H41.0969Z"
          fill="currentColor"
        />
        <path
          d="M19.0775 52.7134H10.0749C9.47438 52.7134 8.89843 52.4748 8.47377 52.0502C8.04912 51.6255 7.81055 51.0496 7.81055 50.449V43.3518C7.81055 42.7512 8.04912 42.1753 8.47377 41.7506C8.89843 41.326 9.47438 41.0874 10.0749 41.0874H19.0904C19.691 41.0874 20.2669 41.326 20.6916 41.7506C21.1162 42.1753 21.3548 42.7512 21.3548 43.3518V50.4523C21.3544 50.7504 21.2951 51.0456 21.1803 51.3208C21.0655 51.596 20.8975 51.8458 20.6859 52.0559C20.4743 52.266 20.2233 52.4322 19.9473 52.5451C19.6712 52.6579 19.3757 52.7151 19.0775 52.7134ZM10.0749 42.2067C9.77466 42.2067 9.48668 42.3259 9.27435 42.5383C9.06202 42.7506 8.94274 43.0386 8.94274 43.3388V50.4393C8.9406 50.5894 8.96829 50.7383 9.02423 50.8776C9.08017 51.0168 9.16323 51.1435 9.26858 51.2504C9.37393 51.3572 9.49946 51.4421 9.63789 51.5C9.77632 51.5579 9.92488 51.5877 10.0749 51.5877H19.0904C19.2387 51.5877 19.3855 51.5585 19.5225 51.5018C19.6594 51.445 19.7839 51.3619 19.8887 51.257C19.9935 51.1522 20.0767 51.0277 20.1334 50.8908C20.1902 50.7538 20.2194 50.607 20.2194 50.4587V43.3518C20.2194 43.0521 20.1005 42.7646 19.8889 42.5523C19.6773 42.3401 19.3901 42.2205 19.0904 42.2196L10.0749 42.2067Z"
          fill="currentColor"
        />
        <path
          d="M34.5953 52.7134H25.5798C24.9793 52.7134 24.4033 52.4748 23.9787 52.0502C23.554 51.6255 23.3154 51.0496 23.3154 50.449V43.3518C23.3154 42.7512 23.554 42.1753 23.9787 41.7506C24.4033 41.326 24.9793 41.0874 25.5798 41.0874H34.5953C35.1959 41.0874 35.7718 41.326 36.1965 41.7506C36.6211 42.1753 36.8597 42.7512 36.8597 43.3518V50.4522C36.8588 51.0522 36.6199 51.6274 36.1953 52.0513C35.7708 52.4753 35.1953 52.7134 34.5953 52.7134ZM25.5798 42.2067C25.2795 42.2067 24.9916 42.3259 24.7792 42.5383C24.5669 42.7506 24.4476 43.0386 24.4476 43.3388V50.4393C24.4485 50.739 24.5681 51.0262 24.7804 51.2378C24.9926 51.4494 25.2801 51.5683 25.5798 51.5683H34.5953C34.895 51.5683 35.1825 51.4494 35.3947 51.2378C35.607 51.0262 35.7266 50.739 35.7275 50.4393V43.3518C35.7275 43.0515 35.6082 42.7635 35.3959 42.5512C35.1836 42.3389 34.8956 42.2196 34.5953 42.2196L25.5798 42.2067Z"
          fill="currentColor"
        />
        <path
          d="M50.1127 41.6567H41.094C40.1578 41.6567 39.3989 42.4156 39.3989 43.3518V50.4555C39.3989 51.3916 40.1578 52.1505 41.094 52.1505H50.1127C51.0489 52.1505 51.8078 51.3916 51.8078 50.4555V43.3518C51.8078 42.4156 51.0489 41.6567 50.1127 41.6567Z"
          fill="#F7C5D7"
        />
        <path
          d="M50.1124 52.7134H41.0969C40.4964 52.7134 39.9204 52.4748 39.4957 52.0502C39.0711 51.6255 38.8325 51.0496 38.8325 50.449V43.3518C38.8325 42.7512 39.0711 42.1753 39.4957 41.7506C39.9204 41.326 40.4964 41.0874 41.0969 41.0874H50.1124C50.7129 41.0874 51.2889 41.326 51.7136 41.7506C52.1382 42.1753 52.3768 42.7512 52.3768 43.3518V50.4522C52.3759 51.0522 52.137 51.6274 51.7124 52.0513C51.2879 52.4753 50.7124 52.7134 50.1124 52.7134ZM41.0969 42.2067C40.7972 42.2075 40.51 42.3272 40.2984 42.5394C40.0868 42.7516 39.9679 43.0391 39.968 43.3388V50.4393C39.9654 50.5892 39.9927 50.7381 40.0482 50.8773C40.1038 51.0165 40.1866 51.1433 40.2917 51.2502C40.3968 51.3571 40.5221 51.442 40.6603 51.4999C40.7986 51.5579 40.947 51.5877 41.0969 51.5877H50.1124C50.4121 51.5877 50.6996 51.4688 50.9118 51.2572C51.1241 51.0456 51.2437 50.7584 51.2446 50.4587V43.3518C51.2446 43.0515 51.1253 42.7635 50.913 42.5512C50.7007 42.3389 50.4127 42.2196 50.1124 42.2196L41.0969 42.2067Z"
          fill="#E23B77"
        />
        <path
          d="M16.4831 4.56604H16.4798C15.097 4.56604 13.9761 5.68701 13.9761 7.0698V15.7003C13.9761 17.0831 15.097 18.2041 16.4798 18.2041H16.4831C17.8659 18.2041 18.9868 17.0831 18.9868 15.7003V7.0698C18.9868 5.68701 17.8659 4.56604 16.4831 4.56604Z"
          fill="#D3DFF0"
        />
        <path
          d="M16.4831 4C17.297 4.00086 18.0773 4.32456 18.6528 4.90009C19.2284 5.47561 19.5521 6.25595 19.5529 7.06986V15.7004C19.5529 16.5154 19.2292 17.2971 18.6528 17.8734C18.0765 18.4497 17.2949 18.7735 16.4798 18.7735C15.6648 18.7735 14.8831 18.4497 14.3068 17.8734C13.7305 17.2971 13.4067 16.5154 13.4067 15.7004V7.06986C13.4085 6.25565 13.7329 5.47532 14.3089 4.89989C14.885 4.32446 15.6656 4.00085 16.4798 4"
          fill="currentColor"
        />
        <path
          d="M16.4834 5.1322C15.9686 5.1322 15.4749 5.33669 15.111 5.70068C14.747 6.06467 14.5425 6.55834 14.5425 7.0731V15.7037C14.5425 16.2184 14.747 16.7121 15.111 17.0761C15.4749 17.4401 15.9686 17.6446 16.4834 17.6446C16.9981 17.6446 17.4918 17.4401 17.8558 17.0761C18.2198 16.7121 18.4243 16.2184 18.4243 15.7037V7.06987C18.4234 6.55567 18.2186 6.06283 17.8547 5.69954C17.4908 5.33625 16.9976 5.1322 16.4834 5.1322Z"
          fill="#D3DFF0"
        />
        <path
          d="M43.6916 4.56604H43.6883C42.3055 4.56604 41.1846 5.68701 41.1846 7.0698V15.7003C41.1846 17.0831 42.3055 18.2041 43.6883 18.2041H43.6916C45.0744 18.2041 46.1953 17.0831 46.1953 15.7003V7.0698C46.1953 5.68701 45.0744 4.56604 43.6916 4.56604Z"
          fill="#D3DFF0"
        />
        <path
          d="M43.6913 4C44.5055 4.00085 45.2861 4.32446 45.8622 4.89989C46.4382 5.47532 46.7626 6.25565 46.7644 7.06986V15.7004C46.7644 16.5154 46.4406 17.2971 45.8643 17.8734C45.2879 18.4497 44.5063 18.7735 43.6913 18.7735C42.8762 18.7735 42.0946 18.4497 41.5182 17.8734C40.9419 17.2971 40.6182 16.5154 40.6182 15.7004V7.06986C40.619 6.25595 40.9427 5.47561 41.5182 4.90009C42.0938 4.32456 42.8741 4.00086 43.688 4"
          fill="currentColor"
        />
        <path
          d="M43.6914 5.1322C43.1766 5.1322 42.683 5.33669 42.319 5.70068C41.955 6.06467 41.7505 6.55834 41.7505 7.0731V15.7037C41.7505 16.2184 41.955 16.7121 42.319 17.0761C42.683 17.4401 43.1766 17.6446 43.6914 17.6446C44.2061 17.6446 44.6998 17.4401 45.0638 17.0761C45.4278 16.7121 45.6323 16.2184 45.6323 15.7037V7.06987C45.6314 6.55567 45.4266 6.06283 45.0627 5.69954C44.6988 5.33625 44.2056 5.1322 43.6914 5.1322Z"
          fill="#D3DFF0"
        />
      </g>
      <defs>
        <clipPath id="calendarillustration_clip0_2031_5514">
          <rect width="52" height="52.4852" fill="currentColor" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </IconBase>
  )
}

export default CalendarIllustration
