import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function ConcorsiEPromozioni({ className }: Props) {
  return (
    <IconBase name="concorsi-e-promozioni" w={100} className={className}>
      <rect width="100" height="100" rx="50" fill="currentColor"/>
      <g clipPath="url(#clip0_4495_867)">
      <path d="M64.5833 31.25H62.5V29.1667C62.5 28.0208 61.5625 27.0833 60.4167 27.0833C59.2708 27.0833 58.3333 28.0208 58.3333 29.1667V31.25H41.6667V29.1667C41.6667 28.0208 40.7292 27.0833 39.5833 27.0833C38.4375 27.0833 37.5 28.0208 37.5 29.1667V31.25H35.4167C33.1042 31.25 31.2708 33.125 31.2708 35.4167L31.25 64.5833C31.25 66.875 33.1042 68.75 35.4167 68.75H64.5833C66.875 68.75 68.75 66.875 68.75 64.5833V35.4167C68.75 33.125 66.875 31.25 64.5833 31.25ZM62.5 64.5833H37.5C36.3542 64.5833 35.4167 63.6458 35.4167 62.5V41.6667H64.5833V62.5C64.5833 63.6458 63.6458 64.5833 62.5 64.5833ZM41.6667 45.8333H47.9167C49.0625 45.8333 50 46.7708 50 47.9167V54.1667C50 55.3125 49.0625 56.25 47.9167 56.25H41.6667C40.5208 56.25 39.5833 55.3125 39.5833 54.1667V47.9167C39.5833 46.7708 40.5208 45.8333 41.6667 45.8333Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_4495_867">
      <rect width="50" height="50" fill="currentColor" transform="translate(25 25)"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default ConcorsiEPromozioni
