import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function YouTube({ className }: Props) {
  return (
    <IconBase name="you-tube" w={24} h={18} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M9 13.0003V5.00029L17 8.99329L9 13.0003ZM19.615 0.184293C16.011 -0.061707 7.984 -0.060707 4.385 0.184293C0.488 0.450293 0.029 2.80429 0 9.00029C0.029 15.1853 0.484 17.5493 4.385 17.8153C7.985 18.0613 16.011 18.0623 19.615 17.8153C23.512 17.5503 23.971 15.1963 24 9.00029C23.971 2.81529 23.516 0.451293 19.615 0.184293Z" fill="currentColor"/>
    </IconBase>
  )
}

export default YouTube
