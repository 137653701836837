import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Reduce({ className }: Props) {
  return (
    <IconBase name="reduce" w={20} className={className}>
      <path d="M15.0917 6.075L17.0083 7.99167L12.0083 7.99167L12.0083 2.99167L13.925 4.90833L16.3167 2.5L17.5 3.68333L15.0917 6.075Z" fill="currentColor"/>
      <path d="M6.07503 4.90833L7.9917 2.99167L7.9917 7.99167L2.9917 7.99167L4.90837 6.075L2.50003 3.68333L3.68337 2.5L6.07503 4.90833Z" fill="currentColor"/>
      <path d="M2.99158 12.0083L4.90824 13.925L2.49991 16.3167L3.68324 17.5L6.07491 15.0917L7.99158 17.0083L7.99158 12.0083L2.99158 12.0083Z" fill="currentColor"/>
      <path d="M12.0083 17.0083L13.925 15.0917L16.3167 17.5L17.5 16.3167L15.0917 13.925L17.0083 12.0083L12.0083 12.0083L12.0083 17.0083Z" fill="currentColor"/>
    </IconBase>
  )
}

export default Reduce
