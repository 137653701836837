import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Lunghezza({ className }: Props) {
  return (
    <IconBase name="lunghezza" w={24} className={className}>
      <path d="M21 6H3C1.9 6 1 6.9 1 8V16C1 17.1 1.9 18 3 18H21C22.1 18 23 17.1 23 16V8C23 6.9 22.1 6 21 6ZM21 16H3V8H5V12H7V8H9V12H11V8H13V12H15V8H17V12H19V8H21V16Z" fill="currentColor"/>
    </IconBase>
  )
}

export default Lunghezza
