/* eslint-disable react/no-array-index-key */
import React from 'react'
import { BreadcrumbJsonLd } from 'next-seo'
import { ChevronRight, Home } from 'components/atoms/Icons'
import styles from './Breadcrumbs.module.sass'

interface ItemProps {
  label: string
  url?: string
  isHome?: boolean
  first?: boolean
  last?: boolean
  variant?: '' | 'white'
}
interface Props {
  breadcrumbs: {
    label: string
    url?: string
  }[]
  variant?: '' | 'white'
  className?: string
}

const BreadcrumbsItem = (props: ItemProps) => {
  const { label = '', url = '', isHome = false, first = false, last = false, variant = '' } = props

  return (
    <div className={`${styles.item} ${variant ? styles[`item--${variant}`] : ''}`}>
      {!first && <ChevronRight className={styles.icon} />}
      {last || !url ? (
        <span className={`${styles.itemLabel} ${last ? styles['itemLabel--last'] : ''}`}>
          {isHome ? <Home /> : <>{label}</>}
        </span>
      ) : (
        <a className={`${styles.itemLabel} ${variant ? styles[`item--link`] : ''}`} href={url}>
          {isHome ? <Home /> : <>{label}</>}
        </a>
      )}
    </div>
  )
}

const Breadcrumbs = (props: Props) => {
  const { breadcrumbs = [], variant = '', className = '' } = props

  const jsonLdItems = breadcrumbs.map((item, i) => ({
    position: i + 1,
    name: item.label ? item.label.replace(/"/g, '') : '',
    item: item.url,
  }))

  return (
    <section className={`${styles.breadcrumbs} ${styles[`breadcrumbs--n${breadcrumbs.length}`]} ${className}`}>
      {breadcrumbs.map((item, index) => (
        <BreadcrumbsItem
          label={item.label}
          url={item.url}
          first={index === 0}
          last={index === breadcrumbs.length - 1}
          variant={variant}
          isHome={item.url === '/' && index === 0}
          key={index}
        />
      ))}
      <BreadcrumbJsonLd itemListElements={jsonLdItems} />
    </section>
  )
}

Breadcrumbs.Item = BreadcrumbsItem

export default Breadcrumbs
