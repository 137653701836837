import React, { ReactNode } from 'react'
import { OverlayTrigger, Tooltip as BTooltip } from 'react-bootstrap'
import { OverlayTriggerRenderProps } from 'react-bootstrap/esm/OverlayTrigger'

export interface Props {
  label: string | ReactNode
  id: string
  className?: string
  color?: '' | 'primary'
  pos?: 'top' | 'right' | 'bottom' | 'left'
  children?:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | ((props: OverlayTriggerRenderProps) => React.ReactNode)
}

const Tooltip = (mainProps: Props) => {
  const {
    label = '',
    id = '',
    pos = 'top',
    color = '',
    className = '',
    children = null,
  } = mainProps

  const classNames = `tooltip ${color ? `tooltip--color-${color}` : ''} ${className}`

  const renderTooltip = (props: any) => (
    <BTooltip id={id} className={classNames} {...props}>
      {label}
    </BTooltip>
  )

  return (
    <OverlayTrigger placement={pos} delay={{ show: 50, hide: 50 }} overlay={renderTooltip}>
      {children}
    </OverlayTrigger>
  )
}

export default Tooltip
