import { FormikProps } from 'formik'
import { isReclamoOrInfoProdotti } from 'utils'
import * as yup from 'yup'

export const RECLAMO = 'Reclamo'
export const INFORMAZIONI_PRODOTTI = 'Informazioni prodotti'
export const PRIVACY_INFO_ACCOUNT = 'Privacy e informazioni account'

export enum RequestType {
  'case1', // acquisti
  'case2', // prodotti
  'case3', // generico
}

export interface IPurpose {
  purpose: string
  reason: string
}

export const purposes = {
  '0': [
    { purpose: 'Riscontro problemi di pagamento sul sito', reason: 'Inquiry' },
    { purpose: 'Riscontro problemi di applicazione sconti e coupon', reason: 'Negative' },
    {
      purpose: 'Riscontro problemi di navigazione sul sito o anomalie tecniche',
      reason: 'Negative',
    },
    {
      purpose: 'Ho effettuato un ordine e vorrei modificare i dati di consegna',
      reason: 'Inquiry',
    },
    {
      purpose:
        'Il mio ordine non è ancora stato spedito o non so come controllare lo stato spedizione',
      reason: 'Inquiry',
    },
    {
      purpose: 'Il mio ordine risulta spedito: problemi di giacenza o consegna',
      reason: 'Negative',
    },
    { purpose: 'Desidero annullare il mio ordine', reason: 'Inquiry' },
    {
      purpose: 'Ho effettuato un ordine e desidero modificare o aggiungere prodotti',
      reason: 'Inquiry',
    },
    {
      purpose: 'Ho ricevuto il mio ordine e ci sono prodotti danneggiati o difettosi',
      reason: 'Negative',
    },
    {
      purpose: 'Ho ricevuto il mio ordine e desidero esercitare il diritto di recesso',
      reason: 'Inquiry',
    },
    {
      purpose:
        'Ho ricevuto il mio ordine e ci sono prodotti mancanti o differenti da quanto ordinato',
      reason: 'Negative',
    },
    { purpose: 'Desidero sapere lo stato del mio rimborso ordine', reason: 'Inquiry' },
    { purpose: 'Ho bisogno di supporto sulla creazione di un un ordine', reason: 'Inquiry' },
  ],
  '1': [
    { purpose: 'Composizione: Ingredienti o Allergeni', reason: 'Inquiry' },
    { purpose: 'Dove posso trovare il prodotto', reason: 'Inquiry' },
    { purpose: 'Informazioni sul prezzo', reason: 'Inquiry' },
    { purpose: 'Confezione: materiale e info prodotti', reason: 'Inquiry' },
    { purpose: 'Modalità di preparazione ed utilizzo', reason: 'Inquiry' },
    { purpose: 'Data di scadenza', reason: 'Inquiry' },
    { purpose: 'Il prodotto può essere utilizzato in caso di...', reason: 'Inquiry' },
    { purpose: 'Modalità di conservazione', reason: 'Inquiry' },
    { purpose: 'Dove si trova lo stabilimento produttivo', reason: 'Inquiry' },
    { purpose: 'Altro', reason: 'Inquiry' },
  ],
  '2': [
    { purpose: 'Ingredienti e Allergeni', reason: 'Negative' },
    { purpose: 'Problemi su sapore / consistenza / odore', reason: 'Negative' },
    {
      purpose: 'Il prodotto non è disponibile / non riesco a trovare il prodotto',
      reason: 'Negative',
    },
    { purpose: 'Prezzo', reason: 'Negative' },
    {
      purpose: 'Problemi legati alla confezione o alle informazioni presenti',
      reason: 'Negative',
    },
    { purpose: 'Presenza di corpi estranei', reason: 'Negative' },
    { purpose: 'Problemi su utilizzo e conservazione', reason: 'Negative' },
    { purpose: 'Stabilimento o processo produttivo', reason: 'Negative' },
    { purpose: 'Altro', reason: 'Negative' },
  ],
  '3': [
    { purpose: 'Registrazione sul sito', reason: 'Inquiry' },
    { purpose: 'Recupero Password', reason: 'Inquiry' },
    { purpose: 'Impossibile accedere al sito', reason: 'Inquiry' },
    { purpose: 'Cancellazione account o disiscrizione newsletter', reason: 'Inquiry' },
    { purpose: 'Informazioni sul trattamento dei dati personali', reason: 'Inquiry' },
    { purpose: 'Voglio ricevere le newsletter', reason: 'Inquiry' },
    { purpose: 'Altro', reason: 'Inquiry' },
  ],
  '4': [
    { purpose: 'Informazioni sui Concorsi', reason: 'Inquiry' },
    { purpose: 'Promozioni, offerte e coupon', reason: 'Inquiry' },
    {
      purpose: 'Riscontro problemi di applicazione sconti e coupon sui siti',
      reason: 'Negative',
    },
    { purpose: 'Altro', reason: 'Inquiry' },
  ],
  '5': [
    { purpose: 'Voglio lavorare in Danone', reason: 'Inquiry' },
    {
      purpose: 'Voglio commercializzare i vostri prodotti nel mio sito online',
      reason: 'Inquiry',
    },
    { purpose: 'Voglio commercializzare i vostri prodotti nel mio negozio', reason: 'Inquiry' },
    {
      purpose: 'Voglio avere maggiori informazioni sulla certificazione Bcorp',
      reason: 'Inquiry',
    },
    {
      purpose: 'Voglio avere maggiori informazioni sulla sostenibilità dei prodotti Danone',
      reason: 'Inquiry',
    },
    { purpose: 'Voglio avere informazioni su Istituto Fondazione Danone', reason: 'Inquiry' },
    { purpose: 'Altro', reason: 'Inquiry' },
  ],
  '6': [
    { purpose: 'Voglio fare un complimento', reason: 'Praise' },
    { purpose: 'Voglio suggerire qualcosa', reason: 'Suggestion' },
  ],
}

const genderValues = ['F', 'M', 'X']

export const genderProps = {
  options: ['Donna', 'Uomo', 'Altro'].map((label, index) => ({
    label,
    value: genderValues[index],
  })),
}

export interface StepProps {
  registered: boolean
  noAccount?: boolean
  step?: string
  setSteps?: Function
  requestType?: RequestType
  setRequestType?: Function
  formik?: FormikProps<IinitialVaues>
  setFormik?: Function
  purposes?: []
  setPurposes?: Function
  purposeArea?: string
  setPurposeArea?: Function
}

export interface IinitialVaues {
  case1: {
    isCase1: boolean
    store: string
    orderNumber: string
    orderDate: string
    orderEmail: string
  }
  case2: {
    purpose: string
    request: string
    file: string
    contact: string
    contactBy: string
    brand: string
    reason: string
  }
  case3: {
    isCase3: boolean
    ean: string
    lotto: string
    productName: string
    brand: string
    expiryDate: string
  }
  personalData1: {
    isNotRegistered: boolean
    firstname: string
    lastname: string
    email: string
    informativa: boolean
  }
  personalData2: {
    registration: boolean
    password: string
    gender: string
    birthday: string
    optinMarketing: boolean
    optinProgramMarketing2: boolean
    optinProgramMarketing3: boolean
    optinProgramMarketing4: boolean
  }
  optins?: any
}

export const validationSchema = (t, purposeArea, utente) =>
  yup.object().shape({
    case2: yup.object().shape({
      purpose: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
      request: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
      contact: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
      contactBy: yup
        .string()
        .required('Questo campo non può essere vuoto')
        .when('contact', {
          is: 'email',
          then: yup
            .string()
            .email("Inserisci un'email valida")
            .required('Questo campo non può essere vuoto'),
        }),
      file: yup
        .mixed()
        .nullable()
        .test(
          'file',
          'File troppo grande.',
          (value) => !value || (value && value.size <= 1024 * 1024 * 2)
        ),
    }),
    case1: yup.object().shape({
      isCase1: yup.boolean(),
      store: yup
        .string()
        .nullable()
        .when('isCase1', {
          is: true,
          then: yup.string().required('Questo campo non può essere vuoto'),
        }),
      orderNumber: yup.string().nullable(),
      orderDate: yup.string().nullable(),
      orderEmail: yup
        .string()
        .nullable()
        .when('isCase1', {
          is: true,
          then: yup
            .string()
            .email("Inserisci un'email valida")
            .required('Questo campo non può essere vuoto'),
        }),
    }),
    case3: yup.object().shape({
      isCase3: yup.boolean(),
      ean: yup
        .string()
        .nullable()
        .when('isCase3', {
          is: (value: boolean) => value && !isReclamoOrInfoProdotti(purposeArea),
          then: yup.string().required("Inserisci l'EAN"),
        }),
      lotto: yup
        .string()
        .nullable()
        .when('isCase3', {
          is: (value: boolean) => value && !isReclamoOrInfoProdotti(purposeArea),
          then: yup.string().required('Inserisci il lotto'),
        }),
      productName: yup
        .string()
        .nullable()
        .when('isCase3', {
          is: (value: boolean) => value && !isReclamoOrInfoProdotti(purposeArea),
          then: yup.string().required('Inserisci il prodotto'),
        }),
      brand: yup
        .string()
        .nullable()
        .when('isCase3', {
          is: true,
          then: yup.string().required('Inserisci il brand'),
        }),
    }),
    personalData1: yup.object().shape({
      isNotRegistered: yup.boolean(),
      firstname: yup
        .string()
        .nullable()
        .when('isNotRegistered', {
          is: true,
          then: yup.string().required('Questo campo non può essere vuoto'),
        }),
      lastname: yup
        .string()
        .nullable()
        .when('isNotRegistered', {
          is: true,
          then: yup.string().required('Questo campo non può essere vuoto'),
        }),
      email: yup
        .string()
        .nullable()
        .when('isNotRegistered', {
          is: true,
          then: yup.string().required('Questo campo non può essere vuoto'),
        }),
      informativa: yup
        .boolean()
        .test(
          'informativa',
          "Devi accettare l'informativa",
          (value) => !utente || (value && utente)
        )
        .when('isNotRegistered', {
          is: true,
          then: yup.boolean().oneOf([true], t("Devi accettare l'informativa")),
        }),
    }),
    personalData2: yup.object().shape({
      registration: yup.boolean(),
      password: yup
        .string()
        .nullable()
        .when('registration', {
          is: true,
          then: yup
            .string()
            .required(t('Questo campo non può essere vuoto'))
            .min(8, t('Minimo 8 Caratteri'))
            .matches('^(?=.*?[A-Z])', 'Questo campo deve contenere almeno una lettera maiuscola.'),
        }),
      gender: yup
        .string()
        .nullable()
        .when('registration', {
          is: true,
          then: yup.string().required('Questo campo non può essere vuoto'),
        }),
      birthday: yup
        .date()
        .nullable()
        .when('registration', {
          is: true,
          then: yup
            .date()
            .max(
              new Date(new Date().setFullYear(new Date().getFullYear() - 14)),
              t('Devi avere almeno 14 anni')
            )
            .required('Questo campo non può essere vuoto'),
        }),
      optinMarketing: yup.boolean(),
      optinProgramMarketing2: yup.boolean(),
      optinProgramMarketing3: yup.boolean(),
      optinProgramMarketing4: yup.boolean(),
    }),
  })
