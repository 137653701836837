import React from 'react'
import { Question } from 'components/atoms/Icons'
import { Tooltip } from 'components/atoms'
import { Props as TooltipProps } from 'components/atoms/Tooltip/Tooltip'
import styles from './Help.module.sass'

export interface Props {
  label?: string
  tooltip?: TooltipProps
  className?: string
}

const Help = (props: Props) => {

  const { 
    label = '',
    tooltip = null,
    className = '',
  } = props

  const helpContent = (
    <div className={`${styles.help} ${className}`}>
      <Question className={styles.icon} /> 
      {label && <span className={styles.label}>{label}</span>}
    </div>
  )

  return tooltip ? (
    <Tooltip {...tooltip}>{helpContent}</Tooltip>
  ) : (
    <>{helpContent}</>
  )
}

export default Help
