import React from 'react'
import { Props as FormFieldProps } from 'components/atoms/FormField/FormField'
import { FormField, FormInput, FormInputPassword, FormSelect, FormTextarea, FormCheckbox, FormRadio, FormFile, FormDate } from '@components/atoms'

interface Props {
  id?: string
  label?: string
  placeholder?: string
  type?: 'text' | 'email' | 'number' | 'password' | 'select' | 'textarea' | 'checkbox' | 'radio' | 'file' | 'date' 
  name: string
  required?: boolean
  readOnly?: boolean
  disabled?: boolean
  layout?: 'sm' | 'md' | 'lg' | 'full'
  space?: 'none' | 'sm' | 'md' | 'lg' | 'xl'
  options?: any[]
  togglePassword?: boolean
  checkboxLabel?: string
  formik: any
  className?: string
}

const FormikField = (props: Props) => {

  const { 
    id = '',
    label = undefined,
    placeholder = undefined,
    name = '',
    required = undefined,
    readOnly = undefined,
    disabled = undefined,
    layout = undefined,
    space = undefined,
    formik = {},
    type = 'text',
    togglePassword = undefined,
    checkboxLabel = undefined,
    options = [],
    className = '',
  } = props

  const componentsMap = {
    text: FormInput,
    email: FormInput,
    number: FormInput,
    password: FormInputPassword, 
    select: FormSelect, 
    textarea: FormTextarea, 
    checkbox: FormCheckbox, 
    radio: FormRadio, 
    file: FormFile, 
    date: FormDate 
  }

  const formFieldProps: FormFieldProps = {
    label: label,
    required: required,
    layout: layout,
    space: space,
    status: formik?.touched?.[name] ? (formik?.errors?.[name] ? 'error' : 'success') : "",
    errorMessage: formik?.errors?.[name],
    className: className
  }

  const formElementProps = {
    id: id,
    placeholder: placeholder,
    readOnly: readOnly,
    disabled: disabled,
    value: formik?.values?.[name],
    onBlur: formik?.handleBlur(name),
    onChange: formik?.handleChange(name),
    options: type ===  'select' || type === 'radio' ? options : undefined,
    togglePassword: type === 'password' ? togglePassword : undefined,
    label: type === 'checkbox' ? checkboxLabel : undefined,
    type: type === 'text' || type === 'email' || type === 'number' ? type : undefined,
  }

  const FormComponent = componentsMap[type]

  return (
    <FormField {...formFieldProps}>
      <FormComponent {...formElementProps} />
    </FormField>
  )
}

export default FormikField
