import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Expand({ className }: Props) {
  return (
    <IconBase name="expand" w={20} className={className}>
      <path d="M12.5002 2.50006L14.4169 4.41673L12.0086 6.80839L13.1919 7.99173L15.5836 5.58339L17.5002 7.50006V2.50006H12.5002ZM2.50024 7.50006L4.41691 5.58339L6.80858 7.99173L7.99191 6.80839L5.58358 4.41673L7.50024 2.50006H2.50024V7.50006ZM7.50024 17.5001L5.58358 15.5834L7.99191 13.1917L6.80858 12.0084L4.41691 14.4167L2.50024 12.5001V17.5001H7.50024ZM17.5002 12.5001L15.5836 14.4167L13.1919 12.0084L12.0086 13.1917L14.4169 15.5834L12.5002 17.5001H17.5002V12.5001Z" fill="currentColor"/>
    </IconBase>
  )
}

export default Expand
