import React, { useState, useRef, useEffect } from 'react'
import { ChevronDown, ChevronUp } from '@components/atoms/Icons'
import styles from './Accordion.module.sass'

interface Props {
  label: string
  content: string
  isOpen?: boolean
  className?: string
  onClick?: () => void
}

const Accordion = (props: Props) => {
  const { label = '', content = '', isOpen = false, className = '', onClick = () => undefined } = props

  const [open, setOpen] = useState(false)
  const innerContentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isOpen) setOpen(true)
  }, [])

  return (
    <div
      className={`${styles.accordion} ${open ? styles['accordion--open'] : ''}${className ? ` ${className}` : ''}`}
    >
      <button type="button" className={styles.trigger} onClick={() => {
        setOpen(!open)
        onClick()
      }}>
        <span>{label}</span>
        <span className={styles.arrow}>{open ? <ChevronUp /> : <ChevronDown />}</span>
      </button>
      <div
        className={styles.content}
        style={{
          maxHeight: innerContentRef?.current && open ? innerContentRef.current.clientHeight : 0,
        }}
      >
        <div className={`${styles.innerContent} custom-content`} ref={innerContentRef} dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  )
}

export default Accordion
