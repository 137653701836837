import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function BiberonIllustration({ className }: Props) {
  return (
    <IconBase name="biberon-illustration" w={40} className={className}>
      <g clipPath="url(#biberonillustration_clip0_2033_6315)">
        <path
          d="M32.619 10.7921C32.5274 9.49766 33.8995 8.18727 34.4691 7.70334C34.6772 7.5273 34.83 7.29496 34.9092 7.03421C34.9961 6.79392 35.0143 6.53418 34.9619 6.2841C34.9095 6.03402 34.7885 5.80347 34.6125 5.61827L34.3954 5.38527C34.2107 5.20914 33.9804 5.0882 33.7305 5.03611C33.4806 4.98403 33.2212 5.00288 32.9815 5.09053C32.7202 5.16863 32.4875 5.32165 32.3123 5.53064C31.8284 6.10021 30.52 7.47233 29.2216 7.38072C28.7708 7.33293 28.3152 7.3978 27.8957 7.5695C27.4761 7.74121 27.1058 8.01438 26.8179 8.36451L29.118 10.6627L29.3451 10.8917L31.6352 13.1819C31.9829 12.895 32.2545 12.5269 32.4261 12.1101C32.5976 11.6933 32.6639 11.2406 32.619 10.7921Z"
          fill="#F7C5D7"
        />
        <path
          d="M31.5951 13.6441L26.3535 8.40255L26.5228 8.16357C26.8453 7.764 27.2625 7.45128 27.7365 7.25375C28.2105 7.05622 28.7263 6.98014 29.2372 7.03242C30.4002 7.11009 31.6727 5.73 32.0372 5.30382C32.256 5.04296 32.547 4.85246 32.8736 4.75617C33.1733 4.64956 33.497 4.62905 33.8078 4.69699C34.1186 4.76492 34.4041 4.91857 34.6321 5.14052L34.8591 5.36755C35.3072 5.81364 35.4426 6.43896 35.2434 7.12602C35.1454 7.45233 34.9544 7.74299 34.6938 7.96244C34.2676 8.32489 32.8836 9.59943 32.9652 10.7624C33.0179 11.2728 32.9422 11.7883 32.745 12.262C32.5478 12.7357 32.2354 13.1526 31.836 13.4748L31.5951 13.6441ZM27.2895 8.35277L31.6468 12.7101C31.8773 12.4543 32.0517 12.1531 32.159 11.826C32.2664 11.4988 32.3042 11.1529 32.2702 10.8102C32.1686 9.36244 33.6144 7.96443 34.2417 7.42474C34.3988 7.29398 34.5144 7.12028 34.5743 6.92488C34.7018 6.48078 34.6341 6.12829 34.3652 5.85347L34.1382 5.62644C33.8713 5.35958 33.5109 5.28988 33.0688 5.42729C32.8727 5.48589 32.6982 5.60086 32.5669 5.75788C32.0332 6.38519 30.6372 7.82702 29.1814 7.73143C28.8401 7.697 28.4955 7.7345 28.1696 7.84153C27.8437 7.94855 27.5439 8.12271 27.2895 8.35277Z"
          fill="#E23B77"
        />
        <path
          d="M27.2638 8.82186L31.1785 12.7366C31.6285 13.1866 31.8814 13.797 31.8814 14.4335C31.8814 15.0699 31.6285 15.6803 31.1785 16.1303L29.3662 17.9427L22.0577 10.6342L23.87 8.82186C24.3201 8.37182 24.9304 8.119 25.5669 8.119C26.2033 8.119 26.8137 8.37182 27.2638 8.82186Z"
          fill="white"
        />
        <path
          d="M29.3666 18.4335L21.564 10.6329L23.6231 8.57369C24.1391 8.05904 24.8381 7.77002 25.5668 7.77002C26.2956 7.77002 26.9946 8.05904 27.5105 8.57369L31.4237 12.4869C31.6793 12.742 31.882 13.045 32.0204 13.3785C32.1587 13.712 32.2299 14.0695 32.2299 14.4306C32.2299 14.7917 32.1587 15.1492 32.0204 15.4827C31.882 15.8162 31.6793 16.1192 31.4237 16.3743L29.3666 18.4335ZM22.5497 10.6329L29.3666 17.4477L30.9318 15.8824C31.1224 15.6919 31.2736 15.4657 31.3768 15.2168C31.4799 14.9679 31.533 14.7011 31.533 14.4316C31.533 14.1622 31.4799 13.8953 31.3768 13.6464C31.2736 13.3975 31.1224 13.1713 30.9318 12.9808L27.0266 9.06559C26.6411 8.68215 26.1195 8.46691 25.5758 8.46691C25.0321 8.46691 24.5105 8.68215 24.125 9.06559L22.5497 10.6329Z"
          fill="#164194"
        />
        <path
          d="M5.45299 24.9794L18.051 12.3853C18.5529 11.8216 19.1709 11.3734 19.8627 11.0716C20.5544 10.7697 21.3033 10.6214 22.0579 10.6368L29.3666 17.9455C29.3816 18.6983 29.2337 19.4454 28.9329 20.1356C28.6321 20.8259 28.1856 21.4429 27.624 21.9444L15.02 34.5444C14.8806 34.6841 14.7149 34.7949 14.5326 34.8704C14.3503 34.946 14.1549 34.9849 13.9576 34.9849C13.7602 34.9849 13.5648 34.946 13.3825 34.8704C13.2002 34.7949 13.0346 34.6841 12.8951 34.5444L5.45299 27.1043C5.17195 26.8221 5.01416 26.4401 5.01416 26.0419C5.01416 25.6436 5.17195 25.2616 5.45299 24.9794Z"
          fill="#D3DFF0"
        />
        <path
          d="M13.9565 35.3332C13.7135 35.3335 13.4728 35.2857 13.2483 35.1928C13.0237 35.0998 12.8198 34.9635 12.6481 34.7915L5.20595 27.3494C4.85985 27.0019 4.66553 26.5314 4.66553 26.041C4.66553 25.5505 4.85985 25.0801 5.20595 24.7326L17.792 12.1465C18.3262 11.543 18.9858 11.0636 19.7248 10.7419C20.4637 10.4203 21.2641 10.2642 22.0697 10.2845H22.2071L29.709 17.7883V17.9257C29.7278 18.7311 29.5708 19.5309 29.2489 20.2693C28.927 21.0078 28.4479 21.6671 27.845 22.2014L15.2589 34.7875C14.9142 35.1348 14.4458 35.3311 13.9565 35.3332ZM21.8885 10.9775C21.2147 10.9901 20.5506 11.1407 19.9371 11.4198C19.3237 11.699 18.774 12.1008 18.3218 12.6005L18.2979 12.6284L5.69984 25.2245C5.48386 25.4413 5.36259 25.7349 5.36259 26.041C5.36259 26.347 5.48386 26.6406 5.69984 26.8575L13.14 34.2916C13.3571 34.5071 13.6506 34.6281 13.9565 34.6281C14.2624 34.6281 14.5559 34.5071 14.773 34.2916L27.3989 21.6677C27.9008 21.2135 28.3037 20.6609 28.5826 20.0441C28.8615 19.4274 29.0104 18.7599 29.02 18.0831L21.9104 10.9715L21.8885 10.9775Z"
          fill="#164194"
        />
        <path
          d="M27.1803 22.741C27.0884 22.741 27.0003 22.7045 26.9353 22.6395L23.4243 19.1365C23.392 19.104 23.3664 19.0656 23.349 19.0232C23.3316 18.9809 23.3227 18.9356 23.3228 18.8898C23.3228 18.844 23.332 18.7987 23.3496 18.7565C23.3672 18.7142 23.3929 18.6759 23.4253 18.6436C23.4578 18.6113 23.4962 18.5857 23.5386 18.5683C23.5809 18.5508 23.6262 18.5419 23.672 18.542C23.7178 18.5421 23.7631 18.5512 23.8053 18.5688C23.8476 18.5864 23.8859 18.6121 23.9182 18.6446L27.4252 22.1535C27.4748 22.2022 27.5088 22.2645 27.5227 22.3326C27.5367 22.4007 27.5299 22.4714 27.5034 22.5356C27.4768 22.5998 27.4317 22.6547 27.3738 22.693C27.3158 22.7314 27.2477 22.7516 27.1783 22.751L27.1803 22.741Z"
          fill="#164194"
        />
        <path
          d="M24.611 25.318C24.5651 25.3181 24.5198 25.3092 24.4774 25.2918C24.435 25.2744 24.3965 25.2488 24.364 25.2164L20.8531 21.7075C20.788 21.6421 20.7515 21.5537 20.7515 21.4615C20.7515 21.3693 20.788 21.2809 20.8531 21.2156C20.8851 21.1829 20.9234 21.1569 20.9656 21.1392C21.0079 21.1214 21.0532 21.1123 21.099 21.1123C21.1448 21.1123 21.1901 21.1214 21.2324 21.1392C21.2746 21.1569 21.3129 21.1829 21.345 21.2156L24.8579 24.7325C24.9075 24.7811 24.9415 24.8435 24.9554 24.9116C24.9694 24.9797 24.9626 25.0504 24.9361 25.1146C24.9096 25.1788 24.8644 25.2336 24.8065 25.272C24.7485 25.3104 24.6805 25.3306 24.611 25.3299V25.318Z"
          fill="#164194"
        />
        <path
          d="M22.0481 27.8891C21.9562 27.8891 21.8681 27.8526 21.8031 27.7876L18.2842 24.2766C18.219 24.2111 18.1824 24.1224 18.1826 24.03C18.1828 23.9375 18.2197 23.8489 18.2852 23.7837C18.3507 23.7185 18.4394 23.6819 18.5318 23.6821C18.6243 23.6823 18.7129 23.7192 18.7781 23.7847L22.2871 27.2937C22.3367 27.3423 22.3707 27.4047 22.3846 27.4728C22.3985 27.5408 22.3918 27.6115 22.3652 27.6757C22.3387 27.74 22.2936 27.7948 22.2356 27.8332C22.1777 27.8716 22.1096 27.8917 22.0401 27.8911L22.0481 27.8891Z"
          fill="#164194"
        />
        <path
          d="M19.4713 30.458C19.4255 30.4581 19.3801 30.4492 19.3377 30.4318C19.2953 30.4143 19.2568 30.3887 19.2243 30.3564L15.7154 26.8474C15.6829 26.8151 15.6572 26.7768 15.6396 26.7345C15.622 26.6923 15.6129 26.647 15.6128 26.6012C15.6127 26.5554 15.6216 26.5101 15.6391 26.4678C15.6565 26.4254 15.6821 26.387 15.7144 26.3545C15.7467 26.3221 15.785 26.2964 15.8273 26.2788C15.8695 26.2612 15.9148 26.252 15.9606 26.252C16.0064 26.2519 16.0517 26.2608 16.094 26.2782C16.1364 26.2956 16.1748 26.3212 16.2073 26.3535L19.7182 29.8645C19.7679 29.9131 19.8018 29.9755 19.8158 30.0436C19.8297 30.1117 19.823 30.1823 19.7964 30.2466C19.7699 30.3108 19.7247 30.3656 19.6668 30.404C19.6089 30.4424 19.5408 30.4625 19.4713 30.4619V30.458Z"
          fill="#164194"
        />
        <path
          d="M16.9 33.0293C16.8081 33.0292 16.72 32.9927 16.655 32.9277L13.1441 29.4167C13.1118 29.3843 13.0862 29.3458 13.0687 29.3035C13.0513 29.2612 13.0424 29.2159 13.0425 29.1701C13.0426 29.1243 13.0517 29.079 13.0693 29.0368C13.0869 28.9945 13.1126 28.9561 13.1451 28.9239C13.1775 28.8916 13.216 28.866 13.2583 28.8485C13.3006 28.8311 13.3459 28.8222 13.3917 28.8223C13.4375 28.8224 13.4828 28.8315 13.525 28.8491C13.5673 28.8667 13.6057 28.8924 13.6379 28.9248L17.1469 32.4338C17.1966 32.4825 17.2305 32.5448 17.2445 32.6129C17.2584 32.681 17.2517 32.7517 17.2251 32.8159C17.1986 32.8801 17.1534 32.9349 17.0955 32.9733C17.0376 33.0117 16.9695 33.0319 16.9 33.0313V33.0293Z"
          fill="#164194"
        />
      </g>
      <defs>
        <clipPath id="biberonillustration_clip0_2033_6315">
          <rect
            width="30.6667"
            height="30.6667"
            fill="white"
            transform="translate(4.6665 4.6665)"
          />
        </clipPath>
      </defs>
    </IconBase>
  )
}

export default BiberonIllustration
