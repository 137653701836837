import React from 'react'
import { WImage } from 'components/atoms'
import styles from './NumeroVerdeItem.module.sass'

interface Props {
  title: string
  image?: string
  number: string
  className?: string
}

const NumeroVerdeItem = (props: Props) => {
  const { title, image, number, className = '' } = props

  return (
    <div className={`${styles.root} ${image ? styles[`root--image`] : ''} ${className}`}>
      <div className={styles.left}>
        {image && (
          <WImage
            src={image}
            maxWidth={124}
            maxHeight={60}
            alt={title}
            title={title}
            className={styles.image}
          />
        )}
        <p className={styles.title}>{title}</p>
      </div>
      <div className={styles.right}>
        <a className={styles.number} href={`tel:${number}`}>
          {number}
        </a>
      </div>
    </div>
  )
}

export default NumeroVerdeItem
