import React, { useEffect, useState } from 'react'
import useTrans from '@hooks/useTrans'
import { Share, Print, Copy } from '@components/atoms/Icons'
import {
  FacebookIcon,
  FacebookShareButton,
  PinterestIcon,
  PinterestShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
import { BRAND } from 'utils/settings'
import { Modal, Button } from 'components/atoms'
import { Mail } from 'components/atoms/Icons'
import styles from './ShareButton.module.sass'

interface Props {
  item: { titolo?: string; mainImage?: string }
  className?: string
}

const ShareButton = (props: Props) => {
  const { item, className = '' } = props

  const t = useTrans()

  const [open, setOpen] = useState(false)
  const [url, setUrl] = useState('')

  const [copiedToClipboard, setCopiedToClipboard] = useState(false)

  useEffect(() => {
    setUrl(window.location.href)
  }, [])

  const handlePrint = () => {
    window.print()
  }

  const handleCopyToClipboard = () => {
    const ua = window.navigator.userAgent
    const isIE = /MSIE|Trident/.test(ua)

    if (!isIE) {
      window.navigator.clipboard.writeText(window.location.href).then(
        () => {
          setCopiedToClipboard(true)
          setTimeout(() => setCopiedToClipboard(false), 2000)
        },
        (error) => alert('Could not copy text: ', error)
      )
    } else {
      // TODO: gestione IE
    }
  }

  return (
    <>
      <button
        type="button"
        className={`${styles.shareButton} ${className}`}
        onClick={() => setOpen(true)}
      >
        <span className={styles.label}>{t('Condividi')}</span>
        <span className={styles.icon}>
          <Share />
        </span>
      </button>
      <Modal open={open} setOpen={setOpen} closeButton className={styles.modal}>
        <Modal.Content>
          <p className={styles.title}>{t('Condividi')}</p>
          <div className={styles.social}>
            <WhatsappShareButton url={url} className={styles.socialButton}>
              <WhatsappIcon size={30} bgStyle={{ fill: '#EAF0F7' }} iconFillColor="#50c949" />
            </WhatsappShareButton>
            <FacebookShareButton url={url} className={styles.socialButton}>
              <FacebookIcon size={30} bgStyle={{ fill: '#EAF0F7' }} iconFillColor="#1773ea" />
            </FacebookShareButton>
            {item.mainImage && (
              <PinterestShareButton
                url={url}
                media={item.mainImage}
                className={styles.socialButton}
              >
                <PinterestIcon size={30} bgStyle={{ fill: '#EAF0F7' }} iconFillColor="#b7081b" />
              </PinterestShareButton>
            )}
          </div>
          <div className={styles.links}>
            <Button
              icon={<Mail />}
              label={t('Invia per email')}
              href={`mailto:?subject=${BRAND} | ${item.titolo}&body=${url}`}
              external
              variant="social"
              className={styles.linksButton}
            />
            <Button
              icon={<Copy />}
              label={t('Copia link')}
              onClick={() => {
                handleCopyToClipboard()
              }}
              variant="social"
              className={styles.linksButton}
            />
            <Button
              icon={<Print />}
              label={t('Stampa')}
              onClick={() => {
                handlePrint()
              }}
              variant="social"
              className={styles.linksButton}
            />
          </div>
        </Modal.Content>
      </Modal>
      {copiedToClipboard && (
        <div className={styles.copied}>{t('Il link è stato copiato nei tuoi appunti!')}</div>
      )}
    </>
  )
}

export default ShareButton
