import React from 'react'
import { DOMAIN } from 'utils/settings'

interface Props {
  type:
    | 'commerciale'
    | 'profilazione'
    | 'informativa'
    | 'datiParticolari'
    | 'informativa-contatto'
    | 'statistici'
    | 'analisiDatiParticolari'
}

const OptinLabel = (props: Props) => {
  const { type } = props

  const informativa = (
    <a href={`${DOMAIN}/informativa.pdf`}>
      <u>Informativa sulla privacy</u>
    </a>
  )

  switch (type) {
    case 'informativa':
      return (
        <>
          Dichiaro di aver preso visione dell'{informativa} di Danone Nutricia S.p.A. Società
          Benefit
        </>
      )
    case 'informativa-contatto':
      return (
        <>
          Dichiaro di aver preso visione dell'{informativa} Privacy di Danone Nutricia S.p.A.
          Società Benefit
          <br />
          <i style={{ marginTop: '10px', display: 'block', fontSize: '12px' }}>
            Il trattamento dei dati particolari da lei volontariamente condivisi in occasione della
            richiesta di assistenza verrà effettuato solo se necessario e sulla base del suo
            consenso manifestato attraverso il conferimento volontario dei dati personali
            particolari.
          </i>
        </>
      )
    case 'commerciale':
      return (
        <>
          Acconsento al trattamento dei dati personali per le finalità di cui alla sezione 3, lett.
          b), dell&#39;{informativa}: svolgere attività di informazione e promozione commerciale e/o
          contattarla per indagare la sua volontà a partecipare a ricerche di mercato e/o ad entrare
          a far parte delle communities Danone, a mezzo, a titolo esemplificativo e non esaustivo,
          e-mail, telefono, sms, mms, Whatsapp, posta ordinaria e newsletter
        </>
      )
    case 'profilazione':
      return (
        <>
          Acconsento al trattamento dei dati personali per lo svolgimento di attività di
          profilazione che consiste nell’analisi sulle abitudini o scelte di consumo e la
          definizione del profilo dell’interessato, di cui alla sezione 3, lett. c), dell&#39;
          {informativa}.
        </>
      )
    case 'datiParticolari':
      return (
        <>
          Acconsento al trattamento dei dati personali, tra questi: i dati sulla mia gravidanza,
          nonché i dati personali del/dei mio/miei figlio/i da parte di Danone Nutricia S.p.A.
          Società Benefit per l'invio di newsletter. Sono consapevole di poter revocare il mio
          consenso e scegliere da quali brand della Società ricevere comunicazioni e newsletter
          direttamente dal mio account accedendo alla sezione “Privacy”, oppure di poter revocare il
          mio consenso cliccando il pulsante di annullamento dell'iscrizione incluso in ogni
          newsletter. Per ulteriori informazioni consultare l’{informativa}
        </>
      )
    case 'statistici':
      return (
        <>
          Acconsento al trattamento dei dati personali da parte di Danone Nutricia S.p.A. Società
          Benefit e al trasferimento egli stessi a Danone Trade B.V. per lo svolgimento di attività
          di analisi statistica in forma anonima, di cui alla sezione 3, lett. d), dell’informativa.
          Sono consapevole di poter revocare il mio consenso direttamente dal mio account accedendo
          alla sezione “Privacy”. Per ulteriori informazioni consultare l&#39;{informativa}.
        </>
      )
    case 'analisiDatiParticolari':
      return (
        <>
          Acconsento al trattamento dei dati personali, tra questi: i dati sulla mia gravidanza,
          nonché i dati personali del/dei mio/miei figlio/i da parte di Danone Nutricia S.p.A.
          Società Benefit e al trasferimento degli stessi a Danone Trade B.V. per lo svolgimento di
          attività di analisi statistica in forma anonima, di cui alla sezione 3, lett. d),
          dell’informativa. Sono consapevole di poter revocare il mio consenso direttamente dal mio
          account accedendo alla sezione “Privacy”. Per ulteriori informazioni, posso consultare l’
          {informativa}.
        </>
      )

    default:
      return null
  }
}

export default OptinLabel
