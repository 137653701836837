import React, { ReactNode } from 'react'
import { FormikErrors } from 'formik'
import { Alert } from 'components/atoms/Icons'
import { FormError, Tooltip } from 'components/atoms'
import { Props as TooltipProps } from 'components/atoms/Tooltip/Tooltip'
import styles from './FormField.module.sass'

export interface Props {
  label?: string
  size?: 'md'
  layout?: 'sm' | 'md' | 'lg' | 'full'
  space?: 'none' | 'sm' | 'md' | 'lg' | 'xl'
  color?: '' | 'white'
  status?: '' | 'success' | 'error'
  errorMessage?: string | string[] | FormikErrors<any> | FormikErrors<any>[]
  className?: string
  helper?: TooltipProps
  required?: boolean
  children?: ReactNode
}

const FormField = (props: Props) => {
  const {
    label = '',
    size = 'md',
    layout = 'md',
    space = 'md',
    color = '',
    status = '',
    errorMessage = '',
    className = '',
    helper = null,
    required = false,
    children = null,
  } = props

  const classNames = `${styles.formField}
    ${size ? styles[`formField--${size}`] : ''}
    ${size ? styles[`formField--layout-${layout}`] : ''}
    ${space ? styles[`formField--space-${space}`] : ''}
    ${color ? styles[`formField--${color}`] : ''}
    ${status ? styles[`formField--${status}`] : ''}
    ${required ? styles[`formField--required`] : ''}
    ${className ? className : ''}`

  return (
    <div className={classNames}>
      <div className={styles.head}>
        {label && <span className={styles.label}>{label}</span>}
        {helper && (
          <Tooltip {...helper}>
            <span className={styles.helper}>
              <Alert />
            </span>
          </Tooltip>
        )}
      </div>
      <div className={styles.children}>{children}</div>
      <FormError message={errorMessage as string} visible={status === 'error'} />
    </div>
  )
}

export default FormField
