import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function CustomareCareIllustration({ className }: Props) {
  return (
    <IconBase name="customare-care-illustration" w={60} h={61} className={className}>
      <g clipPath="url(#customarecareillustration_clip0_2364_670)">
        <path
          d="M49.4221 23.3656C49.3565 18.2894 47.2939 13.4434 43.6811 9.87695C40.0682 6.31053 35.196 4.31079 30.1194 4.31079C25.0427 4.31079 20.1705 6.31053 16.5576 9.87695C12.9448 13.4434 10.8822 18.2894 10.8166 23.3656V25.9464H13.6555V23.3656C13.6555 18.9991 15.3901 14.8115 18.4776 11.7239C21.5652 8.63631 25.7529 6.90173 30.1194 6.90173C34.4858 6.90173 38.6735 8.63631 41.7611 11.7239C44.8486 14.8115 46.5832 18.9991 46.5832 23.3656V25.9464H49.4221V23.3656Z"
          fill="white"
        />
        <path
          d="M49.4221 26.524H46.5832C46.4335 26.524 46.2899 26.4645 46.184 26.3586C46.0781 26.2528 46.0187 26.1092 46.0187 25.9595V23.3787C45.9592 19.2011 44.2579 15.2148 41.2826 12.2817C38.3074 9.34856 34.2973 7.70423 30.1194 7.70423C25.9414 7.70423 21.9313 9.34856 18.9561 12.2817C15.9808 15.2148 14.2796 19.2011 14.2201 23.3787V25.9595C14.2201 26.1092 14.1606 26.2528 14.0547 26.3586C13.9488 26.4645 13.8052 26.524 13.6555 26.524H10.8166C10.6669 26.524 10.5233 26.4645 10.4174 26.3586C10.3116 26.2528 10.2521 26.1092 10.2521 25.9595V23.3787C10.3187 18.1534 12.4411 13.1647 16.1597 9.49318C19.8783 5.82167 24.8937 3.76294 30.1194 3.76294C35.3451 3.76294 40.3604 5.82167 44.079 9.49318C47.7976 13.1647 49.9201 18.1534 49.9866 23.3787V25.9595C49.9866 26.1092 49.9272 26.2528 49.8213 26.3586C49.7154 26.4645 49.5718 26.524 49.4221 26.524ZM47.1478 25.3949H48.8575V23.3658C48.7929 18.4387 46.7902 13.7353 43.2832 10.2739C39.7761 6.8126 35.0469 4.87183 30.1194 4.87183C25.1918 4.87183 20.4626 6.8126 16.9555 10.2739C13.4485 13.7353 11.4458 18.4387 11.3812 23.3658V25.3949H13.091V23.3658C13.1526 18.8901 14.9737 14.6187 18.1605 11.4755C21.3472 8.33233 25.6433 6.57013 30.1194 6.57013C34.5954 6.57013 38.8915 8.33233 42.0782 11.4755C45.265 14.6187 47.0862 18.8901 47.1478 23.3658V25.3949Z"
          fill="#164194"
        />
        <path
          d="M46.6735 47.6865H48.9769C49.8731 47.6865 50.5995 46.96 50.5995 46.0639V27.566C50.5995 26.6698 49.8731 25.9433 48.9769 25.9433H46.6735C45.7773 25.9433 45.0509 26.6698 45.0509 27.566V46.0639C45.0509 46.96 45.7773 47.6865 46.6735 47.6865Z"
          fill="#D3DFF0"
        />
        <path
          d="M48.993 48.251H46.6897C46.1098 48.2501 45.554 48.0194 45.144 47.6094C44.734 47.1994 44.5033 46.6436 44.5024 46.0637V27.5659C44.5033 26.9861 44.734 26.4302 45.144 26.0202C45.554 25.6102 46.1098 25.3795 46.6897 25.3787H48.993C49.5728 25.3795 50.1287 25.6102 50.5387 26.0202C50.9487 26.4302 51.1794 26.9861 51.1802 27.5659V46.0637C51.1794 46.6436 50.9487 47.1994 50.5387 47.6094C50.1287 48.0194 49.5728 48.2501 48.993 48.251ZM46.6897 26.5078C46.409 26.5078 46.1399 26.6192 45.9415 26.8177C45.743 27.0161 45.6315 27.2853 45.6315 27.5659V46.0637C45.6315 46.3444 45.743 46.6135 45.9415 46.8119C46.1399 47.0104 46.409 47.1219 46.6897 47.1219H48.993C49.2736 47.1219 49.5428 47.0104 49.7412 46.8119C49.9397 46.6135 50.0511 46.3444 50.0511 46.0637V27.5659C50.0511 27.2853 49.9397 27.0161 49.7412 26.8177C49.5428 26.6192 49.2736 26.5078 48.993 26.5078H46.6897Z"
          fill="#164194"
        />
        <path
          d="M50.5996 27.8884H51.7287C53.2422 27.8884 54.6938 28.4897 55.764 29.5599C56.8342 30.6301 57.4355 32.0817 57.4355 33.5952V39.6665C57.4355 41.1801 56.8342 42.6316 55.764 43.7018C54.6938 44.7721 53.2422 45.3733 51.7287 45.3733H50.5996V27.8884Z"
          fill="#D3DFF0"
        />
        <path
          d="M51.7287 45.9347H50.606C50.4563 45.9347 50.3127 45.8753 50.2068 45.7694C50.101 45.6635 50.0415 45.5199 50.0415 45.3702V27.8885C50.0415 27.7388 50.101 27.5952 50.2068 27.4893C50.3127 27.3835 50.4563 27.324 50.606 27.324H51.7351C53.3979 27.3257 54.992 27.987 56.1677 29.1627C57.3435 30.3384 58.0047 31.9326 58.0065 33.5953V39.6634C58.0047 41.3272 57.3426 42.9224 56.1654 44.0983C54.9883 45.2742 53.3925 45.9347 51.7287 45.9347ZM51.1641 44.8056H51.7287C53.0909 44.8005 54.3959 44.2571 55.3591 43.2939C56.3224 42.3306 56.8658 41.0256 56.8709 39.6634V33.5953C56.8658 32.2331 56.3224 30.9281 55.3591 29.9648C54.3959 29.0016 53.0909 28.4582 51.7287 28.4531H51.1641V44.8056Z"
          fill="#164194"
        />
        <path
          d="M13.3265 25.9431H11.0231C10.1269 25.9431 9.40042 26.6696 9.40042 27.5658V46.0636C9.40042 46.9598 10.1269 47.6863 11.0231 47.6863H13.3265C14.2226 47.6863 14.9491 46.9598 14.9491 46.0636V27.5658C14.9491 26.6696 14.2226 25.9431 13.3265 25.9431Z"
          fill="#D3DFF0"
        />
        <path
          d="M13.3265 48.251H11.0231C10.4433 48.2501 9.88743 48.0194 9.47743 47.6094C9.06743 47.1994 8.83673 46.6436 8.83588 46.0637V27.5659C8.83673 26.9861 9.06743 26.4302 9.47743 26.0202C9.88743 25.6102 10.4433 25.3795 11.0231 25.3787H13.3265C13.9063 25.3795 14.4621 25.6102 14.8721 26.0202C15.2821 26.4302 15.5128 26.9861 15.5137 27.5659V46.0637C15.5128 46.6436 15.2821 47.1994 14.8721 47.6094C14.4621 48.0194 13.9063 48.2501 13.3265 48.251ZM11.0231 26.5078C10.7425 26.5078 10.4733 26.6192 10.2749 26.8177C10.0765 27.0161 9.96497 27.2853 9.96497 27.5659V46.0637C9.96497 46.3444 10.0765 46.6135 10.2749 46.8119C10.4733 47.0104 10.7425 47.1219 11.0231 47.1219H13.3265C13.6071 47.1219 13.8762 47.0104 14.0747 46.8119C14.2731 46.6135 14.3846 46.3444 14.3846 46.0637V27.5659C14.3846 27.2853 14.2731 27.0161 14.0747 26.8177C13.8762 26.6192 13.6071 26.5078 13.3265 26.5078H11.0231Z"
          fill="#164194"
        />
        <path
          d="M9.40042 45.7412H8.27132C6.75779 45.7412 5.30625 45.14 4.23602 44.0697C3.16579 42.9995 2.56454 41.548 2.56454 40.0344L2.56454 33.9663C2.56454 32.4528 3.16579 31.0013 4.23602 29.931C5.30625 28.8608 6.75779 28.2596 8.27132 28.2596H9.40042L9.40042 45.7412Z"
          fill="#D3DFF0"
        />
        <path
          d="M9.40043 46.3058H8.27133C6.60859 46.3041 5.01443 45.6428 3.8387 44.4671C2.66297 43.2914 2.00171 41.6972 2 40.0345V33.9664C2.00597 32.3064 2.66912 30.7164 3.84439 29.5442C5.01966 28.3719 6.61138 27.7129 8.27133 27.7112H9.40043C9.54628 27.7128 9.68585 27.7708 9.78989 27.873C9.89393 27.9753 9.95436 28.1138 9.95852 28.2596V45.7413C9.95853 45.8899 9.89993 46.0325 9.79545 46.1382C9.69096 46.2439 9.54903 46.3041 9.40043 46.3058ZM8.27133 28.8242C6.90909 28.8293 5.6041 29.3727 4.64085 30.3359C3.6776 31.2992 3.1342 32.6042 3.1291 33.9664V40.0345C3.1342 41.3967 3.6776 42.7017 4.64085 43.6649C5.6041 44.6282 6.90909 45.1716 8.27133 45.1767H8.83588V28.8242H8.27133Z"
          fill="#164194"
        />
        <path
          d="M41.0603 55.0158H36.3213C36.1716 55.0158 36.028 54.9563 35.9221 54.8505C35.8163 54.7446 35.7568 54.601 35.7568 54.4513C35.7568 54.3016 35.8163 54.158 35.9221 54.0521C36.028 53.9462 36.1716 53.8867 36.3213 53.8867H41.0603C42.7042 53.885 44.2803 53.2312 45.4427 52.0688C46.6052 50.9064 47.259 49.3303 47.2607 47.6864C47.2607 47.5366 47.3202 47.393 47.426 47.2872C47.5319 47.1813 47.6755 47.1218 47.8252 47.1218C47.9749 47.1218 48.1185 47.1813 48.2244 47.2872C48.3303 47.393 48.3898 47.5366 48.3898 47.6864C48.3881 49.6297 47.6153 51.493 46.2411 52.8672C44.867 54.2414 43.0037 55.0141 41.0603 55.0158Z"
          fill="#164194"
        />
        <path
          d="M34.3535 51.6704H31.6017C30.5149 51.6704 29.6339 52.5514 29.6339 53.6383V54.4157C29.6339 55.5025 30.5149 56.3836 31.6017 56.3836H34.3535C35.4403 56.3836 36.3213 55.5025 36.3213 54.4157V53.6383C36.3213 52.5514 35.4403 51.6704 34.3535 51.6704Z"
          fill="#F7C5D7"
        />
        <path
          d="M34.3535 56.9482H31.6017C30.9303 56.9474 30.2867 56.6803 29.812 56.2056C29.3372 55.7308 29.0702 55.0872 29.0693 54.4158V53.6384C29.0702 52.967 29.3372 52.3234 29.812 51.8486C30.2867 51.3739 30.9303 51.1068 31.6017 51.106H34.3535C35.0249 51.1068 35.6685 51.3739 36.1432 51.8486C36.6179 52.3234 36.885 52.967 36.8859 53.6384V54.4158C36.885 55.0872 36.6179 55.7308 36.1432 56.2056C35.6685 56.6803 35.0249 56.9474 34.3535 56.9482ZM31.6017 52.2286C31.2295 52.2286 30.8726 52.3765 30.6094 52.6396C30.3463 52.9028 30.1984 53.2597 30.1984 53.6319V54.4094C30.1984 54.7816 30.3463 55.1385 30.6094 55.4017C30.8726 55.6648 31.2295 55.8127 31.6017 55.8127H34.3535C34.7257 55.8127 35.0826 55.6648 35.3458 55.4017C35.6089 55.1385 35.7568 54.7816 35.7568 54.4094V53.6319C35.7568 53.2597 35.6089 52.9028 35.3458 52.6396C35.0826 52.3765 34.7257 52.2286 34.3535 52.2286H31.6017Z"
          fill="#E23B77"
        />
      </g>
      <defs>
        <clipPath id="customarecareillustration_clip0_2364_670">
          <rect width="56" height="53.4482" fill="white" transform="translate(2 3.5)" />
        </clipPath>
      </defs>
    </IconBase>
  )
}

export default CustomareCareIllustration
