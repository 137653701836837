import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  SocialCamelJSON: any;
};

export type AddFiglio = {
  __typename?: 'AddFiglio';
  children?: Maybe<ChildrenNode>;
};

export type AddParametroCurva = {
  __typename?: 'AddParametroCurva';
  curvaCrescita?: Maybe<CurveNode>;
};

export type ArticoloNode = Node & {
  __typename?: 'ArticoloNode';
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  noindex: Scalars['Boolean'];
  titolo: Scalars['String'];
  descrizione?: Maybe<Scalars['String']>;
  mainImage?: Maybe<Scalars['String']>;
  previewImage?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  statoPubblicazione: PaginaStatoPubblicazione;
  visibilitaDal?: Maybe<Scalars['String']>;
  visibilitaAl?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  abstract?: Maybe<Scalars['String']>;
  tipo?: Maybe<Scalars['String']>;
  autore?: Maybe<AutoreNode>;
  /** Mostra il box dei prodotti consigliati a frontend */
  consigliatiVisibili: Scalars['Boolean'];
  ordinamento: Scalars['Int'];
  numeroVisite: Scalars['Int'];
  /** (es. 1-6 o 27) */
  numeroSettimana?: Maybe<Scalars['String']>;
  prodottiConsigliati: ProdottoConsigliatoNodeConnection;
  content?: Maybe<Scalars['String']>;
  ctaLabel?: Maybe<Scalars['String']>;
  categorie: CategoriaNodeConnection;
  prodottiConsigliatiArticoli: ProdottoConsigliatoArticoloNodeConnection;
  pk?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['Int']>;
  adminUrl?: Maybe<Scalars['String']>;
  altMainImage?: Maybe<Scalars['String']>;
  titleMainImage?: Maybe<Scalars['String']>;
  temi?: Maybe<Array<Maybe<ValoreAttributoNode>>>;
  correlati?: Maybe<Array<Maybe<ArticoloNode>>>;
  pagina?: Maybe<PaginaNode>;
  categoria?: Maybe<CategoriaNode>;
  pubblicato?: Maybe<Scalars['Boolean']>;
};


export type ArticoloNodeProdottiConsigliatiArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArticoloNodeCategorieArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArticoloNodeProdottiConsigliatiArticoliArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  articoloId_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ArticoloNodeConnection = {
  __typename?: 'ArticoloNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ArticoloNodeEdge>>;
};

/** A Relay edge containing a `ArticoloNode` and its cursor. */
export type ArticoloNodeEdge = {
  __typename?: 'ArticoloNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ArticoloNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type AutocompleteType = Node & {
  __typename?: 'AutocompleteType';
  /** The ID of the object. */
  id: Scalars['ID'];
  object?: Maybe<SearchObject>;
};

export type AutoreNode = {
  __typename?: 'AutoreNode';
  id: Scalars['ID'];
  titolo?: Maybe<Scalars['String']>;
  abstract?: Maybe<Scalars['String']>;
  previewImage?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  ordinamento: Scalars['Int'];
  attivo: Scalars['Boolean'];
  articoli: ArticoloNodeConnection;
  pk?: Maybe<Scalars['Int']>;
};


export type AutoreNodeArticoliArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  categorie_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BreadcrumbType = {
  __typename?: 'BreadcrumbType';
  label?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Case1Input = {
  isCase1?: Maybe<Scalars['Boolean']>;
  store?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderDate?: Maybe<Scalars['String']>;
  orderEmail?: Maybe<Scalars['String']>;
};

export type Case2Input = {
  purpose?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  request?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['GenericScalar']>;
  contact?: Maybe<Scalars['String']>;
  contactBy?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
};

export type Case3Input = {
  isCase3?: Maybe<Scalars['Boolean']>;
  ean?: Maybe<Scalars['String']>;
  lotto?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['String']>;
};

export type CategoriaFaqNode = {
  __typename?: 'CategoriaFaqNode';
  id: Scalars['ID'];
  alias: Scalars['String'];
  slug: Scalars['String'];
  titolo?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  ordine: Scalars['Int'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  faqs: Array<FaqNode>;
  pk?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type CategoriaNode = Node & {
  __typename?: 'CategoriaNode';
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  noindex: Scalars['Boolean'];
  titolo: Scalars['String'];
  descrizione?: Maybe<Scalars['String']>;
  mainImage?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  statoPubblicazione: PaginaStatoPubblicazione;
  /** Data e ora da quando sarà visibile il contenuto (non obbligatorio) */
  visibilitaDal?: Maybe<Scalars['DateTime']>;
  /** Data e ora fino a quando sarà visibile il contenuto (non obbligatorio) */
  visibilitaAl?: Maybe<Scalars['DateTime']>;
  slug: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  tipo?: Maybe<Scalars['String']>;
  /** Per dare priorità nel calcolo delle breadcrumbs (es. parto e riparto) */
  rilevanza: Scalars['Int'];
  /** Per ordinare (es. trimestri gravidanza) */
  ordinamento: Scalars['Int'];
  articoli?: Maybe<Array<Maybe<ArticoloNode>>>;
  parent?: Maybe<CategoriaNode>;
  slugTree?: Maybe<Scalars['String']>;
  linkBox?: Maybe<LinkBoxNode>;
  categorieProdotti: CategoriaProdottoConsigliatoHubNodeConnection;
  lft: Scalars['Int'];
  rght: Scalars['Int'];
  treeId: Scalars['Int'];
  level: Scalars['Int'];
  children: CategoriaNodeConnection;
  pk?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['Int']>;
  pagina?: Maybe<PaginaNode>;
  paginaHub?: Maybe<PaginaNode>;
  next?: Maybe<CategoriaNode>;
  prev?: Maybe<CategoriaNode>;
};


export type CategoriaNodeCategorieProdottiArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CategoriaNodeChildrenArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CategoriaNodeConnection = {
  __typename?: 'CategoriaNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CategoriaNodeEdge>>;
};

/** A Relay edge containing a `CategoriaNode` and its cursor. */
export type CategoriaNodeEdge = {
  __typename?: 'CategoriaNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CategoriaNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CategoriaProdottoConsigliatoHubNode = Node & {
  __typename?: 'CategoriaProdottoConsigliatoHubNode';
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  noindex: Scalars['Boolean'];
  titolo?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  mainImage?: Maybe<Scalars['String']>;
  previewImage?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  statoPubblicazione: PaginaStatoPubblicazione;
  /** Data e ora da quando sarà visibile il contenuto (non obbligatorio) */
  visibilitaDal?: Maybe<Scalars['DateTime']>;
  /** Data e ora fino a quando sarà visibile il contenuto (non obbligatorio) */
  visibilitaAl?: Maybe<Scalars['DateTime']>;
  slug: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  linkEsterno?: Maybe<Scalars['String']>;
  /** (es. Tutti i nostri latti) */
  labelProdotti?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  ordinamento: Scalars['Int'];
  prodotti?: Maybe<Array<Maybe<ProdottoConsigliatoNode>>>;
  parent?: Maybe<CategoriaProdottoConsigliatoHubNode>;
  video?: Maybe<Scalars['String']>;
  lft: Scalars['Int'];
  rght: Scalars['Int'];
  treeId: Scalars['Int'];
  level: Scalars['Int'];
  children?: Maybe<Array<Maybe<CategoriaProdottoNode>>>;
  categorieProdotti: CategoriaNodeConnection;
  pk?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['Int']>;
  siblings?: Maybe<Array<Maybe<CategoriaProdottoNode>>>;
  pagina?: Maybe<PaginaNode>;
};


export type CategoriaProdottoConsigliatoHubNodeCategorieProdottiArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CategoriaProdottoConsigliatoHubNodeConnection = {
  __typename?: 'CategoriaProdottoConsigliatoHubNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CategoriaProdottoConsigliatoHubNodeEdge>>;
};

/** A Relay edge containing a `CategoriaProdottoConsigliatoHubNode` and its cursor. */
export type CategoriaProdottoConsigliatoHubNodeEdge = {
  __typename?: 'CategoriaProdottoConsigliatoHubNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CategoriaProdottoConsigliatoHubNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CategoriaProdottoNode = Node & {
  __typename?: 'CategoriaProdottoNode';
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  noindex: Scalars['Boolean'];
  titolo: Scalars['String'];
  descrizione?: Maybe<Scalars['String']>;
  mainImage?: Maybe<Scalars['String']>;
  previewImage?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  statoPubblicazione: PaginaStatoPubblicazione;
  /** Data e ora da quando sarà visibile il contenuto (non obbligatorio) */
  visibilitaDal?: Maybe<Scalars['DateTime']>;
  /** Data e ora fino a quando sarà visibile il contenuto (non obbligatorio) */
  visibilitaAl?: Maybe<Scalars['DateTime']>;
  slug: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  linkEsterno?: Maybe<Scalars['String']>;
  /** (es. Tutti i nostri latti) */
  labelProdotti?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  ordinamento: Scalars['Int'];
  prodotti?: Maybe<Array<Maybe<ProdottoConsigliatoNode>>>;
  parent?: Maybe<CategoriaProdottoConsigliatoHubNode>;
  video?: Maybe<Scalars['String']>;
  lft: Scalars['Int'];
  rght: Scalars['Int'];
  treeId: Scalars['Int'];
  level: Scalars['Int'];
  children?: Maybe<Array<Maybe<CategoriaProdottoNode>>>;
  categorieProdotti: CategoriaNodeConnection;
  pk?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['Int']>;
  siblings?: Maybe<Array<Maybe<CategoriaProdottoNode>>>;
  pagina?: Maybe<PaginaNode>;
};


export type CategoriaProdottoNodeCategorieProdottiArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ChildrenInput = {
  gender?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  isTwin?: Maybe<Scalars['Boolean']>;
};

export type ChildrenNode = {
  __typename?: 'ChildrenNode';
  pk?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dataNascita?: Maybe<Scalars['Date']>;
  genderType?: Maybe<Scalars['String']>;
  growthCurve?: Maybe<Array<Maybe<CurveNode>>>;
  isTwin?: Maybe<Scalars['Boolean']>;
};

export type ChildrenUpdate = {
  gender?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  isTwin?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
};

export type ContattoEventoInput = {
  email: Scalars['String'];
  privacy: Scalars['Boolean'];
  evento?: Maybe<Scalars['Int']>;
};

export type ContattoSalesforce = {
  __typename?: 'ContattoSalesforce';
  status?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type ContattoSalesforceInput = {
  case1?: Maybe<Case1Input>;
  case2?: Maybe<Case2Input>;
  case3?: Maybe<Case3Input>;
  personalData1?: Maybe<PersonalData1Input>;
  personalData2?: Maybe<PersonalData2Input>;
  purposeArea?: Maybe<Scalars['String']>;
  optins?: Maybe<UtentePrivacyInput>;
};

export type ContestNode = {
  __typename?: 'ContestNode';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  ctaLabel?: Maybe<Scalars['String']>;
  externalLink?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  visible: Scalars['Boolean'];
  fromDate?: Maybe<Scalars['DateTime']>;
  toDate?: Maybe<Scalars['DateTime']>;
  fileUrl?: Maybe<Scalars['String']>;
};

export type CreateAndActivateUser = {
  __typename?: 'CreateAndActivateUser';
  message?: Maybe<Scalars['GenericScalar']>;
};

export type CreateIndirizzo = {
  __typename?: 'CreateIndirizzo';
  indirizzo?: Maybe<IndirizzoNode>;
};

export type CreateRichiestaContatto = {
  __typename?: 'CreateRichiestaContatto';
  status?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type CreateRichiestaContattoEvento = {
  __typename?: 'CreateRichiestaContattoEvento';
  status?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type CreateRichiestaSpecialized = {
  __typename?: 'CreateRichiestaSpecialized';
  status?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type CreateUtente = {
  __typename?: 'CreateUtente';
  content?: Maybe<Scalars['GenericScalar']>;
};

export type CurveInput = {
  childrenId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  param1?: Maybe<Scalars['Float']>;
  param2?: Maybe<Scalars['Float']>;
};

export type CurveNode = {
  __typename?: 'CurveNode';
  pk?: Maybe<Scalars['String']>;
  insertionDate?: Maybe<Scalars['Date']>;
  growthCurveWeight?: Maybe<Scalars['Float']>;
  growthCurveHeight?: Maybe<Scalars['Float']>;
};

export type CurveUpdate = {
  childrenId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  param1?: Maybe<Scalars['Float']>;
  param2?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
};



export type DeleteFiglio = {
  __typename?: 'DeleteFiglio';
  message?: Maybe<Scalars['GenericScalar']>;
};

export type DeleteIndirizzo = {
  __typename?: 'DeleteIndirizzo';
  message?: Maybe<Scalars['String']>;
};

export type DeleteParametroCurva = {
  __typename?: 'DeleteParametroCurva';
  message?: Maybe<Scalars['GenericScalar']>;
};

export type DeleteUtente = {
  __typename?: 'DeleteUtente';
  message?: Maybe<Scalars['String']>;
};

export type ElementoPaginaType = {
  __typename?: 'ElementoPaginaType';
  id: Scalars['ID'];
  titolo?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  mainImage?: Maybe<Scalars['String']>;
  cta?: Maybe<Scalars['String']>;
  externalUrl?: Maybe<Scalars['String']>;
  blank: Scalars['Boolean'];
  linkedObjectId?: Maybe<Scalars['Int']>;
  pagina: PaginaNode;
  ordinamento: Scalars['Int'];
  attivo: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
};

export type EventoNode = {
  __typename?: 'EventoNode';
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  noindex: Scalars['Boolean'];
  titolo: Scalars['String'];
  descrizione?: Maybe<Scalars['String']>;
  mainImage?: Maybe<Scalars['String']>;
  previewImage?: Maybe<Scalars['String']>;
  statoPubblicazione: PaginaStatoPubblicazione;
  /** Data e ora da quando sarà visibile il contenuto (non obbligatorio) */
  visibilitaDal?: Maybe<Scalars['DateTime']>;
  /** Data e ora fino a quando sarà visibile il contenuto (non obbligatorio) */
  visibilitaAl?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  slug: Scalars['String'];
  abstract?: Maybe<Scalars['String']>;
  noteAggiuntive?: Maybe<Scalars['String']>;
  ordine?: Maybe<Scalars['Int']>;
  form: Scalars['Boolean'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  pk?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  pagina?: Maybe<PaginaNode>;
};

export type FaqNode = {
  __typename?: 'FaqNode';
  id: Scalars['ID'];
  alias: Scalars['String'];
  slug: Scalars['String'];
  domanda?: Maybe<Scalars['String']>;
  risposta?: Maybe<Scalars['String']>;
  categoria: CategoriaFaqNode;
  ordine: Scalars['Int'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  pk?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};


export type GigyaBrandNode = {
  __typename?: 'GigyaBrandNode';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  optinName?: Maybe<Scalars['String']>;
  breadcrumb?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  domain?: Maybe<Scalars['String']>;
  numeroVerde?: Maybe<Scalars['String']>;
  /** Link per monitoraggio ordine */
  b2xMonitoringLink?: Maybe<Scalars['String']>;
  /** Link per pacco in giacenza */
  b2xStockLink?: Maybe<Scalars['String']>;
  linkInformativa?: Maybe<Scalars['String']>;
  isStore: Scalars['Boolean'];
  isMinisito: Scalars['Boolean'];
  pk?: Maybe<Scalars['Int']>;
};

export type InBound = {
  __typename?: 'InBound';
  data?: Maybe<Scalars['GenericScalar']>;
};

export type InBoundInput = {
  authorization: Scalars['String'];
};

export type IndirizzoDeleteInput = {
  pk: Scalars['Int'];
};

export type IndirizzoInput = {
  nome: Scalars['String'];
  cognome: Scalars['String'];
  indirizzo: Scalars['String'];
  default?: Maybe<Scalars['Boolean']>;
  telefono?: Maybe<Scalars['String']>;
  citta: Scalars['String'];
  provincia: Scalars['String'];
  cap: Scalars['String'];
};

export type IndirizzoNode = Node & {
  __typename?: 'IndirizzoNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  nome?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  indirizzo?: Maybe<Scalars['String']>;
  citta?: Maybe<Scalars['String']>;
  provincia?: Maybe<Scalars['String']>;
  cap?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  pk?: Maybe<Scalars['Int']>;
};

export type IndirizzoNodeConnection = {
  __typename?: 'IndirizzoNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IndirizzoNodeEdge>>;
};

/** A Relay edge containing a `IndirizzoNode` and its cursor. */
export type IndirizzoNodeEdge = {
  __typename?: 'IndirizzoNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<IndirizzoNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type IndirizzoUpdateInput = {
  pk: Scalars['Int'];
  nome?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  indirizzo?: Maybe<Scalars['String']>;
  telefono?: Maybe<Scalars['String']>;
  citta?: Maybe<Scalars['String']>;
  provincia?: Maybe<Scalars['String']>;
  cap?: Maybe<Scalars['String']>;
};

export type LinkBoxNode = {
  __typename?: 'LinkBoxNode';
  id: Scalars['ID'];
  titolo?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  cta?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  blank: Scalars['Boolean'];
  /** (es. per box contatti) */
  categorie: CategoriaNodeConnection;
  pk?: Maybe<Scalars['Int']>;
};


export type LinkBoxNodeCategorieArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type Logout = {
  __typename?: 'Logout';
  status?: Maybe<Scalars['Boolean']>;
};

export type MenuItemNode = Node & {
  __typename?: 'MenuItemNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  alias?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  attivo?: Maybe<Scalars['Boolean']>;
  blank: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  nameIt?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
  ctaLabel?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionIt?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageMobile?: Maybe<Scalars['String']>;
  parent?: Maybe<MenuItemNode>;
  url?: Maybe<Scalars['String']>;
  linkedObjectId?: Maybe<Scalars['Int']>;
  /** Id di un elemento o paragrafo all'interno della pagina linkata */
  linkedAnchorId?: Maybe<Scalars['String']>;
  lft: Scalars['Int'];
  rght: Scalars['Int'];
  treeId: Scalars['Int'];
  level: Scalars['Int'];
  children: MenuItemNodeConnection;
  pk?: Maybe<Scalars['Int']>;
  finalUrl?: Maybe<Scalars['String']>;
};


export type MenuItemNodeChildrenArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  parent?: Maybe<Scalars['ID']>;
  parent_Isnull?: Maybe<Scalars['Boolean']>;
  attivo?: Maybe<Scalars['Boolean']>;
};

export type MenuItemNodeConnection = {
  __typename?: 'MenuItemNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MenuItemNodeEdge>>;
};

/** A Relay edge containing a `MenuItemNode` and its cursor. */
export type MenuItemNodeEdge = {
  __typename?: 'MenuItemNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<MenuItemNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum Modello {
  Pagina = 'pagina',
  Articolo = 'articolo',
  Categoria = 'categoria'
}

export type Mutation = {
  __typename?: 'Mutation';
  contattoSalesforce?: Maybe<ContattoSalesforce>;
  visitaArticolo?: Maybe<VisitaArticolo>;
  createRichiestaContattoEvento?: Maybe<CreateRichiestaContattoEvento>;
  createRichiestaContatto?: Maybe<CreateRichiestaContatto>;
  createRichiestaSpecialized?: Maybe<CreateRichiestaSpecialized>;
  createUtente?: Maybe<CreateUtente>;
  createAndActivateUser?: Maybe<CreateAndActivateUser>;
  oldCreateUtente?: Maybe<OldCreateUtente>;
  updateUtente?: Maybe<UpdateUtente>;
  oldUpdateUtente?: Maybe<OldUpdateUtente>;
  updateOptins?: Maybe<UpdateUtenteOptins>;
  updatePassword?: Maybe<UpdatePassword>;
  oldUpdatePassword?: Maybe<OldUpdatePassword>;
  deleteUtente?: Maybe<DeleteUtente>;
  oldDeleteUtente?: Maybe<OldDeleteUtente>;
  createIndirizzo?: Maybe<CreateIndirizzo>;
  updateIndirizzo?: Maybe<UpdateIndirizzo>;
  deleteIndirizzo?: Maybe<DeleteIndirizzo>;
  updateServiceUser?: Maybe<UpdateSubscriptionServiceUser>;
  oldUpdateServiceUser?: Maybe<OldUpdateSubscriptionServiceUser>;
  recuperaPassword?: Maybe<RecuperaPassword>;
  oldRecuperaPassword?: Maybe<OldRecuperaPassword>;
  resetPassword?: Maybe<ResetPassword>;
  oldResetPassword?: Maybe<OldResetPassword>;
  resendActivation?: Maybe<ResendActivation>;
  unsubscribe?: Maybe<Unsubscribe>;
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  oldTokenAuth?: Maybe<OldObtainJsonWebToken>;
  verifyToken?: Maybe<Verify>;
  inbound?: Maybe<InBound>;
  logout?: Maybe<Logout>;
  /** Social Auth for JSON Web Token (JWT) with token_issued signal */
  socialAuth?: Maybe<SocialAuthSignalJwt>;
  addFiglio?: Maybe<AddFiglio>;
  updateFiglio?: Maybe<UpdateFiglio>;
  deleteFiglio?: Maybe<DeleteFiglio>;
  addParametroCurva?: Maybe<AddParametroCurva>;
  updateParametroCurva?: Maybe<UpdateParametroCurva>;
  deleteParametroCurva?: Maybe<DeleteParametroCurva>;
  refreshToken?: Maybe<Refresh>;
  revokeToken?: Maybe<Revoke>;
};


export type MutationContattoSalesforceArgs = {
  input: ContattoSalesforceInput;
};


export type MutationVisitaArticoloArgs = {
  pk: Scalars['Int'];
};


export type MutationCreateRichiestaContattoEventoArgs = {
  input: ContattoEventoInput;
};


export type MutationCreateRichiestaContattoArgs = {
  input: RichiestaContattoInput;
};


export type MutationCreateRichiestaSpecializedArgs = {
  input: RichiestaSpecializedInput;
};


export type MutationCreateUtenteArgs = {
  input: UtenteCreateInput;
};


export type MutationCreateAndActivateUserArgs = {
  input: UtenteCreateInput;
};


export type MutationOldCreateUtenteArgs = {
  input: UtenteInput;
};


export type MutationUpdateUtenteArgs = {
  input: UtenteUpdateInput;
};


export type MutationOldUpdateUtenteArgs = {
  input: OldUtenteUpdateInput;
};


export type MutationUpdateOptinsArgs = {
  optins: UtenteOptinsInput;
};


export type MutationUpdatePasswordArgs = {
  input: PasswordUpdateInput;
};


export type MutationOldUpdatePasswordArgs = {
  input: OldPasswordUpdateInput;
};


export type MutationCreateIndirizzoArgs = {
  input: IndirizzoInput;
};


export type MutationUpdateIndirizzoArgs = {
  input: IndirizzoUpdateInput;
};


export type MutationDeleteIndirizzoArgs = {
  input: IndirizzoDeleteInput;
};


export type MutationUpdateServiceUserArgs = {
  privacyData?: Maybe<UtentePrivacyInput>;
};


export type MutationOldUpdateServiceUserArgs = {
  privacyInformativa?: Maybe<Scalars['Boolean']>;
  privacyProfilazione?: Maybe<Scalars['Boolean']>;
  privacyStatistica?: Maybe<Scalars['Boolean']>;
  value: Scalars['Boolean'];
};


export type MutationRecuperaPasswordArgs = {
  email: Scalars['String'];
};


export type MutationOldRecuperaPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationOldResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationResendActivationArgs = {
  email: Scalars['String'];
};


export type MutationUnsubscribeArgs = {
  input: UnsubscribeInput;
};


export type MutationTokenAuthArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationOldTokenAuthArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationVerifyTokenArgs = {
  token?: Maybe<Scalars['String']>;
};


export type MutationInboundArgs = {
  token: InBoundInput;
};


export type MutationSocialAuthArgs = {
  accessToken: Scalars['String'];
  provider: Scalars['String'];
};


export type MutationAddFiglioArgs = {
  children?: Maybe<ChildrenInput>;
};


export type MutationUpdateFiglioArgs = {
  children?: Maybe<ChildrenUpdate>;
};


export type MutationDeleteFiglioArgs = {
  id?: Maybe<Scalars['String']>;
};


export type MutationAddParametroCurvaArgs = {
  curve?: Maybe<CurveInput>;
};


export type MutationUpdateParametroCurvaArgs = {
  curve?: Maybe<CurveUpdate>;
};


export type MutationDeleteParametroCurvaArgs = {
  childrenId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};


export type MutationRefreshTokenArgs = {
  token?: Maybe<Scalars['String']>;
};


export type MutationRevokeTokenArgs = {
  refreshToken?: Maybe<Scalars['String']>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type ObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  data?: Maybe<Scalars['GenericScalar']>;
  token: Scalars['String'];
};

export type OldCreateUtente = {
  __typename?: 'OldCreateUtente';
  utente?: Maybe<UtenteNode>;
};

export type OldDeleteUtente = {
  __typename?: 'OldDeleteUtente';
  message?: Maybe<Scalars['String']>;
};

export type OldObtainJsonWebToken = {
  __typename?: 'OldObtainJSONWebToken';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  user?: Maybe<UtenteNode>;
  token: Scalars['String'];
};

export type OldPasswordUpdateInput = {
  oldPassword?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['String']>;
  confirmPassword?: Maybe<Scalars['String']>;
};

export type OldRecuperaPassword = {
  __typename?: 'OldRecuperaPassword';
  status?: Maybe<Scalars['Boolean']>;
};

export type OldResetPassword = {
  __typename?: 'OldResetPassword';
  status?: Maybe<Scalars['Boolean']>;
};

export type OldUpdatePassword = {
  __typename?: 'OldUpdatePassword';
  message?: Maybe<Scalars['String']>;
};

export type OldUpdateSubscriptionServiceUser = {
  __typename?: 'OldUpdateSubscriptionServiceUser';
  success?: Maybe<Scalars['Boolean']>;
};

export type OldUpdateUtente = {
  __typename?: 'OldUpdateUtente';
  utente?: Maybe<UtenteNode>;
};

export type OldUtenteUpdateInput = {
  nome?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  dataNascita?: Maybe<Scalars['Date']>;
  mobile?: Maybe<Scalars['String']>;
  sesso?: Maybe<Scalars['String']>;
  privacy?: Maybe<Scalars['Boolean']>;
  privacyCommerciale?: Maybe<Scalars['Boolean']>;
  privacyProfilazione?: Maybe<Scalars['Boolean']>;
  privacyInformativa?: Maybe<Scalars['Boolean']>;
  privacyStatistica?: Maybe<Scalars['Boolean']>;
  socialCompleted?: Maybe<Scalars['Boolean']>;
};

export type OptinNode = {
  __typename?: 'OptinNode';
  breadcrumb?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  optinName?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Boolean']>;
  informativa?: Maybe<Scalars['String']>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type PaginaNode = Node & {
  __typename?: 'PaginaNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionIt?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleIt?: Maybe<Scalars['String']>;
  noindex: Scalars['Boolean'];
  titolo: Scalars['String'];
  titoloIt?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  descrizioneIt?: Maybe<Scalars['String']>;
  mainImage?: Maybe<Scalars['String']>;
  previewImage?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  statoPubblicazione: PaginaStatoPubblicazione;
  /** Data e ora da quando sarà visibile il contenuto (non obbligatorio) */
  visibilitaDal?: Maybe<Scalars['DateTime']>;
  /** Data e ora fino a quando sarà visibile il contenuto (non obbligatorio) */
  visibilitaAl?: Maybe<Scalars['DateTime']>;
  mobileImage?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  slugIt?: Maybe<Scalars['String']>;
  tipologia: PaginaTipologia;
  template?: Maybe<Scalars['String']>;
  parent?: Maybe<PaginaNode>;
  chiave?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  inRicerca: Scalars['Boolean'];
  inSitemap: Scalars['Boolean'];
  site?: Maybe<Scalars['Int']>;
  hubCardTitle?: Maybe<Scalars['String']>;
  hubCardDescription?: Maybe<Scalars['String']>;
  lft: Scalars['Int'];
  rght: Scalars['Int'];
  treeId: Scalars['Int'];
  level: Scalars['Int'];
  children: PaginaNodeConnection;
  elementi?: Maybe<Array<Maybe<ElementoPaginaType>>>;
  pk?: Maybe<Scalars['Int']>;
  model?: Maybe<Scalars['Int']>;
  breadcrumbs?: Maybe<Array<Maybe<BreadcrumbType>>>;
  seo?: Maybe<SeoType>;
  url?: Maybe<Scalars['String']>;
};


export type PaginaNodeChildrenArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  tipologia?: Maybe<Scalars['String']>;
};

export type PaginaNodeConnection = {
  __typename?: 'PaginaNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaginaNodeEdge>>;
};

/** A Relay edge containing a `PaginaNode` and its cursor. */
export type PaginaNodeEdge = {
  __typename?: 'PaginaNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PaginaNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum PaginaStatoPubblicazione {
  /** Pubblicato */
  Pubblicato = 'PUBBLICATO',
  /** Non Pubblicato */
  Nopubblicato = 'NOPUBBLICATO'
}

/** An enumeration. */
export enum PaginaTipologia {
  /** Pagina */
  Page = 'PAGE',
  /** Pagina applicazione */
  PageApp = 'PAGE_APP',
  /** Pagine Entità */
  PageAppMany = 'PAGE_APP_MANY'
}

export type PasswordUpdateInput = {
  password: Scalars['String'];
  newPassword: Scalars['String'];
};

export type PersonalData1Input = {
  isNotRegistered?: Maybe<Scalars['Boolean']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  informativa?: Maybe<Scalars['Boolean']>;
};

export type PersonalData2Input = {
  registration?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  optinMarketing?: Maybe<Scalars['Boolean']>;
  optinProgramMarketing2?: Maybe<Scalars['Boolean']>;
  optinProgramMarketing3?: Maybe<Scalars['Boolean']>;
  optinProgramMarketing4?: Maybe<Scalars['Boolean']>;
};

export type ProdottoConsigliatoArticoloNode = Node & {
  __typename?: 'ProdottoConsigliatoArticoloNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  prodotto?: Maybe<ProdottoConsigliatoNode>;
  articolo?: Maybe<ArticoloNode>;
  ordinamento: Scalars['Int'];
  pk?: Maybe<Scalars['Int']>;
};

export type ProdottoConsigliatoArticoloNodeConnection = {
  __typename?: 'ProdottoConsigliatoArticoloNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProdottoConsigliatoArticoloNodeEdge>>;
};

/** A Relay edge containing a `ProdottoConsigliatoArticoloNode` and its cursor. */
export type ProdottoConsigliatoArticoloNodeEdge = {
  __typename?: 'ProdottoConsigliatoArticoloNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ProdottoConsigliatoArticoloNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ProdottoConsigliatoNode = Node & {
  __typename?: 'ProdottoConsigliatoNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  titolo?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  mainImage?: Maybe<Scalars['String']>;
  cta?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  blank: Scalars['Boolean'];
  ordinamento: Scalars['Int'];
  categorie: CategoriaProdottoConsigliatoHubNodeConnection;
  articoloSet: ArticoloNodeConnection;
  prodottiConsigliatiArticoli: ProdottoConsigliatoArticoloNodeConnection;
  pk?: Maybe<Scalars['Int']>;
  informazioniAggiuntive?: Maybe<Array<Maybe<ValoreAttributoNode>>>;
};


export type ProdottoConsigliatoNodeCategorieArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProdottoConsigliatoNodeArticoloSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  categorie_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type ProdottoConsigliatoNodeProdottiConsigliatiArticoliArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  articoloId_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ProdottoConsigliatoNodeConnection = {
  __typename?: 'ProdottoConsigliatoNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProdottoConsigliatoNodeEdge>>;
};

/** A Relay edge containing a `ProdottoConsigliatoNode` and its cursor. */
export type ProdottoConsigliatoNodeEdge = {
  __typename?: 'ProdottoConsigliatoNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ProdottoConsigliatoNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  search?: Maybe<SearchConnection>;
  autocomplete?: Maybe<Array<Maybe<AutocompleteType>>>;
  contests?: Maybe<Array<Maybe<ContestNode>>>;
  eventi?: Maybe<Array<Maybe<EventoNode>>>;
  evento?: Maybe<EventoNode>;
  categorieFaq?: Maybe<Array<Maybe<CategoriaFaqNode>>>;
  faq?: Maybe<Array<Maybe<FaqNode>>>;
  pagina?: Maybe<PaginaNode>;
  pagine?: Maybe<PaginaNodeConnection>;
  menuitem?: Maybe<MenuItemNode>;
  menuitems?: Maybe<MenuItemNodeConnection>;
  me?: Maybe<UtenteNode>;
  indirizzi?: Maybe<IndirizzoNodeConnection>;
  brands?: Maybe<Array<Maybe<GigyaBrandNode>>>;
  stores?: Maybe<Array<Maybe<GigyaBrandNode>>>;
  minisito?: Maybe<GigyaBrandNode>;
  optins?: Maybe<UtenteOptinsNode>;
  brandOptins?: Maybe<Array<Maybe<OptinNode>>>;
  optin?: Maybe<OptinNode>;
  unsubs?: Maybe<Array<Maybe<UnsubBrandNode>>>;
  getFiglio?: Maybe<ChildrenNode>;
  figliUtente?: Maybe<Array<Maybe<ChildrenNode>>>;
  getCurvaCrescita?: Maybe<Array<Maybe<CurveNode>>>;
  categoriaProdotto?: Maybe<CategoriaProdottoNode>;
  prodottoConsigliato?: Maybe<ProdottoConsigliatoNode>;
  prodottiConsigliati?: Maybe<ProdottoConsigliatoNodeConnection>;
  categoriaProdottiConsigliatiHub?: Maybe<CategoriaProdottoConsigliatoHubNodeConnection>;
  categoria?: Maybe<CategoriaNode>;
  categorie?: Maybe<CategoriaNodeConnection>;
  articolo?: Maybe<ArticoloNode>;
  articoli?: Maybe<ArticoloNodeConnection>;
  articoliHub?: Maybe<ArticoloNodeConnection>;
  autori?: Maybe<Array<AutoreNode>>;
  prodottiConsigliatiArticoli?: Maybe<ProdottoConsigliatoArticoloNodeConnection>;
};


export type QuerySearchArgs = {
  query: Scalars['String'];
  models?: Maybe<Array<Maybe<Modello>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAutocompleteArgs = {
  query: Scalars['String'];
  models?: Maybe<Array<Maybe<Modello>>>;
};


export type QueryEventoArgs = {
  slug: Scalars['String'];
};


export type QueryPaginaArgs = {
  chiave?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  contentTypeId?: Maybe<Scalars['Int']>;
  objectId?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['Boolean']>;
};


export type QueryPagineArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  tipologia?: Maybe<Scalars['String']>;
};


export type QueryMenuitemArgs = {
  alias?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};


export type QueryMenuitemsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  parent?: Maybe<Scalars['ID']>;
  parent_Isnull?: Maybe<Scalars['Boolean']>;
  attivo?: Maybe<Scalars['Boolean']>;
};


export type QueryIndirizziArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  nome?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  indirizzo?: Maybe<Scalars['String']>;
  citta?: Maybe<Scalars['String']>;
  provincia?: Maybe<Scalars['String']>;
  cap?: Maybe<Scalars['String']>;
  utente?: Maybe<Scalars['ID']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
};


export type QueryOptinArgs = {
  domain?: Maybe<Scalars['String']>;
};


export type QueryGetFiglioArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryGetCurvaCrescitaArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryCategoriaProdottoArgs = {
  slug: Scalars['String'];
};


export type QueryProdottoConsigliatoArgs = {
  pk: Scalars['ID'];
};


export type QueryProdottiConsigliatiArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCategoriaProdottiConsigliatiHubArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  categorieProdotti_ElementiPagina_Pagina_Id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
};


export type QueryCategoriaArgs = {
  slugTree: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
};


export type QueryCategorieArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryArticoloArgs = {
  slug: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
};


export type QueryArticoliArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  categorie_ElementiPagina_Pagina_Id?: Maybe<Scalars['Float']>;
  categorie_Id?: Maybe<Scalars['ID']>;
  tipo?: Maybe<Scalars['String']>;
};


export type QueryArticoliHubArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  categorie_ElementiPagina_Pagina_Id?: Maybe<Scalars['Float']>;
  categorie_Id?: Maybe<Scalars['ID']>;
  tipo?: Maybe<Scalars['String']>;
};


export type QueryProdottiConsigliatiArticoliArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  articoloId_In?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type RecuperaPassword = {
  __typename?: 'RecuperaPassword';
  content?: Maybe<Scalars['GenericScalar']>;
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

export type ResendActivation = {
  __typename?: 'ResendActivation';
  content?: Maybe<Scalars['GenericScalar']>;
};

export type ResetPassword = {
  __typename?: 'ResetPassword';
  status?: Maybe<Scalars['Boolean']>;
};

export type Revoke = {
  __typename?: 'Revoke';
  revoked: Scalars['Int'];
};

export type RichiestaContattoInput = {
  nome: Scalars['String'];
  cognome: Scalars['String'];
  email: Scalars['String'];
  argomento: Scalars['String'];
  domanda: Scalars['String'];
  telefono?: Maybe<Scalars['String']>;
  privacyCareline: Scalars['Boolean'];
  captcha: Scalars['String'];
  site: Scalars['String'];
};

export type RichiestaSpecializedInput = {
  email: Scalars['String'];
  captcha: Scalars['String'];
  site: Scalars['String'];
  termini: Scalars['Boolean'];
};

export type SearchConnection = {
  __typename?: 'SearchConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SearchEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  suggestion?: Maybe<Scalars['String']>;
};


export type SearchConnectionSuggestionArgs = {
  query: Scalars['String'];
};

/** A Relay edge containing a `Search` and its cursor. */
export type SearchEdge = {
  __typename?: 'SearchEdge';
  /** The item at the end of the edge */
  node?: Maybe<SearchType>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type SearchObject = PaginaNode | ArticoloNode | CategoriaNode | FaqNode;

export type SearchType = Node & {
  __typename?: 'SearchType';
  /** The ID of the object. */
  id: Scalars['ID'];
  object?: Maybe<SearchObject>;
  modelName?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
};

export type SeoType = {
  __typename?: 'SeoType';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  noindex?: Maybe<Scalars['Boolean']>;
};

/** Social Auth for JSON Web Token (JWT) with token_issued signal */
export type SocialAuthSignalJwt = {
  __typename?: 'SocialAuthSignalJWT';
  social?: Maybe<SocialType>;
  token?: Maybe<Scalars['String']>;
};


export type SocialType = {
  __typename?: 'SocialType';
  id: Scalars['ID'];
  provider: Scalars['String'];
  uid: Scalars['String'];
  extraData?: Maybe<Scalars['SocialCamelJSON']>;
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
};

export type UnsubBrandNode = {
  __typename?: 'UnsubBrandNode';
  name?: Maybe<Scalars['String']>;
  optinName?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Boolean']>;
};

export type Unsubscribe = {
  __typename?: 'Unsubscribe';
  content?: Maybe<Scalars['GenericScalar']>;
};

export type UnsubscribeInput = {
  token?: Maybe<Scalars['String']>;
  preferences?: Maybe<Scalars['GenericScalar']>;
};

export type UpdateFiglio = {
  __typename?: 'UpdateFiglio';
  children?: Maybe<ChildrenNode>;
};

export type UpdateIndirizzo = {
  __typename?: 'UpdateIndirizzo';
  indirizzo?: Maybe<IndirizzoNode>;
};

export type UpdateParametroCurva = {
  __typename?: 'UpdateParametroCurva';
  curvaCrescita?: Maybe<CurveNode>;
};

export type UpdatePassword = {
  __typename?: 'UpdatePassword';
  message?: Maybe<Scalars['String']>;
};

export type UpdateSubscriptionServiceUser = {
  __typename?: 'UpdateSubscriptionServiceUser';
  message?: Maybe<Scalars['GenericScalar']>;
};

export type UpdateUtente = {
  __typename?: 'UpdateUtente';
  utente?: Maybe<UtenteNode>;
};

export type UpdateUtenteOptins = {
  __typename?: 'UpdateUtenteOptins';
  message?: Maybe<Scalars['GenericScalar']>;
};

export type UtenteCreateInput = {
  gender?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  email1: Scalars['String'];
  password: Scalars['String'];
  birthday: Scalars['Date'];
  birthdayMonth?: Maybe<Scalars['Int']>;
  birthdayDay?: Maybe<Scalars['Int']>;
  phoneMobile?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Maybe<ChildrenInput>>>;
  optins?: Maybe<UtentePrivacyInput>;
};

export type UtenteInput = {
  nome: Scalars['String'];
  cognome: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  giornoNascita?: Maybe<Scalars['String']>;
  meseNascita?: Maybe<Scalars['String']>;
  annoNascita?: Maybe<Scalars['String']>;
  dataNascita?: Maybe<Scalars['Date']>;
  mobile?: Maybe<Scalars['String']>;
  telefono?: Maybe<Scalars['String']>;
  sesso?: Maybe<Scalars['String']>;
  privacy: Scalars['Boolean'];
  privacyCommerciale?: Maybe<Scalars['Boolean']>;
  privacyProfilazione?: Maybe<Scalars['Boolean']>;
  privacyStatistica?: Maybe<Scalars['Boolean']>;
  privacyInformativa?: Maybe<Scalars['Boolean']>;
  isActive: Scalars['Boolean'];
};

export type UtenteNode = {
  __typename?: 'UtenteNode';
  pk?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Boolean']>;
  isStaff?: Maybe<Scalars['Boolean']>;
  edit?: Maybe<Scalars['Boolean']>;
  isImpersonate?: Maybe<Scalars['Boolean']>;
  stopImpersonateUrl?: Maybe<Scalars['String']>;
  sesso?: Maybe<Scalars['String']>;
  hashEmail?: Maybe<Scalars['String']>;
  dataNascita?: Maybe<Scalars['Date']>;
  mobile?: Maybe<Scalars['String']>;
  isPregnant?: Maybe<Scalars['Boolean']>;
  figli?: Maybe<Array<Maybe<ChildrenNode>>>;
  ordini?: Maybe<Array<Maybe<Scalars['GenericScalar']>>>;
  privacy?: Maybe<Scalars['Boolean']>;
  privacyCommerciale?: Maybe<Scalars['Boolean']>;
  privacyInformativa?: Maybe<Scalars['Boolean']>;
  privacyProfilazione?: Maybe<Scalars['Boolean']>;
  privacyStatistica?: Maybe<Scalars['Boolean']>;
};

export type UtenteOptinsInput = {
  optinActimel?: Maybe<Scalars['Boolean']>;
  optinActivia?: Maybe<Scalars['Boolean']>;
  optinAlpro?: Maybe<Scalars['Boolean']>;
  optinAptamil?: Maybe<Scalars['Boolean']>;
  optinDanacol?: Maybe<Scalars['Boolean']>;
  optinDanette?: Maybe<Scalars['Boolean']>;
  optinDanoneKids?: Maybe<Scalars['Boolean']>;
  optinHipro?: Maybe<Scalars['Boolean']>;
  optinMellin?: Maybe<Scalars['Boolean']>;
  optinNutricia?: Maybe<Scalars['Boolean']>;
  optinMarketing?: Maybe<Scalars['Boolean']>;
  optinProgramMarketing1?: Maybe<Scalars['Boolean']>;
  optinProgramMarketing2?: Maybe<Scalars['Boolean']>;
  optinProgramMarketing3?: Maybe<Scalars['Boolean']>;
  optinProgramMarketing4?: Maybe<Scalars['Boolean']>;
};

export type UtenteOptinsNode = {
  __typename?: 'UtenteOptinsNode';
  optinBrands?: Maybe<Array<Maybe<OptinNode>>>;
  optinMarketing?: Maybe<Scalars['String']>;
  optinProgramMarketing1?: Maybe<Scalars['String']>;
  optinProgramMarketing2?: Maybe<Scalars['String']>;
  optinProgramMarketing3?: Maybe<Scalars['String']>;
  optinProgramMarketing4?: Maybe<Scalars['String']>;
};

export type UtentePrivacyInput = {
  optinMarketing?: Maybe<Scalars['Boolean']>;
  optinProgramMarketing1?: Maybe<Scalars['Boolean']>;
  optinProgramMarketing2?: Maybe<Scalars['Boolean']>;
  optinProgramMarketing3?: Maybe<Scalars['Boolean']>;
  optinProgramMarketing4?: Maybe<Scalars['Boolean']>;
  optinActimel?: Maybe<Scalars['Boolean']>;
  optinActivia?: Maybe<Scalars['Boolean']>;
  optinAlpro?: Maybe<Scalars['Boolean']>;
  optinAptamil?: Maybe<Scalars['Boolean']>;
  optinDanacol?: Maybe<Scalars['Boolean']>;
  optinDanette?: Maybe<Scalars['Boolean']>;
  optinDanoneKids?: Maybe<Scalars['Boolean']>;
  optinHipro?: Maybe<Scalars['Boolean']>;
  optinMellin?: Maybe<Scalars['Boolean']>;
  optinNutricia?: Maybe<Scalars['Boolean']>;
};

export type UtenteUpdateInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  birthdayMonth?: Maybe<Scalars['Int']>;
  birthdayDay?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['String']>;
  phoneMobile?: Maybe<Scalars['String']>;
  optins?: Maybe<UtentePrivacyInput>;
};

export type ValoreAttributoNode = {
  __typename?: 'ValoreAttributoNode';
  id: Scalars['ID'];
  nome: Scalars['String'];
  chiave: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  parent?: Maybe<ValoreAttributoNode>;
  descrizione?: Maybe<Scalars['String']>;
  ordine: Scalars['Int'];
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  prodotti: ProdottoConsigliatoNodeConnection;
  articoli: ArticoloNodeConnection;
  children: Array<ValoreAttributoNode>;
  pk?: Maybe<Scalars['Int']>;
};


export type ValoreAttributoNodeProdottiArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ValoreAttributoNodeArticoliArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  categorie_Id_In?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar'];
};

export type VisitaArticolo = {
  __typename?: 'VisitaArticolo';
  status?: Maybe<Scalars['Boolean']>;
};

export type ArticoliCorrelatiQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ArticoliCorrelatiQuery = (
  { __typename?: 'Query' }
  & { articolo?: Maybe<(
    { __typename?: 'ArticoloNode' }
    & { correlati?: Maybe<Array<Maybe<(
      { __typename?: 'ArticoloNode' }
      & Pick<ArticoloNode, 'pk' | 'titolo' | 'descrizione' | 'previewImage' | 'numeroSettimana' | 'url' | 'tipo'>
      & { temi?: Maybe<Array<Maybe<(
        { __typename?: 'ValoreAttributoNode' }
        & Pick<ValoreAttributoNode, 'pk' | 'nome'>
      )>>> }
    )>>> }
  )> }
);

export type ProdottiConsigliatiQueryVariables = Exact<{
  articoloId_In?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type ProdottiConsigliatiQuery = (
  { __typename?: 'Query' }
  & { prodottiConsigliatiArticoli?: Maybe<(
    { __typename?: 'ProdottoConsigliatoArticoloNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'ProdottoConsigliatoArticoloNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'ProdottoConsigliatoArticoloNode' }
        & { prodotto?: Maybe<(
          { __typename?: 'ProdottoConsigliatoNode' }
          & Pick<ProdottoConsigliatoNode, 'pk' | 'titolo' | 'descrizione' | 'mainImage' | 'cta' | 'link' | 'blank'>
        )> }
      )> }
    )>> }
  )> }
);

export type ProdottiConsigliatiAllQueryVariables = Exact<{ [key: string]: never; }>;


export type ProdottiConsigliatiAllQuery = (
  { __typename?: 'Query' }
  & { prodottiConsigliati?: Maybe<(
    { __typename?: 'ProdottoConsigliatoNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'ProdottoConsigliatoNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'ProdottoConsigliatoNode' }
        & Pick<ProdottoConsigliatoNode, 'pk' | 'titolo' | 'descrizione' | 'mainImage' | 'cta' | 'link' | 'blank'>
      )> }
    )>> }
  )> }
);

export type ArticoliHubQueryVariables = Exact<{
  categorie_ElementiPagina_Pagina_Id?: Maybe<Scalars['Float']>;
  tipo?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type ArticoliHubQuery = (
  { __typename?: 'Query' }
  & { articoliHub?: Maybe<(
    { __typename?: 'ArticoloNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'ArticoloNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'ArticoloNode' }
        & Pick<ArticoloNode, 'pk' | 'titolo' | 'abstract' | 'url' | 'previewImage'>
        & { temi?: Maybe<Array<Maybe<(
          { __typename?: 'ValoreAttributoNode' }
          & Pick<ValoreAttributoNode, 'pk' | 'nome'>
        )>>> }
      )> }
    )>> }
  )> }
);

export type VisitaArticoloMutationVariables = Exact<{
  pk: Scalars['Int'];
}>;


export type VisitaArticoloMutation = (
  { __typename?: 'Mutation' }
  & { visitaArticolo?: Maybe<(
    { __typename?: 'VisitaArticolo' }
    & Pick<VisitaArticolo, 'status'>
  )> }
);

export type CategoriaSettimanaQueryVariables = Exact<{
  slugTree: Scalars['String'];
}>;


export type CategoriaSettimanaQuery = (
  { __typename?: 'Query' }
  & { categoria?: Maybe<(
    { __typename?: 'CategoriaNode' }
    & Pick<CategoriaNode, 'pk'>
    & { articoli?: Maybe<Array<Maybe<(
      { __typename?: 'ArticoloNode' }
      & Pick<ArticoloNode, 'pk' | 'titolo' | 'abstract' | 'url' | 'previewImage' | 'numeroSettimana'>
      & { temi?: Maybe<Array<Maybe<(
        { __typename?: 'ValoreAttributoNode' }
        & Pick<ValoreAttributoNode, 'pk' | 'nome'>
      )>>> }
    )>>>, next?: Maybe<(
      { __typename?: 'CategoriaNode' }
      & Pick<CategoriaNode, 'titolo' | 'url'>
    )>, prev?: Maybe<(
      { __typename?: 'CategoriaNode' }
      & Pick<CategoriaNode, 'titolo' | 'url'>
    )> }
  )> }
);

export type CreateRichiestaContattoMutationVariables = Exact<{
  input: RichiestaContattoInput;
}>;


export type CreateRichiestaContattoMutation = (
  { __typename?: 'Mutation' }
  & { createRichiestaContatto?: Maybe<(
    { __typename?: 'CreateRichiestaContatto' }
    & Pick<CreateRichiestaContatto, 'status' | 'message'>
  )> }
);

export type ContattoSalesforceMutationVariables = Exact<{
  input: ContattoSalesforceInput;
}>;


export type ContattoSalesforceMutation = (
  { __typename?: 'Mutation' }
  & { contattoSalesforce?: Maybe<(
    { __typename?: 'ContattoSalesforce' }
    & Pick<ContattoSalesforce, 'status' | 'message'>
  )> }
);

export type GetCurvaCrescitaQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCurvaCrescitaQuery = (
  { __typename?: 'Query' }
  & { getCurvaCrescita?: Maybe<Array<Maybe<(
    { __typename?: 'CurveNode' }
    & Pick<CurveNode, 'pk' | 'growthCurveWeight' | 'growthCurveHeight' | 'insertionDate'>
  )>>> }
);

export type AddParametroCurvaMutationVariables = Exact<{
  curve: CurveInput;
}>;


export type AddParametroCurvaMutation = (
  { __typename?: 'Mutation' }
  & { addParametroCurva?: Maybe<(
    { __typename?: 'AddParametroCurva' }
    & { curvaCrescita?: Maybe<(
      { __typename?: 'CurveNode' }
      & Pick<CurveNode, 'pk' | 'growthCurveWeight' | 'growthCurveHeight' | 'insertionDate'>
    )> }
  )> }
);

export type UpdateParametroCurvaMutationVariables = Exact<{
  curve: CurveUpdate;
}>;


export type UpdateParametroCurvaMutation = (
  { __typename?: 'Mutation' }
  & { updateParametroCurva?: Maybe<(
    { __typename?: 'UpdateParametroCurva' }
    & { curvaCrescita?: Maybe<(
      { __typename?: 'CurveNode' }
      & Pick<CurveNode, 'pk' | 'growthCurveWeight' | 'growthCurveHeight' | 'insertionDate'>
    )> }
  )> }
);

export type DeleteParametroCurvaMutationVariables = Exact<{
  id: Scalars['String'];
  childrenId: Scalars['String'];
}>;


export type DeleteParametroCurvaMutation = (
  { __typename?: 'Mutation' }
  & { deleteParametroCurva?: Maybe<(
    { __typename?: 'DeleteParametroCurva' }
    & Pick<DeleteParametroCurva, 'message'>
  )> }
);

export type AddFiglioMutationVariables = Exact<{
  children: ChildrenInput;
}>;


export type AddFiglioMutation = (
  { __typename?: 'Mutation' }
  & { addFiglio?: Maybe<(
    { __typename?: 'AddFiglio' }
    & { children?: Maybe<(
      { __typename?: 'ChildrenNode' }
      & Pick<ChildrenNode, 'firstName' | 'lastName' | 'dataNascita' | 'isTwin' | 'pk'>
    )> }
  )> }
);

export type GetFigliUtenteQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFigliUtenteQuery = (
  { __typename?: 'Query' }
  & { figliUtente?: Maybe<Array<Maybe<(
    { __typename?: 'ChildrenNode' }
    & Pick<ChildrenNode, 'pk' | 'firstName' | 'lastName' | 'dataNascita' | 'isTwin'>
  )>>> }
);

export type GetFiglioQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetFiglioQuery = (
  { __typename?: 'Query' }
  & { getFiglio?: Maybe<(
    { __typename?: 'ChildrenNode' }
    & Pick<ChildrenNode, 'pk' | 'firstName' | 'lastName' | 'dataNascita' | 'genderType' | 'isTwin'>
  )> }
);

export type GetFiglioWithCurveQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetFiglioWithCurveQuery = (
  { __typename?: 'Query' }
  & { getFiglio?: Maybe<(
    { __typename?: 'ChildrenNode' }
    & Pick<ChildrenNode, 'firstName' | 'lastName' | 'dataNascita' | 'pk' | 'genderType' | 'isTwin'>
    & { growthCurve?: Maybe<Array<Maybe<(
      { __typename?: 'CurveNode' }
      & Pick<CurveNode, 'pk' | 'insertionDate' | 'growthCurveWeight' | 'growthCurveHeight'>
    )>>> }
  )> }
);

export type UpdateFiglioMutationVariables = Exact<{
  children: ChildrenUpdate;
}>;


export type UpdateFiglioMutation = (
  { __typename?: 'Mutation' }
  & { updateFiglio?: Maybe<(
    { __typename?: 'UpdateFiglio' }
    & { children?: Maybe<(
      { __typename?: 'ChildrenNode' }
      & Pick<ChildrenNode, 'pk' | 'firstName' | 'dataNascita' | 'genderType' | 'isTwin'>
    )> }
  )> }
);

export type DeleteFiglioMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteFiglioMutation = (
  { __typename?: 'Mutation' }
  & { deleteFiglio?: Maybe<(
    { __typename?: 'DeleteFiglio' }
    & Pick<DeleteFiglio, 'message'>
  )> }
);

export type SearchQueryVariables = Exact<{
  query: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
}>;


export type SearchQuery = (
  { __typename?: 'Query' }
  & { search?: Maybe<(
    { __typename?: 'SearchConnection' }
    & Pick<SearchConnection, 'totalCount' | 'count' | 'suggestion'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges: Array<Maybe<(
      { __typename?: 'SearchEdge' }
      & { node?: Maybe<(
        { __typename?: 'SearchType' }
        & Pick<SearchType, 'modelName' | 'score' | 'url'>
        & { object?: Maybe<(
          { __typename?: 'PaginaNode' }
          & Pick<PaginaNode, 'pk' | 'titolo' | 'descrizione' | 'url' | 'mainImage'>
        ) | (
          { __typename?: 'ArticoloNode' }
          & Pick<ArticoloNode, 'pk' | 'titolo' | 'descrizione' | 'abstract' | 'url' | 'mainImage' | 'numeroSettimana'>
          & { categoria?: Maybe<(
            { __typename?: 'CategoriaNode' }
            & Pick<CategoriaNode, 'titolo'>
          )> }
        ) | (
          { __typename?: 'CategoriaNode' }
          & Pick<CategoriaNode, 'pk' | 'titolo' | 'descrizione' | 'url' | 'mainImage'>
        ) | { __typename?: 'FaqNode' }> }
      )> }
    )>> }
  )> }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { tokenAuth?: Maybe<(
    { __typename?: 'ObtainJSONWebToken' }
    & Pick<ObtainJsonWebToken, 'data'>
  )> }
);

export type InBoundMutationVariables = Exact<{
  token: InBoundInput;
}>;


export type InBoundMutation = (
  { __typename?: 'Mutation' }
  & { inbound?: Maybe<(
    { __typename?: 'InBound' }
    & Pick<InBound, 'data'>
  )> }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & { logout?: Maybe<(
    { __typename?: 'Logout' }
    & Pick<Logout, 'status'>
  )> }
);

export type UpdatePasswordMutationVariables = Exact<{
  input: PasswordUpdateInput;
}>;


export type UpdatePasswordMutation = (
  { __typename?: 'Mutation' }
  & { updatePassword?: Maybe<(
    { __typename?: 'UpdatePassword' }
    & Pick<UpdatePassword, 'message'>
  )> }
);

export type CreateUtenteMutationVariables = Exact<{
  input: UtenteCreateInput;
}>;


export type CreateUtenteMutation = (
  { __typename?: 'Mutation' }
  & { createUtente?: Maybe<(
    { __typename?: 'CreateUtente' }
    & Pick<CreateUtente, 'content'>
  )> }
);

export type SocialAuthMutationVariables = Exact<{
  provider: Scalars['String'];
  accessToken: Scalars['String'];
}>;


export type SocialAuthMutation = (
  { __typename?: 'Mutation' }
  & { socialAuth?: Maybe<(
    { __typename?: 'SocialAuthSignalJWT' }
    & Pick<SocialAuthSignalJwt, 'token'>
    & { social?: Maybe<(
      { __typename?: 'SocialType' }
      & Pick<SocialType, 'uid'>
    )> }
  )> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'UtenteNode' }
    & Pick<UtenteNode, 'pk' | 'email' | 'nome' | 'cognome' | 'completed' | 'isStaff' | 'edit' | 'isImpersonate' | 'stopImpersonateUrl' | 'sesso' | 'hashEmail' | 'dataNascita' | 'ordini'>
  )> }
);

export type GetUtenteQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUtenteQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'UtenteNode' }
    & Pick<UtenteNode, 'pk' | 'nome' | 'cognome' | 'hashEmail' | 'sesso' | 'dataNascita' | 'mobile' | 'isPregnant'>
    & { figli?: Maybe<Array<Maybe<(
      { __typename?: 'ChildrenNode' }
      & Pick<ChildrenNode, 'pk' | 'firstName' | 'lastName' | 'dataNascita' | 'genderType'>
    )>>> }
  )> }
);

export type GetEmailPrivacyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmailPrivacyQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'UtenteNode' }
    & Pick<UtenteNode, 'email' | 'privacy' | 'privacyCommerciale' | 'privacyInformativa' | 'privacyProfilazione'>
  )> }
);

export type BrandsQueryVariables = Exact<{ [key: string]: never; }>;


export type BrandsQuery = (
  { __typename?: 'Query' }
  & { brands?: Maybe<Array<Maybe<(
    { __typename?: 'GigyaBrandNode' }
    & Pick<GigyaBrandNode, 'pk' | 'name' | 'image' | 'url' | 'numeroVerde'>
  )>>> }
);

export type StoresQueryVariables = Exact<{ [key: string]: never; }>;


export type StoresQuery = (
  { __typename?: 'Query' }
  & { stores?: Maybe<Array<Maybe<(
    { __typename?: 'GigyaBrandNode' }
    & Pick<GigyaBrandNode, 'pk' | 'name' | 'image' | 'url' | 'numeroVerde' | 'b2xMonitoringLink' | 'b2xStockLink'>
  )>>> }
);

export type UpdateServiceUserMutationVariables = Exact<{
  privacyData: UtentePrivacyInput;
}>;


export type UpdateServiceUserMutation = (
  { __typename?: 'Mutation' }
  & { updateServiceUser?: Maybe<(
    { __typename?: 'UpdateSubscriptionServiceUser' }
    & Pick<UpdateSubscriptionServiceUser, 'message'>
  )> }
);

export type DeleteUtenteMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteUtenteMutation = (
  { __typename?: 'Mutation' }
  & { deleteUtente?: Maybe<(
    { __typename?: 'DeleteUtente' }
    & Pick<DeleteUtente, 'message'>
  )> }
);

export type RecuperaPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RecuperaPasswordMutation = (
  { __typename?: 'Mutation' }
  & { recuperaPassword?: Maybe<(
    { __typename?: 'RecuperaPassword' }
    & Pick<RecuperaPassword, 'content'>
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename?: 'ResetPassword' }
    & Pick<ResetPassword, 'status'>
  )> }
);

export type ResendActivationMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResendActivationMutation = (
  { __typename?: 'Mutation' }
  & { resendActivation?: Maybe<(
    { __typename?: 'ResendActivation' }
    & Pick<ResendActivation, 'content'>
  )> }
);

export type OptinsQueryVariables = Exact<{ [key: string]: never; }>;


export type OptinsQuery = (
  { __typename?: 'Query' }
  & { optins?: Maybe<(
    { __typename?: 'UtenteOptinsNode' }
    & Pick<UtenteOptinsNode, 'optinMarketing' | 'optinProgramMarketing1' | 'optinProgramMarketing2' | 'optinProgramMarketing3' | 'optinProgramMarketing4'>
    & { optinBrands?: Maybe<Array<Maybe<(
      { __typename?: 'OptinNode' }
      & Pick<OptinNode, 'image' | 'name' | 'optinName' | 'url' | 'value'>
    )>>> }
  )> }
);

export type OptinQueryVariables = Exact<{
  domain: Scalars['String'];
}>;


export type OptinQuery = (
  { __typename?: 'Query' }
  & { optin?: Maybe<(
    { __typename?: 'OptinNode' }
    & Pick<OptinNode, 'breadcrumb' | 'image' | 'name' | 'value' | 'optinName' | 'url'>
  )> }
);

export type UpdatePrivacyMutationVariables = Exact<{
  input: UtenteUpdateInput;
}>;


export type UpdatePrivacyMutation = (
  { __typename?: 'Mutation' }
  & { updateUtente?: Maybe<(
    { __typename?: 'UpdateUtente' }
    & { utente?: Maybe<(
      { __typename?: 'UtenteNode' }
      & Pick<UtenteNode, 'privacy' | 'privacyCommerciale' | 'privacyInformativa' | 'privacyProfilazione'>
    )> }
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  input: UtenteUpdateInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUtente?: Maybe<(
    { __typename?: 'UpdateUtente' }
    & { utente?: Maybe<(
      { __typename?: 'UtenteNode' }
      & Pick<UtenteNode, 'pk' | 'nome' | 'cognome' | 'sesso' | 'dataNascita' | 'mobile'>
    )> }
  )> }
);

export type UpdateVividanoneUserMutationVariables = Exact<{
  input: UtenteUpdateInput;
}>;


export type UpdateVividanoneUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUtente?: Maybe<(
    { __typename?: 'UpdateUtente' }
    & { utente?: Maybe<(
      { __typename?: 'UtenteNode' }
      & Pick<UtenteNode, 'pk' | 'nome' | 'cognome'>
    )> }
  )> }
);

export type UpdateUtenteOptinsMutationVariables = Exact<{
  optins: UtenteOptinsInput;
}>;


export type UpdateUtenteOptinsMutation = (
  { __typename?: 'Mutation' }
  & { updateOptins?: Maybe<(
    { __typename?: 'UpdateUtenteOptins' }
    & Pick<UpdateUtenteOptins, 'message'>
  )> }
);

export type UnsubscribeMutationVariables = Exact<{
  input: UnsubscribeInput;
}>;


export type UnsubscribeMutation = (
  { __typename?: 'Mutation' }
  & { unsubscribe?: Maybe<(
    { __typename?: 'Unsubscribe' }
    & Pick<Unsubscribe, 'content'>
  )> }
);

export type UnsubsQueryVariables = Exact<{ [key: string]: never; }>;


export type UnsubsQuery = (
  { __typename?: 'Query' }
  & { unsubs?: Maybe<Array<Maybe<(
    { __typename?: 'UnsubBrandNode' }
    & Pick<UnsubBrandNode, 'name' | 'optinName' | 'image' | 'value'>
  )>>> }
);

export type BrandOptinsQueryVariables = Exact<{ [key: string]: never; }>;


export type BrandOptinsQuery = (
  { __typename?: 'Query' }
  & { brandOptins?: Maybe<Array<Maybe<(
    { __typename?: 'OptinNode' }
    & Pick<OptinNode, 'image' | 'name' | 'optinName' | 'url' | 'value' | 'informativa'>
  )>>> }
);


export const ArticoliCorrelatiDocument = gql`
    query ArticoliCorrelati($slug: String!) {
  articolo(slug: $slug) {
    correlati {
      pk
      titolo
      descrizione
      previewImage
      numeroSettimana
      url
      tipo
      temi {
        pk
        nome
      }
    }
  }
}
    `;

/**
 * __useArticoliCorrelatiQuery__
 *
 * To run a query within a React component, call `useArticoliCorrelatiQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticoliCorrelatiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticoliCorrelatiQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useArticoliCorrelatiQuery(baseOptions: Apollo.QueryHookOptions<ArticoliCorrelatiQuery, ArticoliCorrelatiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticoliCorrelatiQuery, ArticoliCorrelatiQueryVariables>(ArticoliCorrelatiDocument, options);
      }
export function useArticoliCorrelatiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticoliCorrelatiQuery, ArticoliCorrelatiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticoliCorrelatiQuery, ArticoliCorrelatiQueryVariables>(ArticoliCorrelatiDocument, options);
        }
export type ArticoliCorrelatiQueryHookResult = ReturnType<typeof useArticoliCorrelatiQuery>;
export type ArticoliCorrelatiLazyQueryHookResult = ReturnType<typeof useArticoliCorrelatiLazyQuery>;
export type ArticoliCorrelatiQueryResult = Apollo.QueryResult<ArticoliCorrelatiQuery, ArticoliCorrelatiQueryVariables>;
export const ProdottiConsigliatiDocument = gql`
    query ProdottiConsigliati($articoloId_In: [ID]) {
  prodottiConsigliatiArticoli(articoloId_In: $articoloId_In) {
    edges {
      node {
        prodotto {
          pk
          titolo
          descrizione
          mainImage
          cta
          link
          blank
        }
      }
    }
  }
}
    `;

/**
 * __useProdottiConsigliatiQuery__
 *
 * To run a query within a React component, call `useProdottiConsigliatiQuery` and pass it any options that fit your needs.
 * When your component renders, `useProdottiConsigliatiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProdottiConsigliatiQuery({
 *   variables: {
 *      articoloId_In: // value for 'articoloId_In'
 *   },
 * });
 */
export function useProdottiConsigliatiQuery(baseOptions?: Apollo.QueryHookOptions<ProdottiConsigliatiQuery, ProdottiConsigliatiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProdottiConsigliatiQuery, ProdottiConsigliatiQueryVariables>(ProdottiConsigliatiDocument, options);
      }
export function useProdottiConsigliatiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProdottiConsigliatiQuery, ProdottiConsigliatiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProdottiConsigliatiQuery, ProdottiConsigliatiQueryVariables>(ProdottiConsigliatiDocument, options);
        }
export type ProdottiConsigliatiQueryHookResult = ReturnType<typeof useProdottiConsigliatiQuery>;
export type ProdottiConsigliatiLazyQueryHookResult = ReturnType<typeof useProdottiConsigliatiLazyQuery>;
export type ProdottiConsigliatiQueryResult = Apollo.QueryResult<ProdottiConsigliatiQuery, ProdottiConsigliatiQueryVariables>;
export const ProdottiConsigliatiAllDocument = gql`
    query ProdottiConsigliatiAll {
  prodottiConsigliati {
    edges {
      node {
        pk
        titolo
        descrizione
        mainImage
        cta
        link
        blank
      }
    }
  }
}
    `;

/**
 * __useProdottiConsigliatiAllQuery__
 *
 * To run a query within a React component, call `useProdottiConsigliatiAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useProdottiConsigliatiAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProdottiConsigliatiAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useProdottiConsigliatiAllQuery(baseOptions?: Apollo.QueryHookOptions<ProdottiConsigliatiAllQuery, ProdottiConsigliatiAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProdottiConsigliatiAllQuery, ProdottiConsigliatiAllQueryVariables>(ProdottiConsigliatiAllDocument, options);
      }
export function useProdottiConsigliatiAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProdottiConsigliatiAllQuery, ProdottiConsigliatiAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProdottiConsigliatiAllQuery, ProdottiConsigliatiAllQueryVariables>(ProdottiConsigliatiAllDocument, options);
        }
export type ProdottiConsigliatiAllQueryHookResult = ReturnType<typeof useProdottiConsigliatiAllQuery>;
export type ProdottiConsigliatiAllLazyQueryHookResult = ReturnType<typeof useProdottiConsigliatiAllLazyQuery>;
export type ProdottiConsigliatiAllQueryResult = Apollo.QueryResult<ProdottiConsigliatiAllQuery, ProdottiConsigliatiAllQueryVariables>;
export const ArticoliHubDocument = gql`
    query ArticoliHub($categorie_ElementiPagina_Pagina_Id: Float, $tipo: String, $first: Int) {
  articoliHub(
    categorie_ElementiPagina_Pagina_Id: $categorie_ElementiPagina_Pagina_Id
    tipo: $tipo
    first: $first
  ) {
    edges {
      node {
        pk
        titolo
        abstract
        url
        previewImage
        temi {
          pk
          nome
        }
      }
    }
  }
}
    `;

/**
 * __useArticoliHubQuery__
 *
 * To run a query within a React component, call `useArticoliHubQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticoliHubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticoliHubQuery({
 *   variables: {
 *      categorie_ElementiPagina_Pagina_Id: // value for 'categorie_ElementiPagina_Pagina_Id'
 *      tipo: // value for 'tipo'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useArticoliHubQuery(baseOptions?: Apollo.QueryHookOptions<ArticoliHubQuery, ArticoliHubQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticoliHubQuery, ArticoliHubQueryVariables>(ArticoliHubDocument, options);
      }
export function useArticoliHubLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticoliHubQuery, ArticoliHubQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticoliHubQuery, ArticoliHubQueryVariables>(ArticoliHubDocument, options);
        }
export type ArticoliHubQueryHookResult = ReturnType<typeof useArticoliHubQuery>;
export type ArticoliHubLazyQueryHookResult = ReturnType<typeof useArticoliHubLazyQuery>;
export type ArticoliHubQueryResult = Apollo.QueryResult<ArticoliHubQuery, ArticoliHubQueryVariables>;
export const VisitaArticoloDocument = gql`
    mutation VisitaArticolo($pk: Int!) {
  visitaArticolo(pk: $pk) {
    status
  }
}
    `;
export type VisitaArticoloMutationFn = Apollo.MutationFunction<VisitaArticoloMutation, VisitaArticoloMutationVariables>;

/**
 * __useVisitaArticoloMutation__
 *
 * To run a mutation, you first call `useVisitaArticoloMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVisitaArticoloMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [visitaArticoloMutation, { data, loading, error }] = useVisitaArticoloMutation({
 *   variables: {
 *      pk: // value for 'pk'
 *   },
 * });
 */
export function useVisitaArticoloMutation(baseOptions?: Apollo.MutationHookOptions<VisitaArticoloMutation, VisitaArticoloMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VisitaArticoloMutation, VisitaArticoloMutationVariables>(VisitaArticoloDocument, options);
      }
export type VisitaArticoloMutationHookResult = ReturnType<typeof useVisitaArticoloMutation>;
export type VisitaArticoloMutationResult = Apollo.MutationResult<VisitaArticoloMutation>;
export type VisitaArticoloMutationOptions = Apollo.BaseMutationOptions<VisitaArticoloMutation, VisitaArticoloMutationVariables>;
export const CategoriaSettimanaDocument = gql`
    query CategoriaSettimana($slugTree: String!) {
  categoria(slugTree: $slugTree) {
    pk
    articoli {
      pk
      titolo
      abstract
      url
      previewImage
      numeroSettimana
      temi {
        pk
        nome
      }
    }
    next {
      titolo
      url
    }
    prev {
      titolo
      url
    }
  }
}
    `;

/**
 * __useCategoriaSettimanaQuery__
 *
 * To run a query within a React component, call `useCategoriaSettimanaQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriaSettimanaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriaSettimanaQuery({
 *   variables: {
 *      slugTree: // value for 'slugTree'
 *   },
 * });
 */
export function useCategoriaSettimanaQuery(baseOptions: Apollo.QueryHookOptions<CategoriaSettimanaQuery, CategoriaSettimanaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriaSettimanaQuery, CategoriaSettimanaQueryVariables>(CategoriaSettimanaDocument, options);
      }
export function useCategoriaSettimanaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriaSettimanaQuery, CategoriaSettimanaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriaSettimanaQuery, CategoriaSettimanaQueryVariables>(CategoriaSettimanaDocument, options);
        }
export type CategoriaSettimanaQueryHookResult = ReturnType<typeof useCategoriaSettimanaQuery>;
export type CategoriaSettimanaLazyQueryHookResult = ReturnType<typeof useCategoriaSettimanaLazyQuery>;
export type CategoriaSettimanaQueryResult = Apollo.QueryResult<CategoriaSettimanaQuery, CategoriaSettimanaQueryVariables>;
export const CreateRichiestaContattoDocument = gql`
    mutation CreateRichiestaContatto($input: RichiestaContattoInput!) {
  createRichiestaContatto(input: $input) {
    status
    message
  }
}
    `;
export type CreateRichiestaContattoMutationFn = Apollo.MutationFunction<CreateRichiestaContattoMutation, CreateRichiestaContattoMutationVariables>;

/**
 * __useCreateRichiestaContattoMutation__
 *
 * To run a mutation, you first call `useCreateRichiestaContattoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRichiestaContattoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRichiestaContattoMutation, { data, loading, error }] = useCreateRichiestaContattoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRichiestaContattoMutation(baseOptions?: Apollo.MutationHookOptions<CreateRichiestaContattoMutation, CreateRichiestaContattoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRichiestaContattoMutation, CreateRichiestaContattoMutationVariables>(CreateRichiestaContattoDocument, options);
      }
export type CreateRichiestaContattoMutationHookResult = ReturnType<typeof useCreateRichiestaContattoMutation>;
export type CreateRichiestaContattoMutationResult = Apollo.MutationResult<CreateRichiestaContattoMutation>;
export type CreateRichiestaContattoMutationOptions = Apollo.BaseMutationOptions<CreateRichiestaContattoMutation, CreateRichiestaContattoMutationVariables>;
export const ContattoSalesforceDocument = gql`
    mutation ContattoSalesforce($input: ContattoSalesforceInput!) {
  contattoSalesforce(input: $input) {
    status
    message
  }
}
    `;
export type ContattoSalesforceMutationFn = Apollo.MutationFunction<ContattoSalesforceMutation, ContattoSalesforceMutationVariables>;

/**
 * __useContattoSalesforceMutation__
 *
 * To run a mutation, you first call `useContattoSalesforceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContattoSalesforceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contattoSalesforceMutation, { data, loading, error }] = useContattoSalesforceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContattoSalesforceMutation(baseOptions?: Apollo.MutationHookOptions<ContattoSalesforceMutation, ContattoSalesforceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContattoSalesforceMutation, ContattoSalesforceMutationVariables>(ContattoSalesforceDocument, options);
      }
export type ContattoSalesforceMutationHookResult = ReturnType<typeof useContattoSalesforceMutation>;
export type ContattoSalesforceMutationResult = Apollo.MutationResult<ContattoSalesforceMutation>;
export type ContattoSalesforceMutationOptions = Apollo.BaseMutationOptions<ContattoSalesforceMutation, ContattoSalesforceMutationVariables>;
export const GetCurvaCrescitaDocument = gql`
    query GetCurvaCrescita($id: String!) {
  getCurvaCrescita(id: $id) {
    pk
    growthCurveWeight
    growthCurveHeight
    insertionDate
  }
}
    `;

/**
 * __useGetCurvaCrescitaQuery__
 *
 * To run a query within a React component, call `useGetCurvaCrescitaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurvaCrescitaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurvaCrescitaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCurvaCrescitaQuery(baseOptions: Apollo.QueryHookOptions<GetCurvaCrescitaQuery, GetCurvaCrescitaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurvaCrescitaQuery, GetCurvaCrescitaQueryVariables>(GetCurvaCrescitaDocument, options);
      }
export function useGetCurvaCrescitaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurvaCrescitaQuery, GetCurvaCrescitaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurvaCrescitaQuery, GetCurvaCrescitaQueryVariables>(GetCurvaCrescitaDocument, options);
        }
export type GetCurvaCrescitaQueryHookResult = ReturnType<typeof useGetCurvaCrescitaQuery>;
export type GetCurvaCrescitaLazyQueryHookResult = ReturnType<typeof useGetCurvaCrescitaLazyQuery>;
export type GetCurvaCrescitaQueryResult = Apollo.QueryResult<GetCurvaCrescitaQuery, GetCurvaCrescitaQueryVariables>;
export const AddParametroCurvaDocument = gql`
    mutation AddParametroCurva($curve: CurveInput!) {
  addParametroCurva(curve: $curve) {
    curvaCrescita {
      pk
      growthCurveWeight
      growthCurveHeight
      insertionDate
    }
  }
}
    `;
export type AddParametroCurvaMutationFn = Apollo.MutationFunction<AddParametroCurvaMutation, AddParametroCurvaMutationVariables>;

/**
 * __useAddParametroCurvaMutation__
 *
 * To run a mutation, you first call `useAddParametroCurvaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddParametroCurvaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addParametroCurvaMutation, { data, loading, error }] = useAddParametroCurvaMutation({
 *   variables: {
 *      curve: // value for 'curve'
 *   },
 * });
 */
export function useAddParametroCurvaMutation(baseOptions?: Apollo.MutationHookOptions<AddParametroCurvaMutation, AddParametroCurvaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddParametroCurvaMutation, AddParametroCurvaMutationVariables>(AddParametroCurvaDocument, options);
      }
export type AddParametroCurvaMutationHookResult = ReturnType<typeof useAddParametroCurvaMutation>;
export type AddParametroCurvaMutationResult = Apollo.MutationResult<AddParametroCurvaMutation>;
export type AddParametroCurvaMutationOptions = Apollo.BaseMutationOptions<AddParametroCurvaMutation, AddParametroCurvaMutationVariables>;
export const UpdateParametroCurvaDocument = gql`
    mutation UpdateParametroCurva($curve: CurveUpdate!) {
  updateParametroCurva(curve: $curve) {
    curvaCrescita {
      pk
      growthCurveWeight
      growthCurveHeight
      insertionDate
    }
  }
}
    `;
export type UpdateParametroCurvaMutationFn = Apollo.MutationFunction<UpdateParametroCurvaMutation, UpdateParametroCurvaMutationVariables>;

/**
 * __useUpdateParametroCurvaMutation__
 *
 * To run a mutation, you first call `useUpdateParametroCurvaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParametroCurvaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParametroCurvaMutation, { data, loading, error }] = useUpdateParametroCurvaMutation({
 *   variables: {
 *      curve: // value for 'curve'
 *   },
 * });
 */
export function useUpdateParametroCurvaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateParametroCurvaMutation, UpdateParametroCurvaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateParametroCurvaMutation, UpdateParametroCurvaMutationVariables>(UpdateParametroCurvaDocument, options);
      }
export type UpdateParametroCurvaMutationHookResult = ReturnType<typeof useUpdateParametroCurvaMutation>;
export type UpdateParametroCurvaMutationResult = Apollo.MutationResult<UpdateParametroCurvaMutation>;
export type UpdateParametroCurvaMutationOptions = Apollo.BaseMutationOptions<UpdateParametroCurvaMutation, UpdateParametroCurvaMutationVariables>;
export const DeleteParametroCurvaDocument = gql`
    mutation DeleteParametroCurva($id: String!, $childrenId: String!) {
  deleteParametroCurva(id: $id, childrenId: $childrenId) {
    message
  }
}
    `;
export type DeleteParametroCurvaMutationFn = Apollo.MutationFunction<DeleteParametroCurvaMutation, DeleteParametroCurvaMutationVariables>;

/**
 * __useDeleteParametroCurvaMutation__
 *
 * To run a mutation, you first call `useDeleteParametroCurvaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteParametroCurvaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteParametroCurvaMutation, { data, loading, error }] = useDeleteParametroCurvaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      childrenId: // value for 'childrenId'
 *   },
 * });
 */
export function useDeleteParametroCurvaMutation(baseOptions?: Apollo.MutationHookOptions<DeleteParametroCurvaMutation, DeleteParametroCurvaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteParametroCurvaMutation, DeleteParametroCurvaMutationVariables>(DeleteParametroCurvaDocument, options);
      }
export type DeleteParametroCurvaMutationHookResult = ReturnType<typeof useDeleteParametroCurvaMutation>;
export type DeleteParametroCurvaMutationResult = Apollo.MutationResult<DeleteParametroCurvaMutation>;
export type DeleteParametroCurvaMutationOptions = Apollo.BaseMutationOptions<DeleteParametroCurvaMutation, DeleteParametroCurvaMutationVariables>;
export const AddFiglioDocument = gql`
    mutation AddFiglio($children: ChildrenInput!) {
  addFiglio(children: $children) {
    children {
      firstName
      lastName
      dataNascita
      isTwin
      pk
    }
  }
}
    `;
export type AddFiglioMutationFn = Apollo.MutationFunction<AddFiglioMutation, AddFiglioMutationVariables>;

/**
 * __useAddFiglioMutation__
 *
 * To run a mutation, you first call `useAddFiglioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFiglioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFiglioMutation, { data, loading, error }] = useAddFiglioMutation({
 *   variables: {
 *      children: // value for 'children'
 *   },
 * });
 */
export function useAddFiglioMutation(baseOptions?: Apollo.MutationHookOptions<AddFiglioMutation, AddFiglioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFiglioMutation, AddFiglioMutationVariables>(AddFiglioDocument, options);
      }
export type AddFiglioMutationHookResult = ReturnType<typeof useAddFiglioMutation>;
export type AddFiglioMutationResult = Apollo.MutationResult<AddFiglioMutation>;
export type AddFiglioMutationOptions = Apollo.BaseMutationOptions<AddFiglioMutation, AddFiglioMutationVariables>;
export const GetFigliUtenteDocument = gql`
    query GetFigliUtente {
  figliUtente {
    pk
    firstName
    lastName
    dataNascita
    isTwin
  }
}
    `;

/**
 * __useGetFigliUtenteQuery__
 *
 * To run a query within a React component, call `useGetFigliUtenteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFigliUtenteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFigliUtenteQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFigliUtenteQuery(baseOptions?: Apollo.QueryHookOptions<GetFigliUtenteQuery, GetFigliUtenteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFigliUtenteQuery, GetFigliUtenteQueryVariables>(GetFigliUtenteDocument, options);
      }
export function useGetFigliUtenteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFigliUtenteQuery, GetFigliUtenteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFigliUtenteQuery, GetFigliUtenteQueryVariables>(GetFigliUtenteDocument, options);
        }
export type GetFigliUtenteQueryHookResult = ReturnType<typeof useGetFigliUtenteQuery>;
export type GetFigliUtenteLazyQueryHookResult = ReturnType<typeof useGetFigliUtenteLazyQuery>;
export type GetFigliUtenteQueryResult = Apollo.QueryResult<GetFigliUtenteQuery, GetFigliUtenteQueryVariables>;
export const GetFiglioDocument = gql`
    query GetFiglio($id: String!) {
  getFiglio(id: $id) {
    pk
    firstName
    lastName
    dataNascita
    genderType
    isTwin
  }
}
    `;

/**
 * __useGetFiglioQuery__
 *
 * To run a query within a React component, call `useGetFiglioQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFiglioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFiglioQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFiglioQuery(baseOptions: Apollo.QueryHookOptions<GetFiglioQuery, GetFiglioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFiglioQuery, GetFiglioQueryVariables>(GetFiglioDocument, options);
      }
export function useGetFiglioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFiglioQuery, GetFiglioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFiglioQuery, GetFiglioQueryVariables>(GetFiglioDocument, options);
        }
export type GetFiglioQueryHookResult = ReturnType<typeof useGetFiglioQuery>;
export type GetFiglioLazyQueryHookResult = ReturnType<typeof useGetFiglioLazyQuery>;
export type GetFiglioQueryResult = Apollo.QueryResult<GetFiglioQuery, GetFiglioQueryVariables>;
export const GetFiglioWithCurveDocument = gql`
    query GetFiglioWithCurve($id: String!) {
  getFiglio(id: $id) {
    firstName
    lastName
    dataNascita
    pk
    genderType
    isTwin
    growthCurve {
      pk
      insertionDate
      growthCurveWeight
      growthCurveHeight
    }
  }
}
    `;

/**
 * __useGetFiglioWithCurveQuery__
 *
 * To run a query within a React component, call `useGetFiglioWithCurveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFiglioWithCurveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFiglioWithCurveQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFiglioWithCurveQuery(baseOptions: Apollo.QueryHookOptions<GetFiglioWithCurveQuery, GetFiglioWithCurveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFiglioWithCurveQuery, GetFiglioWithCurveQueryVariables>(GetFiglioWithCurveDocument, options);
      }
export function useGetFiglioWithCurveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFiglioWithCurveQuery, GetFiglioWithCurveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFiglioWithCurveQuery, GetFiglioWithCurveQueryVariables>(GetFiglioWithCurveDocument, options);
        }
export type GetFiglioWithCurveQueryHookResult = ReturnType<typeof useGetFiglioWithCurveQuery>;
export type GetFiglioWithCurveLazyQueryHookResult = ReturnType<typeof useGetFiglioWithCurveLazyQuery>;
export type GetFiglioWithCurveQueryResult = Apollo.QueryResult<GetFiglioWithCurveQuery, GetFiglioWithCurveQueryVariables>;
export const UpdateFiglioDocument = gql`
    mutation UpdateFiglio($children: ChildrenUpdate!) {
  updateFiglio(children: $children) {
    children {
      pk
      firstName
      dataNascita
      genderType
      isTwin
    }
  }
}
    `;
export type UpdateFiglioMutationFn = Apollo.MutationFunction<UpdateFiglioMutation, UpdateFiglioMutationVariables>;

/**
 * __useUpdateFiglioMutation__
 *
 * To run a mutation, you first call `useUpdateFiglioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFiglioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFiglioMutation, { data, loading, error }] = useUpdateFiglioMutation({
 *   variables: {
 *      children: // value for 'children'
 *   },
 * });
 */
export function useUpdateFiglioMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFiglioMutation, UpdateFiglioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFiglioMutation, UpdateFiglioMutationVariables>(UpdateFiglioDocument, options);
      }
export type UpdateFiglioMutationHookResult = ReturnType<typeof useUpdateFiglioMutation>;
export type UpdateFiglioMutationResult = Apollo.MutationResult<UpdateFiglioMutation>;
export type UpdateFiglioMutationOptions = Apollo.BaseMutationOptions<UpdateFiglioMutation, UpdateFiglioMutationVariables>;
export const DeleteFiglioDocument = gql`
    mutation DeleteFiglio($id: String!) {
  deleteFiglio(id: $id) {
    message
  }
}
    `;
export type DeleteFiglioMutationFn = Apollo.MutationFunction<DeleteFiglioMutation, DeleteFiglioMutationVariables>;

/**
 * __useDeleteFiglioMutation__
 *
 * To run a mutation, you first call `useDeleteFiglioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFiglioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFiglioMutation, { data, loading, error }] = useDeleteFiglioMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFiglioMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFiglioMutation, DeleteFiglioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFiglioMutation, DeleteFiglioMutationVariables>(DeleteFiglioDocument, options);
      }
export type DeleteFiglioMutationHookResult = ReturnType<typeof useDeleteFiglioMutation>;
export type DeleteFiglioMutationResult = Apollo.MutationResult<DeleteFiglioMutation>;
export type DeleteFiglioMutationOptions = Apollo.BaseMutationOptions<DeleteFiglioMutation, DeleteFiglioMutationVariables>;
export const SearchDocument = gql`
    query Search($query: String!, $first: Int, $last: Int, $after: String, $before: String) {
  search(
    query: $query
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
    count
    suggestion(query: $query)
    edges {
      node {
        object {
          ... on ArticoloNode {
            pk
            titolo
            descrizione
            abstract
            url
            mainImage
            numeroSettimana
            categoria {
              titolo
            }
          }
          ... on CategoriaNode {
            pk
            titolo
            descrizione
            url
            mainImage
          }
          ... on PaginaNode {
            pk
            titolo
            descrizione
            url
            mainImage
          }
        }
        modelName
        score
        url
      }
    }
  }
}
    `;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
      }
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  tokenAuth(email: $email, password: $password) {
    data
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const InBoundDocument = gql`
    mutation InBound($token: InBoundInput!) {
  inbound(token: $token) {
    data
  }
}
    `;
export type InBoundMutationFn = Apollo.MutationFunction<InBoundMutation, InBoundMutationVariables>;

/**
 * __useInBoundMutation__
 *
 * To run a mutation, you first call `useInBoundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInBoundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inBoundMutation, { data, loading, error }] = useInBoundMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useInBoundMutation(baseOptions?: Apollo.MutationHookOptions<InBoundMutation, InBoundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InBoundMutation, InBoundMutationVariables>(InBoundDocument, options);
      }
export type InBoundMutationHookResult = ReturnType<typeof useInBoundMutation>;
export type InBoundMutationResult = Apollo.MutationResult<InBoundMutation>;
export type InBoundMutationOptions = Apollo.BaseMutationOptions<InBoundMutation, InBoundMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    status
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($input: PasswordUpdateInput!) {
  updatePassword(input: $input) {
    message
  }
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const CreateUtenteDocument = gql`
    mutation CreateUtente($input: UtenteCreateInput!) {
  createUtente(input: $input) {
    content
  }
}
    `;
export type CreateUtenteMutationFn = Apollo.MutationFunction<CreateUtenteMutation, CreateUtenteMutationVariables>;

/**
 * __useCreateUtenteMutation__
 *
 * To run a mutation, you first call `useCreateUtenteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUtenteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUtenteMutation, { data, loading, error }] = useCreateUtenteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUtenteMutation(baseOptions?: Apollo.MutationHookOptions<CreateUtenteMutation, CreateUtenteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUtenteMutation, CreateUtenteMutationVariables>(CreateUtenteDocument, options);
      }
export type CreateUtenteMutationHookResult = ReturnType<typeof useCreateUtenteMutation>;
export type CreateUtenteMutationResult = Apollo.MutationResult<CreateUtenteMutation>;
export type CreateUtenteMutationOptions = Apollo.BaseMutationOptions<CreateUtenteMutation, CreateUtenteMutationVariables>;
export const SocialAuthDocument = gql`
    mutation SocialAuth($provider: String!, $accessToken: String!) {
  socialAuth(provider: $provider, accessToken: $accessToken) {
    social {
      uid
    }
    token
  }
}
    `;
export type SocialAuthMutationFn = Apollo.MutationFunction<SocialAuthMutation, SocialAuthMutationVariables>;

/**
 * __useSocialAuthMutation__
 *
 * To run a mutation, you first call `useSocialAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSocialAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [socialAuthMutation, { data, loading, error }] = useSocialAuthMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useSocialAuthMutation(baseOptions?: Apollo.MutationHookOptions<SocialAuthMutation, SocialAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SocialAuthMutation, SocialAuthMutationVariables>(SocialAuthDocument, options);
      }
export type SocialAuthMutationHookResult = ReturnType<typeof useSocialAuthMutation>;
export type SocialAuthMutationResult = Apollo.MutationResult<SocialAuthMutation>;
export type SocialAuthMutationOptions = Apollo.BaseMutationOptions<SocialAuthMutation, SocialAuthMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    pk
    email
    nome
    cognome
    completed
    isStaff
    edit
    isImpersonate
    stopImpersonateUrl
    sesso
    hashEmail
    dataNascita
    ordini
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const GetUtenteDocument = gql`
    query GetUtente {
  me {
    pk
    nome
    cognome
    hashEmail
    sesso
    dataNascita
    mobile
    isPregnant
    figli {
      pk
      firstName
      lastName
      dataNascita
      genderType
    }
  }
}
    `;

/**
 * __useGetUtenteQuery__
 *
 * To run a query within a React component, call `useGetUtenteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUtenteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUtenteQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUtenteQuery(baseOptions?: Apollo.QueryHookOptions<GetUtenteQuery, GetUtenteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUtenteQuery, GetUtenteQueryVariables>(GetUtenteDocument, options);
      }
export function useGetUtenteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUtenteQuery, GetUtenteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUtenteQuery, GetUtenteQueryVariables>(GetUtenteDocument, options);
        }
export type GetUtenteQueryHookResult = ReturnType<typeof useGetUtenteQuery>;
export type GetUtenteLazyQueryHookResult = ReturnType<typeof useGetUtenteLazyQuery>;
export type GetUtenteQueryResult = Apollo.QueryResult<GetUtenteQuery, GetUtenteQueryVariables>;
export const GetEmailPrivacyDocument = gql`
    query GetEmailPrivacy {
  me {
    email
    privacy
    privacyCommerciale
    privacyInformativa
    privacyProfilazione
  }
}
    `;

/**
 * __useGetEmailPrivacyQuery__
 *
 * To run a query within a React component, call `useGetEmailPrivacyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailPrivacyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailPrivacyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmailPrivacyQuery(baseOptions?: Apollo.QueryHookOptions<GetEmailPrivacyQuery, GetEmailPrivacyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailPrivacyQuery, GetEmailPrivacyQueryVariables>(GetEmailPrivacyDocument, options);
      }
export function useGetEmailPrivacyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailPrivacyQuery, GetEmailPrivacyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailPrivacyQuery, GetEmailPrivacyQueryVariables>(GetEmailPrivacyDocument, options);
        }
export type GetEmailPrivacyQueryHookResult = ReturnType<typeof useGetEmailPrivacyQuery>;
export type GetEmailPrivacyLazyQueryHookResult = ReturnType<typeof useGetEmailPrivacyLazyQuery>;
export type GetEmailPrivacyQueryResult = Apollo.QueryResult<GetEmailPrivacyQuery, GetEmailPrivacyQueryVariables>;
export const BrandsDocument = gql`
    query Brands {
  brands {
    pk
    name
    image
    url
    numeroVerde
  }
}
    `;

/**
 * __useBrandsQuery__
 *
 * To run a query within a React component, call `useBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrandsQuery(baseOptions?: Apollo.QueryHookOptions<BrandsQuery, BrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandsQuery, BrandsQueryVariables>(BrandsDocument, options);
      }
export function useBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandsQuery, BrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandsQuery, BrandsQueryVariables>(BrandsDocument, options);
        }
export type BrandsQueryHookResult = ReturnType<typeof useBrandsQuery>;
export type BrandsLazyQueryHookResult = ReturnType<typeof useBrandsLazyQuery>;
export type BrandsQueryResult = Apollo.QueryResult<BrandsQuery, BrandsQueryVariables>;
export const StoresDocument = gql`
    query Stores {
  stores {
    pk
    name
    image
    url
    numeroVerde
    b2xMonitoringLink
    b2xStockLink
  }
}
    `;

/**
 * __useStoresQuery__
 *
 * To run a query within a React component, call `useStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoresQuery({
 *   variables: {
 *   },
 * });
 */
export function useStoresQuery(baseOptions?: Apollo.QueryHookOptions<StoresQuery, StoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoresQuery, StoresQueryVariables>(StoresDocument, options);
      }
export function useStoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoresQuery, StoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoresQuery, StoresQueryVariables>(StoresDocument, options);
        }
export type StoresQueryHookResult = ReturnType<typeof useStoresQuery>;
export type StoresLazyQueryHookResult = ReturnType<typeof useStoresLazyQuery>;
export type StoresQueryResult = Apollo.QueryResult<StoresQuery, StoresQueryVariables>;
export const UpdateServiceUserDocument = gql`
    mutation updateServiceUser($privacyData: UtentePrivacyInput!) {
  updateServiceUser(privacyData: $privacyData) {
    message
  }
}
    `;
export type UpdateServiceUserMutationFn = Apollo.MutationFunction<UpdateServiceUserMutation, UpdateServiceUserMutationVariables>;

/**
 * __useUpdateServiceUserMutation__
 *
 * To run a mutation, you first call `useUpdateServiceUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceUserMutation, { data, loading, error }] = useUpdateServiceUserMutation({
 *   variables: {
 *      privacyData: // value for 'privacyData'
 *   },
 * });
 */
export function useUpdateServiceUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceUserMutation, UpdateServiceUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceUserMutation, UpdateServiceUserMutationVariables>(UpdateServiceUserDocument, options);
      }
export type UpdateServiceUserMutationHookResult = ReturnType<typeof useUpdateServiceUserMutation>;
export type UpdateServiceUserMutationResult = Apollo.MutationResult<UpdateServiceUserMutation>;
export type UpdateServiceUserMutationOptions = Apollo.BaseMutationOptions<UpdateServiceUserMutation, UpdateServiceUserMutationVariables>;
export const DeleteUtenteDocument = gql`
    mutation deleteUtente {
  deleteUtente {
    message
  }
}
    `;
export type DeleteUtenteMutationFn = Apollo.MutationFunction<DeleteUtenteMutation, DeleteUtenteMutationVariables>;

/**
 * __useDeleteUtenteMutation__
 *
 * To run a mutation, you first call `useDeleteUtenteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUtenteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUtenteMutation, { data, loading, error }] = useDeleteUtenteMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteUtenteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUtenteMutation, DeleteUtenteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUtenteMutation, DeleteUtenteMutationVariables>(DeleteUtenteDocument, options);
      }
export type DeleteUtenteMutationHookResult = ReturnType<typeof useDeleteUtenteMutation>;
export type DeleteUtenteMutationResult = Apollo.MutationResult<DeleteUtenteMutation>;
export type DeleteUtenteMutationOptions = Apollo.BaseMutationOptions<DeleteUtenteMutation, DeleteUtenteMutationVariables>;
export const RecuperaPasswordDocument = gql`
    mutation recuperaPassword($email: String!) {
  recuperaPassword(email: $email) {
    content
  }
}
    `;
export type RecuperaPasswordMutationFn = Apollo.MutationFunction<RecuperaPasswordMutation, RecuperaPasswordMutationVariables>;

/**
 * __useRecuperaPasswordMutation__
 *
 * To run a mutation, you first call `useRecuperaPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecuperaPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recuperaPasswordMutation, { data, loading, error }] = useRecuperaPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRecuperaPasswordMutation(baseOptions?: Apollo.MutationHookOptions<RecuperaPasswordMutation, RecuperaPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecuperaPasswordMutation, RecuperaPasswordMutationVariables>(RecuperaPasswordDocument, options);
      }
export type RecuperaPasswordMutationHookResult = ReturnType<typeof useRecuperaPasswordMutation>;
export type RecuperaPasswordMutationResult = Apollo.MutationResult<RecuperaPasswordMutation>;
export type RecuperaPasswordMutationOptions = Apollo.BaseMutationOptions<RecuperaPasswordMutation, RecuperaPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($token: String!, $password: String!) {
  resetPassword(password: $password, token: $token) {
    status
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ResendActivationDocument = gql`
    mutation resendActivation($email: String!) {
  resendActivation(email: $email) {
    content
  }
}
    `;
export type ResendActivationMutationFn = Apollo.MutationFunction<ResendActivationMutation, ResendActivationMutationVariables>;

/**
 * __useResendActivationMutation__
 *
 * To run a mutation, you first call `useResendActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendActivationMutation, { data, loading, error }] = useResendActivationMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendActivationMutation(baseOptions?: Apollo.MutationHookOptions<ResendActivationMutation, ResendActivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendActivationMutation, ResendActivationMutationVariables>(ResendActivationDocument, options);
      }
export type ResendActivationMutationHookResult = ReturnType<typeof useResendActivationMutation>;
export type ResendActivationMutationResult = Apollo.MutationResult<ResendActivationMutation>;
export type ResendActivationMutationOptions = Apollo.BaseMutationOptions<ResendActivationMutation, ResendActivationMutationVariables>;
export const OptinsDocument = gql`
    query Optins {
  optins {
    optinBrands {
      image
      name
      optinName
      url
      value
    }
    optinMarketing
    optinProgramMarketing1
    optinProgramMarketing2
    optinProgramMarketing3
    optinProgramMarketing4
  }
}
    `;

/**
 * __useOptinsQuery__
 *
 * To run a query within a React component, call `useOptinsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptinsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptinsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOptinsQuery(baseOptions?: Apollo.QueryHookOptions<OptinsQuery, OptinsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptinsQuery, OptinsQueryVariables>(OptinsDocument, options);
      }
export function useOptinsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptinsQuery, OptinsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptinsQuery, OptinsQueryVariables>(OptinsDocument, options);
        }
export type OptinsQueryHookResult = ReturnType<typeof useOptinsQuery>;
export type OptinsLazyQueryHookResult = ReturnType<typeof useOptinsLazyQuery>;
export type OptinsQueryResult = Apollo.QueryResult<OptinsQuery, OptinsQueryVariables>;
export const OptinDocument = gql`
    query Optin($domain: String!) {
  optin(domain: $domain) {
    breadcrumb
    image
    name
    value
    optinName
    url
  }
}
    `;

/**
 * __useOptinQuery__
 *
 * To run a query within a React component, call `useOptinQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptinQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useOptinQuery(baseOptions: Apollo.QueryHookOptions<OptinQuery, OptinQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptinQuery, OptinQueryVariables>(OptinDocument, options);
      }
export function useOptinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptinQuery, OptinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptinQuery, OptinQueryVariables>(OptinDocument, options);
        }
export type OptinQueryHookResult = ReturnType<typeof useOptinQuery>;
export type OptinLazyQueryHookResult = ReturnType<typeof useOptinLazyQuery>;
export type OptinQueryResult = Apollo.QueryResult<OptinQuery, OptinQueryVariables>;
export const UpdatePrivacyDocument = gql`
    mutation updatePrivacy($input: UtenteUpdateInput!) {
  updateUtente(input: $input) {
    utente {
      privacy
      privacyCommerciale
      privacyInformativa
      privacyProfilazione
    }
  }
}
    `;
export type UpdatePrivacyMutationFn = Apollo.MutationFunction<UpdatePrivacyMutation, UpdatePrivacyMutationVariables>;

/**
 * __useUpdatePrivacyMutation__
 *
 * To run a mutation, you first call `useUpdatePrivacyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrivacyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrivacyMutation, { data, loading, error }] = useUpdatePrivacyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePrivacyMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePrivacyMutation, UpdatePrivacyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePrivacyMutation, UpdatePrivacyMutationVariables>(UpdatePrivacyDocument, options);
      }
export type UpdatePrivacyMutationHookResult = ReturnType<typeof useUpdatePrivacyMutation>;
export type UpdatePrivacyMutationResult = Apollo.MutationResult<UpdatePrivacyMutation>;
export type UpdatePrivacyMutationOptions = Apollo.BaseMutationOptions<UpdatePrivacyMutation, UpdatePrivacyMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UtenteUpdateInput!) {
  updateUtente(input: $input) {
    utente {
      pk
      nome
      cognome
      sesso
      dataNascita
      mobile
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateVividanoneUserDocument = gql`
    mutation updateVividanoneUser($input: UtenteUpdateInput!) {
  updateUtente(input: $input) {
    utente {
      pk
      nome
      cognome
    }
  }
}
    `;
export type UpdateVividanoneUserMutationFn = Apollo.MutationFunction<UpdateVividanoneUserMutation, UpdateVividanoneUserMutationVariables>;

/**
 * __useUpdateVividanoneUserMutation__
 *
 * To run a mutation, you first call `useUpdateVividanoneUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVividanoneUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVividanoneUserMutation, { data, loading, error }] = useUpdateVividanoneUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVividanoneUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVividanoneUserMutation, UpdateVividanoneUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVividanoneUserMutation, UpdateVividanoneUserMutationVariables>(UpdateVividanoneUserDocument, options);
      }
export type UpdateVividanoneUserMutationHookResult = ReturnType<typeof useUpdateVividanoneUserMutation>;
export type UpdateVividanoneUserMutationResult = Apollo.MutationResult<UpdateVividanoneUserMutation>;
export type UpdateVividanoneUserMutationOptions = Apollo.BaseMutationOptions<UpdateVividanoneUserMutation, UpdateVividanoneUserMutationVariables>;
export const UpdateUtenteOptinsDocument = gql`
    mutation updateUtenteOptins($optins: UtenteOptinsInput!) {
  updateOptins(optins: $optins) {
    message
  }
}
    `;
export type UpdateUtenteOptinsMutationFn = Apollo.MutationFunction<UpdateUtenteOptinsMutation, UpdateUtenteOptinsMutationVariables>;

/**
 * __useUpdateUtenteOptinsMutation__
 *
 * To run a mutation, you first call `useUpdateUtenteOptinsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUtenteOptinsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUtenteOptinsMutation, { data, loading, error }] = useUpdateUtenteOptinsMutation({
 *   variables: {
 *      optins: // value for 'optins'
 *   },
 * });
 */
export function useUpdateUtenteOptinsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUtenteOptinsMutation, UpdateUtenteOptinsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUtenteOptinsMutation, UpdateUtenteOptinsMutationVariables>(UpdateUtenteOptinsDocument, options);
      }
export type UpdateUtenteOptinsMutationHookResult = ReturnType<typeof useUpdateUtenteOptinsMutation>;
export type UpdateUtenteOptinsMutationResult = Apollo.MutationResult<UpdateUtenteOptinsMutation>;
export type UpdateUtenteOptinsMutationOptions = Apollo.BaseMutationOptions<UpdateUtenteOptinsMutation, UpdateUtenteOptinsMutationVariables>;
export const UnsubscribeDocument = gql`
    mutation Unsubscribe($input: UnsubscribeInput!) {
  unsubscribe(input: $input) {
    content
  }
}
    `;
export type UnsubscribeMutationFn = Apollo.MutationFunction<UnsubscribeMutation, UnsubscribeMutationVariables>;

/**
 * __useUnsubscribeMutation__
 *
 * To run a mutation, you first call `useUnsubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeMutation, { data, loading, error }] = useUnsubscribeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnsubscribeMutation(baseOptions?: Apollo.MutationHookOptions<UnsubscribeMutation, UnsubscribeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsubscribeMutation, UnsubscribeMutationVariables>(UnsubscribeDocument, options);
      }
export type UnsubscribeMutationHookResult = ReturnType<typeof useUnsubscribeMutation>;
export type UnsubscribeMutationResult = Apollo.MutationResult<UnsubscribeMutation>;
export type UnsubscribeMutationOptions = Apollo.BaseMutationOptions<UnsubscribeMutation, UnsubscribeMutationVariables>;
export const UnsubsDocument = gql`
    query Unsubs {
  unsubs {
    name
    optinName
    image
    value
  }
}
    `;

/**
 * __useUnsubsQuery__
 *
 * To run a query within a React component, call `useUnsubsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnsubsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnsubsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnsubsQuery(baseOptions?: Apollo.QueryHookOptions<UnsubsQuery, UnsubsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnsubsQuery, UnsubsQueryVariables>(UnsubsDocument, options);
      }
export function useUnsubsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnsubsQuery, UnsubsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnsubsQuery, UnsubsQueryVariables>(UnsubsDocument, options);
        }
export type UnsubsQueryHookResult = ReturnType<typeof useUnsubsQuery>;
export type UnsubsLazyQueryHookResult = ReturnType<typeof useUnsubsLazyQuery>;
export type UnsubsQueryResult = Apollo.QueryResult<UnsubsQuery, UnsubsQueryVariables>;
export const BrandOptinsDocument = gql`
    query BrandOptins {
  brandOptins {
    image
    name
    optinName
    url
    value
    informativa
  }
}
    `;

/**
 * __useBrandOptinsQuery__
 *
 * To run a query within a React component, call `useBrandOptinsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandOptinsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandOptinsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrandOptinsQuery(baseOptions?: Apollo.QueryHookOptions<BrandOptinsQuery, BrandOptinsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandOptinsQuery, BrandOptinsQueryVariables>(BrandOptinsDocument, options);
      }
export function useBrandOptinsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandOptinsQuery, BrandOptinsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandOptinsQuery, BrandOptinsQueryVariables>(BrandOptinsDocument, options);
        }
export type BrandOptinsQueryHookResult = ReturnType<typeof useBrandOptinsQuery>;
export type BrandOptinsLazyQueryHookResult = ReturnType<typeof useBrandOptinsLazyQuery>;
export type BrandOptinsQueryResult = Apollo.QueryResult<BrandOptinsQuery, BrandOptinsQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Node": [
      "ArticoloNode",
      "AutocompleteType",
      "CategoriaNode",
      "CategoriaProdottoConsigliatoHubNode",
      "CategoriaProdottoNode",
      "IndirizzoNode",
      "MenuItemNode",
      "PaginaNode",
      "ProdottoConsigliatoArticoloNode",
      "ProdottoConsigliatoNode",
      "SearchType"
    ],
    "SearchObject": [
      "PaginaNode",
      "ArticoloNode",
      "CategoriaNode",
      "FaqNode"
    ]
  }
};
      export default result;
    