import React from 'react'
import Image from 'next/legacy/image'
import LogoImage from 'images/logo.png'

interface Props {
  width?: number
  height?: number
  className?: string
}

function Logo({ className, width = 167, height = 55 }: Props) {
  return <Image src={LogoImage} width={width} height={height} />
}

export default Logo
