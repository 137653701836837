import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Gravidanza({ className }: Props) {
  return (
    <IconBase name="gravidanza" w={80} h={80} className={className}>
      <path d="M52.0789 38.4211C54.2846 40.375 63.7015 49.3676 63.1043 60.055C63.1043 60.055 63.2665 66.1235 58.6479 70" stroke="currentColor" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M24.3947 10C24.1843 13.0076 23.4102 18.4044 20.6033 22.3486C16.5113 28.104 18.7846 35.6265 20.6033 43.1528C22.422 50.679 16.639 57.071 16.5 65.2632" stroke="currentColor" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M47.3878 40C47.3878 40 58.6368 36.8258 56.5599 29.5399C55.0181 24.1289 44.7458 18.4929 42.6052 10" stroke="currentColor" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M39.3559 49.1262L40.6059 51.0074L41.855 49.1256C41.9945 48.9154 42.1455 48.7147 42.3071 48.525C43.4998 47.1243 45.3358 46.2368 47.2243 46.2368C48.8829 46.2368 50.3424 46.8831 51.3875 47.9282C52.4326 48.9733 53.0789 50.4329 53.0789 52.0915C53.0789 54.2906 52.0774 56.3959 50.0145 58.8684C48.0566 61.2148 45.3007 63.7121 41.8999 66.7938C41.6871 66.9866 41.4717 67.1818 41.2539 67.3793L41.2518 67.3812L40.5994 67.9751L39.9757 67.4115C39.9752 67.4111 39.9748 67.4107 39.9743 67.4103C39.7552 67.2112 39.5386 67.0145 39.3247 66.8202C35.91 63.7194 33.1504 61.2135 31.1925 58.8642C29.1291 56.3884 28.1313 54.2865 28.1313 52.0915C28.1313 50.4329 28.7776 48.9733 29.8227 47.9282C30.8678 46.8831 32.3274 46.2368 33.986 46.2368C35.8745 46.2368 37.7104 47.1243 38.9032 48.525C39.0648 48.7147 39.2159 48.9156 39.3559 49.1262Z" fill="#E23B77" fillOpacity="0.3" stroke="#E23B77" strokeWidth="3" strokeLinecap="round"/>
    </IconBase>
  )
}

export default Gravidanza
