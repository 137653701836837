import React from 'react'
import { Close2 } from 'components/atoms/Icons'
import styles from './Chip.module.sass'

interface Props {
  label: string
  removable?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const Chip = (props: Props) => {
  const { label = '', removable = false, onClick = () => {} } = props

  const chipContent = (
    <>
      <div className={styles.label}>{label}</div>
      {removable && (
        <span className={styles.icon}>
          <Close2 />
        </span>
      )}
    </>
  )

  return removable || onClick ? (
    <button type="button" className={`${styles.chip} ${styles['chip--clickable']}`} onClick={onClick}>
      {chipContent}
    </button>
  ) : (
    <div className="chip">{chipContent}</div>
  )
}

export default Chip
