import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function SimpleCheck({ className }: Props) {
  return (
    <IconBase name="simple-check" w={18} h={13} className={className}>
      <path d="M5.99965 10.1701L2.52965 6.70007C2.13965 6.31007 1.50965 6.31007 1.11965 6.70007C0.729648 7.09007 0.729648 7.72007 1.11965 8.11007L5.29965 12.2901C5.68965 12.6801 6.31965 12.6801 6.70965 12.2901L17.2896 1.71007C17.6796 1.32007 17.6796 0.690068 17.2896 0.300068C16.8997 -0.0899316 16.2696 -0.0899316 15.8796 0.300068L5.99965 10.1701Z" fill="currentColor"/>
    </IconBase>
  )
}

export default SimpleCheck
