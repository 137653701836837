import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export const isBorn = (dataNascita: string) => dayjs().isAfter(dataNascita, 'day')

export const dateDiffLabel = (dateFrom: string, dateTo: string) => {
  let value = dayjs(dateTo).diff(dayjs(dateFrom), 'day')
  let um = value === 1 ? 'giorno' : 'giorni'
  if (value > 31) {
    value = dayjs(dateTo).diff(dayjs(dateFrom), 'month')
    um = value === 1 ? 'mese' : 'mesi'
    if (value > 24) {
      value = dayjs(dateTo).diff(dayjs(dateFrom), 'year')
      um = value === 1 ? 'anno' : 'anni'
    }
  }
  return [value, um]
}

export const getAge = (dataNascita: string) =>
  dataNascita && isBorn(dataNascita)
    ? dateDiffLabel(dataNascita, dayjs().format('YYYY-MM-DD'))
    : null

export const getDateDiffInMonths = (dateFrom: string, dateTo: string) =>
  dateFrom && dateTo && dateFrom < dateTo ? dayjs(dateTo).diff(dateFrom, 'month', true) : 0

export const getWillBeBorn = (dataNascita: string) =>
  dataNascita && !isBorn(dataNascita)
    ? dateDiffLabel(dayjs().format('YYYY-MM-DD'), dataNascita)
    : null

export const formatDate = (data) => (data ? dayjs(data).format('DD/MM/YYYY') : null)

export const getYearOptions = (yearFrom: number, yearNumber: number) =>
  [...Array.from({ length: yearNumber }).keys()].map((i) => ({
    label: (i + yearFrom).toString(),
    value: (i + yearFrom).toString(),
  }))

export const getImageUrl = (url) => `/r/images/${url}`

export function escape(s: String) {
  return s
    .replace(/&amp;/g, '&')
    .replace(/&#39;/g, "'")
    .replace(/&quot;/g, '"')
}

export const scrollTo = (ref: any, toolbar: boolean, offset: number, isMobile: boolean, scrollY: number) => {
  const y = ref.offsetTop - (toolbar ? 40 : 0) - (offset || 0) - (isMobile ? scrollY > 0 ? 60 : 176 : 110) 
  window.scrollTo({
    top: y, 
    behavior: 'smooth'
  });
}
