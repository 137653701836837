import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Account({ className }: Props) {
  return (
    <IconBase name="account" w={90} className={className}>
      <path d="M38.98 47.3335C40.5957 46.3603 41.6876 44.6041 41.6876 42.5737C41.6876 39.4993 39.209 37.0088 36.1492 37.0088C33.0895 37.0088 30.6108 39.4993 30.6108 42.5737C30.6108 44.6174 31.7159 46.3824 33.3492 47.3512L32.0196 55.3845C31.8303 56.5346 32.7108 57.583 33.8731 57.583H38.4649C39.6272 57.583 40.5077 56.5346 40.3184 55.3845L38.98 47.3335Z" fill="#F7C5D7" stroke="#E23B77" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M47.7367 69.5754H24.7556C17.2493 69.5754 11.1606 63.462 11.1606 55.9153V38.1722C11.1606 30.6299 17.2449 24.5121 24.7556 24.5121H47.7367C55.243 24.5121 61.3317 30.6255 61.3317 38.1722V55.9153C61.3317 63.4576 55.243 69.5754 47.7367 69.5754Z" stroke="#164194" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M28.9773 69.5754C23.0031 69.5754 18.1647 64.7094 18.1647 58.711V35.3809C18.1647 29.378 23.0075 24.5165 28.9773 24.5165H22.1446C16.1704 24.5165 11.332 29.3825 11.332 35.3809V58.711C11.332 64.7139 16.1748 69.5754 22.1446 69.5754H28.9773Z" fill="#D3DFF0" stroke="#164194" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M50.0966 24.5121H44.8136V14.2316C44.8136 11.3341 42.7488 8.97191 40.2085 8.97191H30.9632C28.423 8.97191 26.3582 11.3297 26.3582 14.2316V24.5165H21.0752V14.2316C21.0752 8.40569 25.5129 3.66357 30.9632 3.66357H40.2041C45.6588 3.66357 50.0922 8.40569 50.0922 14.2316V24.5121H50.0966Z" fill="#D3DFF0" />
      <path d="M50.0966 24.5121H44.8136V14.2316C44.8136 11.3341 42.7488 8.97191 40.2085 8.97191H30.9632C28.423 8.97191 26.3582 11.3297 26.3582 14.2316V24.5165H21.0752V14.2316C21.0752 8.40569 25.5129 3.66357 30.9632 3.66357H40.2041C45.6588 3.66357 50.0922 8.40569 50.0922 14.2316V24.5121H50.0966Z" stroke="#164194" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M71.7522 43.6044L55.8944 59.5382C50.8007 57.2999 44.6415 58.2687 40.4724 62.4578C35.0353 67.921 35.0353 76.7726 40.4724 82.2358C45.9095 87.699 54.7189 87.699 60.156 82.2358C64.3252 78.0466 65.2937 71.858 63.0617 66.7399L67.2837 62.4976L67.09 58.4368L71.1271 58.6314L70.9334 54.5749L74.9705 54.7696L74.7768 50.7087L78.8183 50.9033L78.0654 44.1883L71.7522 43.6044ZM48.5554 78.4359C47.3667 79.6303 45.4384 79.6303 44.2497 78.4359C43.061 77.2415 43.061 75.304 44.2497 74.1096C45.4384 72.9152 47.3667 72.9152 48.5554 74.1096C49.7485 75.304 49.7485 77.2415 48.5554 78.4359Z" fill="#D3DFF0" stroke="#164194" strokeWidth="2" strokeMiterlimit="10"/>
    </IconBase>
  )
}

export default Account
