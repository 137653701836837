import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Twitter({ className }: Props) {
  return (
    <IconBase name="twitter" w={24} h={20} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M24 2.30879C23.117 2.70079 22.168 2.96479 21.172 3.08379C22.189 2.47479 22.97 1.50979 23.337 0.359787C22.386 0.923787 21.332 1.33379 20.21 1.55479C19.313 0.597786 18.032 -0.000213623 16.616 -0.000213623C13.437 -0.000213623 11.101 2.96579 11.819 6.04479C7.728 5.83979 4.1 3.87979 1.671 0.900786C0.381 3.11379 1.002 6.00879 3.194 7.47479C2.388 7.44879 1.628 7.22779 0.965 6.85879C0.911 9.13979 2.546 11.2738 4.914 11.7488C4.221 11.9368 3.462 11.9808 2.69 11.8328C3.316 13.7888 5.134 15.2118 7.29 15.2518C5.22 16.8748 2.612 17.5998 0 17.2918C2.179 18.6888 4.768 19.5038 7.548 19.5038C16.69 19.5038 21.855 11.7838 21.543 4.85779C22.505 4.16279 23.34 3.29579 24 2.30879Z" fill="#243469"/>
    </IconBase>
  )
}

export default Twitter
