import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function DirittiIllustration({ className }: Props) {
  return (
    <IconBase name="diritti-illustration" w={61} h={60} className={className}>
      <g clipPath="url(#dirittiillustration_clip0_1723_21363)">
      <path d="M25.6935 38.7839C25.5889 38.7836 25.4866 38.7528 25.3992 38.6953C25.3118 38.6378 25.2431 38.556 25.2015 38.46L14.2142 13.0929L3.21164 38.46C3.14337 38.5684 3.03845 38.6487 2.91597 38.6863C2.7935 38.7239 2.6616 38.7162 2.54426 38.6648C2.42693 38.6133 2.33196 38.5215 2.27664 38.4059C2.22131 38.2904 2.20931 38.1588 2.2428 38.0351L13.7221 11.5465C13.7637 11.4509 13.8322 11.3695 13.9194 11.3124C14.0065 11.2552 14.1085 11.2248 14.2127 11.2248C14.3169 11.2248 14.4188 11.2552 14.506 11.3124C14.5931 11.3695 14.6617 11.4509 14.7032 11.5465L26.1825 38.0351C26.2108 38.0995 26.226 38.1688 26.2274 38.2391C26.2287 38.3094 26.216 38.3793 26.1901 38.4446C26.1643 38.51 26.1257 38.5696 26.0767 38.62C26.0276 38.6703 25.9691 38.7105 25.9044 38.7381C25.8382 38.7681 25.7663 38.7838 25.6935 38.7839Z" fill="#164194"/>
      <path d="M57.6621 38.7839C57.5579 38.7834 57.4561 38.7525 57.3692 38.695C57.2824 38.6374 57.2142 38.5557 57.1731 38.46L46.1705 13.0929L35.1833 38.46C35.1239 38.5854 35.0181 38.6828 34.8883 38.7316C34.7584 38.7805 34.6146 38.777 34.4873 38.7219C34.36 38.6668 34.2591 38.5643 34.2059 38.4362C34.1527 38.308 34.1514 38.1642 34.2022 38.0351L45.6815 11.5465C45.7231 11.4509 45.7916 11.3695 45.8788 11.3124C45.9659 11.2552 46.0679 11.2248 46.1721 11.2248C46.2763 11.2248 46.3782 11.2552 46.4654 11.3124C46.5525 11.3695 46.6211 11.4509 46.6626 11.5465L58.1542 38.0351C58.2104 38.1652 58.2127 38.3124 58.1606 38.4442C58.1084 38.576 58.0061 38.6817 57.876 38.7381C57.8087 38.7681 57.7358 38.7837 57.6621 38.7839Z" fill="#164194"/>
      <path d="M31.7786 49.8262L31.7786 11.7421H28.6215L28.6215 49.8262H31.7786Z" fill="#D3DFF0"/>
      <path d="M31.7786 50.358H28.6215C28.4796 50.358 28.3436 50.3017 28.2433 50.2014C28.143 50.1011 28.0866 49.965 28.0866 49.8232V11.7512C28.0866 11.6094 28.143 11.4734 28.2433 11.3731C28.3436 11.2727 28.4796 11.2164 28.6215 11.2164H31.7786C31.9204 11.2164 32.0565 11.2727 32.1568 11.3731C32.2571 11.4734 32.3134 11.6094 32.3134 11.7512V49.8324C32.311 49.9726 32.2536 50.1063 32.1536 50.2046C32.0535 50.303 31.9189 50.3581 31.7786 50.358ZM29.1563 49.2883H31.2437V12.2769H29.1563V49.2883Z" fill="#164194"/>
      <path d="M25.6936 38.2491C25.6936 42.5615 20.5529 46.0548 14.2142 46.0548C7.87551 46.0548 2.73486 42.5615 2.73486 38.2491H25.6936Z" fill="#F7C5D7"/>
      <path d="M14.2142 46.5927C7.58821 46.5927 2.20001 42.8518 2.20001 38.2521C2.20321 38.1124 2.26096 37.9795 2.36092 37.8818C2.46088 37.7842 2.5951 37.7295 2.73486 37.7295H25.6935C25.8354 37.7295 25.9714 37.7859 26.0717 37.8862C26.172 37.9865 26.2284 38.1225 26.2284 38.2644C26.2284 42.8488 20.8371 46.5927 14.2142 46.5927ZM3.29721 38.7839C3.71286 42.5431 8.45008 45.5077 14.2142 45.5077C19.9783 45.5077 24.7155 42.5309 25.1281 38.7839H3.29721Z" fill="#E23B77"/>
      <path d="M57.6621 38.2491C57.6621 42.5615 52.5245 46.0548 46.1828 46.0548C39.841 46.0548 34.7126 42.5615 34.7126 38.2491H57.6621Z" fill="#F7C5D7"/>
      <path d="M46.1828 46.5927C39.5598 46.5927 34.1686 42.8518 34.1686 38.2521C34.1718 38.1108 34.2308 37.9765 34.3328 37.8786C34.4347 37.7806 34.5713 37.7271 34.7126 37.7295H57.6713C57.8131 37.7295 57.9492 37.7859 58.0495 37.8862C58.1498 37.9865 58.2061 38.1225 58.2061 38.2644C58.197 42.8488 52.8088 46.5927 46.1828 46.5927ZM35.2688 38.787C35.6814 42.5462 40.4186 45.5108 46.1828 45.5108C51.9469 45.5108 56.6872 42.534 57.0998 38.787H35.2688Z" fill="#E23B77"/>
      <path d="M47.8026 10.5349H12.5974C11.9695 10.5349 11.4605 11.0439 11.4605 11.6718V11.8124C11.4605 12.4403 11.9695 12.9493 12.5974 12.9493H47.8026C48.4305 12.9493 48.9395 12.4403 48.9395 11.8124V11.6718C48.9395 11.0439 48.4305 10.5349 47.8026 10.5349Z" fill="#D3DFF0"/>
      <path d="M47.7995 13.4811H12.5974C12.1541 13.4811 11.7288 13.305 11.4153 12.9914C11.1018 12.6779 10.9257 12.2527 10.9257 11.8093V11.6718C10.9257 11.2284 11.1018 10.8032 11.4153 10.4897C11.7288 10.1761 12.1541 10 12.5974 10H47.7995C48.2432 10 48.6687 10.176 48.9827 10.4895C49.2967 10.8029 49.4736 11.2281 49.4744 11.6718V11.8093C49.4736 12.253 49.2967 12.6782 48.9827 12.9916C48.6687 13.3051 48.2432 13.4811 47.7995 13.4811ZM12.5974 11.0697C12.4353 11.0697 12.2798 11.1341 12.1652 11.2487C12.0506 11.3634 11.9862 11.5188 11.9862 11.6809V11.8185C11.9862 11.9806 12.0506 12.1361 12.1652 12.2507C12.2798 12.3653 12.4353 12.4297 12.5974 12.4297H47.7995C47.9617 12.4297 48.1171 12.3653 48.2318 12.2507C48.3464 12.1361 48.4108 11.9806 48.4108 11.8185V11.6809C48.4108 11.5188 48.3464 11.3634 48.2318 11.2487C48.1171 11.1341 47.9617 11.0697 47.7995 11.0697H12.5974Z" fill="#164194"/>
      </g>
      <defs>
      <clipPath id="dirittiillustration_clip0_1723_21363">
      <rect width="56" height="40.3611" fill="white" transform="translate(2.20001 10)"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default DirittiIllustration
