import React, { ReactNode } from 'react'
import styles from './FormLayout.module.sass'

interface Props {
  children?: ReactNode
  className?: string
}

const FormLayout = ({ children, className }: Props) =>
    <div className={className ? className : styles.formLayout}>{children}</div>

export default FormLayout
