import React, { ReactNode } from 'react'
import styles from './WContainer.module.sass'

interface Props {
  variant?: 'md' | 'sm' | 'xs' | 'xxs' | 'none' | 'fluid' | 'md-no-mobile' | 'sm-no-mobile' | 'xs-no-mobile'
  children?: ReactNode
}

const WContainer = (props: Props) => {
  const { variant = 'md', children = null } = props

  return variant === 'none' ? (
    <>{children}</>
  ) : (
    <div className={`${styles.wContainer} ${styles[`wContainer--${variant}`]}`}>{children}</div>
  )
}

export default WContainer
