import React, { ReactNode } from 'react'

interface Props {
  name: string
  className: string
  w: number
  h?: number
  children: ReactNode
}

const IconBase = ({ name, className, w, h, children }: Props) => (
  <svg
    viewBox={`0 0 ${w} ${h ? h : w}`}
    xmlns="http://www.w3.org/2000/svg"
    className={`icon icon--${name}${className ? ' ' + className : ''}`}
    fill="none"
    stroke="none"
  >
    {children}
  </svg>
)

export default IconBase
