import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function SupportoPsicopedagogicoIllustration({ className }: Props) {
  return (
    <IconBase name="supporto-psicopedagogico-illustration" w={61} h={60} className={className}>
      <g clipPath="url(#supportopsicopedagogicoillustration_clip0_1723_21360)">
      <path d="M41.032 23.6711C40.8137 23.1885 40.7903 22.6378 40.9667 22.1377C41.1431 21.6376 41.5053 21.228 41.9753 20.9972C42.4285 20.7282 42.9659 20.6471 43.4763 20.7704C43.9867 20.8938 44.4309 21.2124 44.7171 21.6601L49.204 30.0245C50.6472 32.5223 50.4365 34.2835 49.9272 36.9542L49.8611 37.2745L51.2541 38.6963L55.656 43.1795C53.8985 47.154 50.764 50.3292 46.8521 52.0979L41.2301 46.369C41.2301 46.369 38.0859 46.8237 36.0987 44.7902L30.1937 38.7795L41.032 23.6711Z" fill="white"/>
      <path d="M38.6141 35.0585C38.5521 35.0588 38.4907 35.0465 38.4335 35.0223C38.3763 34.9981 38.3243 34.9625 38.2808 34.9176L32.379 28.9069L33.0456 28.228L38.9474 34.2387C38.9915 34.2831 39.0265 34.3359 39.0504 34.3942C39.0743 34.4525 39.0866 34.515 39.0866 34.5781C39.0866 34.6413 39.0743 34.7038 39.0504 34.7621C39.0265 34.8203 38.9915 34.8732 38.9474 34.9176C38.9042 34.9629 38.8523 34.9988 38.795 35.023C38.7377 35.0473 38.6761 35.0593 38.6141 35.0585Z" fill="#164194"/>
      <path d="M41.5666 32.0611C41.5046 32.0614 41.4432 32.0491 41.386 32.0249C41.3287 32.0007 41.2768 31.9651 41.2333 31.9202L35.3315 25.9095L35.9981 25.2307L41.8998 31.2414C41.944 31.2857 41.979 31.3386 42.0029 31.3969C42.0268 31.4551 42.0391 31.5176 42.0391 31.5808C42.0391 31.6439 42.0268 31.7065 42.0029 31.7647C41.979 31.823 41.944 31.8759 41.8998 31.9202C41.8566 31.9656 41.8048 32.0015 41.7475 32.0257C41.6902 32.0499 41.6286 32.062 41.5666 32.0611Z" fill="#164194"/>
      <path d="M19.368 23.6711C19.5854 23.1884 19.6085 22.638 19.4321 22.1381C19.2558 21.6383 18.8941 21.2287 18.4247 20.9972C17.971 20.7281 17.4331 20.6468 16.9222 20.7702C16.4113 20.8935 15.9665 21.2121 15.6798 21.6601L11.1929 30.0245C9.74965 32.5223 9.96032 34.2835 10.4697 36.9542L10.5389 37.2745L9.14281 38.6963L4.74083 43.1795C6.50087 47.1523 9.63458 50.3267 13.5448 52.0979L19.1699 46.369C19.1699 46.369 22.3142 46.8237 24.2982 44.7902L30.2032 38.7795L19.368 23.6711Z" fill="white"/>
      <path d="M21.7859 35.0585C21.7239 35.0593 21.6623 35.0473 21.605 35.023C21.5477 34.9988 21.4958 34.9629 21.4526 34.9176C21.4085 34.8732 21.3735 34.8203 21.3496 34.7621C21.3257 34.7038 21.3134 34.6413 21.3134 34.5781C21.3134 34.515 21.3257 34.4525 21.3496 34.3942C21.3735 34.3359 21.4085 34.2831 21.4526 34.2387L27.3544 28.228C27.4439 28.1431 27.5621 28.097 27.6843 28.0992C27.8065 28.1013 27.9231 28.1518 28.0095 28.2398C28.0959 28.3278 28.1454 28.4465 28.1475 28.5709C28.1497 28.6954 28.1043 28.8158 28.021 28.9069L22.1192 34.9176C22.0758 34.9626 22.0239 34.9983 21.9666 35.0225C21.9094 35.0467 21.8479 35.059 21.7859 35.0585Z" fill="#164194"/>
      <path d="M18.8335 32.0611C18.7715 32.0616 18.71 32.0494 18.6528 32.0252C18.5955 32.001 18.5436 31.9653 18.5002 31.9202C18.4561 31.8759 18.421 31.823 18.3971 31.7647C18.3732 31.7065 18.3609 31.6439 18.3609 31.5808C18.3609 31.5176 18.3732 31.4551 18.3971 31.3969C18.421 31.3386 18.4561 31.2857 18.5002 31.2414L24.4051 25.2307C24.4487 25.1857 24.5006 25.1501 24.5578 25.1257C24.615 25.1014 24.6764 25.0888 24.7384 25.0888C24.8004 25.0888 24.8618 25.1014 24.919 25.1257C24.9762 25.1501 25.0281 25.1857 25.0717 25.2307C25.1593 25.3211 25.2083 25.4431 25.2083 25.5701C25.2083 25.6971 25.1593 25.8191 25.0717 25.9095L19.1668 31.9202C19.1233 31.9653 19.0714 32.001 19.0142 32.0252C18.9569 32.0494 18.8955 32.0616 18.8335 32.0611Z" fill="#164194"/>
      <path d="M29.0932 11.7746L30.2 13.4718L31.3068 11.7746C32.1496 10.4829 33.2919 9.42264 34.6328 8.6876C35.9737 7.95257 37.4718 7.56542 38.9945 7.5604C40.1076 7.54664 41.2122 7.75984 42.2432 8.18742C43.2741 8.61501 44.2107 9.24835 44.9977 10.0502C45.7846 10.852 46.4062 11.806 46.8256 12.8562C47.2451 13.9063 47.454 15.0313 47.4401 16.165C47.4401 19.3673 46 22.3678 43.2142 25.7718C40.5824 28.9741 36.8847 32.3974 32.3853 36.5507L31.508 37.3577L30.1937 38.5746L28.9172 37.3994L28.0367 36.586C23.5153 32.4006 19.8145 28.9741 17.1827 25.7718C14.3969 22.3646 12.96 19.3673 12.96 16.165C12.9465 15.0316 13.1557 13.9069 13.5753 12.8572C13.995 11.8074 14.6166 10.8537 15.4035 10.0522C16.1905 9.25078 17.1269 8.61773 18.1576 8.19034C19.1884 7.76295 20.2927 7.54986 21.4055 7.56361C22.7448 7.57601 24.0659 7.88021 25.2802 8.45574C26.4945 9.03128 27.5738 9.86483 28.4455 10.9004C28.6787 11.1781 28.895 11.47 29.0932 11.7746Z" fill="#F7C5D7"/>
      <path d="M30.1969 39.3271L27.6657 36.9959C23.0562 32.7336 19.4183 29.368 16.7582 26.1081C13.7523 22.4447 12.4097 19.3673 12.4097 16.165C12.3958 14.9579 12.619 13.7602 13.0661 12.6422C13.5133 11.5243 14.1754 10.5086 15.0135 9.65504C15.8516 8.80146 16.8489 8.12714 17.9466 7.67174C19.0443 7.21633 20.2203 6.98903 21.4055 7.0032C22.8235 7.01411 24.2228 7.33412 25.5092 7.94172C26.7957 8.54931 27.9395 9.43039 28.8637 10.5257C29.1104 10.8228 29.3403 11.1339 29.5523 11.4576L30.2 12.4535L30.8477 11.4576C31.0597 11.1339 31.2896 10.8228 31.5363 10.5257C32.4606 9.43039 33.6044 8.54931 34.8908 7.94172C36.1773 7.33412 37.5765 7.01411 38.9945 7.0032C40.1797 6.98903 41.3558 7.21633 42.4535 7.67174C43.5512 8.12714 44.5484 8.80146 45.3865 9.65504C46.2246 10.5086 46.8867 11.5243 47.3339 12.6422C47.781 13.7602 48.0042 14.9579 47.9903 16.165C47.9903 19.3673 46.6477 22.4543 43.6355 26.1305C40.9817 29.3712 37.347 32.7176 32.7595 36.9574L30.1969 39.3271ZM29.2945 36.9895L30.1937 37.8157L32.0143 36.128C36.5829 31.9138 40.1894 28.5899 42.7928 25.4068C45.6227 21.9483 46.8804 19.0951 46.8804 16.1618C46.8943 15.1019 46.6997 14.0499 46.3078 13.0679C45.916 12.0859 45.3349 11.1938 44.599 10.4443C43.8631 9.69476 42.9871 9.103 42.0229 8.70393C41.0587 8.30487 40.0258 8.10659 38.9851 8.1208C37.7283 8.13357 36.4888 8.42025 35.3499 8.96156C34.211 9.50286 33.199 10.2863 32.3821 11.259C32.1624 11.5215 31.9587 11.7974 31.7722 12.0852L30.2 14.4838L28.6279 12.0852C28.4413 11.7974 28.2376 11.5215 28.0179 11.259C27.1993 10.2842 26.1848 9.49964 25.043 8.95825C23.9012 8.41687 22.6586 8.13129 21.3992 8.1208C20.3589 8.10788 19.3266 8.3072 18.3631 8.70701C17.3996 9.10681 16.5244 9.69901 15.7892 10.4487C15.054 11.1984 14.4736 12.0904 14.0822 13.0721C13.6908 14.0539 13.4963 15.1055 13.5102 16.165C13.5102 19.0951 14.7679 21.9291 17.5978 25.4068C20.2075 28.6091 23.8203 31.9363 28.3983 36.1665L29.2945 36.9895Z" fill="#E23B77"/>
      <path d="M16.3746 36.7301C16.8714 33.7487 15.1169 31.3502 15.1169 31.3502L19.3743 23.6647C19.5917 23.1819 19.6148 22.6316 19.4384 22.1317C19.2621 21.6318 18.9004 21.2223 18.431 20.9908C17.9773 20.7217 17.4394 20.6404 16.9285 20.7638C16.4176 20.8871 15.9728 21.2057 15.686 21.6537L11.1992 30.0181C9.75594 32.5159 9.9666 34.2771 10.476 36.9478L10.5451 37.2681L9.14909 38.6899L4.74711 43.1731C6.50715 47.1459 9.64086 50.3203 13.5511 52.0915L19.1762 46.3626C19.1762 46.3626 22.3205 46.8173 24.3045 44.7838L30.2094 38.7731" fill="white"/>
      <path d="M13.5416 52.6358C13.4648 52.6356 13.3888 52.6193 13.3184 52.5878C9.28749 50.7587 6.05852 47.4827 4.24718 43.3844C4.20225 43.2808 4.18902 43.1658 4.20921 43.0544C4.2294 42.943 4.28208 42.8404 4.36038 42.76L9.93517 37.0823C9.40064 34.2771 9.18997 32.3942 10.7212 29.7427L15.2018 21.3943C15.5559 20.8148 16.1173 20.3983 16.7675 20.2327C17.4176 20.0672 18.1055 20.1656 18.6857 20.5073C18.9836 20.6534 19.2502 20.8579 19.4704 21.1091C19.6906 21.3604 19.86 21.6533 19.9689 21.9712C20.0779 22.2892 20.1242 22.6259 20.1052 22.9621C20.0863 23.2983 20.0024 23.6274 19.8585 23.9305L15.7709 31.331C16.2489 32.1092 17.3588 34.2515 16.928 36.8261C16.9001 36.969 16.8186 37.0952 16.7008 37.178C16.583 37.2607 16.4383 37.2934 16.2972 37.2693C16.1561 37.2451 16.0298 37.166 15.945 37.0486C15.8603 36.9312 15.8238 36.7848 15.8433 36.6404C16.2929 33.9409 14.6987 31.7121 14.683 31.6897C14.6198 31.6021 14.5827 31.4979 14.576 31.3895C14.5693 31.281 14.5934 31.1729 14.6453 31.078L18.9026 23.3925C19.0497 23.0421 19.0563 22.6469 18.9212 22.2915C18.7861 21.9361 18.5199 21.6486 18.1795 21.4904C17.851 21.2913 17.4604 21.2272 17.0871 21.3111C16.7138 21.395 16.386 21.6205 16.1703 21.9419L11.6928 30.2903C10.3219 32.6632 10.5452 34.3251 11.0231 36.8454L11.0891 37.1304C11.1106 37.223 11.1082 37.3196 11.0824 37.4111C11.0565 37.5025 11.0079 37.5856 10.9413 37.6523L5.42314 43.2723C7.0958 46.8385 9.9272 49.7063 13.4379 51.3902L18.7832 45.9463C18.8431 45.8845 18.9164 45.8378 18.9972 45.81C19.078 45.7822 19.1641 45.774 19.2485 45.7861C19.2768 45.7861 22.1444 46.1704 23.9146 44.3675L29.8164 38.3536L30.5962 39.1446L24.6912 45.1585C22.8676 47.0158 20.239 46.9902 19.3774 46.9262L13.9378 52.4661C13.833 52.5738 13.6906 52.6349 13.5416 52.6358Z" fill="#164194"/>
      <path d="M44.0254 36.7301C43.5286 33.7487 45.2831 31.3502 45.2831 31.3502L41.0257 23.6647C40.8074 23.1821 40.784 22.6314 40.9604 22.1313C41.1368 21.6312 41.499 21.2216 41.969 20.9908C42.4222 20.7219 42.9597 20.6407 43.47 20.764C43.9804 20.8874 44.4247 21.206 44.7108 21.6537L49.1977 30.0181C50.6409 32.5159 50.4303 34.2771 49.9209 36.9478L49.8549 37.2681L51.2478 38.6899L55.6498 43.1731C53.8922 47.1476 50.7577 50.3228 46.8458 52.0915L41.2238 46.3626C41.2238 46.3626 38.0796 46.8173 36.0924 44.7838L30.1874 38.7731" fill="white"/>
      <path d="M46.8584 52.6359C46.7125 52.6349 46.5728 52.5764 46.4685 52.4725L41.0289 46.9326C40.1674 46.9966 37.5387 47.0222 35.7151 45.1649L29.8101 39.151L30.5899 38.36L36.4948 44.3739C38.2745 46.1864 41.1295 45.7958 41.1578 45.7926C41.2423 45.7799 41.3285 45.7879 41.4094 45.8157C41.4902 45.8435 41.5635 45.8905 41.6232 45.9527L46.9684 51.3966C50.4795 49.7097 53.3099 46.8384 54.98 43.2691L49.4713 37.6555C49.4047 37.5888 49.3561 37.5057 49.3302 37.4143C49.3044 37.3228 49.302 37.2262 49.3235 37.1336L49.3927 36.8293C49.8674 34.3284 50.0907 32.6664 48.7292 30.3063L44.2329 21.9291C43.8682 21.2886 42.9752 21.0933 42.2331 21.4936C41.8902 21.6541 41.6233 21.9459 41.4903 22.3056C41.3573 22.6653 41.3689 23.0639 41.5225 23.4149L45.7673 31.0748C45.8208 31.1692 45.8458 31.2776 45.839 31.3864C45.8323 31.4952 45.7943 31.5996 45.7296 31.6865C45.7139 31.7089 44.1197 33.9281 44.5693 36.6372C44.5888 36.7816 44.5523 36.9279 44.4676 37.0454C44.3828 37.1628 44.2565 37.2419 44.1154 37.2661C43.9743 37.2902 43.8296 37.2575 43.7118 37.1748C43.594 37.092 43.5125 36.9658 43.4846 36.8229C43.0538 34.2611 44.1637 32.106 44.6448 31.3278L40.5572 23.9625C40.4099 23.6592 40.3231 23.3292 40.3017 22.9914C40.2803 22.6537 40.3249 22.3151 40.4328 21.995C40.5406 21.6749 40.7097 21.3798 40.9302 21.1266C41.1507 20.8735 41.4183 20.6673 41.7175 20.5201C42.2933 20.1775 42.977 20.0752 43.6253 20.2346C44.2735 20.394 44.8361 20.8027 45.1951 21.3751L49.6914 29.7555C51.2132 32.391 51.0025 34.2739 50.4711 37.0599V37.0823L56.0459 42.76C56.1246 42.8402 56.1776 42.9428 56.1978 43.0543C56.218 43.1658 56.2045 43.2809 56.1591 43.3844C54.3463 47.4838 51.1149 50.76 47.0816 52.5878C47.0112 52.6193 46.9352 52.6356 46.8584 52.6359Z" fill="#164194"/>
      <path d="M24.7384 38.075C24.6764 38.0759 24.6148 38.0638 24.5575 38.0396C24.5002 38.0154 24.4483 37.9795 24.4051 37.9341C24.361 37.8898 24.326 37.8369 24.3021 37.7786C24.2782 37.7204 24.2659 37.6578 24.2659 37.5947C24.2659 37.5315 24.2782 37.469 24.3021 37.4107C24.326 37.3525 24.361 37.2996 24.4051 37.2552L26.0904 35.5388L26.7885 36.1793L25.0717 37.9405C25.0276 37.9843 24.9755 38.0187 24.9183 38.0418C24.8611 38.0649 24.7999 38.0762 24.7384 38.075Z" fill="#164194"/>
      <path d="M34.3001 35.5292L35.9981 37.2553C36.0856 37.3457 36.1347 37.4677 36.1347 37.5947C36.1347 37.7217 36.0856 37.8437 35.9981 37.9341C35.9545 37.979 35.9026 38.0146 35.8454 38.0388C35.7881 38.063 35.7267 38.0753 35.6648 38.075C35.6027 38.0759 35.5412 38.0638 35.4838 38.0396C35.4265 38.0154 35.3747 37.9795 35.3315 37.9341L33.6021 36.1761L34.3001 35.5292Z" fill="#164194"/>
      </g>
      <defs>
      <clipPath id="supportopsicopedagogicoillustration_clip0_1723_21360">
      <rect width="52" height="45.6327" fill="white" transform="translate(4.20001 7)"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default SupportoPsicopedagogicoIllustration
