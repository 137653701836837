import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function ChevronDown({ className }: Props) {
  return (
    <IconBase name="chevron-down" w={24} className={className}>
      <path d="M19 9L12.5 15.5L6 9" stroke="#0D3164" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </IconBase>
  )
}

export default ChevronDown
