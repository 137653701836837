import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Home({ className }: Props) {
  return (
    <IconBase name="home" w={12} className={className}>
      <path d="M1.26439 6.375H1.375C1.65114 6.375 1.875 6.59886 1.875 6.875V11.125C1.875 11.4011 2.09886 11.625 2.375 11.625H4.375C4.65114 11.625 4.875 11.4011 4.875 11.125V8.375C4.875 8.09886 5.09886 7.875 5.375 7.875H6.625C6.90114 7.875 7.125 8.09886 7.125 8.375V11.125C7.125 11.4011 7.34886 11.625 7.625 11.625H9.625C9.90114 11.625 10.125 11.4011 10.125 11.125V6.875C10.125 6.59886 10.3489 6.375 10.625 6.375H10.7356C11.1889 6.375 11.4083 5.82024 11.0776 5.51023L6.34197 1.0706C6.14964 0.890289 5.85036 0.890289 5.65803 1.0706L0.92242 5.51023C0.591743 5.82024 0.811122 6.375 1.26439 6.375Z" fill="#3F77C1"/>
    </IconBase>
  )
}

export default Home
