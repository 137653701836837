import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function ArrowUp({ className }: Props) {
  return (
    <IconBase name="arrow-up" w={24} className={className}>
      <line x1="12.5" y1="19" x2="12.5" y2="5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 11.5L12.5 4L20 11.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </IconBase>
  )
}

export default ArrowUp
