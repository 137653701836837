import React from 'react'
import { ArrowRight } from '@components/atoms/Icons'
import NextLink from 'next/link'
import styles from './Link.module.sass'

interface Props {
  className?: string
  label?: string
  href?: string
  arrow?: boolean
  newPage?: boolean
  decoration?: boolean
  onClick?: any
}

const Link = (props: Props) => {
  const {
    className = '',
    label = '',
    href = '',
    arrow = true,
    decoration = false,
    newPage = false,
    onClick = () => {},
  } = props

  const attributes = {
    ...(onClick && !href && { onClick: onClick }),
    ...(href && { href: href }),
  }

  return (
    <div className={`${styles.root} ${className}`}>
      <NextLink legacyBehavior href={href}>
        <a {...attributes} className={styles.cta} target={newPage ? '_blank' : null}>
          {label}
          {arrow && <ArrowRight />}
        </a>
      </NextLink>
      {decoration && <div className={styles.decoration} />}
    </div>
  )
}

export default Link
