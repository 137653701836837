import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Play({ className }: Props) {
  return (
    <IconBase name="play" w={24} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.47427 3.14935C4.76909 2.96714 5.13723 2.95058 5.44721 3.10557L21.4472 11.1056C21.786 11.275 22 11.6212 22 12C22 12.3788 21.786 12.725 21.4472 12.8944L5.44721 20.8944C5.13723 21.0494 4.76909 21.0329 4.47427 20.8507C4.17945 20.6684 4 20.3466 4 20V4C4 3.65342 4.17945 3.33156 4.47427 3.14935ZM6 5.61804V18.382L18.7639 12L6 5.61804Z" fill="currentColor"/>
    </IconBase>
  )
}

export default Play
