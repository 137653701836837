import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { escape } from 'utils/safe'
import { BRAND, DOMAIN, LOGO_URL } from 'utils/settings'

// DOCS https://github.com/garmeeh/next-seo

const MetaSeo = ({ seo, additionalMetaTags = null }) => {
  const { title = '', description = '', image = '', noindex = false } = seo || {}

  const router = useRouter()

  const ogImage = image || LOGO_URL

  const og = {
    locale: 'it_IT',
    site_name: BRAND,
    url: DOMAIN + router?.asPath,
    title,
    description,
    images: ogImage ? [{ url: ogImage }] : [],
    type: 'website',
  }

  return (
    <NextSeo
      title={escape(title)}
      description={escape(description)}
      additionalMetaTags={additionalMetaTags}
      openGraph={og}
      canonical={(DOMAIN + router.asPath.split('?')[0])}
      noindex={noindex}
      nofollow={noindex}
    />
  )
}

export default MetaSeo
