import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Question({ className }: Props) {
  return (
    <IconBase name="question" w={24} className={className}>
      <circle cx="12" cy="12" r="9" stroke="currentColor" strokeWidth="2" />
      <path d="M11.184 13.012C11.184 12.572 11.264 12.2 11.424 11.896C11.584 11.592 11.816 11.26 12.12 10.9C12.352 10.62 12.524 10.384 12.636 10.192C12.748 10 12.804 9.784 12.804 9.544C12.804 9.272 12.708 9.06 12.516 8.908C12.324 8.748 12.056 8.668 11.712 8.668C11.368 8.668 11.004 8.744 10.62 8.896C10.244 9.048 9.872 9.264 9.504 9.544L9 8.416C9.328 8.12 9.752 7.884 10.272 7.708C10.792 7.532 11.312 7.444 11.832 7.444C12.32 7.444 12.752 7.528 13.128 7.696C13.504 7.856 13.796 8.088 14.004 8.392C14.22 8.688 14.328 9.028 14.328 9.412C14.328 9.828 14.228 10.192 14.028 10.504C13.828 10.816 13.54 11.16 13.164 11.536C12.836 11.888 12.588 12.176 12.42 12.4C12.26 12.624 12.16 12.88 12.12 13.168L12.072 13.492H11.184V13.012ZM10.812 16V14.344H12.492V16H10.812Z" fill="currentColor"/>
    </IconBase>
  )
}

export default Question
