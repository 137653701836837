import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Facebook({ className }: Props) {
  return (
    <IconBase name="facebook" w={24} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9698 1.52588e-05C5.35907 1.52588e-05 0 5.35908 0 11.9698C0 17.9558 4.39406 22.9152 10.1326 23.7992V15.5651H7.05173V11.9698H10.1326V8.96821C10.1326 8.96821 10.0769 4.51437 14.6422 4.51437C14.6422 4.51437 16.5947 4.62186 17.3467 4.77749V7.86333H15.6093C15.6093 7.86333 14.0273 8.07079 14.0273 9.34146V11.9698H17.3467L16.905 15.5651H14.0273V23.7599C19.657 22.784 23.9396 17.8785 23.9396 11.9698C23.9396 5.35908 18.5806 1.52588e-05 11.9698 1.52588e-05Z" fill="currentColor"/>
    </IconBase>
  )
}

export default Facebook
