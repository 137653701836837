import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function CheckboxMixed({ className }: Props) {
  return (
    <IconBase name="checkbox-mixed" w={20} className={className}>
      <rect x="2" y="2" width="16" height="16" rx="1" stroke="currentColor" strokeWidth="2"/>
      <line x1="7" y1="10" x2="13" y2="10" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    </IconBase>
  )
}

export default CheckboxMixed
