import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Share({ className }: Props) {
  return (
    <IconBase name="share" w={20} className={className}>
      <path d="M15 13.4001C14.3667 13.4001 13.8 13.6501 13.3667 14.0417L7.425 10.5834C7.46667 10.3917 7.5 10.2001 7.5 10.0001C7.5 9.80008 7.46667 9.60842 7.425 9.41675L13.3 5.99175C13.75 6.40841 14.3417 6.66675 15 6.66675C16.3833 6.66675 17.5 5.55008 17.5 4.16675C17.5 2.78341 16.3833 1.66675 15 1.66675C13.6167 1.66675 12.5 2.78341 12.5 4.16675C12.5 4.36675 12.5333 4.55841 12.575 4.75008L6.7 8.17508C6.25 7.75842 5.65833 7.50008 5 7.50008C3.61667 7.50008 2.5 8.61675 2.5 10.0001C2.5 11.3834 3.61667 12.5001 5 12.5001C5.65833 12.5001 6.25 12.2417 6.7 11.8251L12.6333 15.2917C12.5917 15.4667 12.5667 15.6501 12.5667 15.8334C12.5667 17.1751 13.6583 18.2667 15 18.2667C16.3417 18.2667 17.4333 17.1751 17.4333 15.8334C17.4333 14.4917 16.3417 13.4001 15 13.4001ZM15 3.33341C15.4583 3.33341 15.8333 3.70841 15.8333 4.16675C15.8333 4.62508 15.4583 5.00008 15 5.00008C14.5417 5.00008 14.1667 4.62508 14.1667 4.16675C14.1667 3.70841 14.5417 3.33341 15 3.33341ZM5 10.8334C4.54167 10.8334 4.16667 10.4584 4.16667 10.0001C4.16667 9.54175 4.54167 9.16675 5 9.16675C5.45833 9.16675 5.83333 9.54175 5.83333 10.0001C5.83333 10.4584 5.45833 10.8334 5 10.8334ZM15 16.6834C14.5417 16.6834 14.1667 16.3084 14.1667 15.8501C14.1667 15.3917 14.5417 15.0167 15 15.0167C15.4583 15.0167 15.8333 15.3917 15.8333 15.8501C15.8333 16.3084 15.4583 16.6834 15 16.6834Z" fill="currentColor"/>
    </IconBase>
  )
}

export default Share
