import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function CurveCrescitaIllustration({ className }: Props) {
  return (
    <IconBase name="curve-crescita-illustration" w={60} className={className}>
      <g clipPath="url(#curvecrescitaillustration_clip0_2031_5496)">
        <path
          d="M54.4474 11.7678V49H5.55225V42.2023L13.0302 40.4437L25.2461 31.709L34.7158 21.2557L48.0428 13.5695"
          fill="#D3DFF0"
        />
        <path
          d="M5.5522 43.0447C5.4195 43.044 5.2915 42.9968 5.19162 42.9117C5.09174 42.8266 5.02665 42.7093 5.00826 42.5813C4.98987 42.4533 5.0194 42.3231 5.09146 42.2145C5.16352 42.106 5.27329 42.0264 5.40069 41.9902L12.7839 39.9549L24.8704 31.3094L34.2927 20.9053C34.331 20.863 34.3757 20.8267 34.4253 20.7977L47.7523 13.1114C47.7934 13.0896 47.8368 13.0721 47.8817 13.0592L54.2927 11.2575C54.4338 11.2167 54.5857 11.2322 54.7151 11.3005C54.8444 11.3689 54.9406 11.4844 54.9824 11.6218C55.0243 11.7592 55.0084 11.9072 54.9383 12.0332C54.8681 12.1591 54.7495 12.2528 54.6084 12.2936L48.2637 14.0737L35.0724 21.6862L25.6595 32.0626C25.6332 32.0924 25.6035 32.1192 25.5711 32.1426L13.3584 40.8772C13.3053 40.9161 13.2454 40.9453 13.1816 40.9633L5.70372 43.0263C5.65417 43.0384 5.60329 43.0446 5.5522 43.0447Z"
          fill="currentColor"
        />
        <path
          d="M48.0367 16.6009C49.7556 16.6009 51.1491 15.2437 51.1491 13.5695C51.1491 11.8953 49.7556 10.538 48.0367 10.538C46.3178 10.538 44.9243 11.8953 44.9243 13.5695C44.9243 15.2437 46.3178 16.6009 48.0367 16.6009Z"
          fill="#F7C5D7"
        />
        <path
          d="M48.0273 17.139C47.3027 17.1384 46.5944 16.9285 45.9922 16.5359C45.3899 16.1433 44.9207 15.5856 44.6438 14.9334C44.3669 14.2811 44.2948 13.5634 44.4365 12.8712C44.5783 12.179 44.9276 11.5433 45.4402 11.0444C45.9529 10.5455 46.6059 10.2058 47.3167 10.0684C48.0275 9.93088 48.7642 10.0018 49.4337 10.272C50.1031 10.5423 50.6753 10.9998 51.0779 11.5867C51.4804 12.1736 51.6953 12.8636 51.6953 13.5695C51.6936 14.5162 51.3065 15.4236 50.6189 16.0928C49.9313 16.7619 48.9993 17.1382 48.0273 17.139ZM48.0273 11.0761C47.5212 11.0767 47.0265 11.2234 46.606 11.4978C46.1854 11.7721 45.8578 12.1618 45.6645 12.6174C45.4712 13.0731 45.421 13.5743 45.5201 14.0578C45.6192 14.5413 45.8633 14.9853 46.2214 15.3337C46.5796 15.6821 47.0357 15.9193 47.5322 16.0152C48.0287 16.1112 48.5433 16.0616 49.0109 15.8728C49.4785 15.684 49.8781 15.3644 50.1593 14.9544C50.4404 14.5445 50.5905 14.0625 50.5905 13.5695C50.5896 12.9079 50.3192 12.2737 49.8386 11.8062C49.358 11.3387 48.7066 11.0761 48.0273 11.0761Z"
          fill="#E23B77"
        />
        <path
          d="M34.7066 24.2933C36.4255 24.2933 37.819 22.9361 37.819 21.2619C37.819 19.5876 36.4255 18.2304 34.7066 18.2304C32.9877 18.2304 31.5942 19.5876 31.5942 21.2619C31.5942 22.9361 32.9877 24.2933 34.7066 24.2933Z"
          fill="white"
        />
        <path
          d="M34.7063 24.8314C33.9815 24.8314 33.2729 24.622 32.6702 24.2298C32.0676 23.8376 31.5978 23.2801 31.3205 22.6279C31.0431 21.9756 30.9705 21.2579 31.1119 20.5655C31.2533 19.8731 31.6024 19.2371 32.1149 18.7379C32.6274 18.2387 33.2804 17.8987 33.9913 17.761C34.7022 17.6232 35.4391 17.6939 36.1087 17.9641C36.7784 18.2343 37.3507 18.6918 37.7534 19.2788C38.1561 19.8658 38.371 20.5559 38.371 21.2619C38.3694 22.2081 37.9827 23.115 37.2958 23.7841C36.6089 24.4531 35.6777 24.8297 34.7063 24.8314ZM34.7063 18.7685C34.2 18.7685 33.705 18.9147 33.284 19.1887C32.863 19.4627 32.5349 19.8521 32.3412 20.3077C32.1474 20.7633 32.0967 21.2646 32.1955 21.7483C32.2943 22.232 32.5381 22.6763 32.8961 23.025C33.2541 23.3737 33.7103 23.6112 34.2068 23.7074C34.7034 23.8036 35.2182 23.7542 35.6859 23.5655C36.1537 23.3768 36.5535 23.0572 36.8348 22.6471C37.1161 22.2371 37.2662 21.755 37.2662 21.2619C37.2654 20.6008 36.9954 19.9671 36.5155 19.4997C36.0356 19.0322 35.385 18.7693 34.7063 18.7685Z"
          fill="currentColor"
        />
        <path
          d="M25.2462 34.7405C26.9651 34.7405 28.3585 33.3832 28.3585 31.709C28.3585 30.0348 26.9651 28.6776 25.2462 28.6776C23.5272 28.6776 22.1338 30.0348 22.1338 31.709C22.1338 33.3832 23.5272 34.7405 25.2462 34.7405Z"
          fill="white"
        />
        <path
          d="M25.2463 35.2785C24.5207 35.2791 23.8112 35.0701 23.2076 34.6779C22.604 34.2857 22.1334 33.728 21.8553 33.0752C21.5772 32.4225 21.5041 31.7041 21.6453 31.0108C21.7865 30.3176 22.1355 29.6808 22.6484 29.1808C23.1612 28.6809 23.8148 28.3403 24.5264 28.2022C25.238 28.0641 25.9756 28.1347 26.646 28.405C27.3165 28.6753 27.8895 29.1332 28.2927 29.7208C28.6959 30.3084 28.9111 30.9992 28.9111 31.7059C28.9102 32.6526 28.5239 33.5604 27.8369 34.2301C27.1499 34.8998 26.2183 35.2769 25.2463 35.2785ZM25.2463 29.2125C24.7391 29.2119 24.2431 29.3579 23.8211 29.632C23.3991 29.906 23.0701 30.2959 22.8757 30.7522C22.6813 31.2085 22.6303 31.7107 22.7291 32.1953C22.8279 32.6798 23.0721 33.125 23.4307 33.4743C23.7894 33.8236 24.2464 34.0614 24.7439 34.1577C25.2414 34.2539 25.757 34.2042 26.2255 34.0149C26.6939 33.8255 27.0942 33.5051 27.3756 33.0941C27.657 32.683 27.8069 32.1999 27.8063 31.7059C27.8054 31.0449 27.5354 30.4111 27.0555 29.9437C26.5756 29.4763 25.925 29.2133 25.2463 29.2125Z"
          fill="currentColor"
        />
        <path
          d="M13.0303 43.4782C14.7493 43.4782 16.1427 42.121 16.1427 40.4467C16.1427 38.7725 14.7493 37.4153 13.0303 37.4153C11.3114 37.4153 9.91797 38.7725 9.91797 40.4467C9.91797 42.121 11.3114 43.4782 13.0303 43.4782Z"
          fill="white"
        />
        <path
          d="M13.0302 44.0162C12.3048 44.0162 11.5956 43.8067 10.9924 43.4141C10.3893 43.0216 9.91913 42.4636 9.64151 41.8108C9.36389 41.158 9.29126 40.4397 9.43278 39.7467C9.57431 39.0537 9.92365 38.4171 10.4366 37.9175C10.9496 37.4178 11.6031 37.0776 12.3147 36.9397C13.0262 36.8019 13.7637 36.8726 14.4339 37.143C15.1041 37.4134 15.677 37.8713 16.08 38.4589C16.483 39.0464 16.6982 39.7371 16.6982 40.4437C16.6973 41.3909 16.3106 42.2991 15.6229 42.9689C14.9352 43.6388 14.0028 44.0154 13.0302 44.0162ZM13.0302 37.9502C12.5232 37.9502 12.0275 38.0967 11.6059 38.3712C11.1843 38.6456 10.8558 39.0357 10.6619 39.4921C10.468 39.9484 10.4174 40.4506 10.5166 40.9349C10.6157 41.4193 10.8602 41.8641 11.2189 42.2131C11.5777 42.5621 12.0347 42.7996 12.5321 42.8956C13.0295 42.9916 13.545 42.9417 14.0133 42.7523C14.4816 42.5629 14.8817 42.2424 15.1629 41.8315C15.4442 41.4205 15.594 40.9376 15.5934 40.4437C15.5925 39.7821 15.3221 39.1479 14.8415 38.6804C14.3609 38.2128 13.7095 37.9502 13.0302 37.9502Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="curvecrescitaillustration_clip0_2031_5496">
          <rect width="50" height="39" fill="white" transform="translate(5 10)" />
        </clipPath>
      </defs>
    </IconBase>
  )
}

export default CurveCrescitaIllustration
