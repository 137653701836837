import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function ProdottiIllustration({ className }: Props) {
  return (
    <IconBase name="prodotti-illustration" w={40} className={className}>
      <g clipPath="url(#prodottiillustration_clip0_2033_6291)">
        <path d="M22.5171 5.50525L20.0549 3.04297H6.17224L3.70996 5.50525" fill="white" />
        <path
          d="M22.5173 5.8821C22.4678 5.88225 22.4187 5.87262 22.3729 5.85379C22.3271 5.83495 22.2855 5.80728 22.2504 5.77233L19.9 3.41982H6.34025L3.97268 5.77233C3.90218 5.84312 3.80645 5.88299 3.70655 5.8832C3.60665 5.8834 3.51076 5.84391 3.43998 5.77341C3.36919 5.70291 3.3293 5.60718 3.3291 5.50728C3.3289 5.40738 3.3684 5.31149 3.4389 5.2407L5.90979 2.77842C5.98056 2.70746 6.07645 2.66725 6.17667 2.6665H20.0593C20.1086 2.66681 20.1574 2.67685 20.2028 2.69606C20.2483 2.71526 20.2894 2.74325 20.324 2.77842L22.7863 5.2407C22.8398 5.29314 22.8765 5.36034 22.8916 5.43372C22.9067 5.5071 22.8996 5.58332 22.8712 5.65264C22.8428 5.72196 22.7943 5.78123 22.7321 5.82287C22.6698 5.86451 22.5965 5.88663 22.5216 5.88641L22.5173 5.8821Z"
          fill="#164194"
        />
        <path
          d="M29.2845 4.72607H31.7059C32.0465 4.72607 32.3837 4.79316 32.6984 4.9235C33.0131 5.05384 33.299 5.24488 33.5398 5.48571C33.7806 5.72655 33.9717 6.01246 34.102 6.32713C34.2324 6.6418 34.2994 6.97905 34.2994 7.31965V9.87878H26.6909V7.31965C26.6909 6.63179 26.9642 5.9721 27.4506 5.48571C27.9369 4.99932 28.5966 4.72607 29.2845 4.72607Z"
          fill="#F7C5D7"
        />
        <path
          d="M34.2992 10.2556H26.6906C26.5907 10.2556 26.4949 10.216 26.4243 10.1453C26.3537 10.0747 26.314 9.97888 26.314 9.87898V7.322C26.3145 6.53422 26.6276 5.77884 27.1844 5.22159C27.7413 4.66435 28.4964 4.35075 29.2842 4.34961H31.7056C32.4934 4.35075 33.2485 4.66435 33.8053 5.22159C34.3622 5.77884 34.6753 6.53422 34.6758 7.322V9.87898C34.6758 9.97888 34.6361 10.0747 34.5655 10.1453C34.4949 10.216 34.3991 10.2556 34.2992 10.2556ZM27.0673 9.50232H33.9225V7.322C33.9219 6.73402 33.6882 6.17024 33.2727 5.75427C32.8571 5.3383 32.2936 5.10407 31.7056 5.10293H29.2842C28.6962 5.10407 28.1327 5.3383 27.7171 5.75427C27.3015 6.17024 27.0679 6.73402 27.0673 7.322V9.50232Z"
          fill="#E23B77"
        />
        <path
          d="M24.717 32.0158V14.9111C24.717 14.9111 24.4049 12.014 26.6907 9.87891H34.2992C36.585 12.014 36.2729 14.9111 36.2729 14.9111V32.0158C36.2729 32.5084 36.0772 32.9808 35.7289 33.3292C35.3805 33.6775 34.9081 33.8732 34.4154 33.8732H26.5723C26.0802 33.8721 25.6087 33.676 25.2609 33.3278C24.9132 32.9796 24.7176 32.5078 24.717 32.0158Z"
          fill="white"
        />
        <path
          d="M34.4152 34.2501H26.5721C25.9799 34.2489 25.4123 34.0132 24.9936 33.5945C24.5749 33.1757 24.3391 32.6081 24.338 32.0159V14.9306C24.2785 13.9438 24.4351 12.9559 24.7968 12.0359C25.1585 11.1158 25.7166 10.2857 26.4322 9.6036C26.5018 9.53876 26.5933 9.50262 26.6884 9.50244H34.2969C34.3926 9.50251 34.4848 9.53862 34.5552 9.6036C35.2707 10.2857 35.8286 11.1158 36.19 12.0359C36.5514 12.956 36.7074 13.9439 36.6472 14.9306V32.0159C36.6467 32.6079 36.4114 33.1755 35.993 33.5943C35.5746 34.0131 35.0072 34.2489 34.4152 34.2501ZM26.8433 10.2558C24.8438 12.2208 25.0892 14.8446 25.0913 14.8725C25.0924 14.8854 25.0924 14.8984 25.0913 14.9113V32.0159C25.0936 32.4074 25.2505 32.7821 25.5279 33.0582C25.8053 33.3344 26.1807 33.4897 26.5721 33.4903H34.4131C34.8057 33.4897 35.182 33.3335 35.4596 33.0559C35.7372 32.7784 35.8934 32.402 35.8939 32.0095V14.9113C35.8939 14.8984 35.8939 14.8855 35.8939 14.8704C35.8939 14.8553 36.1436 12.2208 34.1441 10.2558H26.8433Z"
          fill="#164194"
        />
        <path d="M22.5171 5.50537H3.70996V31.0558H22.5171V5.50537Z" fill="white" />
        <path
          d="M22.5173 31.4328H3.71016C3.61026 31.4328 3.51446 31.3931 3.44382 31.3224C3.37318 31.2518 3.3335 31.156 3.3335 31.0561V5.50996C3.3335 5.41006 3.37318 5.31426 3.44382 5.24362C3.51446 5.17299 3.61026 5.1333 3.71016 5.1333H22.5173C22.6171 5.13387 22.7125 5.17373 22.783 5.24425C22.8536 5.31476 22.8934 5.41024 22.894 5.50996V31.0561C22.894 31.1056 22.8842 31.1546 22.8653 31.2003C22.8464 31.246 22.8186 31.2875 22.7837 31.3224C22.7487 31.3574 22.7072 31.3852 22.6615 31.4041C22.6158 31.423 22.5668 31.4328 22.5173 31.4328ZM4.08682 30.6795H22.1407V5.88232H4.08682V30.6795Z"
          fill="#164194"
        />
        <path d="M7.0293 25.6278L9.49158 23.1655H27.9006L30.3629 25.6278" fill="#D3DFF0" />
        <path
          d="M30.3626 26.0042C30.2633 26.0041 30.168 25.9646 30.0978 25.8944L27.7453 23.5419H9.64626L7.29589 25.8944C7.22539 25.9652 7.12967 26.0051 7.02977 26.0053C6.92986 26.0055 6.83398 25.966 6.76319 25.8955C6.69241 25.825 6.65255 25.7293 6.65234 25.6294C6.65214 25.5295 6.69162 25.4336 6.76212 25.3628L9.22441 22.9005C9.29519 22.8295 9.39108 22.7893 9.4913 22.7886H27.9003C27.95 22.7887 27.9991 22.7987 28.0449 22.8179C28.0907 22.8371 28.1323 22.8652 28.1672 22.9005L30.6294 25.3628C30.6831 25.4153 30.7198 25.4827 30.7349 25.5563C30.7499 25.6299 30.7427 25.7063 30.714 25.7757C30.6853 25.8451 30.6365 25.9044 30.5739 25.9458C30.5113 25.9873 30.4377 26.0091 30.3626 26.0085V26.0042Z"
          fill="#164194"
        />
        <path d="M30.3629 25.6279H7.0293V37.5734H30.3629V25.6279Z" fill="#D3DFF0" />
        <path
          d="M30.3626 37.952H7.029C6.92911 37.952 6.8333 37.9123 6.76267 37.8416C6.69203 37.771 6.65234 37.6752 6.65234 37.5753V25.6276C6.65234 25.5277 6.69203 25.4319 6.76267 25.3613C6.8333 25.2907 6.92911 25.251 7.029 25.251H30.3626C30.4625 25.251 30.5583 25.2907 30.6289 25.3613C30.6995 25.4319 30.7392 25.5277 30.7392 25.6276V37.5796C30.7381 37.6787 30.6979 37.7734 30.6274 37.8432C30.5569 37.9129 30.4617 37.952 30.3626 37.952ZM7.41644 37.1986H29.9967V26.0065H7.41644V37.1986Z"
          fill="#164194"
        />
        <path d="M20.3995 13.835H5.82812V18.7143H20.3995V13.835Z" fill="#D3DFF0" />
        <path
          d="M20.3992 19.0931H5.82783C5.72794 19.0931 5.63213 19.0534 5.5615 18.9828C5.49086 18.9122 5.45117 18.8164 5.45117 18.7165V13.8371C5.45117 13.7372 5.49086 13.6414 5.5615 13.5708C5.63213 13.5001 5.72794 13.4604 5.82783 13.4604H20.3992C20.4991 13.4604 20.5949 13.5001 20.6655 13.5708C20.7362 13.6414 20.7758 13.7372 20.7758 13.8371V18.7165C20.7753 18.8162 20.7354 18.9117 20.6649 18.9822C20.5944 19.0527 20.4989 19.0926 20.3992 19.0931ZM6.19372 18.3398H20.0118V14.2138H6.19372V18.3398Z"
          fill="#164194"
        />
      </g>
      <defs>
        <clipPath id="prodottiillustration_clip0_2033_6291">
          <rect
            width="33.3333"
            height="35.2855"
            fill="white"
            transform="translate(3.3335 2.6665)"
          />
        </clipPath>
      </defs>
    </IconBase>
  )
}

export default ProdottiIllustration
