import React from 'react'
import styles from './NotificationBadge.module.sass'

interface Props {
  label: string
}

const NotificationBadge = (props: Props) => {
  const { label = '' } = props

  return label ? (
    <span className={`${styles.notificationBadge} ${styles['notificationBadge--labeled']}`}>{label}</span>
  ) : (
    <span className={styles.notificationBadge} />
  )
}

export default NotificationBadge
