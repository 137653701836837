import React from 'react'
import { Close2 } from 'components/atoms/Icons'
import styles from './Label.module.sass'

interface Props {
  label: string
  size?: 'sm' | 'md'
  responsive?: boolean
  color?: 'white' | 'blue' | 'light-blue'
  shadow?: boolean
  removable?: boolean
  bordered?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
}

const Label = (props: Props) => {
  const {
    label = '',
    size = 'md',
    responsive = false,
    color = 'blue',
    shadow = false,
    removable = false,
    bordered = false,
    onClick = undefined,
    className = '',
  } = props

  const classNames = `
    ${styles.label} 
    ${styles[`label--${size}`]} 
    ${responsive ? styles[`label--responsive`] : ''} 
    ${styles[`label--${color}`]} 
    ${shadow ? styles['label--shadow'] : ''}
    ${bordered ? styles['label--bordered'] : ''}
    ${removable || onClick ? styles['label--clickable'] : ''}
    ${className}
  `

  const labelContent = (
    <>
      <div className={styles.text}>{label}</div>
      {removable && (
        <span className={styles.icon}>
          <Close2 />
        </span>
      )}
    </>
  )

  return removable || onClick ? (
    <button type="button" className={classNames} onClick={onClick}>
      {labelContent}
    </button>
  ) : (
    <div className={classNames}>{labelContent}</div>
  )
}

export default Label
