import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function CiuccioM({ className }: Props) {
  return (
    <IconBase name="ciuccio-m" w={80} className={className}>
      <path d="M14.5898 65.4101C4.17077 54.991 1.55067 41.2903 8.62668 34.2143C15.7027 27.1383 29.4034 29.7584 39.8224 40.1774C50.2415 50.5965 52.8616 64.2972 45.7856 71.3732C38.7096 78.4492 25.0088 75.8291 14.5898 65.4101ZM35.7258 44.2741C27.8743 36.4226 17.3413 33.6929 12.7233 38.311C8.10529 42.929 10.8349 53.4619 18.6864 61.3134C26.5379 69.165 37.0709 71.8946 41.6889 67.2766C46.3069 62.6585 43.5773 52.1256 35.7258 44.2741Z" fill="#D3DFF0" stroke="#164194" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M25.1142 33.049C25.1142 33.049 21.3681 37.5005 21.9464 39.402C22.5248 41.3036 27.7825 46.2677 30.7575 49.2427C33.7325 52.2177 38.6966 57.4754 40.5982 58.0538C42.4997 58.6321 46.9775 54.9079 46.9775 54.9079" fill="white"/>
      <path d="M25.1142 33.049C25.1142 33.049 21.3681 37.5005 21.9464 39.402C22.5248 41.3036 27.7825 46.2677 30.7575 49.2427C33.7325 52.2177 38.6966 57.4754 40.5982 58.0538C42.4997 58.6321 46.9775 54.9079 46.9775 54.9079" stroke="#164194" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M68.9034 65.3152C72.3802 61.8384 63.0618 46.883 48.0903 31.9114C33.1187 16.9398 18.1633 7.62149 14.6865 11.0983C11.2096 14.5752 20.528 29.5305 35.4996 44.5021C50.4711 59.4737 65.4265 68.792 68.9034 65.3152Z" fill="white" stroke="#164194" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M71.6841 29.6401C76.1707 24.2641 77.266 15.751 70.7596 9.24021C64.2488 2.72941 55.7357 3.82914 50.3597 8.31573C46.1491 11.8296 44.3001 17.9242 43.9365 20.7458C43.2749 25.894 38.2888 30.8363 36.3347 32.602C35.8834 33.0095 35.7958 33.6842 36.1375 34.1881C36.9131 35.3316 38.4334 37.4084 40.5146 39.4896C42.5958 41.5708 44.6726 43.0911 45.8161 43.8666C46.32 44.2084 46.9947 44.1208 47.4022 43.6695C49.1679 41.711 54.1102 36.7293 59.254 36.0633C62.0756 35.6996 68.1702 33.8507 71.6841 29.6401Z" fill="#D3DFF0" stroke="#164194" strokeWidth="2" strokeMiterlimit="10"/>
    </IconBase>
  )
}

export default CiuccioM
