import { useState } from 'react'

function useCookie(key, initialValue) {
  // ? Faccio i check su Window per evitare Crash dovuti al SSR
  const [cookieExists, setCookieExists] = useState(() => {
    try {
      if (typeof window !== 'undefined') {
        return document.cookie.match(/^(.*;)?\s*disclaimer\s*=\s*[^;]+(.*)?$/)
      }
    } catch (error) {
      console.log(error)
      return initialValue
    }
  })

  // ? Versione Wrappata del setState => per settare il Valore Nuovo
  const setCookie = (key, value, expirationDays) => {
    try {
      if (typeof window !== 'undefined') {
        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        document.cookie = `${key}=${value}; expires=${tomorrow.toUTCString()}; path=/; secure`
      }
    } catch (error) {
      console.log(error)
    }
  }

  return [cookieExists, setCookie]
}

export default useCookie
