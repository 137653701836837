import React from 'react'
import styles from './HeaderBox.module.sass'


interface Props {
  title?: string
  description?: string
  classNameHeader?: string
  classNameTitle?: string
  classNameDescription?: string
}

const HeaderBox = (props: Props) => {

  const {
    title = '',
    description = '',
    classNameHeader = '',
    classNameTitle = '',
    classNameDescription = '',
  } = props

  return (
     <div className={`${styles.header} ${classNameHeader}`}>
        <div className={`${styles.title} ${classNameTitle}`}>{title}</div>
        <div
            className={`${styles.description} ${classNameDescription}`}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
        />
     </div>
  )
}

export default HeaderBox
