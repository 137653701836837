import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function ChevronRight({ className }: Props) {
  return (
    <IconBase name="chevron-right" w={11} h={10} className={className}>
      <path d="M4.6665 2.08325L7.37484 4.79159L4.6665 7.49992" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </IconBase>
  )
}

export default ChevronRight
