/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Link from 'next/link'
import styles from './SettimanaGravidanza.module.sass'

interface Props {
  numero: string
  url: string
  selected?: boolean
  className?: string
}

const SettimanaGravidanza = (props: Props) => {
  const { numero, url, selected = false, className = '' } = props

  return (
    <Link legacyBehavior href={url}>
      <a
        className={`${styles.settimana} ${
          selected ? styles['settimana--selected'] : ''
        } ${className}`}
      >
        {numero}°
      </a>
    </Link>
  )
}

export default SettimanaGravidanza
