/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import isEqual from 'lodash/isEqual'
import { useMemo } from 'react'
import merge from 'deepmerge'

const GQL_HOST = process.env.GQL_HOST

let apolloClient
let prevToken

export default function createApolloClient(initialState = null, jwt = null, cookies = null) {
  // The `ctx` (NextPageContext) will only be present on the server.
  // use it to extract auth headers (ctx.req) or similar.

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',

    link: createHttpLink({
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${jwt || ''}`,
      },
      uri: GQL_HOST, // Server URL (must be absolute)
    }),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            me: {
              merge: true,
            },
          },
        },
      },
    }).restore(initialState),
  })
}

export function initializeApollo(initialState = null, jwt = null, cookies = null) {
  const _apolloClient =
    jwt !== prevToken
      ? createApolloClient(initialState, jwt, cookies)
      : apolloClient ?? createApolloClient(initialState, jwt, cookies)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  prevToken = jwt

  return _apolloClient
}

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

export function useApollo(pageProps, cookies = null) {
  const jwt = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('token')) : null
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(() => initializeApollo(state, jwt, cookies), [state, jwt, cookies])
  return store
}
