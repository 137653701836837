/* eslint-disable react-hooks/exhaustive-deps */
import '../styles/global.sass'

import React, { useEffect, useState } from 'react'
import App, { AppProps } from 'next/app'
import { ApolloProvider } from '@apollo/client'
import { useApollo } from 'gql/client'
import NProgress from 'nprogress'
import Router, { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { wrapper } from '../store'
import useGTM from '@hooks/useGTM'
import { api, isEmpty } from 'utils'
import { setPagina } from 'containers/pagina'
import { REDIRECTS } from 'utils/endpoint'
import { fetchTraduzioni } from 'containers/traduzioni'
import { fetchMenu } from 'containers/menu'
import useUtente from '@hooks/useUtente'
import NewRelic from 'utils/NewRelic'
import { LoaderOverlay } from '@components/atoms'
import { useInBoundMutation } from '@gql/graphql'
import useLocalStorage from '@hooks/useLocalStorage'
import useTrans from '@hooks/useTrans'

NProgress.configure({
  minimum: 0.3,
  easing: 'ease',
  speed: 800,
  showSpinner: false,
})

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

function PageWrapper({ loginRequired = false, pageProps, children }) {
  const router = useRouter()
  const t = useTrans()
  const { pagina } = useSelector((state: any) => state?.pagina)

  const { utente, loading } = useUtente()
  const dispatch = useDispatch()
  const { initGTMdataLayer, updateGTMDataLayer } = useGTM()

  const [_, setToken] = useLocalStorage('token', null)
  const [inbound, { ld }] = useInBoundMutation()

  // if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
  //   const ReactDOM = require('react-dom')
  //   const axe = require('@axe-core/react')
  //   axe.default(React, ReactDOM, 1000)
  // }

  useEffect(() => {
    initGTMdataLayer('GTM-5ZCNRSV')
  }, [])

  useEffect(() => {
    if (isEmpty(pageProps)) {
      dispatch(setPagina({ pagina: { chiave: '404', titolo: '404' } }))
    } else {
      dispatch(setPagina(pageProps))
    }
  }, [router.asPath])

  useEffect(() => {
    const checkUtente = setTimeout(() => {
      if (loginRequired && !loading && !utente) router.replace(`/login?next=${router.asPath}`)
    }, 300)

    return () => clearTimeout(checkUtente)
  }, [loginRequired, utente, loading])

  useEffect(() => {
    if (!loading && !isEmpty(pagina)) {
      updateGTMDataLayer(pagina, utente)
    }
  }, [utente, loading, pagina])

  useEffect(() => {
    const jwt = router.query?.jwt

    if (jwt) {
      inbound({
        variables: {
          token: {
            authorization: jwt,
          },
        },
      })
        .then((res) => {
          const refreshed_token = res.data?.inbound?.data?.token
          if (refreshed_token) {
            setToken(refreshed_token)
            window.location.href = router.asPath.split('?')[0]
          }
          router.push('/login')
        })
        .catch(() => {
          router.push('/login')
        })
    }
  }, [router.query?.jwt])

  return loginRequired ? (
    loading ? (
      <LoaderOverlay />
    ) : utente ? (
      children
    ) : (
      <LoaderOverlay />
    )
  ) : (
    children
  )
}

interface CustomAppProps {
  cookies?: any
}

function MyApp({ Component, pageProps, cookies }: AppProps & CustomAppProps) {
  const apolloStore = useApollo(pageProps, cookies)
  const router = useRouter()

  return (
    <ApolloProvider client={apolloStore}>
      <PageWrapper loginRequired={Component.loginRequired || false} pageProps={pageProps}>
        <Component {...pageProps} />
        <NewRelic />
      </PageWrapper>
    </ApolloProvider>
  )
}

export default wrapper.withRedux(MyApp)
