import React, { Component } from 'react'
import { Close2 } from 'components/atoms/Icons'
import styles from './NotificationBar.module.sass'

interface Props {
  label: string
  icon: Component
  variant: 'alert' | 'warning' | 'success'
  onClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const NotificationBar = (props: Props) => {
  const { label = '', icon = null, variant = 'alert', onClose = () => {} } = props

  return (
    <div className={`${styles.notificationBar} ${styles[`notificationBar--${variant}`]}`}>
      {icon && <span className={styles.icon}>{icon}</span>}
      <span className={styles.label}>{label}</span>
      {onClose && (
        <button
          className={styles.close}
          type="button"
          aria-label="Close"
          onClick={onClose}
        >
          <Close2 />
        </button>
      )}
    </div>
  )
}

export default NotificationBar
