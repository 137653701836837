import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Edit({ className }: Props) {
  return (
    <IconBase name="edit" w={16} className={className}>
      <path d="M0.5 12.375V15.5H3.625L12.8417 6.28333L9.71667 3.15833L0.5 12.375ZM15.8417 3.28333L12.7167 0.158325L10.6083 2.27499L13.7333 5.39999L15.8417 3.28333Z" fill="currentColor"/>
    </IconBase>
  )
}

export default Edit
