import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Pediatra({ className }: Props) {
  return (
    <IconBase name="pediatra" w={90} className={className}>
      <path d="M50.9199 87.3104C36.9599 87.3104 25.6099 75.9604 25.6099 62.0004V56.8003H30.6099V62.0004C30.6099 73.2004 39.7199 82.3104 50.9199 82.3104C62.1199 82.3104 71.2299 73.2004 71.2299 62.0004V28.8503H76.2299V62.0004C76.2299 75.9604 64.8699 87.3104 50.9199 87.3104Z" fill="#D3DFF0" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M73.7298 28.8602C80.8708 28.8602 86.6598 23.0713 86.6598 15.9302C86.6598 8.7892 80.8708 3.00024 73.7298 3.00024C66.5888 3.00024 60.7998 8.7892 60.7998 15.9302C60.7998 23.0713 66.5888 28.8602 73.7298 28.8602Z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M73.73 23.6602C77.9992 23.6602 81.46 20.1994 81.46 15.9302C81.46 11.661 77.9992 8.2002 73.73 8.2002C69.4608 8.2002 66 11.661 66 15.9302C66 20.1994 69.4608 23.6602 73.73 23.6602Z" fill="#E23B77" fillOpacity="0.3" stroke="#E23B77" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M9.32022 19.8215C8.91022 17.8715 8.70022 15.2615 9.98021 13.6915C11.1302 12.2715 13.1202 12.0615 14.2102 12.0615V7.06152C10.7902 7.06152 7.91022 8.30152 6.10022 10.5515C4.07022 13.0615 3.52022 16.5715 4.46022 20.9615L10.3402 41.2315L15.0802 39.6215L9.32022 19.8215Z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M40.3601 42.3304C39.9801 43.2504 36.2101 51.8004 28.1101 51.8004C19.9801 51.8004 16.2701 43.3204 15.8601 42.3304L15.0701 39.6204L10.3301 41.2304L11.0901 43.8404L11.1601 44.0404C11.3601 44.5604 16.1901 56.8004 28.1101 56.8004C40.0301 56.8004 44.8601 44.5604 45.0601 44.0404L45.9701 40.9004L41.1301 39.6604L40.3601 42.3304Z" fill="#D3DFF0" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M50.1201 10.5403C48.3001 8.29029 45.4201 7.05029 42.0101 7.05029V12.0503C43.1001 12.0503 45.0901 12.2603 46.2401 13.6803C47.5101 15.2503 47.3101 17.8703 46.9001 19.8103L41.1401 39.6503L45.9801 40.8903L51.7301 21.1103L51.7701 20.9403C52.7101 16.5603 52.1601 13.0503 50.1201 10.5403Z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M14.2002 12.7503C14.2002 14.1403 15.7202 15.1703 17.2302 14.7603C20.2202 13.9303 22.3302 11.9103 22.3302 9.55032C22.3302 7.19032 20.2202 5.17032 17.2302 4.34032C15.7302 3.92032 14.2002 4.96032 14.2002 6.35032V12.7503Z" fill="#D3DFF0" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M42.0104 12.7503C42.0104 14.1403 40.4904 15.1703 38.9804 14.7603C35.9904 13.9303 33.8804 11.9103 33.8804 9.55032C33.8804 7.19032 35.9904 5.17032 38.9804 4.34032C40.4804 3.92032 42.0104 4.96032 42.0104 6.35032V12.7503Z" fill="#D3DFF0" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
    </IconBase>
  )
}

export default Pediatra
