import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Scrivici({ className }: Props) {
  return (
    <IconBase name="scrivici" w={100} className={className}>
      <rect width="100" height="100" rx="50" fill="currentColor"/>
      <g clipPath="url(#clip0_4495_2013)">
      <path d="M33.3333 33.3333H66.6666V58.3333H35.7708L33.3333 60.7708V33.3333ZM33.3333 29.1667C31.0416 29.1667 29.1875 31.0417 29.1875 33.3333L29.1666 70.8333L37.5 62.5H66.6666C68.9583 62.5 70.8333 60.625 70.8333 58.3333V33.3333C70.8333 31.0417 68.9583 29.1667 66.6666 29.1667H33.3333ZM37.5 50H62.5V54.1667H37.5V50ZM37.5 43.75H62.5V47.9167H37.5V43.75ZM37.5 37.5H62.5V41.6667H37.5V37.5Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_4495_2013">
      <rect width="50" height="50" fill="currentColor" transform="translate(25 25)"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default Scrivici
