import React from 'react'
import styles from './ProgressBar.module.sass'

interface Props {
  value: number
  max: number
}

const ProgressBar = (props: Props) => {
  const { value = 0, max = 100 } = props

  return (
    <div className={styles.progressBar}>
      <span className={styles.progress} style={{ width: `${(value / max) * 100}%` }} />
    </div>
  )
}

export default ProgressBar
