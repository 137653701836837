import React from 'react'
import { Props as ButtonProps } from 'components/atoms/Button/Button'
import { Button } from 'components/atoms'
import styles from './SocialButton.module.sass'
import { Facebook } from '../Icons'

interface Props extends ButtonProps {
  social: 'facebook',
  template: string,
}

const SocialButton = ({ icon, social, template, className, ...props }: Props) => {
  const socialIcon = icon || (social === 'facebook' ? <Facebook /> : null)

  return (
    <Button
      {...{
        icon: socialIcon,
        iconPos: 'left',
        ...props,
        className: `${social === 'facebook' ? styles.facebook : ''} ${
          template ? styles[`template--${template}`] : ''}${
          className ? `${className}` : ''
        }`,
      }}
    />
  )
}

export default SocialButton
