// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://d9bc8bc374f24eb287a3a7d86a6e0fba@o19983.ingest.sentry.io/4504445685858304',
  // Adjust this value in production, or use tracesSampler for greater control
  // tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development',
  ignoreErrors: ['Cannot set headers after they are sent to the client'],
  integrations: [
    new Sentry.Integrations.BrowserTracing({
      tracingOrigins: ['*'],
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  ignoreErrors: ['Cannot set headers after they are sent to the client'],
})
