import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Print({ className }: Props) {
  return (
    <IconBase name="print" w={25} h={24} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.5 3H17.5C18.05 3 18.5 3.46667 18.5 4.03704V6.11111C18.5 6.68148 18.05 7.14815 17.5 7.14815H7.5C6.95 7.14815 6.5 6.68148 6.5 6.11111V4.03704C6.5 3.46667 6.95 3 7.5 3ZM5.5 8.18518H19.5C21.16 8.18518 22.5 9.57481 22.5 11.2963V15.4444C22.5 16.5852 21.6 17.5185 20.5 17.5185H18.5V19.5926C18.5 20.7333 17.6 21.6667 16.5 21.6667H8.5C7.4 21.6667 6.5 20.7333 6.5 19.5926V17.5185H4.5C3.4 17.5185 2.5 16.5852 2.5 15.4444V11.2963C2.5 9.57481 3.84 8.18518 5.5 8.18518ZM9.5 19.5926H15.5C16.05 19.5926 16.5 19.1259 16.5 18.5556V14.4074H8.5V18.5556C8.5 19.1259 8.95 19.5926 9.5 19.5926ZM19.5 12.3333C18.95 12.3333 18.5 11.8667 18.5 11.2963C18.5 10.7259 18.95 10.2593 19.5 10.2593C20.05 10.2593 20.5 10.7259 20.5 11.2963C20.5 11.8667 20.05 12.3333 19.5 12.3333Z" fill="#2A2A2A"/>
    </IconBase>
  )
}

export default Print
