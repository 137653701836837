import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Reload({ className }: Props) {
  return (
    <IconBase name="reload" w={25} h={24} className={className}>
      <g clipPath="url(#reload_clip0_1320_1293)">
      <path d="M12.75 4V1L8.75 5L12.75 9V6C16.06 6 18.75 8.69 18.75 12C18.75 13.01 18.5 13.97 18.05 14.8L19.51 16.26C20.29 15.03 20.75 13.57 20.75 12C20.75 7.58 17.17 4 12.75 4ZM12.75 18C9.44 18 6.75 15.31 6.75 12C6.75 10.99 7 10.03 7.45 9.2L5.99 7.74C5.21 8.97 4.75 10.43 4.75 12C4.75 16.42 8.33 20 12.75 20V23L16.75 19L12.75 15V18Z" fill="currentColor"/>
      </g>
      <defs>
      <clipPath id="reload_clip0_1320_1293">
      <rect width="24" height="24" fill="currentColor" transform="translate(0.75)"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default Reload
