export { default as Account } from './Account'
export { default as Alert } from './Alert'
export { default as ArrowDown } from './ArrowDown'
export { default as ArrowLeft } from './ArrowLeft'
export { default as ArrowRight } from './ArrowRight'
export { default as ArrowUp } from './ArrowUp'
export { default as BambiniIllustration } from './BambiniIllustration'
export { default as BCorp } from './BCorp'
export { default as BiberonIllustration } from './BiberonIllustration'
export { default as Bin } from './Bin'
export { default as Calendar } from './Calendar'
export { default as CalendarIllustration } from './CalendarIllustration'
export { default as Check } from './Check'
export { default as CheckboxMarked } from './CheckboxMarked'
export { default as CheckboxMixed } from './CheckboxMixed'
export { default as CheckboxUnmarked } from './CheckboxUnmarked'
export { default as ChevronDown } from './ChevronDown'
export { default as ChevronLeft } from './ChevronLeft'
export { default as ChevronRight } from './ChevronRight'
export { default as ChevronUp } from './ChevronUp'
export { default as Chiamaci } from './Chiamaci'
export { default as Ciuccio } from './Ciuccio'
export { default as CiuccioF } from './CiuccioF'
export { default as CiuccioM } from './CiuccioM'
export { default as CiuccioX } from './CiuccioX'
export { default as ClessidraIllustration } from './ClessidraIllustration'
export { default as Close } from './Close'
export { default as Close2 } from './Close2'
export { default as CoccardaIllustration } from './CoccardaIllustration'
export { default as ConcorsiEPromozioni } from './ConcorsiEPromozioni'
export { default as Copy } from './Copy'
export { default as CurveCrescitaIllustration } from './CurveCrescitaIllustration'
export { default as CustomareCareIllustration } from './CustomareCareIllustration'
export { default as Dashboard } from './Dashboard'
export { default as Dati } from './Dati'
export { default as DirittiIllustration } from './DirittiIllustration'
export { default as Domanda } from './Domanda'
export { default as DomandeIllustration } from './DomandeIllustration'
export { default as Edit } from './Edit'
export { default as Expand } from './Expand'
export { default as EyeHide } from './EyeHide'
export { default as EyeShow } from './EyeShow'
export { default as Facebook } from './Facebook'
export { default as Feedback } from './Feedback'
export { default as Gravidanza } from './Gravidanza'
export { default as GravidanzaIllustration } from './GravidanzaIllustration'
export { default as Home } from './Home'
export { default as Hourglass } from './Hourglass'
export { default as HubBg } from './HubBg'
export { default as Info } from './Info'
export { default as Instagram } from './Instagram'
export { default as IscrivitiAllaNewsletter } from './IscrivitiAllaNewsletter'
export { default as LandingProdottoInfoShape } from './LandingProdottoInfoShape'
export { default as LaureaIllustration } from './LaureaIllustration'
export { default as LinkedIn } from './LinkedIn'
export { default as Loader } from './Loader'
export { default as Locked } from './Locked'
export { default as Logo } from './Logo'
export { default as LogoNoBordo } from './LogoNoBordo'
export { default as Logout } from './Logout'
export { default as Lunghezza } from './Lunghezza'
export { default as Mail } from './Mail'
export { default as ManagerDonnaIllustration } from './ManagerDonnaIllustration'
export { default as Menu } from './Menu'
export { default as Minus } from './Minus'
export { default as NeonatoIllustration } from './NeonatoIllustration'
export { default as NewsletterIllustration } from './NewsletterIllustration'
export { default as OstetricaIllustration } from './OstetricaIllustration'
export { default as ParentalPolicyIllustration } from './ParentalPolicyIllustration'
export { default as PartoRiparto } from './PartoRiparto'
export { default as PaternitaIllustration } from './PaternitaIllustration'
export { default as PayoffDesktop } from './PayoffDesktop'
export { default as PayoffMobile } from './PayoffMobile'
export { default as Pediatra } from './Pediatra'
export { default as Peso } from './Peso'
export { default as Phone } from './Phone'
export { default as PianetaIllustration } from './PianetaIllustration'
export { default as Pinterest } from './Pinterest'
export { default as Play } from './Play'
export { default as Plus } from './Plus'
export { default as Print } from './Print'
export { default as ProdottiIllustration } from './ProdottiIllustration'
export { default as Profile } from './Profile'
export { default as Question } from './Question'
export { default as Quote } from './Quote'
export { default as QuoteClose } from './QuoteClose'
export { default as RadioMarked } from './RadioMarked'
export { default as RadioUnmarked } from './RadioUnmarked'
export { default as Reduce } from './Reduce'
export { default as Reload } from './Reload'
export { default as ScarpaIllustration } from './ScarpaIllustration'
export { default as Scrivici } from './Scrivici'
export { default as Search } from './Search'
export { default as Share } from './Share'
export { default as ShoppingBag } from './ShoppingBag'
export { default as SimpleCheck } from './SimpleCheck'
export { default as SocietaIllustration } from './SocietaIllustration'
export { default as SupportoPsicologicoIllustration } from './SupportoPsicologicoIllustration'
export { default as SupportoPsicopedagogicoIllustration } from './SupportoPsicopedagogicoIllustration'
export { default as SvezzamentoIllustration } from './SvezzamentoIllustration'
export { default as Twitter } from './Twitter'
export { default as WelfareIllustration } from './WelfareIllustration'
export { default as YouTube } from './YouTube'
