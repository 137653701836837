import React from 'react'
import { Link } from '@components/atoms'
import styles from './BoxCard.module.sass'

interface Props {
  data: {
    icon: object
    title: string
    subTitle: string
    ctaText: string
    ctaLink: string
    half?: boolean
  }
  minimal?: boolean
  classNameIcon?: string
  classNameInfo?: string
  classNameTitle?: string
}

const BoxCard = (props: Props) => {
  const {
    data = {
      icon: null,
      title: '',
      subTitle: '',
      ctaLink: '',
      ctaText: '',
      half: false,
    },
    minimal = false,
    classNameIcon = null,
    classNameInfo = null,
    classNameTitle = null,
  } = props

  const styleIcon = classNameIcon ? classNameIcon : styles.icon
  const styleInfo = classNameInfo ? classNameInfo : styles.info
  const styleTitle = classNameTitle ? classNameTitle : styles.title

  return (
    <div className={`${styles.boxCard} ${data?.half ? styles['boxCard--half'] : ''}`}>
      <div className={`${styleIcon} ${styles.iconDesktop}`}>{data?.icon}</div>
      <div className={styleInfo}>
        {data?.title && (
          <div className={styleTitle}>
            <div className={`${styleIcon} ${styles.iconMobile}`}>{data?.icon}</div>
            <span> {data?.title} </span>
          </div>
        )}

        {!minimal && (
          <>
            {data?.subTitle && <p className={styles.subTitle}>{data?.subTitle}</p>}
            {data?.ctaText && data?.ctaLink && (
              <Link label={data?.ctaText} href={data?.ctaLink} decoration />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default BoxCard
