import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Close2({ className }: Props) {
  return (
    <IconBase name="close2" w={16} className={className}>
      <path d="M12.667 3.33331L3.33366 12.6666" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.667 12.6667L3.33366 3.33335" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </IconBase>
  )
}

export default Close2
