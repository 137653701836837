import React, { ReactNode } from 'react'
import styles from './Tab.module.sass'

export interface TriggerProps {
  icon?: ReactNode
  label?: string
  active?: boolean
  onClick?: () => void
}

export interface Props {
  active?: boolean
  className?: string
  children?: ReactNode
}

const TabTrigger = (props: TriggerProps) => {
  const { icon = null, label = '', active = false, onClick = () => {} } = props

  return (
    <button
      type="button"
      className={`${styles.trigger} ${active ? styles[`trigger--active`] : ''}`}
      onClick={onClick}
    >
      {icon && <span className={styles.icon}>{icon}</span>}
      {label && <span className={styles.label}>{label}</span>}
    </button>
  )
}

const Tab = (props: Props) => {
  const { active = false, className = '', children = null } = props

  return active ? (
    <div className={`${styles.tab} ${active ? styles[`tab--active`] : ''} ${className}`}>
      {children}
    </div>
  ) : (
    <></>
  )
}

Tab.Trigger = TabTrigger

export default Tab
