import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function CiuccioX({ className }: Props) {
  return (
    <IconBase name="ciuccio-x" w={60} className={className}>
      <path d="M11.6484 48.3517C4.12349 40.8269 2.2312 30.9319 7.34165 25.8214C12.4521 20.711 22.3471 22.6033 29.8719 30.1281C37.3968 37.653 39.2891 47.548 34.1786 52.6584C29.0682 57.7689 19.1732 55.8766 11.6484 48.3517ZM26.9132 33.0868C21.2427 27.4163 13.6356 25.4449 10.3003 28.7801C6.96509 32.1154 8.93649 39.7225 14.607 45.393C20.2776 51.0636 27.8847 53.035 31.2199 49.6997C34.5552 46.3645 32.5838 38.7574 26.9132 33.0868Z" fill="#D3DFF0" stroke="currentColor" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M19.2491 24.9798C19.2491 24.9798 16.5436 28.1948 16.9613 29.5681C17.379 30.9415 21.1762 34.5267 23.3248 36.6753C25.4734 38.8239 29.0587 42.6211 30.432 43.0388C31.8053 43.4565 35.0393 40.7668 35.0393 40.7668" fill="white"/>
      <path d="M19.2491 24.9798C19.2491 24.9798 16.5436 28.1948 16.9613 29.5681C17.379 30.9415 21.1762 34.5267 23.3248 36.6753C25.4734 38.8239 29.0587 42.6211 30.432 43.0388C31.8053 43.4565 35.0393 40.7668 35.0393 40.7668" stroke="currentColor" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M50.8747 48.2832C53.3857 45.7722 46.6558 34.9711 35.843 24.1582C25.0302 13.3454 14.2291 6.61553 11.718 9.12657C9.20699 11.6376 15.9369 22.4387 26.7497 33.2515C37.5625 44.0643 48.3636 50.7943 50.8747 48.2832Z" fill="white" stroke="currentColor" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M52.883 22.5179C56.1233 18.6352 56.9144 12.4868 52.2153 7.78459C47.5131 3.08235 41.3647 3.8766 37.4821 7.11691C34.4411 9.65473 33.1057 14.0564 32.8431 16.0942C32.3653 19.8123 28.7642 23.3817 27.3529 24.657C27.027 24.9513 26.9637 25.4386 27.2105 25.8025C27.7706 26.6284 28.8687 28.1283 30.3717 29.6314C31.8748 31.1344 33.3747 32.2325 34.2006 32.7926C34.5645 33.0394 35.0518 32.9761 35.3461 32.6502C36.6214 31.2357 40.1908 27.6378 43.9057 27.1568C45.9436 26.8942 50.3452 25.5588 52.883 22.5179Z" fill="#D3DFF0" stroke="currentColor" strokeWidth="3" strokeMiterlimit="10"/>
    </IconBase>
  )
}

export default CiuccioX
