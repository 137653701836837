import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function NeonatoIllustration({ className }: Props) {
  return (
    <IconBase name="neonato-illustration" w={91} h={94} className={className}>
      <g clipPath="url(#newborn_clip0_3135_26408)">
      <path d="M45.934 89.3637C58.5784 89.3637 68.8286 76.098 68.8286 59.7338C68.8286 43.3696 58.5784 30.1038 45.934 30.1038C33.2896 30.1038 23.0393 43.3696 23.0393 59.7338C23.0393 76.098 33.2896 89.3637 45.934 89.3637Z" fill="#F7C5D7"/>
      <path d="M45.934 90.1624C32.8681 90.1624 22.2407 76.5108 22.2407 59.7338C22.2407 42.9568 32.8681 29.3052 45.934 29.3052C59 29.3052 69.6274 42.9568 69.6274 59.7338C69.6274 76.5108 59 90.1624 45.934 90.1624ZM45.934 30.9025C33.7466 30.9025 23.838 43.8353 23.838 59.7338C23.838 75.6323 33.7519 88.5651 45.934 88.5651C58.1161 88.5651 68.0301 75.6323 68.0301 59.7338C68.0301 43.8353 58.1161 30.9025 45.934 30.9025Z" fill="#E23B77"/>
      <path d="M65.7884 44.98C65.7884 44.98 60.8474 62.971 24.1787 65.2924L65.7884 44.98Z" fill="#F7C5D7"/>
      <path d="M24.1788 66.091C23.7582 66.091 23.4068 65.7663 23.3801 65.3403C23.3535 64.8984 23.689 64.5204 24.1256 64.4937C34.4069 63.8442 48.6495 61.4482 58.1108 53.7386C63.728 49.1596 65.0058 44.8043 65.0165 44.7617C65.1336 44.3411 65.5755 44.0855 66.0015 44.208C66.4274 44.3251 66.6724 44.7617 66.5552 45.1877C66.502 45.3794 65.1815 49.9849 59.2396 54.878C53.8407 59.3238 43.3837 64.8718 24.2267 66.0857C24.2107 66.0857 24.1948 66.0857 24.1735 66.0857L24.1788 66.091Z" fill="#E23B77"/>
      <path d="M51.3436 58.9511C51.3436 58.9511 35.8711 58.5358 25.4993 46.359L51.3436 58.9511Z" fill="#F7C5D7"/>
      <path d="M51.3435 59.7498C51.3435 59.7498 51.3275 59.7498 51.3222 59.7498C50.678 59.7338 35.3758 59.1801 24.8922 46.8808C24.6047 46.5454 24.6473 46.0396 24.9827 45.7574C25.3181 45.4699 25.8239 45.5125 26.1061 45.8479C36.1159 57.5934 51.2157 58.1525 51.3648 58.1578C51.8067 58.1684 52.1528 58.5358 52.1422 58.9777C52.1315 59.409 51.7748 59.7551 51.3435 59.7551V59.7498Z" fill="#E23B77"/>
      <path d="M62.2371 18.353C62.2371 18.353 64.5745 18.6192 64.1645 23.2035C64.0154 24.8806 63.5362 26.4726 61.497 26.4726C61.497 26.4726 61.3107 27.1009 61.2627 27.2447C58.8721 34.6295 52.7172 39.8846 45.5027 39.8846C38.2882 39.8846 32.1493 34.6402 29.7533 27.266L29.4977 26.4673C27.4585 26.4673 26.9846 24.8753 26.8302 23.1982C26.4203 18.6139 28.7576 18.3477 28.7576 18.3477L28.8375 17.7247C29.9716 8.24208 36.9997 0.931759 45.508 0.931759C54.0163 0.931759 61.1669 8.36986 62.1998 17.975L62.2371 18.3477V18.353Z" fill="white"/>
      <path d="M45.5079 40.8164C38.1337 40.8164 31.4517 35.4867 28.8693 27.5535L28.8055 27.3458C26.2338 26.9305 25.9889 24.2204 25.909 23.2833C25.5683 19.4871 26.9845 18.14 27.911 17.6715L27.9216 17.6076C29.1089 7.57654 36.6748 0 45.5079 0C54.341 0 61.9335 7.58719 63.1049 17.6821C64.0313 18.1613 65.4316 19.5137 65.0908 23.2887C65.0057 24.2258 64.7661 26.9252 62.2051 27.3458C62.1784 27.4257 62.1625 27.4949 62.1465 27.5322C59.5748 35.4761 52.8821 40.8164 45.5026 40.8164H45.5079ZM45.5079 1.86352C37.6172 1.86352 30.85 8.73192 29.7692 17.8365L29.6893 18.4701C29.636 18.8961 29.2953 19.2262 28.8693 19.2741C28.4008 19.37 27.5116 20.307 27.7619 23.1183C27.9429 25.1415 28.5392 25.5355 29.4976 25.5355C29.9023 25.5355 30.259 25.7964 30.3868 26.1798L30.6424 26.9784C32.9691 34.1397 38.943 38.9529 45.5079 38.9529C52.0728 38.9529 58.0574 34.1343 60.3788 26.9571C60.4267 26.8134 60.6024 26.2064 60.6077 26.2064C60.7249 25.8124 61.0869 25.5409 61.5022 25.5409C62.4659 25.5409 63.0569 25.1469 63.238 23.1236C63.4882 20.3124 62.6044 19.3753 62.0986 19.2741C61.662 19.2262 61.3532 18.8801 61.3106 18.4435L61.2733 18.0708C60.283 8.83841 53.4998 1.86884 45.5079 1.86884V1.86352Z" fill="#164194"/>
      <path d="M45.5081 0.931759C45.5081 0.931759 49.3203 4.82918 45.5081 8.75854V0.931759Z" fill="#D3DFF0"/>
      <path d="M45.508 9.6903C45.2738 9.6903 45.0395 9.60511 44.8585 9.42941C44.4911 9.07268 44.4804 8.48168 44.8372 8.1143C47.9785 4.8771 44.9703 1.72509 44.8372 1.59198C44.4751 1.2246 44.4858 0.633596 44.8531 0.276865C45.2205 -0.0851898 45.8115 -0.0745411 46.1683 0.292838C47.707 1.86352 49.6876 5.79821 46.1683 9.41876C45.9872 9.60511 45.7423 9.70095 45.4974 9.70095L45.508 9.6903Z" fill="#164194"/>
      <path d="M40.7429 30.1304C40.7429 30.1304 44.848 32.9417 50.0552 30.1304H40.7429Z" fill="#D3DFF0"/>
      <path d="M45.3271 32.3188C42.3455 32.3188 40.3435 30.9823 40.221 30.8972C39.7951 30.6043 39.6886 30.024 39.9761 29.6033C40.2636 29.1827 40.844 29.0709 41.2699 29.3584C41.4243 29.4649 45.0076 31.797 49.6132 29.3105C50.0657 29.0656 50.6301 29.236 50.875 29.6885C51.12 30.1411 50.9496 30.7055 50.497 30.9504C48.5962 31.978 46.8339 32.3188 45.3218 32.3188H45.3271Z" fill="#164194"/>
      <path d="M62.3862 73.3907C66.9225 67.1506 67.9714 62.9923 64.8513 62.9923C61.7313 62.9923 54.5008 68.1675 53.8992 76.958C53.2975 85.7485 58.8082 93.0482 58.8082 93.0482L68.5358 90.2103C68.5358 90.2103 66.2676 83.688 69.6699 78.0176C73.0721 72.3471 75.5692 66.9962 74.1743 57.9235C72.7846 48.8508 70.378 43.8406 67.1461 44.9747C63.9143 46.1088 67.0663 50.6292 66.8533 64.3234" fill="white"/>
      <path d="M28.6085 73.3907C24.0722 67.1506 23.0233 62.9923 26.1434 62.9923C29.2634 62.9923 36.4939 68.1675 37.0955 76.958C37.6972 85.7485 32.1865 93.0482 32.1865 93.0482L22.4589 90.2103C22.4589 90.2103 24.7271 83.688 21.3248 78.0176C17.9226 72.3471 15.4308 66.9962 16.8205 57.9235C18.2101 48.8508 20.6167 43.8406 23.8486 44.9747C27.0751 46.1088 23.9284 50.6292 24.1414 64.3234" fill="white"/>
      <path d="M68.5357 91.1421C68.1523 91.1421 67.7903 90.9025 67.6572 90.5191C67.5613 90.2369 65.2878 83.5176 68.8711 77.5384C72.0817 72.1874 74.6267 67.0281 73.2531 58.0673C71.8687 49.0265 69.8987 46.6199 68.8445 45.9917C68.3973 45.7254 67.9553 45.6828 67.4549 45.8585C66.4326 46.2206 66.4432 47.1098 66.9544 51.119C67.343 54.1698 67.8755 58.3495 67.785 64.3447C67.7743 64.8558 67.359 65.2604 66.8532 65.2604H66.8372C66.3208 65.2498 65.9108 64.8292 65.9214 64.3127C66.012 58.4559 65.4902 54.3509 65.1068 51.3533C64.601 47.3866 64.2975 44.9907 66.8372 44.1015C67.8595 43.7448 68.8818 43.8459 69.8029 44.3997C72.1669 45.8213 73.9505 50.3257 75.0953 57.7904C76.5595 67.3529 73.7429 73.0446 70.4684 78.5021C67.3377 83.7146 69.3929 89.8483 69.4142 89.9121C69.5793 90.3967 69.3237 90.9291 68.8392 91.0941C68.738 91.1314 68.6369 91.1474 68.5357 91.1474V91.1421Z" fill="#164194"/>
      <path d="M58.8083 93.9746C58.5261 93.9746 58.2492 93.8468 58.0629 93.6019C57.8286 93.2931 52.3498 85.9189 52.9675 76.8835C53.5904 67.8268 61.119 62.0499 64.8514 62.0499C66.3848 62.0499 67.0237 62.8645 67.274 63.349C68.4879 65.729 65.698 70.4144 63.1423 73.9285C62.8388 74.3438 62.2584 74.4396 61.8431 74.1361C61.4278 73.8326 61.3373 73.2523 61.6355 72.837C65.5276 67.486 65.9695 64.8877 65.6181 64.1956C65.5808 64.1264 65.4743 63.9134 64.8567 63.9134C62.5087 63.9134 55.422 68.4178 54.831 77.0113C54.2613 85.3598 59.5004 92.4093 59.5537 92.4785C59.8625 92.8885 59.7826 93.4741 59.3726 93.7829C59.2023 93.9107 59.0053 93.9693 58.8136 93.9693L58.8083 93.9746Z" fill="#164194"/>
      <path d="M22.4589 91.1421C22.3578 91.1421 22.2566 91.1261 22.1501 91.0888C21.6656 90.9184 21.41 90.3913 21.5751 89.9015C21.5964 89.8376 23.6516 83.7093 20.5209 78.4914C17.2517 73.0393 14.4298 67.3476 15.8993 57.7851C17.0441 50.315 18.8224 45.8106 21.1917 44.3944C22.1128 43.8406 23.1404 43.7395 24.1574 44.0962C26.7024 44.9907 26.3936 47.3866 25.8878 51.3533C25.5044 54.3509 24.9827 58.4559 25.0732 64.3127C25.0785 64.8292 24.6685 65.2498 24.1574 65.2604H24.1414C23.6356 65.2604 23.2203 64.8505 23.2097 64.3447C23.1138 58.3548 23.6463 54.1752 24.0403 51.119C24.5514 47.1098 24.562 46.2153 23.5398 45.8585C23.0393 45.6828 22.5974 45.7254 22.1501 45.9917C21.1012 46.6199 19.1259 49.0319 17.7416 58.0673C16.3679 67.0281 18.9129 72.1874 22.1288 77.5384C25.7121 83.5123 23.4439 90.2316 23.3428 90.5191C23.2097 90.9025 22.8476 91.1421 22.4643 91.1421H22.4589Z" fill="#164194"/>
      <path d="M32.1864 93.9746C31.9894 93.9746 31.7924 93.9107 31.6273 93.7883C31.2173 93.4794 31.1322 92.8938 31.4463 92.4838C31.4995 92.4146 36.7387 85.3385 36.169 77.0166C35.5833 68.4231 28.4913 63.9187 26.1432 63.9187C25.5256 63.9187 25.4191 64.1317 25.3819 64.2009C25.0305 64.8931 25.4724 67.4913 29.3592 72.8423C29.6626 73.2576 29.5721 73.8433 29.1515 74.1414C28.7362 74.4449 28.1505 74.3544 27.8524 73.9338C25.2967 70.4197 22.5067 65.7343 23.7207 63.3543C23.9656 62.8698 24.6098 62.0552 26.1432 62.0552C29.8756 62.0552 37.4042 67.8321 38.0272 76.8888C38.6448 85.9242 33.1661 93.2931 32.9318 93.6072C32.7508 93.8522 32.4686 93.9799 32.1864 93.9799V93.9746Z" fill="#164194"/>
      </g>
      <defs>
      <clipPath id="newborn_clip0_3135_26408">
      <rect width="60" height="93.9746" fill="white" transform="translate(15.5)"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default NeonatoIllustration
