import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Hourglass({ className }: Props) {
  return (
    <IconBase name="hourglass" w={24} className={className}>
      <path d="M16.7474 7.78416L16 7.1198L16.7474 7.78416ZM13.5905 12.6644L12.8431 13.3287L13.5905 12.6644ZM16.7474 16.2158L16 16.8802L16.7474 16.2158ZM7.25259 16.2158L6.50518 15.5515L7.25259 16.2158ZM7.25259 7.78416L6.50518 8.44853L7.25259 7.78416ZM6 4V7.1198H8V4H6ZM6.50518 8.44853L9.66205 12L11.1569 10.6713L8 7.1198L6.50518 8.44853ZM9.66205 12L6.50518 15.5515L8 16.8802L11.1569 13.3287L9.66205 12ZM6 16.8802V20H8V16.8802H6ZM8 22H16V20H8V22ZM18 20V16.8802H16V20H18ZM17.4948 15.5515L14.338 12L12.8431 13.3287L16 16.8802L17.4948 15.5515ZM14.338 12L17.4948 8.44853L16 7.1198L12.8431 10.6713L14.338 12ZM18 7.1198V4H16V7.1198H18ZM16 2H8V4H16V2ZM18 4C18 2.89543 17.1046 2 16 2V4V4H18ZM17.4948 8.44853C17.8202 8.08243 18 7.60963 18 7.1198H16L17.4948 8.44853ZM14.338 12L12.8431 10.6713C12.1696 11.429 12.1696 12.571 12.8431 13.3287L14.338 12ZM18 16.8802C18 16.3904 17.8202 15.9176 17.4948 15.5515L16 16.8802H18ZM16 22C17.1046 22 18 21.1046 18 20H16V22ZM6 20C6 21.1046 6.89543 22 8 22V20H6ZM6.50518 15.5515C6.17976 15.9176 6 16.3904 6 16.8802H8L6.50518 15.5515ZM9.66205 12L11.1569 13.3287C11.8304 12.571 11.8304 11.429 11.1569 10.6713L9.66205 12ZM6 7.1198C6 7.60963 6.17976 8.08243 6.50518 8.44853L8 7.1198H6ZM8 4H8V2C6.89543 2 6 2.89543 6 4H8Z" fill="currentColor"/>
    </IconBase>
  )
}

export default Hourglass
