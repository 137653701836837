import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Alert({ className }: Props) {
  return (
    <IconBase name="alert" w={24} className={className}>
      <circle cx="12" cy="12" r="8" stroke="currentColor" strokeWidth="2"/>
      <line x1="12" y1="8" x2="12" y2="13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <circle cx="12" cy="16" r="1" fill="currentColor"/>
    </IconBase>
  )
}

export default Alert
