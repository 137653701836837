import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Pinterest({ className }: Props) {
  return (
    <IconBase name="pinterest" w={24} className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12 0.000213623C5.373 0.000213623 0 5.37221 0 12.0002C0 17.0842 3.163 21.4262 7.627 23.1742C7.522 22.2252 7.427 20.7692 7.669 19.7322C7.887 18.7962 9.076 13.7682 9.076 13.7682C9.076 13.7682 8.717 13.0492 8.717 11.9862C8.717 10.3182 9.684 9.07221 10.888 9.07221C11.911 9.07221 12.406 9.84121 12.406 10.7622C12.406 11.7912 11.751 13.3302 11.412 14.7572C11.129 15.9512 12.011 16.9262 13.189 16.9262C15.322 16.9262 16.961 14.6772 16.961 11.4312C16.961 8.55821 14.897 6.54921 11.949 6.54921C8.535 6.54921 6.531 9.11021 6.531 11.7562C6.531 12.7872 6.928 13.8942 7.424 14.4942C7.522 14.6132 7.536 14.7182 7.507 14.8392L7.174 16.1992C7.121 16.4192 7 16.4662 6.772 16.3592C5.273 15.6622 4.336 13.4712 4.336 11.7112C4.336 7.92621 7.086 4.44921 12.265 4.44921C16.428 4.44921 19.663 7.41621 19.663 11.3802C19.663 15.5162 17.056 18.8442 13.436 18.8442C12.22 18.8442 11.077 18.2132 10.686 17.4662L9.938 20.3182C9.667 21.3622 8.936 22.6692 8.446 23.4652C9.57 23.8122 10.763 24.0002 12 24.0002C18.627 24.0002 24 18.6272 24 12.0002C24 5.37221 18.627 0.000213623 12 0.000213623Z" fill="#243469"/>
    </IconBase>
  )
}

export default Pinterest
