import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Chiamaci({ className }: Props) {
  return (
    <IconBase name="chiamaci" w={100} className={className}>
      <rect width="100" height="100" rx="50" fill="currentColor"/>
      <g clipPath="url(#clip0_4495_3769)">
      <path d="M65.0625 56.7917L59.7709 56.1875C58.5 56.0417 57.25 56.4792 56.3542 57.375L52.5209 61.2083C46.625 58.2083 41.7917 53.3958 38.7917 47.4792L42.6459 43.625C43.5417 42.7292 43.9792 41.4792 43.8334 40.2083L43.2292 34.9583C42.9792 32.8542 41.2084 31.2708 39.0834 31.2708H35.4792C33.125 31.2708 31.1667 33.2292 31.3125 35.5833C32.4167 53.375 46.6459 67.5833 64.4167 68.6875C66.7709 68.8333 68.7292 66.875 68.7292 64.5208V60.9167C68.75 58.8125 67.1667 57.0417 65.0625 56.7917Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_4495_3769">
      <rect width="50" height="50" fill="currentColor" transform="translate(25 25)"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default Chiamaci
