import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function ScarpaIllustration({ className }: Props) {
  return (
    <IconBase name="scarpa-illustration" w={40} className={className}>
      <g clipPath="url(#scarpaillustration_clip0_2033_6254)">
        <path
          d="M3.04102 17.8599C2.63813 20.5036 2.41885 23.172 2.38477 25.8461H9.22522C9.62282 20.1642 3.51344 18.018 3.04102 17.8599Z"
          fill="#D3DFF0"
        />
        <path
          d="M9.22535 26.2198H2.3849C2.28462 26.2197 2.18852 26.1797 2.1177 26.1087C2.08287 26.0732 2.05547 26.031 2.03712 25.9848C2.01877 25.9385 2.00982 25.8891 2.01082 25.8393C2.04735 23.1474 2.26876 20.4612 2.67349 17.7997C2.68204 17.7461 2.70215 17.695 2.73242 17.65C2.76269 17.605 2.80239 17.5671 2.84877 17.5389C2.89536 17.5113 2.94731 17.494 3.00113 17.4881C3.05495 17.4822 3.10941 17.4879 3.16087 17.5047C4.44593 17.9584 5.64283 18.631 6.69866 19.4927C8.7679 21.2028 9.76831 23.4067 9.59303 25.8736C9.58562 25.9666 9.54389 26.0536 9.47594 26.1176C9.40799 26.1816 9.31868 26.218 9.22535 26.2198ZM2.76327 25.4674H8.86195C8.91112 23.3682 8.024 21.5512 6.21983 20.0656C5.34838 19.3601 4.37735 18.7874 3.33829 18.3662C3.00418 20.7195 2.81216 23.0909 2.76327 25.4674Z"
          fill="#164194"
        />
        <path
          d="M9.22521 25.8458H37.5554C37.2112 23.1844 35.4177 19.5911 28.8466 17.4727C19.441 14.4393 19.6548 11.1046 19.6548 11.1046C19.6548 11.1046 14.2893 13.8836 7.47021 11.2008C5.03543 10.2496 3.72079 13.7104 3.04102 17.8596C3.51343 18.0178 9.62281 20.1639 9.22521 25.8458Z"
          fill="white"
        />
        <path
          d="M37.5553 26.2199H9.22512C9.17393 26.2198 9.12329 26.2094 9.07625 26.1892C9.02922 26.169 8.98678 26.1394 8.9515 26.1023C8.9166 26.0646 8.88986 26.0201 8.87294 25.9715C8.85602 25.923 8.84929 25.8714 8.85317 25.8202C9.01349 23.5735 8.13065 21.639 6.22815 20.0699C5.24174 19.269 4.1264 18.6416 2.92977 18.2145C2.84568 18.1862 2.77436 18.129 2.72855 18.053C2.68274 17.9771 2.66541 17.8873 2.67966 17.7998C3.29958 14.0161 4.28931 11.7118 5.62106 10.9593C5.92068 10.7893 6.25589 10.6917 6.59995 10.6742C6.944 10.6568 7.28737 10.72 7.60265 10.8588C14.1887 13.4411 19.4281 10.8054 19.4794 10.7797C19.5381 10.7494 19.6038 10.7352 19.6698 10.7385C19.7358 10.7418 19.7998 10.7625 19.8552 10.7986C19.9105 10.8346 19.9554 10.8847 19.9851 10.9437C20.0149 11.0028 20.0284 11.0686 20.0245 11.1346C20.0245 11.1581 20.0651 14.2513 28.9705 17.12C35.734 19.3004 37.5788 23.0434 37.9358 25.8009C37.9431 25.8541 37.9389 25.9082 37.9236 25.9597C37.9083 26.0111 37.8821 26.0587 37.8469 26.0992C37.8117 26.1397 37.7682 26.1722 37.7193 26.1945C37.6705 26.2168 37.6175 26.2284 37.5638 26.2285L37.5553 26.2199ZM9.60775 25.4675H37.117C36.6532 22.9771 34.7763 19.7749 28.7311 17.8254C21.3028 15.4334 19.6996 12.7827 19.3597 11.6455C17.9018 12.2568 13.1605 13.8387 7.32262 11.5493C7.11068 11.4521 6.87875 11.4065 6.64579 11.4162C6.41284 11.4258 6.18548 11.4905 5.98232 11.6049C5.22773 12.0324 4.18456 13.4304 3.4599 17.6138C4.63036 18.0669 5.72245 18.701 6.69629 19.4928C8.65223 21.1067 9.65265 23.1674 9.60775 25.4675Z"
          fill="#164194"
        />
        <path
          d="M37.5554 25.8457H2.38477C2.38477 26.2283 2.38477 26.5896 2.38477 26.9295C2.38477 28.2912 3.35953 29.4412 4.72121 29.4412H35.2895C35.9039 29.4414 36.4941 29.201 36.9335 28.7715C37.373 28.342 37.6269 27.7576 37.6409 27.1433C37.6459 26.7092 37.6173 26.2754 37.5554 25.8457Z"
          fill="#D3DFF0"
        />
        <path
          d="M35.2768 29.8155H4.70858C3.19085 29.8155 1.99805 28.5478 1.99805 26.9296C1.99805 26.5898 1.99805 26.2242 1.99805 25.8395C1.9983 25.7894 2.0086 25.7399 2.02832 25.6939C2.04805 25.6479 2.0768 25.6064 2.11288 25.5717C2.14895 25.537 2.19162 25.5099 2.23836 25.492C2.28509 25.4741 2.33494 25.4658 2.38496 25.4675H37.5556C37.6465 25.4682 37.7342 25.5017 37.8025 25.5617C37.8708 25.6218 37.9152 25.7044 37.9275 25.7946C37.9855 26.2439 38.0083 26.697 37.9959 27.1498C37.9825 27.8618 37.69 28.5401 37.1815 29.0387C36.6729 29.5372 35.989 29.8161 35.2768 29.8155ZM2.7505 26.22C2.7505 26.4658 2.7505 26.7031 2.7505 26.9275C2.7505 28.1289 3.60555 29.0651 4.71285 29.0651H35.2811C35.7959 29.0658 36.2906 28.8648 36.659 28.5052C37.0274 28.1456 37.2403 27.656 37.252 27.1413C37.2598 26.8367 37.2505 26.532 37.2242 26.2285L2.7505 26.22Z"
          fill="#164194"
        />
        <path
          d="M11.0742 18.5563C11.0742 18.5563 18.6094 13.2272 19.644 11.1045L26.0569 15.8928C26.0569 15.8928 22.6923 21.6046 16.4568 22.1262C12.6668 22.4425 11.0742 18.5563 11.0742 18.5563Z"
          fill="#F7C5D7"
        />
        <path
          d="M16.025 22.5178C12.3376 22.5178 10.745 18.7385 10.7279 18.7C10.6956 18.6218 10.6909 18.5349 10.7146 18.4537C10.7383 18.3725 10.789 18.3017 10.8583 18.2532C12.9318 16.7847 18.494 12.612 19.3063 10.9425C19.33 10.8926 19.3645 10.8487 19.4073 10.8139C19.4502 10.7791 19.5002 10.7542 19.5538 10.7411C19.6074 10.728 19.6633 10.727 19.7173 10.7382C19.7714 10.7493 19.8223 10.7724 19.8663 10.8057L26.2792 15.594C26.353 15.6492 26.4039 15.7296 26.422 15.8199C26.4402 15.9103 26.4243 16.0041 26.3776 16.0835C26.2365 16.3229 22.8376 21.9684 16.4824 22.4964C16.3307 22.5114 16.1746 22.5178 16.025 22.5178ZM11.5359 18.6807C11.8268 19.2411 12.1921 19.7595 12.6219 20.222C13.6907 21.3592 14.9733 21.8744 16.414 21.7525C21.5914 21.325 24.7743 17.1181 25.5439 15.9809L19.7402 11.65C18.2952 13.7749 12.8228 17.7722 11.5466 18.6807H11.5359Z"
          fill="#E23B77"
        />
      </g>
      <defs>
        <clipPath id="scarpaillustration_clip0_2033_6254">
          <rect width="36" height="19.149" fill="white" transform="translate(2 10.6665)" />
        </clipPath>
      </defs>
    </IconBase>
  )
}

export default ScarpaIllustration
