import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Phone({ className }: Props) {
  return (
    <IconBase name="phone" w={24} className={className}>
      <path d="M2.86108 9.60161C4.34817 11.8742 6.19642 14.1362 8.02342 15.9734C9.86104 17.8106 12.1236 19.6478 14.3967 21.1346C16.5211 22.5258 19.3253 22.2072 21.1205 20.4125L21.5666 19.9664C22.2464 19.2868 22.1083 18.1292 21.2798 17.6407C20.1645 16.9823 18.9748 16.1752 17.9657 15.4849C17.5727 15.2194 17.0841 15.177 16.6592 15.3787L14.9172 16.2071C14.471 16.4195 13.9293 16.3664 13.5469 16.0584C12.5272 15.2513 11.5287 14.3699 10.5833 13.4247C9.62736 12.4689 8.75635 11.4813 7.94907 10.4618C7.6304 10.0795 7.57729 9.54851 7.78973 9.09186L8.61826 7.35023C8.82008 6.92544 8.77759 6.42632 8.51204 6.04401C7.8216 5.03513 7.01432 3.84573 6.35575 2.72004C5.86713 1.8917 4.71995 1.75365 4.02951 2.43331C3.89142 2.58198 3.74272 2.73066 3.58338 2.87933C1.78825 4.67406 1.48021 7.47767 2.86108 9.60161Z" fill="currentColor"/>
    </IconBase>
  )
}

export default Phone
