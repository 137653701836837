import React from 'react'
import styles from './FormLegend.module.sass'

interface Props {
  label: string
}

const FormLegend = (props: Props) => {
  const { label = '' } = props

  return <p className={styles.formLegend}>{label}</p>
}

export default FormLegend
