import React, { useState } from 'react'
import Image from 'next/legacy/image'
import { toAltTitle } from 'utils'

interface Props {
  src?: string
  alt?: string
  title?: string
  className?: string
  maxWidth: number
  maxHeight: number
  layout?: 'fixed' | 'fill' | 'intrinsic' | 'responsive'
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down'
  objectPosition?: string
  priority?: false | true
  loading?: 'lazy' | 'eager'
  quality?: number
}

const WImage = (props: Props) => {
  const {
    src = '',
    alt = '',
    title = '',
    maxWidth = 0,
    maxHeight = 0,
    className = '',
    layout = undefined,
    objectFit = undefined,
    objectPosition = undefined,
    priority = false,
    loading = 'lazy',
    quality = 75,
  } = props

  const [imgSrc, setImgSrc] = useState(src)
  const fallbackImage = `http://via.placeholder.com/${maxWidth}x${maxHeight}`

  return layout ? (
    <Image
      src={imgSrc || fallbackImage}
      alt={toAltTitle(alt)}
      title={toAltTitle(title)}
      layout={layout}
      width={layout !== 'fill' ? maxWidth : undefined}
      height={layout !== 'fill' ? maxHeight : undefined}
      objectFit={objectFit}
      objectPosition={objectPosition}
      priority={priority}
      loading={loading}
      quality={quality}
      onError={() => {
        setImgSrc(fallbackImage)
      }}
    />
  ) : (
    <img
      src={src || fallbackImage}
      alt={toAltTitle(alt)}
      title={toAltTitle(title)}
      className={className}
    />
  )
}

export default WImage
