import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Profile({ className }: Props) {
  return (
    <IconBase name="profile" w={20} className={className}>
      <path d="M9.99996 1.66663C5.39996 1.66663 1.66663 5.39996 1.66663 9.99996C1.66663 14.6 5.39996 18.3333 9.99996 18.3333C14.6 18.3333 18.3333 14.6 18.3333 9.99996C18.3333 5.39996 14.6 1.66663 9.99996 1.66663ZM5.89163 15.2333C6.24996 14.4833 8.43329 13.75 9.99996 13.75C11.5666 13.75 13.7583 14.4833 14.1083 15.2333C12.975 16.1333 11.55 16.6666 9.99996 16.6666C8.44996 16.6666 7.02496 16.1333 5.89163 15.2333ZM15.3 14.025C14.1083 12.575 11.2166 12.0833 9.99996 12.0833C8.78329 12.0833 5.89163 12.575 4.69996 14.025C3.84996 12.9083 3.33329 11.5166 3.33329 9.99996C3.33329 6.32496 6.32496 3.33329 9.99996 3.33329C13.675 3.33329 16.6666 6.32496 16.6666 9.99996C16.6666 11.5166 16.15 12.9083 15.3 14.025ZM9.99996 4.99996C8.38329 4.99996 7.08329 6.29996 7.08329 7.91663C7.08329 9.53329 8.38329 10.8333 9.99996 10.8333C11.6166 10.8333 12.9166 9.53329 12.9166 7.91663C12.9166 6.29996 11.6166 4.99996 9.99996 4.99996ZM9.99996 9.16663C9.30829 9.16663 8.74996 8.60829 8.74996 7.91663C8.74996 7.22496 9.30829 6.66663 9.99996 6.66663C10.6916 6.66663 11.25 7.22496 11.25 7.91663C11.25 8.60829 10.6916 9.16663 9.99996 9.16663Z" fill="currentColor"/>
    </IconBase>
  )
}

export default Profile
