import React, { ReactNode, useState } from 'react'
import { Tab } from 'components/atoms'
import styles from './TabGroup.module.sass'

interface Props {
  className?: string
  tabs: {
    key: string
    label?: string
    icon?: ReactNode
    content: ReactNode
  }[]
}

const TabGroup = (props: Props) => {
  const { className = '', tabs = [] } = props

  const [activeTab, setActiveTab] = useState(tabs.length > 0 ? tabs[0].key : '')

  return tabs.length > 0 ? (
    <div className={`${styles.tabGroup} ${className ? ` ${className}` : ''}`}>
      <div className={styles.triggers}>
        {tabs.map((tab) => (
          <Tab.Trigger
            key={`tab-group-trigger__${tab.key}`}
            label={tab.label}
            icon={tab.icon}
            active={tab.key === activeTab}
            onClick={() => setActiveTab(tab.key)}
          />
        ))}
      </div>
      <div className={styles.contents}>
        {tabs.map((tab) => (
          <Tab active={tab.key === activeTab} key={tab.key}>
            {tab.content}
          </Tab>
        ))}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default TabGroup
