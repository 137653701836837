import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Info({ className }: Props) {
  return (
    <IconBase name="info" w={20} className={className}>
      <circle cx="9.99996" cy="10" r="7.58333" stroke="#164194" strokeWidth="1.5"/>
      <path d="M9.54372 12.206L9.07572 6.278L10.9237 6.29L10.4557 12.206H9.54372ZM9.15972 13.094H10.8397V14.75H9.15972V13.094Z" fill="#164194"/>
    </IconBase>
  )
}

export default Info
