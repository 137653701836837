import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function CheckboxMarked({ className }: Props) {
  return (
    <IconBase name="checkbox-marked" w={20} className={className}>
      <rect x="2" y="2" width="16" height="16" rx="1" stroke="currentColor" strokeWidth="2" strokeLinejoin="round"/>
      <path d="M5.0415 9.70832L8.5415 13.2083L14.9582 6.79166" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    </IconBase>
  )
}

export default CheckboxMarked
