import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function RadioMarked({ className }: Props) {
  return (
    <IconBase name="radio-marked" w={20} className={className}>
      <rect x="2" y="2" width="16" height="16" rx="8" stroke="currentColor" strokeWidth="2"/>
      <rect x="5" y="5" width="10" height="10" rx="5" fill="currentColor"/>
    </IconBase>
  )
}

export default RadioMarked
