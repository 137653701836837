import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Dashboard({ className }: Props) {
  return (
    <IconBase name="dashboard" w={24} className={className}>
      <rect x="14" y="12" width="6" height="8" rx="1" stroke="currentColor" strokeWidth="2" />
      <rect x="4" y="4" width="6" height="8" rx="1" stroke="currentColor" strokeWidth="2" />
      <rect x="4" y="16" width="6" height="4" rx="1" stroke="currentColor" strokeWidth="2" />
      <rect x="14" y="4" width="6" height="4" rx="1" stroke="currentColor" strokeWidth="2" />
    </IconBase>
  )
}

export default Dashboard
