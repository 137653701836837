/* eslint-disable unicorn/consistent-function-scoping */

import replace from 'lodash/replace'
import { useRouter } from 'next/router'
import TagManager from 'react-gtm-module'
import { useLocalStorage } from '.'

export default function useGTM() {
  const [token] = useLocalStorage('token', null)
  const router = useRouter()

  const uniformData = (param: string) => replace(replace(param, / /g, '_'), /-/g, '_').toLowerCase()

  const initGTMdataLayer = (GTM_ID: string) => {
    TagManager.initialize({
      gtmId: GTM_ID,
      dataLayerName: 'dataLayer',
    })
  }

  const sendGTMData = (data: any): void => {
    TagManager.dataLayer({
      dataLayer: data,
      dataLayerName: 'dataLayer',
    })
  }

  const getUtenteProps = (utente) => {
    const loginStatus = utente ? 'logged' : 'not_logged'
    const userId = utente ? utente?.pk : 'not_logged'
    const userGenderType = utente
      ? utente.sesso == 'f'
        ? 'mum'
        : utente.sesso == 'm'
        ? 'dad'
        : 'pregnant'
      : ''
    const crmID = ''
    const hashedEmail = utente ? utente.hashEmail : ''
    return {
      loginStatus,
      userId,
      userGenderType,
      crmID,
      hashedEmail,
    }
  }

  const trackingGAevent = (
    id: string,
    category: string,
    action: string,
    label?: string,
    dimension1?: string
  ) => {
    const data = {
      event: 'GAevent',
      eventID: id,
      eventCategory: uniformData(category),
      eventAction: uniformData(action),
      ...(label && { eventLabel: uniformData(label) }),
      ...(dimension1 && { video_category: uniformData(dimension1) }),
    }

    sendGTMData(data)
  }

  const getGTMProps = (pagina) => {
    const brand = 'VIVIDANONE'
    const page = pagina?.pagina?.chiave || ''
    let firstLevelCategory = ''
    let secondLevelCategory = ''
    let thirdLevelCategory = ''
    let pageType = 'other'

    const path = router?.asPath

    if (path.includes('contatti')) pageType = 'contact'
    if (
      path.includes('login') ||
      path.includes('registrazione') ||
      path.includes('recupera-password') ||
      path.includes('dashboard')
    )
      pageType = 'user_account'

    if (page == '404') pageType = '404'

    if (page.includes('homepage')) pageType = 'homepage'

    if (path.includes('/a/')) pageType = 'content_page'

    if (pagina?.categoriaProdotto) {
      pageType = 'product_listing'
      let parts = router?.asPath.split('/')
      firstLevelCategory = uniformData(uniformData(parts[parts.indexOf('') + 1]))
      secondLevelCategory = ''
      thirdLevelCategory = ''
    }

    if (path.includes('/c/') && router?.asPath) pageType = 'content_page'

    if (path.includes('ricerca?q')) pageType = 'search_result'

    return {
      pageType,
      brand,
      firstLevelCategory,
      secondLevelCategory,
      thirdLevelCategory,
    }
  }

  const updateGTMDataLayer = (pagina, utente) => {
    const gtmProps = getGTMProps(pagina)
    const utenteProps = getUtenteProps(utente)

    sendGTMData({
      event: 'wSetup',
      language: 'it',
      ...gtmProps,
      ...utenteProps,
    })

    sendGTMData({
      event: 'virtual_pageview',
      pagePath: router.pathname.split('[token]')[0],
    })
  }

  return {
    initGTMdataLayer,
    sendGTMData,
    getUtenteProps,
    trackingGAevent,
    getGTMProps,
    updateGTMDataLayer,
  }
}
