import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function BCorp({ className }: Props) {
  return (
    <IconBase name="b-corp" w={34} h={50} className={className}>
      <g clipPath="url(#bcorp_clip0_1546_16771)">
      <path d="M4.77204 1.6185C4.69596 1.50424 4.605 1.40056 4.50154 1.31018C4.38903 1.2219 4.26286 1.15247 4.12799 1.10463C3.98777 1.05313 3.83945 1.02702 3.69003 1.02755C3.44296 1.01706 3.19779 1.07486 2.98157 1.19456C2.79087 1.3013 2.6277 1.45084 2.50497 1.63135C2.38315 1.82006 2.29592 2.02885 2.24734 2.248C2.19482 2.48419 2.16889 2.72549 2.17006 2.96742C2.1703 3.20079 2.19622 3.43343 2.24734 3.66115C2.29836 3.8755 2.38547 4.07967 2.50497 4.26496C2.62491 4.44456 2.78899 4.59052 2.98157 4.6889C3.20004 4.80269 3.4436 4.8601 3.69003 4.85591C3.85438 4.86432 4.01863 4.83774 4.17188 4.77793C4.32513 4.71813 4.46385 4.62647 4.57883 4.50905C4.80612 4.25656 4.94216 3.93546 4.96526 3.59692H6.16321C6.1384 3.92571 6.05555 4.24755 5.91847 4.54759C5.7953 4.81856 5.62028 5.06294 5.40322 5.26701C5.18224 5.46559 4.92391 5.61843 4.64323 5.71665C4.33689 5.82288 4.01433 5.87505 3.69003 5.87082C3.29291 5.87526 2.89886 5.80103 2.53073 5.65242C2.20029 5.50746 1.90242 5.29776 1.65481 5.03577C1.41532 4.76587 1.23148 4.45156 1.1138 4.11079C0.981959 3.7441 0.916535 3.35696 0.920588 2.96742C0.91659 2.56951 0.981969 2.17394 1.1138 1.79836C1.23437 1.45504 1.41782 1.13705 1.65481 0.860538C1.89578 0.587871 2.19513 0.372732 2.53073 0.231041C2.89647 0.0732317 3.29156 -0.00557717 3.69003 -0.000202983C3.98702 -0.00445851 4.28276 0.0389175 4.56595 0.128266C4.83167 0.212277 5.08029 0.34278 5.30017 0.513672C5.52013 0.686112 5.70395 0.900001 5.84118 1.14317C5.98663 1.4098 6.07852 1.70218 6.11168 2.00391H4.91374C4.8939 1.86701 4.84564 1.73574 4.77204 1.6185Z" fill="currentColor"/>
      <path d="M7.91506 4.75312C8.01289 4.83976 8.12711 4.90599 8.25099 4.94791C8.37488 4.98983 8.50592 5.00659 8.63641 4.99722C8.84281 5.00685 9.04661 4.94813 9.21606 4.83021C9.3539 4.74849 9.45872 4.62123 9.51233 4.47049H10.4913C10.3367 4.95867 10.092 5.30554 9.76995 5.51109C9.41294 5.72553 9.00143 5.83259 8.58488 5.81941C8.28994 5.82394 7.99689 5.77159 7.72185 5.66525C7.47291 5.57042 7.24843 5.42116 7.06491 5.22846C6.87904 5.03445 6.73831 4.80198 6.65271 4.54758C6.55156 4.27212 6.50348 3.98009 6.51102 3.68684C6.50763 3.39694 6.56011 3.10909 6.6656 2.83894C6.76377 2.58767 6.90791 2.35679 7.09067 2.15806C7.27353 1.96147 7.49792 1.80788 7.74761 1.70842C8.01579 1.59284 8.30567 1.53589 8.59777 1.54141C8.91102 1.53169 9.22153 1.60248 9.49945 1.74696C9.75118 1.87223 9.96799 2.05757 10.1306 2.28653C10.2967 2.51951 10.419 2.78071 10.4913 3.05734C10.5691 3.35037 10.5953 3.65466 10.5686 3.95662H7.63168C7.65744 4.32918 7.74761 4.58611 7.91506 4.75312ZM9.1903 2.62055C9.11048 2.54371 9.01529 2.48453 8.91097 2.4469C8.80664 2.40927 8.69552 2.39402 8.58488 2.40215C8.42956 2.39576 8.27496 2.42659 8.13404 2.49208C8.02969 2.55113 7.93428 2.62466 7.85066 2.71047C7.78673 2.79739 7.73475 2.89244 7.69609 2.9931C7.66883 3.08082 7.65155 3.17132 7.64456 3.26289H9.4608C9.43116 3.02815 9.33761 2.80599 9.1903 2.62055Z" fill="currentColor"/>
      <path d="M12.1529 1.67016V2.42812H12.1658C12.2671 2.17296 12.4477 1.95686 12.6811 1.81147C12.799 1.73875 12.9242 1.67847 13.0546 1.63162C13.1882 1.59052 13.327 1.56889 13.4668 1.56738C13.5498 1.5686 13.6322 1.58157 13.7115 1.60592V2.64652C13.6485 2.62992 13.5836 2.62129 13.5183 2.62083C13.4501 2.61054 13.3812 2.60624 13.3122 2.60798C13.135 2.60458 12.9592 2.63965 12.797 2.71075C12.6599 2.77555 12.5405 2.87253 12.4492 2.99338C12.3559 3.11341 12.2939 3.25467 12.2689 3.40448C12.2307 3.57302 12.2134 3.74561 12.2173 3.91836V5.72977H11.0967V1.67016H12.1529Z" fill="currentColor"/>
      <path d="M16.7 1.66997V2.41509H15.8756V4.43205C15.8756 4.62475 15.9014 4.74038 15.9658 4.80461C16.0753 4.87873 16.208 4.91068 16.3393 4.89454C16.3998 4.89713 16.4603 4.89282 16.5197 4.88169C16.5763 4.87943 16.6325 4.87081 16.6871 4.856V5.71674C16.5848 5.73221 16.4815 5.7408 16.378 5.74243C16.2621 5.74243 16.1461 5.75528 16.0431 5.75528C15.8791 5.75573 15.7154 5.74284 15.5536 5.71674C15.4078 5.6991 15.2673 5.65095 15.1414 5.57542C15.0224 5.50368 14.9246 5.40171 14.858 5.27994C14.7866 5.12725 14.7513 4.96022 14.755 4.79176V2.3894H14.0723V1.64428H14.755V0.423828H15.8756V1.64428H16.7V1.66997Z" fill="currentColor"/>
      <path d="M17.2666 0.115723H18.3873V1.0407H17.2666V0.115723ZM18.3873 5.7298H17.2666V1.67019H18.3873V5.7298Z" fill="currentColor"/>
      <path d="M18.8511 2.4152V1.67009H19.5209V1.36176C19.5109 1.03379 19.6313 0.715169 19.8558 0.475328C20.0877 0.244085 20.4226 0.128461 20.8863 0.128461C20.9852 0.126327 21.0842 0.130619 21.1826 0.141309C21.2856 0.154156 21.3758 0.154156 21.4788 0.167002V1.00205C21.35 0.989202 21.2083 0.976355 21.0666 0.976355C21.0088 0.971276 20.9505 0.977749 20.8952 0.995396C20.8399 1.01304 20.7887 1.04151 20.7446 1.07913C20.6669 1.18226 20.6302 1.31037 20.6416 1.43884V1.68293H21.4144V2.42805H20.6416V5.7297H19.5209V2.4152H18.8511Z" fill="currentColor"/>
      <path d="M22.084 0.115723H23.2046V1.0407H22.084V0.115723ZM23.2046 5.7298H22.084V1.67019H23.2046V5.7298Z" fill="currentColor"/>
      <path d="M24.9693 4.75312C25.0671 4.83976 25.1813 4.90599 25.3052 4.94791C25.4291 4.98983 25.5601 5.00659 25.6906 4.99722C25.897 5.00685 26.1008 4.94813 26.2703 4.83021C26.4081 4.74849 26.5129 4.62123 26.5665 4.47049H27.5455C27.3909 4.95867 27.1462 5.30554 26.8241 5.51109C26.4671 5.72553 26.0556 5.83259 25.6391 5.81941C25.3441 5.82394 25.0511 5.77159 24.776 5.66525C24.5271 5.57042 24.3026 5.42116 24.1191 5.22846C23.9332 5.03445 23.7925 4.80198 23.7069 4.54758C23.6058 4.27212 23.5577 3.98009 23.5652 3.68684C23.5618 3.39694 23.6143 3.10909 23.7198 2.83894C23.818 2.58767 23.9621 2.35679 24.1449 2.15806C24.3277 1.96147 24.5521 1.80788 24.8018 1.70842C25.07 1.59284 25.3599 1.53589 25.652 1.54141C25.9652 1.53169 26.2757 1.60248 26.5536 1.74696C26.8054 1.87223 27.0222 2.05757 27.1848 2.28653C27.3509 2.51951 27.4732 2.78071 27.5455 3.05734C27.6233 3.35037 27.6495 3.65466 27.6228 3.95662H24.6859C24.7116 4.32918 24.8018 4.58611 24.9693 4.75312ZM26.2316 2.62055C26.1518 2.54371 26.0566 2.48453 25.9523 2.4469C25.848 2.40927 25.7368 2.39402 25.6262 2.40215C25.4709 2.39576 25.3163 2.42659 25.1754 2.49208C25.0688 2.54782 24.9729 2.62174 24.892 2.71047C24.8233 2.79429 24.7709 2.89013 24.7374 2.9931C24.7101 3.08082 24.6929 3.17132 24.6859 3.26289H26.5021C26.4782 3.02665 26.384 2.80294 26.2316 2.62055Z" fill="currentColor"/>
      <path d="M31.0236 5.20308C30.9023 5.4085 30.7231 5.5738 30.5083 5.67842C30.2817 5.77932 30.035 5.82764 29.787 5.81973C29.5102 5.82408 29.2364 5.76241 28.9883 5.63988C28.7644 5.52839 28.5701 5.36548 28.4216 5.16454C28.2694 4.95505 28.156 4.72011 28.0867 4.47081C28.0085 4.20374 27.9695 3.92681 27.9707 3.64861C27.9695 3.38305 28.0085 3.11883 28.0867 2.86495C28.1588 2.62466 28.2721 2.39864 28.4216 2.19691C28.5737 2.0027 28.7673 1.84471 28.9883 1.73443C29.2323 1.61332 29.5016 1.55168 29.7741 1.55457C30.0106 1.55219 30.2444 1.60498 30.4568 1.70873C30.6671 1.80796 30.8455 1.96366 30.972 2.15837H30.9849V0.115723H32.1056V5.7298H31.0364V5.21593H31.0236V5.20308ZM30.972 3.19897C30.9457 3.05175 30.8887 2.9117 30.8046 2.78787C30.7293 2.67189 30.6278 2.5751 30.5083 2.50524C30.3727 2.4234 30.2159 2.38319 30.0575 2.38962C29.8952 2.38501 29.7348 2.425 29.5938 2.50524C29.4672 2.5759 29.3608 2.6776 29.2846 2.80072C29.2058 2.92729 29.1492 3.06631 29.1172 3.21182C29.0803 3.37187 29.063 3.53579 29.0656 3.7C29.0643 3.8599 29.0816 4.01941 29.1172 4.17533C29.1559 4.32476 29.2167 4.46763 29.2975 4.59928C29.3737 4.72239 29.4801 4.82409 29.6066 4.89476C29.743 4.97496 29.8993 5.01504 30.0575 5.01038C30.2198 5.01499 30.3802 4.97499 30.5212 4.89476C30.644 4.82364 30.7462 4.72179 30.8175 4.59928C30.8974 4.46996 30.95 4.32568 30.972 4.17533C31.0026 4.01429 31.0198 3.85101 31.0236 3.68715C31.0262 3.52295 31.0089 3.35902 30.972 3.19897Z" fill="currentColor"/>
      <path d="M9.80859 14.8511H16.3264C17.9108 14.8511 19.9976 14.8511 21.0796 15.5063C21.7329 15.8861 22.273 16.4327 22.6442 17.0897C23.0155 17.7468 23.2046 18.4906 23.1921 19.2447C23.1921 21.146 22.1874 22.5849 20.5257 23.2658V23.3172C22.7413 23.7668 23.9521 25.424 23.9521 27.6337C23.9521 30.2673 22.0585 32.7853 18.4905 32.7853H9.80859V14.8511ZM12.2045 22.5592H16.9963C19.7142 22.5592 20.822 21.5828 20.822 19.6943C20.822 17.2021 19.0572 16.8552 16.9963 16.8552H12.2045V22.5592ZM12.2045 30.7683H18.1942C20.2552 30.7683 21.5691 29.5093 21.5691 27.5566C21.5691 25.2185 19.6755 24.5633 17.6661 24.5633H12.2045V30.7683Z" fill="currentColor"/>
      <path d="M30.0704 23.8182C30.0717 26.5153 29.271 29.1522 27.7696 31.3955C26.2682 33.6388 24.1336 35.3877 21.6356 36.421C19.1376 37.4543 16.3885 37.7257 13.7359 37.2007C11.0833 36.6758 8.6463 35.3782 6.73315 33.4719C4.82 31.5656 3.5166 29.1364 2.98776 26.4913C2.45892 23.8463 2.72841 21.1042 3.76213 18.6119C4.79585 16.1196 6.54739 13.9889 8.79525 12.4894C11.0431 10.9899 13.6863 10.1889 16.3907 10.1876C18.1864 10.1863 19.9648 10.5378 21.6243 11.2222C23.2838 11.9066 24.7918 12.9103 26.0622 14.1761C27.3326 15.4419 28.3404 16.945 29.0282 18.5994C29.7159 20.2538 30.0701 22.0272 30.0704 23.8182ZM16.4035 8.10645C13.2878 8.10645 10.242 9.02792 7.65129 10.7543C5.06062 12.4808 3.04144 14.9346 1.84908 17.8055C0.656729 20.6765 0.344754 23.8356 0.952611 26.8834C1.56047 29.9311 3.06086 32.7307 5.26404 34.928C7.46722 37.1253 10.2743 38.6217 13.3302 39.228C16.3861 39.8342 19.5536 39.5231 22.4322 38.3339C25.3108 37.1447 27.7712 35.1309 29.5022 32.5471C31.2332 29.9633 32.1572 26.9256 32.1572 23.8182C32.1573 21.7548 31.75 19.7117 30.9583 17.8053C30.1667 15.899 29.0063 14.1669 27.5434 12.7079C26.0805 11.2489 24.3438 10.0916 22.4324 9.30208C20.521 8.51256 18.4724 8.10628 16.4035 8.10645Z" fill="currentColor"/>
      <path d="M3.5609 42.9086C3.50937 42.5103 3.12294 42.2148 2.68498 42.2148C1.89923 42.2148 1.60297 42.8829 1.60297 43.5766C1.60297 44.2318 1.89923 44.8998 2.68498 44.8998C3.22599 44.8998 3.52226 44.5273 3.58666 44.0134H4.43682C4.34665 45.0026 3.66395 45.6321 2.68498 45.6321C1.44839 45.6321 0.727051 44.72 0.727051 43.5638C0.727051 42.3819 1.44839 41.4697 2.68498 41.4697C3.5609 41.4697 4.29512 41.9836 4.39817 42.8957H3.5609V42.9086Z" fill="currentColor"/>
      <path d="M6.26618 42.6001C7.18074 42.6001 7.7604 43.2039 7.7604 44.116C7.7604 45.0282 7.18074 45.632 6.26618 45.632C5.35162 45.632 4.77197 45.0282 4.77197 44.116C4.77197 43.2039 5.36451 42.6001 6.26618 42.6001ZM6.26618 45.0282C6.80719 45.0282 6.97465 44.5657 6.97465 44.116C6.97465 43.6664 6.80719 43.1911 6.26618 43.1911C5.72518 43.1911 5.5706 43.6535 5.5706 44.116C5.5706 44.5785 5.73806 45.0282 6.26618 45.0282Z" fill="currentColor"/>
      <path d="M8.22382 42.6772H8.97092V43.2167H8.98381C9.06191 43.038 9.18937 42.8852 9.3513 42.776C9.51324 42.6668 9.70298 42.6058 9.89837 42.6001C9.95498 42.6024 10.0111 42.611 10.0658 42.6258V43.3581C9.97212 43.3426 9.8774 43.334 9.78243 43.3324C9.20278 43.3324 8.99669 43.7435 8.99669 44.2573V45.5549H8.21094V42.6772H8.22382Z" fill="currentColor"/>
      <path d="M10.5039 42.6773H11.251V43.0498H11.2639C11.3548 42.9053 11.4827 42.7875 11.6344 42.7085C11.786 42.6295 11.956 42.5921 12.1269 42.6002C13.0286 42.6002 13.4408 43.3325 13.4408 44.1418C13.4408 44.9126 13.0157 45.6321 12.1785 45.6321C12.0097 45.6353 11.8428 45.5972 11.6923 45.521C11.5418 45.4448 11.4124 45.333 11.3154 45.1953H11.2897V46.5699H10.5039V42.6773ZM12.6422 44.1161C12.6422 43.6536 12.4618 43.1912 11.9466 43.1912C11.4313 43.1912 11.2639 43.6536 11.2639 44.1161C11.2639 44.5786 11.4442 45.0283 11.9595 45.0283C12.4747 45.0283 12.6422 44.5915 12.6422 44.1161Z" fill="currentColor"/>
      <path d="M15.3087 42.6001C16.2232 42.6001 16.8029 43.2039 16.8029 44.116C16.8029 45.0282 16.2103 45.632 15.3087 45.632C14.407 45.632 13.8145 45.0282 13.8145 44.116C13.8145 43.2039 14.407 42.6001 15.3087 42.6001ZM15.3087 45.0282C15.8497 45.0282 16.0171 44.5657 16.0171 44.116C16.0171 43.6664 15.8497 43.1911 15.3087 43.1911C14.7677 43.1911 14.6131 43.6535 14.6131 44.116C14.6131 44.5785 14.7677 45.0282 15.3087 45.0282Z" fill="currentColor"/>
      <path d="M17.3308 42.6772H18.0779V43.2167H18.0907C18.1688 43.038 18.2963 42.8852 18.4582 42.776C18.6202 42.6668 18.8099 42.6058 19.0053 42.6001C19.062 42.6014 19.1183 42.61 19.1728 42.6258V43.3581C19.0791 43.3426 18.9843 43.334 18.8894 43.3324C18.3097 43.3324 18.1036 43.7435 18.1036 44.2573V45.5549H17.3179V42.6772H17.3308Z" fill="currentColor"/>
      <path d="M19.4694 43.5636C19.5209 42.8185 20.1778 42.6001 20.8219 42.6001C21.4015 42.6001 22.0842 42.7286 22.0842 43.4223V44.9125C22.0842 45.1695 22.11 45.4392 22.1873 45.5549H21.3887C21.358 45.4636 21.3406 45.3684 21.3371 45.2722C21.2083 45.3933 21.0564 45.4876 20.8905 45.5493C20.7246 45.6111 20.548 45.6392 20.3711 45.6319C19.83 45.6319 19.3921 45.3622 19.3921 44.7712C19.3921 44.116 19.8816 43.9619 20.3711 43.8976C20.8605 43.8334 21.3114 43.8462 21.3114 43.5251C21.3114 43.2039 21.0795 43.1397 20.7961 43.1397C20.5127 43.1397 20.2938 43.2553 20.268 43.5765H19.4694V43.5636ZM21.2985 44.1417C21.1697 44.2573 20.8863 44.2702 20.6416 44.3087C20.3968 44.3473 20.1778 44.4372 20.1778 44.7327C20.1778 45.0281 20.4097 45.1052 20.6673 45.1052C21.2856 45.1052 21.3114 44.617 21.3114 44.45V44.1417H21.2985Z" fill="currentColor"/>
      <path d="M23.7072 42.6771H24.2868V43.2039H23.7072V44.6299C23.7072 44.8997 23.7716 44.9639 24.0421 44.9639C24.1245 44.9688 24.2072 44.9601 24.2868 44.9382V45.5548C24.1333 45.5769 23.9781 45.5855 23.8231 45.5805C23.3336 45.5805 22.9214 45.4649 22.9214 44.8997V43.2039H22.4448V42.6771H22.9214V41.8164H23.7072V42.6771Z" fill="currentColor"/>
      <path d="M25.4718 42.2275H24.686V41.5723H25.4718V42.2275ZM24.686 42.6771H25.4718V45.5548H24.686V42.6771Z" fill="currentColor"/>
      <path d="M27.391 42.6001C28.3056 42.6001 28.8852 43.2039 28.8852 44.116C28.8852 45.0282 28.2927 45.632 27.391 45.632C26.4893 45.632 25.9097 45.0282 25.9097 44.116C25.9097 43.2039 26.4893 42.6001 27.391 42.6001ZM27.391 45.0282C27.932 45.0282 28.0995 44.5657 28.0995 44.116C28.0995 43.6664 27.9449 43.1911 27.391 43.1911C26.8371 43.1911 26.6954 43.6535 26.6954 44.116C26.6954 44.5785 26.85 45.0282 27.391 45.0282Z" fill="currentColor"/>
      <path d="M29.3877 42.6772H30.1348V43.0755H30.1477C30.2414 42.9288 30.371 42.8082 30.5242 42.7251C30.6774 42.6419 30.8492 42.599 31.0236 42.6001C31.8609 42.6001 32.0799 43.0755 32.0799 43.782V45.5549H31.2941V43.9362C31.2941 43.4609 31.1524 43.2296 30.7917 43.2296C30.3667 43.2296 30.1734 43.4737 30.1734 44.0518V45.5549H29.3877V42.6772Z" fill="currentColor"/>
      <path d="M32.1057 47.9961H0.714355V50.0002H32.1057V47.9961Z" fill="currentColor"/>
      <path d="M32.5563 41.457H32.376V41.958H32.2858V41.457H32.1055V41.3799H32.5692V41.457H32.5563ZM33.3292 41.958H33.239V41.4698L33.0458 41.958H32.9814L32.7882 41.4698V41.958H32.6851V41.3799H32.8397L33.02 41.8167L33.1875 41.3799H33.3421V41.958H33.3292Z" fill="currentColor"/>
      </g>
      <defs>
      <clipPath id="bcorp_clip0_1546_16771">
      <rect width="32.6923" height="50" fill="currentColor" transform="translate(0.649902)"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default BCorp
