import React from 'react'
import { Alert } from '@components/atoms/Icons'
import styles from './AlertMessage.module.sass'

interface Props {
  className?: string
  label: string
}

const AlertMessage = (props: Props) => {
  const { className = '', label = '' } = props

  return (
    <p className={`${className} ${styles.alert}`}>
      <Alert />
      {label}
    </p>
  )
}

export default AlertMessage
