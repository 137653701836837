import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Check({ className }: Props) {
  return (
    <IconBase name="check" w={70} className={className}>
      <rect width="70" height="70" rx="35" fill="currentColor" fillOpacity="0.1"/>
      <g clipPath="url(#clip0_3974_12548)">
      <g clipPath="url(#clip1_3974_12548)">
      <path d="M28.7499 43.6875L21.5208 36.4583C20.7083 35.6458 19.3958 35.6458 18.5833 36.4583C17.7708 37.2708 17.7708 38.5833 18.5833 39.3958L27.2916 48.1042C28.1041 48.9167 29.4166 48.9167 30.2291 48.1042L52.2708 26.0625C53.0833 25.25 53.0833 23.9375 52.2708 23.125C51.4583 22.3125 50.1458 22.3125 49.3333 23.125L28.7499 43.6875Z" fill="currentColor"/>
      </g>
      </g>
      <defs>
      <clipPath id="clip0_3974_12548">
      <rect x="10" y="10" width="50" height="50" rx="25" fill="currentColor"/>
      </clipPath>
      <clipPath id="clip1_3974_12548">
      <rect width="50" height="50" fill="currentColor" transform="translate(10 10)"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default Check
