/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable react-hooks/exhaustive-deps */

import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMeQuery } from 'gql/graphql'
import useLocalStorage from "@hooks/useLocalStorage";

export default function useUtente() {
  const router = useRouter()
  const { loading, data, refetch } = useMeQuery()

  const dispatch = useDispatch()
  const { utente } = useSelector((state: any) => state?.utente)

  useEffect(() => {
    if (data) dispatch({ type: 'SET_ME', payload: { data } })
  }, [data])

  useEffect(() => {
    refetch()
  }, [router.asPath])

  return {
    utente: utente || (data?.me && { ...data?.me }),
    loading,
    refetchUtente: refetch,
  }
}
