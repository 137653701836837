/* eslint-disable react/jsx-boolean-value */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState } from 'react'
import { useSocialAuthMutation } from 'gql/graphql'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useRouter } from 'next/router'
import { SocialButton } from 'components/atoms'
import useTrans from './useTrans'
import useLocalStorage from './useLocalStorage'

function useSocialLogin(from, iframe) {
  const router = useRouter()
  const t = useTrans()

  const [socialLogin, { loading }] = useSocialAuthMutation()
  const [profile, setProfile] = useState({ nome: '', cognome: '', email: '' })
  const [error, setError] = useState<string | null>()
  const [, setToken] = useLocalStorage('token', null)

  const handleRedirect = (profileData) => {
    from === 'ariele'
      ? (window.location.href = `https://ariele.psicosocioanalisi.it/public/?nome=${profileData?.nome}&cognome=${profileData?.cognome}&email=${profileData?.email}`)
      : (window.location.href = `https://www.patronato.acli.it/consulenza-mellin/?cognome=${profileData?.cognome}&nome=${profileData?.nome}&email=${profileData?.email}&prres=CITTA`)
  }

  async function handleSocialLogin(provider, accessToken, profileData) {
    if (error) setError(null)

    setProfile(profileData)
    socialLogin({
      variables: {
        provider,
        accessToken,
      },
    })
      .then((res) => {
        setToken(res.data?.socialAuth?.token)
        iframe ? handleRedirect(profileData) : router.push('/dashboard/informazioni-personali')
      })
      .catch(() => {
        iframe
          ? handleRedirect(profileData)
          : router.push(
              `/registrazione?social=true&nome=${profileData?.nome}&cognome=${profileData?.cognome}&email=${profileData?.email}`
            )
      })
  }

  function prepareFacebookLogin(response) {
    if (response.status) {
      if (response.status !== 'unknown') setError('Si è verificato un errore.')
    } else if (response.accessToken) {
      const data = {
        nome: response.first_name || '',
        cognome: response.last_name || '',
        email: response.email || '',
      }
      handleSocialLogin('facebook', response?.accessToken, data)
    }
  }

  const FacebookLoginButton = () => (
    <FacebookLogin
      isMobile={false}
      appId={process.env.FACEBOOK_APP_ID}
      callback={prepareFacebookLogin}
      fields="first_name,last_name,email"
      render={(renderProps) => (
        <SocialButton
          social="facebook"
          template="login"
          onClick={renderProps.onClick}
          label={t('Accedi con Facebook')}
        />
      )}
    />
  )

  return { FacebookLoginButton, socialLogin, error, loading, profile }
}

export default useSocialLogin
