import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function SocietaIllustration({ className }: Props) {
  return (
    <IconBase name="societa-illustration" w={60} className={className}>
      <g clipPath="url(#societaillustration_clip0_2089_864)">
        <path
          d="M18.5773 19.0666C23.6841 19.0666 28.0896 24.6675 30.1535 32.7856C30.689 32.8437 31.2174 32.9541 31.7312 33.1151C33.8612 26.4434 38.1511 21.8802 43.0991 21.8802C47.5245 21.8802 51.4274 25.5307 53.7228 31.0757C53.6075 24.797 51.0229 18.8143 46.5248 14.4144C42.0268 10.0145 35.9748 7.54902 29.6706 7.54834C23.2878 7.54834 17.1663 10.0739 12.6527 14.5694C8.13903 19.065 5.60284 25.1624 5.60196 31.5205C5.60321 33.3325 5.8107 35.1385 6.22046 36.904C7.64269 26.641 12.6238 19.0666 18.5773 19.0666Z"
          fill="white"
        />
        <path
          d="M6.19733 37.4904C6.06616 37.4901 5.93895 37.4457 5.83629 37.3644C5.73364 37.2831 5.66157 37.1696 5.63176 37.0423C5.21897 35.2339 5.01261 33.3849 5.01657 31.5303C5.02445 25.0215 7.62385 18.7815 12.2445 14.1793C16.8652 9.57712 23.1299 6.98861 29.6641 6.98164C36.1284 6.96641 42.3367 9.49721 46.9358 14.0224C51.5387 18.5202 54.1768 24.6437 54.2784 31.0658C54.2812 31.1998 54.2367 31.3305 54.1527 31.4353C54.0687 31.54 53.9506 31.6121 53.8187 31.6391C53.6872 31.6697 53.5491 31.6535 53.4283 31.5933C53.3075 31.5331 53.2116 31.4328 53.1572 31.3096C50.8651 25.7745 47.0913 22.47 43.0661 22.47C38.5249 22.47 34.3805 26.6213 32.2505 33.3029C32.201 33.4457 32.0979 33.5638 31.9628 33.6323C31.8266 33.7015 31.6686 33.7145 31.5229 33.6686C31.0455 33.5107 30.5536 33.4003 30.0543 33.3391C29.9374 33.3259 29.8273 33.2776 29.7386 33.2005C29.65 33.1234 29.587 33.0212 29.5582 32.9075C27.5175 24.8355 23.1979 19.6233 18.5542 19.6233C13.0472 19.6233 8.19839 26.7563 6.75962 36.9732C6.74105 37.1067 6.67581 37.2294 6.57539 37.3198C6.47498 37.4102 6.34581 37.4624 6.21057 37.4674L6.19733 37.4904ZM29.6475 8.13478C23.4218 8.14262 17.4533 10.6097 13.0511 14.9949C8.6488 19.3801 6.17214 25.3254 6.16426 31.527C6.16426 32.186 6.19403 32.8218 6.24695 33.4676C8.3009 24.4962 13.1265 18.4867 18.5707 18.4867C23.7668 18.4867 28.3543 23.7582 30.6001 32.265C30.8481 32.3046 31.0929 32.354 31.3376 32.4166C33.6793 25.5505 38.1445 21.3102 43.0826 21.3102C46.8498 21.3102 50.3789 23.7977 52.8827 28.1039C52.1463 23.0637 49.7674 18.4043 46.1122 14.8428C41.7285 10.5289 35.8096 8.11748 29.6475 8.13478Z"
          fill="#164194"
        />
        <path
          d="M30.1304 32.7857C28.0764 24.6742 23.6709 19.0667 18.5542 19.0667C12.6205 19.0667 7.6394 26.6444 6.18741 36.914C7.02095 40.5073 8.67464 43.8609 11.0201 46.7144C13.3657 49.568 16.3399 51.8446 19.7118 53.3676"
          fill="#D3DFF0"
        />
        <path
          d="M19.7216 53.9441C19.6397 53.9442 19.5586 53.9274 19.4835 53.8947C16.0342 52.3286 12.9916 49.9948 10.5901 47.0732C8.18865 44.1515 6.49231 40.7198 5.6317 37.0424C5.61853 36.9727 5.61853 36.9012 5.6317 36.8315C7.15315 26.0315 12.4749 18.49 18.5739 18.49C23.8461 18.49 28.4965 23.913 30.6993 32.6571C30.7181 32.7307 30.7221 32.8072 30.7112 32.8824C30.7003 32.9575 30.6746 33.0297 30.6355 33.0949C30.5965 33.1601 30.545 33.217 30.4838 33.2623C30.4227 33.3076 30.3531 33.3404 30.2792 33.3589C30.1305 33.3958 29.9732 33.3725 29.8418 33.294C29.7103 33.2156 29.6155 33.0885 29.578 32.9405C27.5373 24.8685 23.2177 19.6563 18.5739 19.6563C13.0868 19.6563 8.25124 26.7399 6.79264 36.9007C7.62661 40.3802 9.24595 43.6246 11.5275 46.3872C13.8091 49.1498 16.693 51.3579 19.9598 52.8437C20.0795 52.8985 20.1768 52.9923 20.2357 53.1096C20.2946 53.2269 20.3116 53.3608 20.2839 53.489C20.2561 53.6173 20.1852 53.7323 20.083 53.815C19.9808 53.8977 19.8533 53.9433 19.7216 53.9441Z"
          fill="#164194"
        />
        <path
          d="M37.7806 54.0957C42.4457 52.4265 46.4797 49.3632 49.3313 45.3243C52.1829 41.2855 53.7131 36.4681 53.7128 31.5304C53.7128 31.3821 53.7128 31.2339 53.7128 31.0856C51.4174 25.5406 47.5146 21.8901 43.0892 21.8901C38.1279 21.8901 33.8513 26.4533 31.7213 33.125"
          fill="#D3DFF0"
        />
        <path
          d="M37.7807 54.6723C37.6452 54.6715 37.5144 54.6234 37.4109 54.5364C37.3074 54.4494 37.2378 54.329 37.2142 54.1962C37.1906 54.0634 37.2145 53.9265 37.2817 53.8094C37.3489 53.6923 37.4553 53.6024 37.5822 53.5554C42.1299 51.9182 46.0615 48.9261 48.8427 44.9856C51.6239 41.0451 53.1199 36.3473 53.1274 31.5304C53.1274 31.448 53.1274 31.3657 53.1274 31.28V31.2075C50.832 25.7318 47.0813 22.4667 43.0792 22.4667C38.538 22.4667 34.3938 26.618 32.2637 33.2997C32.2408 33.372 32.2038 33.4391 32.1548 33.4971C32.1057 33.5551 32.0457 33.6029 31.9781 33.6378C31.9105 33.6727 31.8367 33.694 31.7609 33.7004C31.685 33.7068 31.6086 33.6983 31.5361 33.6753C31.39 33.6283 31.2684 33.5259 31.1978 33.3901C31.1272 33.2544 31.1133 33.0964 31.159 32.9504C33.4511 25.7713 38.0155 21.3136 43.066 21.3136C47.5775 21.3136 51.7482 24.885 54.2255 30.8682C54.2543 30.9336 54.2689 31.0043 54.2685 31.0757V31.2438C54.2685 31.3426 54.2685 31.4382 54.2685 31.5337C54.2604 36.5884 52.69 41.518 49.7709 45.6525C46.8517 49.787 42.7253 52.9259 37.9526 54.6426C37.8971 54.661 37.8391 54.671 37.7807 54.6723Z"
          fill="#164194"
        />
        <path
          d="M31.7081 33.125C31.1943 32.964 30.6659 32.8536 30.1304 32.7955C29.8241 32.7602 29.516 32.7415 29.2076 32.7395C23.3302 32.7395 18.5641 39.0455 18.5641 46.8276C18.5641 50.0168 18.8055 52.0661 19.7217 53.3774C22.5889 54.6705 25.6846 55.386 28.8305 55.4827C29.0985 55.4827 29.3663 55.5025 29.6376 55.5025C31.4691 55.5025 33.2946 55.2936 35.0784 54.8798C35.9905 54.671 36.8894 54.4092 37.7707 54.0956C39.3253 52.8634 39.8379 50.6725 39.8379 46.8177C39.8478 40.1789 36.3782 34.6142 31.7081 33.125Z"
          fill="#F7C5D7"
        />
        <path
          d="M29.6475 55.9967C29.4226 55.9967 29.2043 55.9967 28.986 55.9967H28.8239C25.6089 55.8965 22.4456 55.163 19.5166 53.8387C19.435 53.8025 19.3651 53.7443 19.3149 53.6707C18.2763 52.1848 18.0679 49.9543 18.0679 46.8375C18.0679 38.7952 23.0656 32.2552 29.2076 32.2552C29.5392 32.2561 29.8705 32.2759 30.1999 32.3146C30.7682 32.3791 31.3288 32.4994 31.8734 32.6737C36.8678 34.2683 40.3572 40.0933 40.3572 46.8408C40.3572 50.3694 39.9735 53.0249 38.1015 54.5075C38.0583 54.5409 38.0104 54.5675 37.9592 54.5866C37.0601 54.9049 36.1436 55.1722 35.214 55.3872C33.3877 55.7999 31.5202 56.0044 29.6475 55.9967ZM20.0557 52.9755C22.8299 54.2109 25.8197 54.8947 28.857 54.9886H29.0191C31.0212 55.0502 33.0228 54.8497 34.9726 54.3922C35.8379 54.1937 36.6915 53.9473 37.5293 53.6542C38.8523 52.5703 39.3484 50.6396 39.3484 46.8112C39.3484 40.492 36.1434 35.0524 31.5526 33.5896C31.0709 33.4311 30.5745 33.3207 30.0709 33.2601C29.7833 33.2259 29.4939 33.2083 29.2043 33.2074C23.608 33.2074 19.0569 39.3059 19.0569 46.8013C19.0602 49.582 19.2388 51.6972 20.0557 52.9755Z"
          fill="#E23B77"
        />
        <path
          d="M25.7843 12.0621C25.7843 12.0621 26.7766 12.1774 26.6178 14.1476C26.5517 14.8658 26.3466 15.5511 25.4668 15.5511C25.4668 15.5511 25.3874 15.8213 25.3676 15.8806C24.3357 19.0534 21.6797 21.3102 18.5674 21.3102C15.455 21.3102 12.809 19.0567 11.7738 15.8905L11.6646 15.561C10.7848 15.561 10.5764 14.8757 10.5136 14.1575C10.335 12.1807 11.3438 12.0719 11.3438 12.0719L11.3802 11.8051C11.8664 7.72626 14.8993 4.58643 18.5674 4.58643C22.2354 4.58643 25.3246 7.78227 25.7711 11.9105L25.7843 12.0621Z"
          fill="#D3DFF0"
        />
        <path
          d="M18.5773 21.8901C15.326 21.8901 12.3757 19.5575 11.2379 16.0816C10.4772 15.9169 10.0439 15.2843 9.94802 14.197C9.79918 12.5794 10.378 11.9237 10.8212 11.6601C11.3702 7.29139 14.6943 4 18.5773 4C22.4603 4 25.7876 7.29468 26.3367 11.6667C26.7766 11.9303 27.3487 12.5892 27.2032 14.197C27.104 15.281 26.674 15.9136 25.9199 16.0816C24.7722 19.5575 21.8252 21.8901 18.5773 21.8901ZM11.4397 12.6354C11.2975 12.6947 11.0064 13.0439 11.109 14.0949C11.1883 14.9746 11.4397 14.9746 11.6845 14.9746C11.8065 14.9749 11.9252 15.0136 12.0238 15.0852C12.1224 15.1568 12.1958 15.2576 12.2335 15.3732L12.346 15.7027C13.3382 18.7075 15.8354 20.7238 18.5872 20.7238C21.339 20.7238 23.8561 18.7009 24.8351 15.6928C24.8549 15.6302 24.931 15.3634 24.931 15.3634C24.9666 15.2442 25.04 15.1397 25.1401 15.0655C25.2403 14.9913 25.3618 14.9513 25.4866 14.9515C25.7314 14.9515 25.9828 14.9515 26.0588 14.0718C26.1548 13.0208 25.8637 12.6716 25.7281 12.6123C25.5941 12.608 25.4673 12.5513 25.375 12.4545C25.2828 12.3576 25.2326 12.2285 25.2353 12.095V11.9336C24.8185 8.05575 21.9708 5.13007 18.6203 5.13007C15.2698 5.13007 12.465 8.01292 12.0053 11.8381L11.9689 12.1115C11.9713 12.1821 11.9589 12.2524 11.9325 12.3179C11.9061 12.3834 11.8663 12.4428 11.8155 12.4921C11.7648 12.5414 11.7042 12.5796 11.6378 12.6043C11.5714 12.6289 11.5005 12.6395 11.4298 12.6354H11.4397Z"
          fill="#164194"
        />
        <path
          d="M50.2962 15.0438C50.2962 15.0438 51.2885 15.1558 51.1264 17.1293C51.0636 17.8475 50.8552 18.5328 49.9787 18.5328L49.8762 18.8623C48.8442 22.0351 46.1916 24.2919 43.0792 24.2919C39.9669 24.2919 37.3176 22.0384 36.2857 18.8689L36.1732 18.5394C35.2934 18.5394 35.0883 17.8541 35.0222 17.1359C34.8469 15.1591 35.8557 15.0503 35.8557 15.0503L35.8887 14.7802C36.3782 10.7047 39.4112 7.56482 43.0792 7.56482C46.7472 7.56482 49.8331 10.7607 50.2797 14.8889L50.2962 15.0438Z"
          fill="#D3DFF0"
        />
        <path
          d="M43.0792 24.8718C39.8246 24.8718 36.8777 22.5392 35.7366 19.0633C34.9792 18.8986 34.5459 18.266 34.4467 17.1787C34.3011 15.561 34.8799 14.9054 35.3232 14.6418C35.8722 10.2731 39.1962 6.98169 43.0792 6.98169C46.9622 6.98169 50.2863 10.2764 50.8353 14.6451C51.2785 14.912 51.8474 15.5709 51.7052 17.1787C51.606 18.2627 51.176 18.8953 50.4219 19.0633C49.2808 22.5392 46.3338 24.8718 43.0792 24.8718ZM35.9383 15.6171C35.7994 15.6764 35.5051 16.0256 35.6076 17.0766C35.687 17.9563 35.9383 17.9563 36.1798 17.9563C36.3022 17.9564 36.4215 17.995 36.5207 18.0666C36.6199 18.1382 36.6939 18.2391 36.7321 18.3549L36.8413 18.6844C37.8335 21.6859 40.3307 23.7055 43.0858 23.7055C45.841 23.7055 48.3448 21.6957 49.337 18.6877C49.3569 18.6284 49.433 18.3582 49.433 18.3582C49.4686 18.2391 49.542 18.1346 49.6421 18.0604C49.7422 17.9862 49.8638 17.9462 49.9886 17.9464C50.23 17.9464 50.4814 17.9464 50.5608 17.0667C50.6567 16.0157 50.3623 15.6665 50.23 15.6072C50.1636 15.605 50.0982 15.5899 50.0376 15.5626C49.977 15.5352 49.9225 15.4963 49.877 15.448C49.8315 15.3996 49.7961 15.3428 49.7727 15.2808C49.7493 15.2188 49.7383 15.1528 49.7405 15.0866L49.724 14.9285C49.3073 11.0506 46.4562 8.12495 43.109 8.12495C39.7618 8.12495 36.9505 11.0078 36.494 14.8329L36.4576 15.1064C36.459 15.1744 36.4465 15.242 36.4208 15.305C36.3951 15.368 36.3568 15.4251 36.3082 15.4729C36.2596 15.5207 36.2017 15.5581 36.1382 15.5829C36.0746 15.6077 36.0066 15.6193 35.9383 15.6171Z"
          fill="#164194"
        />
        <path
          d="M18.5276 18.0683C17.5735 18.0744 16.6366 17.8154 15.8221 17.3204C15.6952 17.2336 15.608 17.1003 15.5795 16.9497C15.551 16.799 15.5835 16.6433 15.67 16.5165C15.757 16.391 15.8902 16.3047 16.0406 16.2764C16.191 16.248 16.3466 16.2798 16.4737 16.3649C16.5531 16.4176 18.3689 17.5938 20.7073 16.3353C20.8424 16.2628 21.0009 16.2467 21.1478 16.2906C21.2948 16.3344 21.4183 16.4346 21.4912 16.5692C21.5639 16.7037 21.58 16.8616 21.536 17.008C21.492 17.1544 21.3914 17.2775 21.2563 17.35C20.4222 17.815 19.4835 18.0621 18.5276 18.0683Z"
          fill="#164194"
        />
        <path
          d="M43.0528 21.4454C42.0969 21.4535 41.1581 21.1931 40.344 20.6942C40.2178 20.6075 40.1313 20.4746 40.1034 20.3245C40.0755 20.1744 40.1085 20.0195 40.1951 19.8936C40.2376 19.8312 40.292 19.7778 40.3553 19.7364C40.4186 19.695 40.4894 19.6665 40.5638 19.6524C40.6381 19.6383 40.7145 19.639 40.7886 19.6544C40.8627 19.6698 40.933 19.6995 40.9955 19.742C41.0782 19.7947 42.894 20.9709 45.2291 19.7124C45.3647 19.6397 45.5237 19.6236 45.6712 19.6674C45.8187 19.7113 45.9428 19.8115 46.0163 19.9463C46.0881 20.081 46.1038 20.2386 46.0598 20.3847C46.0159 20.5309 45.9159 20.6539 45.7815 20.7271C44.9466 21.1903 44.0084 21.4372 43.0528 21.4454Z"
          fill="#164194"
        />
        <path
          d="M35.6374 27.2077C35.6374 27.2077 36.5337 27.3099 36.375 29.0627C36.3188 29.7051 36.1335 30.3146 35.3529 30.3146L35.2636 30.6112C34.3441 33.4347 31.9793 35.4478 29.2109 35.4478C26.4426 35.4478 24.081 33.4413 23.1582 30.6177L23.059 30.3146C22.2784 30.3146 22.0932 29.7051 22.0369 29.0627C21.8782 27.3099 22.7778 27.2077 22.7778 27.2077L22.8076 26.9705C23.2409 23.3464 25.9431 20.5426 29.2109 20.5426C32.4787 20.5426 35.224 23.3892 35.6209 27.0628L35.6374 27.2077Z"
          fill="#F7C5D7"
        />
        <path
          d="M29.2109 35.9419C26.3168 35.9419 23.6973 33.863 22.6885 30.7725C22.027 30.6276 21.6301 30.0708 21.5408 29.1252C21.4118 27.6821 21.9311 27.1056 22.3213 26.8782C22.8042 22.9938 25.7579 20.0648 29.2109 20.0648C32.6639 20.0648 35.6142 22.9839 36.0971 26.8815C36.4874 27.1122 37.0001 27.692 36.8711 29.1252C36.7851 30.084 36.4014 30.6408 35.7333 30.7725C34.7245 33.863 32.105 35.9419 29.2109 35.9419ZM22.834 27.7052C22.7117 27.7414 22.4437 28.0676 22.5297 29.0231C22.6025 29.8237 22.834 29.8237 23.059 29.8237C23.1626 29.8221 23.264 29.8531 23.3489 29.9122C23.4338 29.9714 23.4978 30.0557 23.5319 30.1531L23.6311 30.4563C24.5076 33.1381 26.7501 34.9436 29.2109 34.9436C31.6717 34.9436 33.9175 33.1381 34.7906 30.4497L34.8766 30.163C34.9073 30.061 34.9702 29.9715 35.056 29.908C35.1419 29.8445 35.246 29.8103 35.3529 29.8105C35.5778 29.8105 35.8093 29.8105 35.8821 29.0099C35.9681 28.0511 35.7035 27.725 35.5513 27.692C35.2768 27.692 35.1677 27.5108 35.1412 27.2538V27.1122C34.7675 23.6494 32.224 21.0368 29.2241 21.0368C26.2242 21.0368 23.7204 23.6132 23.3136 27.0265L23.2805 27.2703C23.2797 27.3825 23.235 27.49 23.156 27.5699C23.077 27.6499 22.9698 27.696 22.8572 27.6986L22.834 27.7052Z"
          fill="#E23B77"
        />
        <path
          d="M29.191 32.3211C28.6717 32.3244 28.1619 32.1829 27.7192 31.9125C27.6104 31.8384 27.5354 31.7244 27.5106 31.5954C27.4858 31.4664 27.5132 31.3329 27.5869 31.2239C27.6613 31.1165 27.7751 31.0424 27.9038 31.0177C28.0325 30.9931 28.1657 31.0198 28.2748 31.0922C28.5664 31.2556 28.896 31.3395 29.2305 31.3354C29.565 31.3314 29.8925 31.2395 30.18 31.0691C30.2942 31.0091 30.4276 30.9962 30.5513 31.0332C30.675 31.0702 30.7792 31.154 30.8415 31.2668C30.8997 31.3813 30.9112 31.5139 30.8736 31.6367C30.836 31.7595 30.7522 31.863 30.6397 31.9257C30.1977 32.1764 29.6996 32.3124 29.191 32.3211Z"
          fill="#E23B77"
        />
        <path
          d="M29.2076 25.0827C29.0782 25.0831 28.9537 25.0335 28.8603 24.9443C28.7672 24.8511 28.7149 24.7249 28.7149 24.5934C28.7149 24.4619 28.7672 24.3357 28.8603 24.2425C30.4843 22.5787 28.9297 20.9478 28.8603 20.8885C28.7682 20.7955 28.7166 20.67 28.7166 20.5393C28.7166 20.4086 28.7682 20.2831 28.8603 20.1901C28.9533 20.0975 29.0794 20.0455 29.2109 20.0455C29.3424 20.0455 29.4684 20.0975 29.5615 20.1901C30.3619 21.0071 31.3938 23.0466 29.5615 24.9245C29.5168 24.974 29.4622 25.0136 29.4013 25.0408C29.3404 25.0681 29.2744 25.0823 29.2076 25.0827Z"
          fill="#E23B77"
        />
      </g>
      <defs>
        <clipPath id="societaillustration_clip0_2089_86">
          <rect width="49.2917" height="52" fill="white" transform="translate(5 4)" />
        </clipPath>
      </defs>
    </IconBase>
  )
}

export default SocietaIllustration
