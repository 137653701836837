/* eslint-disable react/button-has-type */
import Link from 'next/link'
import React, { ReactNode } from 'react'
import { Loader } from '../Icons'
import styles from './Button.module.sass'

export interface Props {
  label?: string
  labelMobile?: string
  type?: 'button' | 'submit'
  variant?: 'primary' | 'secondary' | 'ghost' | 'social'
  size?: 'sm' | 'md'
  color?: 'default' | 'white' | 'primary'
  responsive?: boolean
  disabled?: boolean
  icon?: ReactNode
  iconPos?: 'left' | 'right'
  onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
  href?: string
  newPage?: boolean
  className?: string
  loading?: boolean
  external?: boolean
}

const Button = (props: Props) => {
  const {
    label = '',
    labelMobile = '',
    type = 'button',
    variant = 'primary',
    size = 'md',
    color = 'default',
    responsive = false,
    disabled = false,
    icon = null,
    iconPos = 'left',
    onClick = () => {},
    href = null,
    newPage = false,
    className = '',
    loading = false,
    external = false,
  } = props

  const classeNames = `
    ${styles.button} 
    ${styles[`button--${variant}`]}
    ${styles[`button--${size}`]}
    ${color ? styles[`button--color-${color}`] : ''}
    ${responsive ? styles['button--responsive'] : ''}
    ${icon && !label && !labelMobile ? styles['button--no-label'] : ''}
    ${icon && !label && labelMobile ? styles['button--no-label-desktop'] : ''}
    ${icon ? styles[`button--icon-${iconPos}`] : ''}
    ${loading ? styles['button--loading'] : ''}
    ${className ? className : ''}`

  const buttonContent = (
    <>
      {icon && <span className={styles.icon}>{icon}</span>}
      {label && (
        <span className={`${styles.label} ${labelMobile ? styles['label--desktop'] : ''}`}>
          {label}
        </span>
      )}
      {labelMobile && (
        <span className={`${styles.label} ${styles['label--mobile']}`}>{labelMobile}</span>
      )}
      {loading && (
        <span className={styles.loader}>
          <Loader />
        </span>
      )}
    </>
  )

  return href ? (
    external ? (
      <a
        className={classeNames}
        href={href}
        target={newPage ? '_blank' : null}
        rel={`${newPage ? 'noopener ' : ''}noreferrer`}
        onClick={onClick}
      >
        {buttonContent}
      </a>
    ) : (
      <Link legacyBehavior href={href}>
        <a
          className={classeNames}
          rel={`${newPage ? 'noopener ' : ''}noreferrer`}
          target={newPage ? '_blank' : null}
          onClick={onClick}
        >
          {buttonContent}
        </a>
      </Link>
    )
  ) : (
    <button type={type} className={classeNames} disabled={disabled} onClick={onClick}>
      {buttonContent}
    </button>
  )
}

export default Button
