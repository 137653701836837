import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function IscrivitiAllaNewsletter({ className }: Props) {
  return (
    <IconBase name="iscriviti-alla-newsletter" w={100} className={className}>
      <rect width="100" height="100" rx="50" fill="currentColor"/>
      <g clipPath="url(#clip0_4495_4361)">
      <path d="M66.6666 33.3333H33.3333C31.0416 33.3333 29.1875 35.2083 29.1875 37.5L29.1666 62.5C29.1666 64.7917 31.0416 66.6667 33.3333 66.6667H66.6666C68.9583 66.6667 70.8333 64.7917 70.8333 62.5V37.5C70.8333 35.2083 68.9583 33.3333 66.6666 33.3333ZM64.5833 62.5H35.4166C34.2708 62.5 33.3333 61.5625 33.3333 60.4167V41.6667L47.7916 50.7083C49.1458 51.5625 50.8541 51.5625 52.2083 50.7083L66.6666 41.6667V60.4167C66.6666 61.5625 65.7291 62.5 64.5833 62.5ZM50 47.9167L33.3333 37.5H66.6666L50 47.9167Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_4495_4361">
      <rect width="50" height="50" fill="currentColor" transform="translate(25 25)"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default IscrivitiAllaNewsletter
