import { useState } from 'react'

function useLocalStorage(key, initialValue) {
  // ? Faccio i check su Window per evitare Crash dovuti al SSR
  const [storedValue, setStoredValue] = useState(() => {
    try {
      if (typeof window !== 'undefined') {
        const item = window.localStorage.getItem(key)
        return item ? JSON.parse(item) : initialValue
      }
    } catch (error) {
      console.log(error)
      return initialValue
    }
  })

  // ? Versione Wrappata del setState => per settare il Valore Nuovo
  const setValue = (value) => {
    try {
      if (typeof window !== 'undefined') {
        const valueToStore = value instanceof Function ? value(storedValue) : value
        setStoredValue(valueToStore)
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (error) {
      console.log(error)
    }
  }

  return [storedValue, setValue]
}

export default useLocalStorage
