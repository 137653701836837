import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function ChevronUp({ className }: Props) {
  return (
    <IconBase name="chevron-up" w={24} className={className}>
      <path d="M6 15L12.5 8.5L19 15" stroke="#0D3164" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </IconBase>
  )
}

export default ChevronUp
