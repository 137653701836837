import React from 'react'
import styles from "@components/atoms/Separator/Separator.module.sass";

export interface Props {
  className?: string
}


const Separator = (props: Props) => {
  const {
    className = '',
  } = props

  return(
    <div className={`${styles.separator} ${className ? className : ''}`}/>
  )
}

export default Separator
