import React from 'react'
import { Close } from 'components/atoms/Icons'
import { AlertMessage, Button, FormError } from 'components/atoms'
import styles from './FormFile.module.sass'
import useTrans from '@hooks/useTrans'

interface Props {
  id: string
  placeholder?: string
  name?: string
  value?: string
  files?: any[]
  className?: string
  accept?: string
  readOnly?: boolean
  disabled?: boolean
  status?: '' | 'success' | 'error'
  errorMessage?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement> | any | any[]) => void
  onRemove?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  showButtonLoad?: boolean
  showAlert?: boolean
}

const FormFile = (props: Props) => {
  const {
    id = '',
    placeholder = '',
    name = '',
    value = null,
    files = [],
    className = '',
    accept = '',
    readOnly = false,
    disabled = false,
    status = '',
    errorMessage = '',
    onChange = () => {},
    onRemove = () => {},
    showButtonLoad = false,
    showAlert = false,
  } = props
  const t = useTrans()

  const classNames = `
    ${styles.formFile}
    ${status ? styles[`formFile--${status}`] : ''}
    ${disabled ? styles['formFile--disabled'] : ''}
    ${files.length > 0 ? styles['formFile--with-value'] : ''}
    ${className ? className : ''}`

  const hiddenFileInput = React.useRef(null)
  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }

  return (
    <div className={classNames}>
      <div className={styles.container}>
        <div className={styles.inputWrap}>
          <label htmlFor={id} className={styles.control}>
            <input
              type="file"
              name={name}
              id={id}
              value={value}
              className={styles.input}
              readOnly={readOnly}
              disabled={disabled}
              accept={accept}
              onChange={onChange}
              ref={hiddenFileInput}
            />
            <span className={styles.content}>
              {files.length > 0 ? (
                <span className={styles.filename}>{files[0]?.name}</span>
              ) : (
                <span className={styles.label}>{placeholder ? placeholder : ''}</span>
              )}
            </span>
          </label>
          {files.length > 0 && (
            <button
              aria-label="Close"
              type="button"
              className={styles.remove}
              onClick={(e) => onRemove(e)}
            >
              <Close />
            </button>
          )}
        </div>
        {showButtonLoad && (
          <Button
            className={styles.loadDesktop}
            type="submit"
            onClick={handleClick}
            label={t('Scegli un file')}
            variant="primary"
          />
        )}
      </div>
      {showAlert && (
        <AlertMessage
          label={`${errorMessage} ${'Dimensione massima 2MB. Formato jpg, png o pdf.'}`}
          className={status === 'error' ? styles.error : null}
        />
      )}
      {showButtonLoad && (
        <Button
          className={styles.loadMobile}
          type="submit"
          onClick={handleClick}
          label={t('Scegli un file')}
          variant="primary"
        />
      )}
      {/* <FormError className={styles.error} message={errorMessage} visible={status === 'error'} /> */}
    </div>
  )
}

export default FormFile
