import React, { useState } from 'react'
import { FormError } from 'components/atoms'

import EyeHide from '../Icons/EyeHide'
import EyeShow from '../Icons/EyeShow'
import styles from './FormInputPassword.module.sass'

interface Props {
  placeholder?: string
  name?: string
  value?: string
  className?: string
  readOnly?: boolean
  disabled?: boolean
  status?: '' | 'success' | 'error'
  errorMessage?: string
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  togglePassword?: boolean
}

const FormInputPassword = (props: Props) => {
  const {
    placeholder = '',
    name = '',
    value = '',
    className = '',
    readOnly = false,
    disabled = false,
    status = '',
    errorMessage = '',
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
    togglePassword = false,
  } = props

  const [showPassword, setShowPassword] = useState(false)

  const classNames = `
    ${styles.formInputPassword}
    ${status ? styles[`formInputPassword--${status}`] : ''}
    ${togglePassword ? styles[`formInputPassword--with-toggler`] : ''}
    ${className}`

  return (
    <div className={classNames}>
      <div className={styles.inputWrap}>
        <input
          type={showPassword ? 'text' : 'password'}
          name={name}
          value={value}
          className={styles.input}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
        />
        {togglePassword && (
          <button
            type="button"
            className={styles.toggler}
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <EyeHide /> : <EyeShow />}
          </button>
        )}
      </div>
      <FormError message={errorMessage} visible={status === 'error'} />
    </div>
  )
}

export default FormInputPassword
