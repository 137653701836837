import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function SvezzamentoIllustration({ className }: Props) {
  return (
    <IconBase name="svezzamento-illustration" w={40} className={className}>
      <g clipPath="url(#svezzamentoillustration_clip0_2033_6272)">
        <path
          d="M33.2694 27.7036C33.2694 30.3194 27.328 31.3872 19.9999 31.3872C12.6719 31.3872 6.73047 30.3194 6.73047 27.7036C6.73047 25.0878 12.6719 22.9624 19.9979 22.9624C27.3238 22.9624 33.2694 25.0857 33.2694 27.7036Z"
          fill="#D3DFF0"
        />
        <path
          d="M19.9983 31.7492C14.9074 31.7492 6.3667 31.2236 6.3667 27.7034C6.3667 24.8413 12.3537 22.6001 19.9983 22.6001C27.643 22.6001 33.63 24.8413 33.63 27.7034C33.632 31.2236 25.0913 31.7492 19.9983 31.7492ZM19.9983 23.3244C12.2771 23.3244 7.09102 25.5884 7.09102 27.7034C7.09102 29.7522 12.0371 31.0146 19.9983 31.0146C27.9596 31.0146 32.9056 29.7419 32.9056 27.7034C32.9077 25.5884 27.7195 23.3244 19.9983 23.3244Z"
          fill="#164194"
        />
        <path
          d="M20.0003 28.8316C30.1096 28.8316 38.3048 26.9164 38.3048 24.554C38.3048 22.1915 30.1096 20.2764 20.0003 20.2764C9.89101 20.2764 1.6958 22.1915 1.6958 24.554C1.6958 26.9164 9.89101 28.8316 20.0003 28.8316Z"
          fill="white"
        />
        <path
          d="M19.9982 29.1936C10.9484 29.1936 1.33154 27.567 1.33154 24.5538C1.33154 22.8527 4.39851 21.7787 6.97293 21.1764C10.4559 20.3631 15.0811 19.9141 19.9982 19.9141C29.046 19.9141 38.6649 21.5407 38.6649 24.5538C38.6649 26.2549 35.5958 27.3311 33.0235 27.9312C29.5426 28.7445 24.9153 29.1936 19.9982 29.1936ZM19.9982 20.6384C9.42526 20.6384 2.05586 22.7079 2.05586 24.5538C2.05586 26.3998 9.42526 28.4693 19.9982 28.4693C30.5712 28.4693 37.9406 26.3998 37.9406 24.5538C37.9406 22.7079 30.5732 20.6384 19.9982 20.6384Z"
          fill="#164194"
        />
        <path
          d="M20 26.9501C27.1377 26.9501 32.9239 25.8772 32.9239 24.5537C32.9239 23.2302 27.1377 22.1572 20 22.1572C12.8624 22.1572 7.07617 23.2302 7.07617 24.5537C7.07617 25.8772 12.8624 26.9501 20 26.9501Z"
          fill="#F7C5D7"
        />
        <path
          d="M19.9979 27.3082C16.5233 27.3082 13.2556 27.0578 10.7929 26.6005C6.71191 25.843 6.71191 24.8704 6.71191 24.5496C6.71191 24.2289 6.71191 23.2562 10.7929 22.4988C13.2556 22.0414 16.5233 21.791 19.9979 21.791C23.4726 21.791 26.7403 22.0414 29.2009 22.4988C33.2819 23.2562 33.2819 24.2289 33.2819 24.5496C33.2819 24.8704 33.2819 25.843 29.2009 26.6005C26.7424 27.062 23.4726 27.3082 19.9979 27.3082ZM19.9979 22.5153C16.5668 22.5153 13.3446 22.7616 10.9254 23.2107C8.06328 23.7425 7.43623 24.3572 7.43623 24.5496C7.43623 24.7421 8.05708 25.3567 10.9254 25.8886C13.3446 26.3377 16.5668 26.5839 19.9979 26.5839C23.4291 26.5839 26.6492 26.3377 29.0685 25.8886C31.9326 25.3567 32.5576 24.7421 32.5576 24.5496C32.5576 24.3572 31.9367 23.7425 29.0685 23.2107C26.6513 22.7657 23.4291 22.5195 19.9979 22.5195V22.5153Z"
          fill="#E23B77"
        />
        <path
          d="M19.7392 17.5319C19.6651 17.532 19.5928 17.5094 19.5319 17.4672C19.4711 17.4249 19.4247 17.365 19.399 17.2955C19.3733 17.226 19.3695 17.1503 19.3881 17.0786C19.4068 17.0069 19.4469 16.9427 19.5032 16.8945C20.7015 15.8598 20.8008 14.2291 20.2069 13.2936C19.0935 11.5408 19.108 10.0239 20.2462 8.78426C20.2784 8.7492 20.3172 8.72083 20.3604 8.70077C20.4035 8.6807 20.4502 8.66934 20.4978 8.66732C20.5453 8.6653 20.5928 8.67267 20.6376 8.68901C20.6823 8.70534 20.7233 8.73033 20.7584 8.76253C20.7934 8.79474 20.8218 8.83353 20.8419 8.8767C20.8619 8.91987 20.8733 8.96657 20.8753 9.01413C20.8773 9.06169 20.87 9.10918 20.8536 9.1539C20.8373 9.19861 20.8123 9.23967 20.7801 9.27473C19.8654 10.2681 19.8778 11.4228 20.8194 12.9046C21.5914 14.1214 21.4879 16.1516 19.9751 17.445C19.9092 17.5009 19.8256 17.5317 19.7392 17.5319Z"
          fill="#164194"
        />
        <path
          d="M14.1644 18.6537C14.107 18.6531 14.0505 18.6391 13.9995 18.6129C13.9485 18.5867 13.9043 18.5489 13.8705 18.5026C13.8146 18.4246 13.7919 18.3277 13.8074 18.233C13.823 18.1383 13.8754 18.0537 13.9533 17.9976C14.2181 17.8177 14.4442 17.5865 14.6184 17.3178C14.7925 17.0491 14.9112 16.7483 14.9673 16.4331C15.0075 16.1963 15 15.9539 14.9452 15.7201C14.8905 15.4863 14.7897 15.2657 14.6486 15.0714C13.5042 13.5483 13.5187 12.2279 14.6941 11.1497C14.7292 11.1175 14.7703 11.0926 14.815 11.0762C14.8597 11.0599 14.9072 11.0525 14.9547 11.0545C15.0023 11.0565 15.049 11.0679 15.0922 11.088C15.1353 11.108 15.1741 11.1364 15.2063 11.1715C15.2385 11.2065 15.2635 11.2476 15.2799 11.2923C15.2962 11.337 15.3036 11.3845 15.3015 11.4321C15.2995 11.4796 15.2882 11.5263 15.2681 11.5695C15.248 11.6127 15.2197 11.6515 15.1846 11.6837C14.2927 12.5115 14.3071 13.4117 15.2281 14.6368C15.4264 14.9085 15.5688 15.2169 15.647 15.5441C15.7251 15.8713 15.7375 16.2108 15.6833 16.5428C15.6131 16.9532 15.4613 17.3454 15.237 17.6962C15.0127 18.047 14.7205 18.3493 14.3775 18.5854C14.3153 18.6297 14.2408 18.6536 14.1644 18.6537Z"
          fill="#164194"
        />
        <path
          d="M24.491 18.1219C24.4148 18.1209 24.3408 18.0959 24.2797 18.0505C24.2185 18.005 24.1732 17.9414 24.1503 17.8687C24.1274 17.796 24.1279 17.718 24.152 17.6456C24.176 17.5733 24.2222 17.5104 24.284 17.4659C24.5493 17.2867 24.776 17.0561 24.9505 16.7877C25.1251 16.5193 25.244 16.2186 25.3002 15.9034C25.3393 15.6666 25.3313 15.4245 25.2766 15.1908C25.2219 14.9571 25.1216 14.7365 24.9815 14.5417C23.835 13.0186 23.8495 11.6982 25.0249 10.6097C25.06 10.5775 25.101 10.5525 25.1458 10.5362C25.1905 10.5198 25.238 10.5125 25.2855 10.5145C25.3331 10.5165 25.3798 10.5279 25.4229 10.5479C25.4661 10.568 25.5049 10.5964 25.5371 10.6314C25.5693 10.6665 25.5943 10.7075 25.6106 10.7523C25.627 10.797 25.6344 10.8445 25.6323 10.892C25.6303 10.9396 25.6189 10.9863 25.5989 11.0295C25.5788 11.0726 25.5504 11.1114 25.5154 11.1436C24.6255 11.9611 24.6379 12.8716 25.5609 14.0968C25.7586 14.3688 25.9005 14.6773 25.9787 15.0044C26.0568 15.3314 26.0695 15.6707 26.0162 16.0028C25.9458 16.4133 25.7937 16.8057 25.5691 17.1565C25.3445 17.5073 25.0517 17.8096 24.7083 18.0453C24.646 18.0936 24.5698 18.1204 24.491 18.1219Z"
          fill="#164194"
        />
      </g>
      <defs>
        <clipPath id="svezzamentoillustration_clip0_2033_6272">
          <rect
            width="37.3333"
            height="23.0829"
            fill="white"
            transform="translate(1.3335 8.6665)"
          />
        </clipPath>
      </defs>
    </IconBase>
  )
}

export default SvezzamentoIllustration
