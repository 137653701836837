import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Dati({ className }: Props) {
  return (
    <IconBase name="dati" w={90} className={className}>
      <path d="M76.941 72.8435H12.7397C7.71921 72.8435 3.65479 68.7639 3.65479 63.7345V26.2656C3.65479 21.2318 7.72357 17.1566 12.7397 17.1566H76.941C81.9615 17.1566 86.0259 21.2362 86.0259 26.2656V63.7301C86.0259 68.7639 81.9615 72.8435 76.941 72.8435Z" stroke="#164194" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
      <path d="M37.5584 61.4451C37.9644 61.4451 38.2743 61.1037 38.2569 60.6966C37.9164 53.6624 32.8173 47.0529 26.5613 47.0529C20.3053 47.0529 15.1888 53.6581 14.8482 60.6966C14.8264 61.0993 15.1407 61.4451 15.5467 61.4451H26.5613H37.5584Z" fill="#F7C5D7" stroke="#E23B77" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M26.5528 49.0052C30.7457 49.0052 34.1447 45.5972 34.1447 41.3932C34.1447 37.1892 30.7457 33.7812 26.5528 33.7812C22.3599 33.7812 18.9609 37.1892 18.9609 41.3932C18.9609 45.5972 22.3599 49.0052 26.5528 49.0052Z" fill="#F7C5D7" stroke="#E23B77" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M77.1811 37.8826H52.7247C51.2273 37.8826 50.0093 36.6658 50.0093 35.16C50.0093 33.6586 51.2229 32.4374 52.7247 32.4374H77.1811C78.6785 32.4374 79.8966 33.6542 79.8966 35.16C79.8966 36.6614 78.6829 37.8826 77.1811 37.8826Z" fill="#D3DFF0" stroke="#164194" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M77.1811 47.7226H52.7247C51.2273 47.7226 50.0093 46.5057 50.0093 45C50.0093 43.4986 51.2229 42.2773 52.7247 42.2773H77.1811C78.6785 42.2773 79.8966 43.4942 79.8966 45C79.8966 46.5013 78.6829 47.7226 77.1811 47.7226Z" fill="#D3DFF0" stroke="#164194" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M77.1811 57.5625H52.7247C51.2273 57.5625 50.0093 56.3457 50.0093 54.8399C50.0093 53.3385 51.2229 52.1173 52.7247 52.1173H77.1811C78.6785 52.1173 79.8966 53.3342 79.8966 54.8399C79.8966 56.3413 78.6829 57.5625 77.1811 57.5625Z" fill="#D3DFF0" stroke="#164194" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M37.5716 61.4452H15.5338C12.5607 61.4452 10.1553 59.0289 10.1553 56.0524V33.9519C10.1553 30.971 12.5651 28.5592 15.5338 28.5592H37.5759C40.549 28.5592 42.9544 30.9754 42.9544 33.9519V56.0524C42.9544 59.0289 40.5446 61.4452 37.5716 61.4452Z" stroke="#164194" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
    </IconBase>
  )
}

export default Dati
