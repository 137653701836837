import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Ciuccio({ className }: Props) {
  return (
    <IconBase name="ciuccio" w={24} className={className}>
      <path d="M16.2393 12.0589C18.9478 15.3663 20.2573 18.4281 19.2324 19.4596C17.9718 20.7285 13.661 18.4445 9.59834 14.3553C5.53567 10.2661 3.2705 5.92722 4.53119 4.6583C5.55194 3.63089 8.58166 4.93664 11.8554 7.64639" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"/>
      <path d="M6.11716 10.1802L4.57993 11.7274C2.47336 13.8478 2.47336 17.2902 4.57993 19.4105C6.6865 21.5308 10.1066 21.5308 12.2132 19.4105L13.7504 17.8633L11.8838 16.4634L10.5824 17.7732C9.37461 18.9889 7.41445 18.9889 6.20662 17.7732C4.9988 16.5575 4.9988 14.5845 6.20662 13.3688L7.52425 12.059L6.11716 10.1802Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"/>
      <path d="M19.5373 4.35129C16.1741 0.962052 12.3473 4.76061 12.087 6.89321C11.8186 9.0954 9.60631 10.7 11.4201 12.5256C13.2908 14.4085 14.8158 12.0098 17.0159 11.8543C19.273 11.6905 23.1201 7.95747 19.5373 4.35129Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"/>
    </IconBase>
  )
}

export default Ciuccio
