import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function PayoffMobile({ className }: Props) {
  return (
    <IconBase name="payoff-mobile" w={416} h={271} className={className}>
      <path d="M2.22998 16.31C2.22998 9.02 2.12002 4.56999 1.90002 2.60999C2.99002 2.27999 5.49002 1.73999 6.90002 1.73999C8.10002 1.73999 8.20001 2.28001 8.20001 7.07001V56.54C8.20001 64.04 8.42003 67.63 8.53003 69.8C7.01003 70.34 4.72003 70.67 3.53003 70.67C2.66003 70.67 2.22003 70.34 2.22003 68.71V16.31H2.22998Z" fill="url(#payoffmobile_0_linear_57_44)"/>
      <path d="M54.42 41.54C54.42 31.21 50.07 26.53 42.68 26.53C35.83 26.53 30.29 31.53 27.57 35.34V67.74C27.57 70.02 27.03 70.24 25.61 70.46C24.3 70.57 22.78 70.68 21.69 70.68C21.91 68.51 22.02 62.2 22.02 50.35V31.54C22.02 26.97 21.37 24.91 20.39 23.93V23.71C21.04 22.95 23.43 21.54 24.3 21.54C27.02 21.54 27.35 27.63 27.35 30.46C31.48 25.78 38 21.54 44.42 21.54C55.62 21.54 59.97 29.04 59.97 41.11V57.86C59.97 64.71 60.19 68.08 60.41 69.82C58.23 70.26 56.6 70.69 55.3 70.69C54.54 70.69 54.21 70.26 54.21 69.49L54.42 41.54Z" fill="url(#payoffmobile_1_linear_57_44)"/>
      <path d="M93.78 25.23C93.89 26.32 92.69 28.27 91.28 28.38C88.89 26.97 85.52 25.99 82.91 25.99C77.91 25.99 73.88 29.03 73.88 33.82C73.88 37.73 76.38 40.13 79.1 41.54C82.69 43.5 85.84 44.7999 89.65 46.9799C94 49.5899 96.17 53.29 96.17 57.2C96.17 67.2 87.58 71.33 79.97 71.33C73.99 71.33 69.97 69.37 68.12 67.74C67.36 67.2 67.03 66.33 67.03 65.78C67.03 64.58 67.79 63.17 68.66 62.74C71.92 65.13 76.16 66.76 80.4 66.76C87.14 66.76 90.51 63.17 90.51 58.5C90.51 55.13 88.55 52.63 84.2 50.45C81.16 48.82 78.55 47.73 76.15 46.43C71.47 43.93 68.43 40.34 68.43 34.8C68.43 26.86 75.5 21.54 83.43 21.54C87.37 21.53 91.93 22.73 93.78 25.23Z" fill="url(#payoffmobile_2_linear_57_44)"/>
      <path d="M109.98 3.81C109.98 5.77 108.68 7.72 106.39 7.72C103.78 7.72 102.69 5.98 102.69 3.91C102.69 1.41 104.65 0 106.5 0C108.68 0 109.98 1.74 109.98 3.81ZM109.22 67.42C109.22 69.38 108.79 69.92 107.81 70.14C106.94 70.36 105.42 70.57 103.35 70.68C103.46 66.33 103.68 60.03 103.68 53.72V35.45C103.68 28.82 103.46 25.55 103.24 23.05C104.65 22.51 107.05 22.18 108.13 22.18C108.78 22.18 109.22 22.72 109.22 28.81V67.42V67.42Z" fill="url(#payoffmobile_3_linear_57_44)"/>
      <path d="M154.13 63.61C153.91 65.68 153.04 67.42 151.41 68.5C149.45 69.8 145.1 71.33 140.21 71.33C125.64 71.33 117.92 61.98 117.92 47.41C117.92 33.27 125.86 21.53 139.78 21.53C151.09 21.53 156.31 30.12 156.63 38.71C156.63 39.47 156.63 40.99 156.41 41.97C156.08 43.93 149.99 45.12 146.84 45.34C143.69 45.45 133.47 45.67 123.79 45.34C123.57 57.84 130.1 66.32 141.62 66.32C146.3 66.32 151.73 64.58 153.91 63.38L154.13 63.61ZM124.34 41.1C135.1 41.32 146.19 41.1 147.72 41.1C149.46 40.99 150.66 40.23 150.66 38.82C150.88 31.21 146.53 25.45 138.59 25.45C131.84 25.45 125.64 31.21 124.34 41.1Z" fill="url(#payoffmobile_4_linear_57_44)"/>
      <path d="M169.46 68.29C169.46 70.03 168.7 70.36 167.72 70.46C166.2 70.68 164.46 70.68 163.59 70.68C163.81 68.51 163.92 62.2 163.92 50.35V31.54C163.92 27.08 163.27 24.58 162.29 23.93V23.6C162.94 22.95 165.44 21.53 166.31 21.53C168.7 21.53 169.25 26.75 169.25 30.45C173.6 25.88 179.58 21.53 186 21.53C192.85 21.53 196.87 24.68 199.27 30.55C203.73 25.77 209.6 21.53 216.45 21.53C226.78 21.53 231.56 28.71 231.56 40.77V57.95C231.56 64.69 231.78 68.06 231.99 69.8C229.92 70.24 228.18 70.67 226.88 70.67C226.34 70.67 226.01 70.24 226.01 69.26V41.75C226.01 30.88 221.77 26.64 214.7 26.64C207.96 26.64 203.5 31.1 200.46 34.36L200.57 67.52C200.57 69.8 200.35 70.13 198.72 70.46C196.87 70.68 195.46 70.68 194.7 70.68C194.92 68.51 195.03 62.2 195.03 50.35V41.65C195.03 31.21 191.12 26.65 183.94 26.65C177.85 26.65 172.41 31.54 169.48 35.02V68.29H169.46Z" fill="url(#payoffmobile_5_linear_57_44)"/>
      <path d="M276.56 63.61C276.34 65.68 275.47 67.42 273.84 68.5C271.88 69.8 267.53 71.33 262.64 71.33C248.07 71.33 240.35 61.98 240.35 47.41C240.35 33.27 248.29 21.53 262.2 21.53C273.51 21.53 278.73 30.12 279.05 38.71C279.05 39.47 279.05 40.99 278.83 41.97C278.5 43.93 272.42 45.12 269.26 45.34C266.11 45.45 255.89 45.67 246.21 45.34C245.99 57.84 252.52 66.32 264.04 66.32C268.72 66.32 274.15 64.58 276.33 63.38L276.56 63.61ZM246.77 41.1C257.54 41.32 268.62 41.1 270.15 41.1C271.89 40.99 273.09 40.23 273.09 38.82C273.31 31.21 268.96 25.45 261.02 25.45C254.27 25.45 248.07 31.21 246.77 41.1Z" fill="url(#payoffmobile_6_linear_57_44)"/>
      <path d="M34.31 132.84C34.31 127.51 31.16 124.14 27.03 124.14C23.44 124.14 21.49 125.44 19.09 126.97V159.59C19.09 161.44 18.87 161.98 16.48 162.31C12.13 162.96 8.33002 163.07 3.65002 163.07C3.87002 156.44 3.97998 149.7 3.97998 143.39V125.77C3.97998 122.83 3.65003 121.31 1.91003 120.23V119.47C3.00003 116.21 7.12998 113.06 11.04 113.06C15.61 113.06 17.56 116.43 18.32 119.91C23 116.1 27.78 113.06 33 113.06C47.46 113.06 49.42 125.45 49.42 133.07V146.01C49.42 151.34 49.53 157.97 49.85 162.21C47.24 162.43 42.24 163.08 36.8 163.08C35.06 163.08 34.3 162.43 34.3 160.8V132.84H34.31Z" fill="url(#payoffmobile_7_linear_57_44)"/>
      <path d="M62.47 127.19C62.47 120.45 62.25 117.19 62.04 115.12C64.65 114.47 72.04 113.71 75.41 113.71C77.15 113.71 77.58 114.25 77.58 120.56C77.58 127.74 77.58 135.89 77.58 143.72C77.58 150.68 82.15 152.31 85.63 152.31C88.46 152.31 90.63 151.33 92.37 150.03V118.93C92.37 115.56 92.7 114.91 94.87 114.47C100.53 113.93 103.46 113.71 107.81 113.71C107.81 115.99 107.48 123.82 107.48 133.39V149.48C107.48 153.5 108.89 154.59 111.72 155.13V155.78C111.29 159.69 107.48 163.72 104.43 163.72C97.9101 163.72 95.62 161 94.1 156.98C91.49 159.37 88.45 161.11 86.16 162.09C84.09 163.07 81.92 163.72 79.31 163.72C67.35 163.72 62.46 155.78 62.46 145.13V127.19H62.47Z" fill="url(#payoffmobile_8_linear_57_44)"/>
      <path d="M152.06 151.65C152.49 154.48 152.06 158.28 150.1 160.02C147.38 162.41 142.6 163.72 137.38 163.72C125.64 163.72 122.16 156.22 122.16 148.93V123.92C117.92 123.92 115.53 121.64 115.53 120.55C117.38 116.96 127.06 108.26 129.12 106.74C131.19 105.11 133.47 103.59 138.36 103.15C138.03 106.41 137.71 110.98 137.71 114.35H153.15C151.74 122.94 149.34 123.16 147.06 123.38C145.21 123.6 140.32 123.92 137.38 123.92C136.95 131.75 137.05 141.86 137.49 146.32C138.03 151.1 140.64 153.17 144.34 153.17C147.6 153.17 150.65 151.97 151.73 151.43L152.06 151.65Z" fill="url(#payoffmobile_9_linear_57_44)"/>
      <path d="M158.04 119.36C159.02 116.53 163.58 113.05 167.06 113.05C172.6 113.05 174.56 117.83 174.67 124.47C177.71 116.97 182.28 113.05 188.26 113.05C190.87 113.05 193.37 114.35 194.46 115.66C194.57 118.27 193.37 123.38 191.2 126.31C190.22 127.51 188.92 128.27 186.96 127.72C185.22 127.07 184.13 126.96 182.5 126.96C180.98 126.96 178.04 127.94 176.52 132.07C175.87 133.81 175.65 134.79 175.65 137.18V159.37C175.65 161.11 175.43 162.09 173.04 162.41C168.69 162.95 164.99 163.06 160.21 163.06C160.43 156.43 160.54 149.69 160.54 143.38V124.9C160.54 122.83 159.89 120.88 158.04 119.9V119.36Z" fill="url(#payoffmobile_10_linear_57_44)"/>
      <path d="M216.43 159.81C216.43 161.77 215.56 162.31 212.73 162.64C210.34 162.97 205.77 163.07 200.99 163.07C201.1 158.61 201.32 152.31 201.32 145.89V127.3C201.32 120.56 201.1 117.19 200.89 115.12C203.61 114.36 211.65 113.71 214.15 113.71C216 113.71 216.43 114.25 216.43 120.56V159.81V159.81ZM216.64 97.83C216.64 102.51 213.27 106.09 208.7 106.09C205.11 106.09 200.98 104.02 200.98 98.26C200.98 92.17 206.09 90 209.14 90C213.82 90 216.64 93.26 216.64 97.83Z" fill="url(#payoffmobile_11_linear_57_44)"/>
      <path d="M271.88 153.28C271.88 159.8 268.73 163.72 261.66 163.72C257.2 163.72 254.05 161.76 252.2 157.96C249.48 160.9 244.92 163.72 240.02 163.72C232.95 163.72 225.99 158.28 225.99 149.59C225.99 138.72 237.95 135.67 246.54 133.82C248.5 133.39 250.89 132.95 252.08 132.73V131.43C252.08 127.19 249.04 124.04 244.03 124.04C240.33 124.04 236.53 125.78 234.46 126.76C229.13 128.28 227.61 123.61 227.72 120.13C232.83 116.54 240.77 113.06 248.16 113.06C257.4 113.06 266.53 118.93 266.53 131.54C266.53 136 266.1 143.17 266.1 150.46C266.1 151.55 267.4 152.96 270.01 152.96C270.55 152.96 271.31 152.96 271.75 152.85L271.88 153.28ZM246.22 153.17C248.39 153.17 249.48 152.74 250.9 151.54C251.55 150.78 251.88 149.69 251.88 147.73V140.34C242.2 141.32 240.14 145.02 240.14 147.41C240.13 151.11 242.85 153.17 246.22 153.17Z" fill="url(#payoffmobile_12_linear_57_44)"/>
      <path d="M296.23 159.26C296.23 161.22 296.01 162.09 293.62 162.41C289.27 162.95 285.57 163.06 280.79 163.06C281.01 156.43 281.12 149.69 281.12 143.38V125.33C281.12 122.72 280.69 121.2 279.05 120.11V119.35C280.25 116.09 284.38 113.04 287.97 113.04C293.19 113.04 294.6 116.63 295.47 119.89C299.82 116.41 305.58 113.04 311.02 113.04C318.2 113.04 321.89 116.41 324.18 120.76C328.42 116.95 334.83 113.04 340.6 113.04C353.43 113.04 356.37 124.46 356.37 131.74V145.98C356.37 151.31 356.48 157.94 356.7 162.18C354.09 162.4 349.09 163.05 343.65 163.05C342.02 163.05 341.26 162.4 341.26 160.77V132.72C341.26 127.94 338.76 124.13 333.87 124.13C330.93 124.13 328.43 125.54 326.37 127.39V159.57C326.37 161.42 326.15 162.07 323.65 162.4C319.3 162.94 315.6 163.05 310.93 163.05C311.15 156.42 311.26 149.68 311.26 143.37V132.71C311.26 127.71 308.65 124.12 304.08 124.12C301.14 124.12 298.54 125.32 296.25 127.27V159.26H296.23Z" fill="url(#payoffmobile_13_linear_57_44)"/>
      <path d="M415.73 137.62C415.73 153.28 405.51 163.72 390.18 163.72C377.02 163.72 365.93 155.57 365.93 138.71C365.93 123.71 376.69 113.05 391.37 113.05C407.36 113.05 415.73 124.36 415.73 137.62ZM381.59 137.19C381.59 149.26 386.16 154.7 391.48 154.7C398.22 154.7 400.07 147.41 400.07 139.04C400.07 130.23 397.35 122.08 390.28 122.08C383.87 122.08 381.59 129.03 381.59 137.19Z" fill="url(#payoffmobile_14_linear_57_44)"/>
      <path d="M18.49 222.13C16.32 237.9 14.25 253.77 13.16 261.16C12.83 263.23 12.29 264.1 11.42 264.64C10.33 265.4 5 266.49 0 266.6C1.09 259.64 3.04002 248.01 4.46002 237.9C5.98002 226.37 6.63003 220.72 6.53003 218.87C7.83003 218.22 16.32 216.59 17.62 216.59C18.92 216.59 19.15 217.46 18.49 222.13ZM21.21 199.84C21.43 205.06 17.4 207.78 14.36 207.78C11.86 207.78 8.60002 206.58 8.27002 201.58C8.05002 195.49 12.73 193.64 15.01 193.64C18.17 193.64 20.89 194.95 21.21 199.84Z" fill="url(#payoffmobile_15_linear_57_44)"/>
      <path d="M45.13 260.95C44.8 262.91 44.26 263.99 43.06 264.65C41.1 265.63 36.43 266.61 31.97 266.61C33.71 256.17 39.25 218.99 40.34 209.2C41.75 198.11 42.19 194.96 42.3 193.22C44.04 192.68 50.78 191.81 53.06 191.81C54.36 191.81 54.69 192.68 54.26 195.72C52.63 209.41 47.09 247.79 45.13 260.95Z" fill="url(#payoffmobile_16_linear_57_44)"/>
      <path d="M150.86 253.55C145.75 262.14 138.57 267.03 130.42 267.03C120.52 267.03 120.09 257.24 121.39 249.2C122.15 244.2 123.24 238.76 124 233.54C125.31 225.49 125.85 221.04 126.07 218.97C127.27 218.32 135.86 216.58 137.38 216.58C138.36 216.58 138.68 217.78 138.25 220.49C137.05 227.12 134.99 239.52 133.36 249.52C132.38 254.85 133.58 257.02 136.51 257.02C141.62 257.02 151.73 248 155.65 222.77C155.98 219.94 156.52 218.96 157.93 218.31C160.11 217.33 166.52 216.35 168.59 216.57C166.31 225.05 163.81 243.86 162.83 252.23C162.5 255.17 161.96 261.47 162.07 265.17C158.92 266.58 152.72 267.02 150.98 267.02C150.22 267.02 150.22 262.24 151.2 253.65L150.86 253.55Z" fill="url(#payoffmobile_17_linear_57_44)"/>
      <path d="M206.31 261.71C202.94 265.08 198.37 267.04 192.61 267.04C186.2 267.04 182.39 262.47 184.24 250.51C185.33 242.79 186.85 234.09 188.05 226.92H180.87C180.87 224.2 182.72 218.87 185.11 218.87H189.35C190.98 210.39 192.83 207.67 194.68 206.58C196.2 205.71 200.66 204.62 203.6 204.62C202.84 208.64 201.86 213.86 200.88 218.86C207.95 218.86 211.43 218.86 213.6 218.53C213.06 221.68 211.97 224.62 209.9 225.92C208.16 226.9 204.79 226.9 199.57 226.9C198.27 234.73 196.42 247.12 195.76 251.15C195.11 255.61 196.19 257.56 198.37 257.56C201.2 257.56 205 255.49 208.48 252.45C209.35 254.75 208.37 259.75 206.31 261.71Z" fill="url(#payoffmobile_18_linear_57_44)"/>
      <path d="M253.39 253.55C248.28 262.14 241.1 267.03 232.95 267.03C223.05 267.03 222.62 257.24 223.92 249.2C224.68 244.2 225.77 238.76 226.53 233.54C227.84 225.49 228.38 221.04 228.6 218.97C229.8 218.32 238.39 216.58 239.91 216.58C240.89 216.58 241.21 217.78 240.78 220.49C239.58 227.12 237.52 239.52 235.89 249.52C234.91 254.85 236.11 257.02 239.04 257.02C244.15 257.02 254.26 248 258.18 222.77C258.51 219.94 259.05 218.96 260.46 218.31C262.64 217.33 269.05 216.35 271.12 216.57C268.84 225.05 266.34 243.86 265.36 252.23C265.03 255.17 264.49 261.47 264.6 265.17C261.45 266.58 255.25 267.02 253.51 267.02C252.75 267.02 252.75 262.24 253.73 253.65L253.39 253.55Z" fill="url(#payoffmobile_19_linear_57_44)"/>
      <path d="M299.6 232.9C305.36 220.29 312.1 216.59 316.78 216.59C318.74 216.59 320.26 217.46 321.13 218.44C321.56 221.59 319.06 227.68 316.89 229.96C315.69 229.42 314.28 228.76 312.43 228.76C308.95 228.76 300.69 234.85 296.01 261.05C295.58 263.33 294.92 264.2 293.84 264.86C292.1 265.73 285.9 266.49 282.86 266.6C284.6 257.79 287.21 240.61 288.4 230.07C288.73 226.92 288.94 221.37 288.62 218.98C290.47 218.11 297.54 216.59 299.17 216.59C300.04 216.59 300.91 222.24 299.28 232.9H299.6V232.9Z" fill="url(#payoffmobile_20_linear_57_44)"/>
      <path d="M372.88 235.72C372.88 253.33 361.79 267.03 345.15 267.03C333.51 267.03 327.21 261.05 327.21 248.22C327.21 231.69 337.21 216.58 354.83 216.58C367.56 216.59 372.88 224.85 372.88 235.72ZM340.27 249.31C340.27 255.4 342.44 259.2 346.79 259.2C354.07 259.2 360.06 248.33 360.06 233.76C360.06 229.41 358.54 224.3 353.53 224.3C345.7 224.31 340.27 236.48 340.27 249.31Z" fill="url(#payoffmobile_21_linear_57_44)"/>
      <path d="M85.29 261.38C84.71 265.24 84.06 268.39 83.3 270.95H95.02C95.68 268.6 96.25 265.97 96.7 263.01C98.33 251.7 99.53 242.24 101.49 226.91C106.71 226.91 109.21 226.8 110.62 225.82C112.8 224.41 113.56 221.69 114.21 218.53C111.71 218.97 106.82 218.86 102.58 218.86C104.65 202.66 107.8 199.61 112.37 199.61C113.68 199.61 115.2 199.94 116.07 200.26C118.57 199.83 119.66 195.8 119.33 193.63C118.13 192.54 115.09 191.35 111.61 191.35C102.48 191.35 93.89 195.81 90.73 218.86L85.95 218.97C83.99 219.19 82.14 224.08 82.04 226.91H89.87C88.88 234.74 87.14 248.44 85.29 261.38Z" fill="url(#payoffmobile_22_linear_57_44)"/>
      <defs>
      <linearGradient id="payoffmobile_0_linear_57_44" x1="1168.21" y1="36.2101" x2="31.9922" y2="36.2101" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffmobile_1_linear_57_44" x1="1168.21" y1="46.1049" x2="31.9923" y2="46.1049" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffmobile_2_linear_57_44" x1="1168.2" y1="46.4313" x2="31.9923" y2="46.4313" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffmobile_3_linear_57_44" x1="1168.19" y1="35.3402" x2="31.9931" y2="35.3402" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffmobile_4_linear_57_44" x1="1168.2" y1="46.4313" x2="31.9923" y2="46.4313" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffmobile_5_linear_57_44" x1="1168.2" y1="46.1048" x2="31.9923" y2="46.1048" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffmobile_6_linear_57_44" x1="1168.21" y1="46.4313" x2="31.9918" y2="46.4313" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffmobile_7_linear_57_44" x1="855.292" y1="138.059" x2="-280.919" y2="138.059" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffmobile_8_linear_57_44" x1="855.293" y1="138.711" x2="-280.92" y2="138.711" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffmobile_9_linear_57_44" x1="855.293" y1="133.438" x2="-280.92" y2="133.438" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffmobile_10_linear_57_44" x1="855.292" y1="138.059" x2="-280.919" y2="138.059" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffmobile_11_linear_57_44" x1="855.293" y1="126.533" x2="-280.92" y2="126.533" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffmobile_12_linear_57_44" x1="855.293" y1="138.386" x2="-280.92" y2="138.386" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffmobile_13_linear_57_44" x1="855.292" y1="138.059" x2="-280.919" y2="138.059" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffmobile_14_linear_57_44" x1="855.292" y1="138.386" x2="-280.919" y2="138.386" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffmobile_15_linear_57_44" x1="401.142" y1="230.124" x2="-735.072" y2="230.124" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffmobile_16_linear_57_44" x1="401.141" y1="229.199" x2="-735.071" y2="229.199" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffmobile_17_linear_57_44" x1="398.302" y1="241.797" x2="-737.91" y2="241.797" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffmobile_18_linear_57_44" x1="398.302" y1="235.831" x2="-737.91" y2="235.831" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffmobile_19_linear_57_44" x1="398.302" y1="241.797" x2="-737.91" y2="241.797" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffmobile_20_linear_57_44" x1="398.302" y1="241.594" x2="-737.912" y2="241.594" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffmobile_21_linear_57_44" x1="398.302" y1="241.812" x2="-737.912" y2="241.812" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffmobile_22_linear_57_44" x1="398.302" y1="231.156" x2="-737.911" y2="231.156" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default PayoffMobile
