import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function CiuccioF({ className }: Props) {
  return (
    <IconBase name="ciuccio-f" w={81} h={80} className={className}>
      <path d="M15.0898 65.4101C4.67077 54.991 2.05067 41.2903 9.12668 34.2143C16.2027 27.1383 29.9034 29.7584 40.3224 40.1774C50.7415 50.5965 53.3616 64.2972 46.2856 71.3732C39.2096 78.4492 25.5088 75.8291 15.0898 65.4101ZM36.2258 44.2741C28.3743 36.4226 17.8413 33.6929 13.2233 38.311C8.60529 42.929 11.3349 53.4619 19.1864 61.3134C27.0379 69.165 37.5709 71.8946 42.1889 67.2766C46.8069 62.6585 44.0773 52.1256 36.2258 44.2741Z" fill="#D3DFF0" stroke="#164194" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M25.6142 33.049C25.6142 33.049 21.8681 37.5005 22.4464 39.402C23.0248 41.3036 28.2825 46.2677 31.2575 49.2427C34.2325 52.2177 39.1966 57.4754 41.0982 58.0538C42.9997 58.6321 47.4775 54.9079 47.4775 54.9079" fill="white" />
      <path d="M25.6142 33.049C25.6142 33.049 21.8681 37.5005 22.4464 39.402C23.0248 41.3036 28.2825 46.2677 31.2575 49.2427C34.2325 52.2177 39.1966 57.4754 41.0982 58.0538C42.9997 58.6321 47.4775 54.9079 47.4775 54.9079" stroke="#164194" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M69.4034 65.3152C72.8802 61.8384 63.5618 46.883 48.5903 31.9114C33.6187 16.9398 18.6633 7.62149 15.1865 11.0983C11.7096 14.5752 21.028 29.5305 35.9996 44.5021C50.9711 59.4737 65.9265 68.792 69.4034 65.3152Z" fill="white" stroke="#164194" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M72.1841 29.6401C76.6707 24.2641 77.766 15.751 71.2596 9.24021C64.7488 2.72941 56.2357 3.82914 50.8597 8.31573C46.6491 11.8296 44.8001 17.9242 44.4365 20.7458C43.7749 25.894 38.7888 30.8363 36.8347 32.602C36.3834 33.0095 36.2958 33.6842 36.6375 34.1881C37.4131 35.3316 38.9334 37.4084 41.0146 39.4896C43.0958 41.5708 45.1726 43.0911 46.3161 43.8666C46.82 44.2084 47.4947 44.1208 47.9022 43.6695C49.6679 41.711 54.6102 36.7293 59.754 36.0633C62.5756 35.6996 68.6702 33.8507 72.1841 29.6401Z" fill="#F7C5D7" stroke="#E23B77" strokeWidth="2" strokeMiterlimit="10"/>
    </IconBase>
  )
}

export default CiuccioF
