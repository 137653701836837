import React from 'react'
import { FormError } from 'components/atoms'
import { RadioUnmarked, RadioMarked } from 'components/atoms/Icons'
import styles from './FormRadio.module.sass'

interface OptionProps {
  id: string
  label?: string
  name?: string
  value?: any
  checked?: boolean
  className?: string
  readOnly?: boolean
  disabled?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

interface Props {
  id: string
  label?: string | React.ReactNode
  name?: string
  value?: any
  className?: string
  classNameOption?: string
  readOnly?: boolean
  disabled?: boolean
  status?: '' | 'success' | 'error'
  errorMessage?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  callback?: () => void
  options: OptionProps[]
}

const FormRadioOption = (props: OptionProps) => {
  const {
    id = '',
    label = '',
    name = '',
    value = '',
    checked = false,
    className = '',
    readOnly = false,
    disabled = false,
    onChange = () => {},
  } = props

  const classNames = `
    ${styles.option}
    ${disabled ? styles['option--disabled'] : ''}
    ${className}`

  return (
    <div className={classNames}>
      <label htmlFor={id} className={styles.inputWrap}>
        <input
          id={id}
          checked={checked}
          name={name}
          value={value}
          className={styles.input}
          type="radio"
          readOnly={readOnly}
          disabled={disabled}
          onChange={onChange}
        />
        <span className={styles.styledInput}>{checked ? <RadioMarked /> : <RadioUnmarked />}</span>
        <span className={styles.label}>{label}</span>
      </label>
    </div>
  )
}

const FormRadio = (props: Props) => {
  const {
    id = '',
    label = '',
    name = '',
    value = null,
    className = '',
    classNameOption = '',
    readOnly = false,
    disabled = false,
    status = '',
    errorMessage = '',
    onChange = () => {},
    callback = () => {},
    options = [],
  } = props

  const onOptionChange = (e: any) => {
    if (!e.target.disabled && !e.target.readOnly) {
      onChange(e)
      callback()
    }
  }

  const classNames = `
    ${styles.formRadio} 
    ${disabled ? styles['formRadio--disabled'] : ''}
    ${status ? styles[`formRadio--${status}`] : ''}
    ${className}`

  const classNameOptions = `${styles.options} ${classNameOption}`

  return (
    <div className={classNames}>
      <label htmlFor={id} className={styles.inputWrap}>
        <span className={styles.labelRadio}>{label}</span>
        <div className={classNameOptions}>
          {options?.map((option) => (
            <FormRadioOption
              id={`${id}-${option.value}`}
              name={name}
              label={option.label}
              value={option.value}
              checked={option.value === value}
              onChange={onOptionChange}
              readOnly={readOnly || option.readOnly}
              disabled={disabled || option.disabled}
              key={`formradiooption-${id}-${option.value}`}
            />
          ))}
        </div>
      </label>
      <FormError className={styles.error} message={errorMessage} visible={status === 'error'} />
    </div>
  )
}

export default FormRadio
