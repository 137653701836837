import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function ParentalPolicyIllustration({ className }: Props) {
  return (
    <IconBase name="parental-policy-illustration" w={80} className={className}>
      <g clipPath="url(#parentalpolicyillustration_clip0_2976_25686)">
      <path d="M43.4667 22.6733H22.1787C20.5219 22.6733 19.1787 24.0165 19.1787 25.6733V28.3013C19.1787 29.9582 20.5219 31.3013 22.1787 31.3013H43.4667C45.1236 31.3013 46.4667 29.9582 46.4667 28.3013V25.6733C46.4667 24.0165 45.1236 22.6733 43.4667 22.6733Z" fill="white"/>
      <path d="M43.4665 32.0013H22.1785C20.1385 32.0013 18.4785 30.3413 18.4785 28.3013V25.6733C18.4785 23.6333 20.1385 21.9733 22.1785 21.9733H43.4665C45.5065 21.9733 47.1665 23.6333 47.1665 25.6733V28.3013C47.1665 30.3413 45.5065 32.0013 43.4665 32.0013ZM22.1785 23.3733C20.9105 23.3733 19.8785 24.4053 19.8785 25.6733V28.3013C19.8785 29.5693 20.9105 30.6013 22.1785 30.6013H43.4665C44.7345 30.6013 45.7665 29.5693 45.7665 28.3013V25.6733C45.7665 24.4053 44.7345 23.3733 43.4665 23.3733H22.1785Z" fill="#164194"/>
      <path d="M24.1265 22.6733L21.0305 13.5813C20.4545 11.8893 21.7105 10.1293 23.4985 10.1293H26.6265C27.4865 10.1293 28.2945 9.70933 28.7785 8.99333C30.1305 6.99333 33.2945 3.53333 36.4625 8.86533C36.9305 9.64933 37.7825 10.1253 38.6945 10.1253H42.3265C44.0985 10.1253 45.3585 11.8573 44.8065 13.5413L41.8305 22.6653H24.1265V22.6733Z" fill="#D3DFF0"/>
      <path d="M42.3346 23.3733H23.6226L20.3626 13.8093C20.0146 12.7853 20.1746 11.6933 20.8026 10.8173C21.4306 9.94134 22.4106 9.43334 23.4946 9.43334H26.6226C27.2586 9.43334 27.8426 9.12534 28.1946 8.60534C29.2106 7.10534 30.8306 5.33734 32.8266 5.33734C32.8586 5.33734 32.8946 5.33734 32.9266 5.33734C34.4426 5.38134 35.8346 6.44934 37.0586 8.51334C37.3946 9.08134 38.0186 9.43334 38.6906 9.43334H42.3226C43.3946 9.43334 44.3706 9.92934 45.0026 10.7973C45.6306 11.6653 45.8026 12.7493 45.4706 13.7653L42.3386 23.3733H42.3346ZM24.6266 21.9733H41.3186L44.1346 13.3333C44.3266 12.7453 44.2266 12.1213 43.8666 11.6213C43.5026 11.1213 42.9386 10.8333 42.3226 10.8333H38.6906C37.5306 10.8333 36.4426 10.2173 35.8586 9.22934C34.8946 7.60534 33.8946 6.76534 32.8906 6.73734C32.8706 6.73734 32.8546 6.73734 32.8346 6.73734C31.3746 6.73734 30.0346 8.39334 29.3586 9.39334C28.7466 10.2973 27.7266 10.8373 26.6266 10.8373H23.4986C22.8746 10.8373 22.3106 11.1293 21.9466 11.6373C21.5826 12.1453 21.4906 12.7733 21.6906 13.3653L24.6266 21.9813V21.9733Z" fill="#164194"/>
      <path d="M24.1628 31.3013C24.1628 31.3013 4.6508 39.6813 7.6828 60.7293C8.4508 66.0774 12.0508 70.5774 17.1428 72.3894C20.6868 73.6493 25.7108 74.6454 32.7908 74.7294C53.0108 74.9614 55.4548 67.2294 55.4548 67.2294C55.4548 67.2294 63.6228 45.0413 43.0028 31.3013H24.1628Z" fill="#D3DFF0"/>
      <path d="M33.6786 75.4333C33.3826 75.4333 33.0866 75.4333 32.7826 75.4293C26.4786 75.3573 21.1386 74.5573 16.9066 73.0493C11.6026 71.1613 7.80256 66.4813 6.99056 60.8293C5.38656 49.6774 10.0706 42.0613 14.2786 37.6374C18.8226 32.8614 23.6826 30.7454 23.8866 30.6574L24.0186 30.6013H43.2146L43.3905 30.7173C54.2785 37.9733 57.1586 47.5814 57.6586 54.3653C58.1986 61.6973 56.1945 67.2373 56.1105 67.4693C56.0145 67.7693 53.3625 75.4333 33.6786 75.4333ZM24.3146 32.0013C23.5306 32.3653 19.2146 34.4733 15.2586 38.6413C9.47056 44.7453 7.15456 52.1414 8.37456 60.6294C9.11456 65.7613 12.5626 70.0173 17.3746 71.7294C21.4586 73.1814 26.6506 73.9573 32.7986 74.0253C52.2785 74.2533 54.7626 67.0894 54.7866 67.0173C54.8186 66.9333 56.7906 61.4533 56.2585 54.4133C55.5546 45.0893 51.0266 37.5493 42.7906 32.0013H24.3146Z" fill="#164194"/>
      <g clipPath="url(#parentalpolicyillustration_clip1_2976_25686)">
      <path d="M39.0806 57.5L39.7619 58.1701C40.0989 58.4995 40.0806 59.0461 39.7363 59.368C39.1612 59.9071 38.4945 60.3413 37.7363 60.6633C36.6996 61.1088 35.5568 61.3296 34.3114 61.3296C33.0659 61.3296 31.8791 61.0938 30.7985 60.6184C29.7179 60.1429 28.8059 59.4766 28.0586 58.6193C27.3114 57.762 26.7875 56.7662 26.4835 55.6432H24.3333C24.1502 55.6469 24 55.4934 24 55.3062V54.4302C24 54.2431 24.1502 54.0896 24.3333 54.0896H26.1978C26.1831 53.9249 26.1758 53.674 26.1758 53.3334C26.1758 52.9927 26.1831 52.7419 26.1978 52.5772H24.3333C24.1502 52.5772 24 52.4274 24 52.2365V51.3605C24 51.1733 24.1502 51.0199 24.3333 51.0199H26.4799C26.7839 49.893 27.3114 48.901 28.0549 48.0437C28.8022 47.1827 29.7143 46.5163 30.7949 46.0447C31.8755 45.5692 33.044 45.3334 34.3077 45.3334C35.5714 45.3334 36.696 45.5542 37.7326 45.9997C38.4908 46.3254 39.1538 46.7559 39.7289 47.2913C40.0733 47.6132 40.0916 48.1635 39.7509 48.4929L39.0733 49.1518C38.7729 49.4438 38.3114 49.4438 37.9927 49.1668C36.9963 48.2983 35.8168 47.864 34.4542 47.864C33.293 47.864 32.2747 48.1448 31.3993 48.7101C30.5201 49.2716 29.8718 50.0428 29.4542 51.0199H35.3663C35.5494 51.0199 35.6996 51.1733 35.6996 51.3605V52.2365C35.6996 52.4237 35.5494 52.5772 35.3663 52.5772H29.044C29.0147 52.9029 29 53.1537 29 53.3334C29 53.5131 29.0147 53.7639 29.044 54.0896H35.3663C35.5494 54.0896 35.6996 54.2431 35.6996 54.4302V55.3062C35.6996 55.4934 35.5494 55.6469 35.3663 55.6469H29.4579C29.8791 56.624 30.5275 57.3951 31.4029 57.9567C32.2784 58.5182 33.2967 58.8027 34.4579 58.8027C35.8168 58.8027 36.9963 58.3647 37.9927 57.4887C38.3114 57.208 38.7766 57.208 39.0806 57.5037V57.5Z" fill="#164194"/>
      </g>
      <path d="M70.9069 50.7453H51.8349C50.2266 50.7453 48.9229 52.0491 48.9229 53.6573C48.9229 55.2656 50.2266 56.5693 51.8349 56.5693H70.9069C72.5151 56.5693 73.8189 55.2656 73.8189 53.6573C73.8189 52.0491 72.5151 50.7453 70.9069 50.7453Z" fill="#F7C5D7"/>
      <path d="M70.9027 57.2693H51.8347C49.8427 57.2693 48.2227 55.6493 48.2227 53.6573C48.2227 51.6653 49.8427 50.0453 51.8347 50.0453H70.9027C72.8946 50.0453 74.5146 51.6653 74.5146 53.6573C74.5146 55.6493 72.8946 57.2693 70.9027 57.2693ZM51.8347 51.4453C50.6147 51.4453 49.6227 52.4373 49.6227 53.6573C49.6227 54.8773 50.6147 55.8693 51.8347 55.8693H70.9027C72.1227 55.8693 73.1146 54.8773 73.1146 53.6573C73.1146 52.4373 72.1227 51.4453 70.9027 51.4453H51.8347Z" fill="#E23B77"/>
      <path d="M70.9069 57.0053H51.8349C50.2266 57.0053 48.9229 58.3091 48.9229 59.9173C48.9229 61.5256 50.2266 62.8293 51.8349 62.8293H70.9069C72.5151 62.8293 73.8189 61.5256 73.8189 59.9173C73.8189 58.3091 72.5151 57.0053 70.9069 57.0053Z" fill="#F7C5D7"/>
      <path d="M70.9027 63.5294H51.8347C49.8427 63.5294 48.2227 61.9094 48.2227 59.9174C48.2227 57.9254 49.8427 56.3054 51.8347 56.3054H70.9027C72.8946 56.3054 74.5146 57.9254 74.5146 59.9174C74.5146 61.9094 72.8946 63.5294 70.9027 63.5294ZM51.8347 57.7054C50.6147 57.7054 49.6227 58.6974 49.6227 59.9174C49.6227 61.1374 50.6147 62.1294 51.8347 62.1294H70.9027C72.1227 62.1294 73.1146 61.1374 73.1146 59.9174C73.1146 58.6974 72.1227 57.7054 70.9027 57.7054H51.8347Z" fill="#E23B77"/>
      <path d="M70.9069 63.2693H51.8349C50.2266 63.2693 48.9229 64.5731 48.9229 66.1813C48.9229 67.7896 50.2266 69.0933 51.8349 69.0933H70.9069C72.5151 69.0933 73.8189 67.7896 73.8189 66.1813C73.8189 64.5731 72.5151 63.2693 70.9069 63.2693Z" fill="#F7C5D7"/>
      <path d="M70.9027 69.7933H51.8347C49.8427 69.7933 48.2227 68.1733 48.2227 66.1813C48.2227 64.1893 49.8427 62.5693 51.8347 62.5693H70.9027C72.8946 62.5693 74.5146 64.1893 74.5146 66.1813C74.5146 68.1733 72.8946 69.7933 70.9027 69.7933ZM51.8347 63.9693C50.6147 63.9693 49.6227 64.9613 49.6227 66.1813C49.6227 67.4013 50.6147 68.3933 51.8347 68.3933H70.9027C72.1227 68.3933 73.1146 67.4013 73.1146 66.1813C73.1146 64.9613 72.1227 63.9693 70.9027 63.9693H51.8347Z" fill="#E23B77"/>
      <path d="M70.8663 69.4094H51.7943C50.1861 69.4094 48.8823 70.7131 48.8823 72.3214C48.8823 73.9296 50.1861 75.2334 51.7943 75.2334H70.8663C72.4746 75.2334 73.7783 73.9296 73.7783 72.3214C73.7783 70.7131 72.4746 69.4094 70.8663 69.4094Z" fill="#F7C5D7"/>
      <path d="M70.8665 75.9333H51.7985C49.8065 75.9333 48.1865 74.3134 48.1865 72.3214C48.1865 70.3294 49.8065 68.7094 51.7985 68.7094H70.8665C72.8585 68.7094 74.4785 70.3294 74.4785 72.3214C74.4785 74.3134 72.8585 75.9333 70.8665 75.9333ZM51.7985 70.1094C50.5785 70.1094 49.5865 71.1014 49.5865 72.3214C49.5865 73.5414 50.5785 74.5333 51.7985 74.5333H70.8665C72.0865 74.5333 73.0785 73.5414 73.0785 72.3214C73.0785 71.1014 72.0865 70.1094 70.8665 70.1094H51.7985Z" fill="#E23B77"/>
      </g>
      <defs>
      <clipPath id="parentalpolicyillustration_clip0_2976_25686">
      <rect width="67.848" height="70.6" fill="white" transform="translate(6.6665 5.33334)"/>
      </clipPath>
      <clipPath id="parentalpolicyillustration_clip1_2976_25686">
      <rect width="16" height="16" fill="white" transform="translate(24 45.3334)"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default ParentalPolicyIllustration
