import React from 'react'
import { Alert } from 'components/atoms/Icons'
import styles from './FormError.module.sass'

interface Props {
  message?: string | string[]
  visible?: boolean
  className?: string
}

const FormError = (props: Props) => {
  const { message = '', visible = false, className = '' } = props

  return visible && message ? (
    <div className={`${styles.formError} ${className}`}>
      <Alert className={styles.icon} />
      {message as string}
    </div>
  ) : (
    <></>
  )
}

export default FormError
