import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Domanda({ className }: Props) {
  return (
    <IconBase name="domanda" w={80} className={className}>
      <path d="M40.0001 77.3322C60.6181 77.3322 77.3323 60.618 77.3323 40C77.3323 19.382 60.6181 2.66785 40.0001 2.66785C19.3821 2.66785 2.66797 19.382 2.66797 40C2.66797 60.618 19.3821 77.3322 40.0001 77.3322Z" fill="#D3DFF0" stroke="#164194" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M39.9999 72.6796C58.0483 72.6796 72.6794 58.0484 72.6794 40C72.6794 21.9516 58.0483 7.32043 39.9999 7.32043C21.9514 7.32043 7.32031 21.9516 7.32031 40C7.32031 58.0484 21.9514 72.6796 39.9999 72.6796Z" fill="white" stroke="#164194" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
      <path d="M30.023 18.1172C32.4807 16.5322 35.5037 15.7441 39.0878 15.7441C43.7938 15.7441 47.7074 16.8706 50.8239 19.119C53.9361 21.3673 55.4944 24.7021 55.4944 29.1187C55.4944 31.8257 54.8176 34.1097 53.4686 35.9618C52.6805 37.0883 51.1623 38.5263 48.9184 40.2761L46.7056 41.9946C45.4991 42.9341 44.7021 44.0249 44.3058 45.276C44.2747 45.3784 44.2435 45.4852 44.2168 45.601C43.7404 47.5511 42.0575 48.9624 40.0539 48.9624C37.4538 48.9624 35.4414 46.6472 35.8377 44.0783C35.9935 43.0632 36.1938 42.2573 36.4298 41.6652C36.9685 40.3206 38.3621 38.7757 40.606 37.0259L42.8811 35.245C43.6291 34.684 44.2302 34.0696 44.6887 33.4018C45.5169 32.2576 45.9354 30.9976 45.9354 29.6218C45.9354 28.0368 45.4723 26.5943 44.5463 25.2942C43.6202 23.9942 41.9328 23.3397 39.4796 23.3397C37.0665 23.3397 35.3568 24.1411 34.3506 25.7439C33.9009 26.4563 33.5536 27.182 33.3043 27.9211C32.7255 29.6396 31.0515 30.7438 29.2394 30.7438H28.7853C25.9358 30.7438 23.8433 27.9834 24.6981 25.2631C25.6821 22.1331 27.4585 19.7512 30.023 18.1172ZM39.4707 53.2455H40.2944C42.6496 53.2455 44.5552 55.1511 44.5552 57.5063V58.0183C44.5552 60.3736 42.6496 62.2791 40.2944 62.2791H39.4707C37.1155 62.2791 35.2099 60.3736 35.2099 58.0183V57.5063C35.2099 55.1511 37.1199 53.2455 39.4707 53.2455Z" fill="#F7C5D7" stroke="#E23B77" strokeWidth="2" strokeMiterlimit="10"/>
    </IconBase>
  )
}

export default Domanda
