/* eslint-disable import/no-extraneous-dependencies */
import { BRAND } from 'utils/settings'
import axios from 'axios'
import {
  INFORMAZIONI_PRODOTTI,
  RECLAMO,
} from '@components/organisms/FormContattaci/FormContattaci.interfaces'

export const API_HOST = process.env.API_HOST
export const API_PREFIX = '/d/api/'
export const API_FULL_PATH = `${API_HOST}${API_PREFIX}`

export const GRAPHENE_HOST = API_HOST
export const GRAPHENE_PREFIX = '/d/graphql/'
export const GRAPHENE_FULL_PATH = `${GRAPHENE_HOST}${GRAPHENE_PREFIX}`

export const api = axios.create({
  baseURL: API_FULL_PATH,
  withCredentials: true,
})

export const graphene = axios.create({
  baseURL: GRAPHENE_FULL_PATH,
  withCredentials: true,
})

export const isEmpty = (obj: any) => Object.keys(obj).length === 0 && obj.constructor === Object

export const toggle = (array: any, value: any) => {
  const index = array.indexOf(value)

  if (index === -1) {
    array.push(value)
  } else {
    array.splice(index, 1)
  }
}

export const toAltTitle = (term: string) =>
  term ? `${term} | ${BRAND}`.replace(/  +/g, ' ') : BRAND

export function isReclamoOrInfoProdotti(purposeArea: string) {
  return purposeArea === RECLAMO || purposeArea === INFORMAZIONI_PRODOTTI
}
