import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function PayoffDesktop({ className }: Props) {
  return (
    <IconBase name="payoff-desktop" w={984} h={70} className={className}>
      <path d="M1.25998 18.37C1.25998 12.08 1.16998 8.22998 0.97998 6.53998C1.91998 6.25998 4.07996 5.78998 5.29996 5.78998C6.32996 5.78998 6.42996 6.26001 6.42996 10.39V53.11C6.42996 59.59 6.61996 62.69 6.70996 64.56C5.39996 65.03 3.41998 65.31 2.38998 65.31C1.63998 65.31 1.25998 65.03 1.25998 63.62V18.37Z" fill="url(#payoffdesktop_0_linear_56_3)"/>
      <path d="M46.3199 40.15C46.3199 31.23 42.5599 27.19 36.1799 27.19C30.2699 27.19 25.4799 31.51 23.1299 34.8V62.78C23.1299 64.75 22.6599 64.94 21.4399 65.13C20.3099 65.22 18.9999 65.32 18.0599 65.32C18.2499 63.44 18.3399 58 18.3399 47.76V31.52C18.3399 27.58 17.7799 25.79 16.9299 24.95V24.76C17.4899 24.1 19.5599 22.88 20.3099 22.88C22.6599 22.88 22.9399 28.14 22.9399 30.58C26.5099 26.54 32.1399 22.88 37.6799 22.88C47.3499 22.88 51.1099 29.36 51.1099 39.78V54.24C51.1099 60.15 51.2999 63.06 51.4899 64.57C49.6099 64.95 48.1999 65.32 47.0799 65.32C46.4199 65.32 46.14 64.95 46.14 64.29L46.3199 40.15Z" fill="url(#payoffdesktop_1_linear_56_3)"/>
      <path d="M80.31 26.07C80.4 27.01 79.37 28.7 78.15 28.79C76.09 27.57 73.17 26.72 70.92 26.72C66.6 26.72 63.13 29.35 63.13 33.48C63.13 36.86 65.29 38.93 67.64 40.15C70.74 41.84 73.46 42.97 76.75 44.84C80.51 47.09 82.38 50.28 82.38 53.67C82.38 62.31 74.96 65.87 68.39 65.87C63.23 65.87 59.75 64.18 58.16 62.77C57.5 62.3 57.22 61.55 57.22 61.08C57.22 60.05 57.88 58.83 58.63 58.45C61.45 60.52 65.11 61.92 68.77 61.92C74.59 61.92 77.5 58.82 77.5 54.78C77.5 51.87 75.81 49.71 72.06 47.83C69.43 46.42 67.18 45.48 65.11 44.36C61.07 42.2 58.44 39.1 58.44 34.31C58.44 27.46 64.54 22.86 71.4 22.86C74.77 22.88 78.71 23.91 80.31 26.07Z" fill="url(#payoffdesktop_2_linear_56_3)"/>
      <path d="M94.3 7.57001C94.3 9.26001 93.17 10.95 91.2 10.95C88.95 10.95 88.01 9.44997 88.01 7.65997C88.01 5.49997 89.7 4.28003 91.3 4.28003C93.17 4.29003 94.3 5.79001 94.3 7.57001ZM93.64 62.5C93.64 64.19 93.26 64.66 92.42 64.85C91.67 65.04 90.35 65.23 88.57 65.32C88.66 61.56 88.85 56.12 88.85 50.67V34.9C88.85 29.17 88.66 26.36 88.47 24.2C89.69 23.73 91.76 23.45 92.7 23.45C93.26 23.45 93.64 23.92 93.64 29.18V62.5V62.5Z" fill="url(#payoffdesktop_3_linear_56_3)"/>
      <path d="M132.42 59.21C132.23 60.99 131.48 62.5 130.07 63.44C128.38 64.57 124.63 65.88 120.4 65.88C107.82 65.88 101.15 57.81 101.15 45.22C101.15 33.01 108 22.88 120.02 22.88C129.78 22.88 134.29 30.3 134.57 37.71C134.57 38.37 134.57 39.68 134.38 40.53C134.1 42.22 128.84 43.25 126.12 43.44C123.4 43.53 114.57 43.72 106.22 43.44C106.03 54.24 111.66 61.56 121.62 61.56C125.66 61.56 130.35 60.06 132.23 59.03L132.42 59.21ZM106.69 39.77C115.98 39.96 125.56 39.77 126.88 39.77C128.38 39.68 129.41 39.02 129.41 37.8C129.6 31.23 125.84 26.25 118.99 26.25C113.17 26.25 107.82 31.23 106.69 39.77Z" fill="url(#payoffdesktop_4_linear_56_3)"/>
      <path d="M145.65 63.25C145.65 64.75 144.99 65.03 144.15 65.13C142.84 65.32 141.33 65.32 140.58 65.32C140.77 63.44 140.86 58 140.86 47.76V31.52C140.86 27.67 140.3 25.51 139.45 24.95V24.67C140.01 24.11 142.17 22.89 142.92 22.89C144.99 22.89 145.45 27.4 145.45 30.59C149.21 26.65 154.37 22.89 159.91 22.89C165.82 22.89 169.3 25.61 171.36 30.68C175.21 26.55 180.28 22.89 186.19 22.89C195.11 22.89 199.24 29.09 199.24 39.51V54.34C199.24 60.16 199.43 63.07 199.62 64.57C197.84 64.95 196.33 65.32 195.21 65.32C194.74 65.32 194.46 64.95 194.46 64.1V40.35C194.46 30.96 190.8 27.3 184.7 27.3C178.88 27.3 175.03 31.15 172.4 33.97L172.49 62.61C172.49 64.58 172.3 64.86 170.89 65.14C169.29 65.33 168.07 65.33 167.42 65.33C167.61 63.45 167.7 58.01 167.7 47.77V40.26C167.7 31.25 164.32 27.3 158.12 27.3C152.86 27.3 148.17 31.52 145.63 34.53V63.25H145.65Z" fill="url(#payoffdesktop_5_linear_56_3)"/>
      <path d="M238.13 59.21C237.94 60.99 237.19 62.5 235.78 63.44C234.09 64.57 230.34 65.88 226.11 65.88C213.53 65.88 206.86 57.81 206.86 45.22C206.86 33.01 213.71 22.88 225.73 22.88C235.49 22.88 240 30.3 240.28 37.71C240.28 38.37 240.28 39.68 240.09 40.53C239.81 42.22 234.55 43.25 231.83 43.44C229.11 43.53 220.28 43.72 211.93 43.44C211.74 54.24 217.37 61.56 227.33 61.56C231.37 61.56 236.06 60.06 237.94 59.03L238.13 59.21ZM212.41 39.77C221.71 39.96 231.28 39.77 232.6 39.77C234.1 39.68 235.13 39.02 235.13 37.8C235.32 31.23 231.56 26.25 224.71 26.25C218.89 26.25 213.54 31.23 212.41 39.77Z" fill="url(#payoffdesktop_6_linear_56_3)"/>
      <path d="M299.15 39.21C299.15 34.61 296.43 31.7 292.86 31.7C289.76 31.7 288.07 32.83 286.01 34.14V62.3C286.01 63.9 285.82 64.37 283.76 64.65C280 65.21 276.72 65.31 272.68 65.31C272.87 59.58 272.96 53.76 272.96 48.32V33.11C272.96 30.58 272.68 29.26 271.18 28.32V27.66C272.12 24.84 275.69 22.12 279.07 22.12C283.01 22.12 284.7 25.03 285.36 28.03C289.4 24.74 293.53 22.12 298.03 22.12C310.52 22.12 312.21 32.82 312.21 39.4V50.57C312.21 55.17 312.3 60.9 312.59 64.56C310.34 64.75 306.02 65.31 301.32 65.31C299.82 65.31 299.16 64.75 299.16 63.34V39.21H299.15Z" fill="url(#payoffdesktop_7_linear_56_3)"/>
      <path d="M323.46 34.33C323.46 28.51 323.27 25.69 323.08 23.91C325.33 23.35 331.72 22.69 334.63 22.69C336.13 22.69 336.51 23.16 336.51 28.6C336.51 34.8 336.51 41.84 336.51 48.6C336.51 54.61 340.45 56.02 343.46 56.02C345.9 56.02 347.78 55.18 349.28 54.05V27.2C349.28 24.29 349.56 23.73 351.44 23.35C356.32 22.88 358.86 22.69 362.61 22.69C362.61 24.66 362.33 31.42 362.33 39.68V53.58C362.33 57.05 363.55 57.99 365.99 58.46V59.02C365.61 62.4 362.33 65.87 359.7 65.87C354.07 65.87 352.1 63.52 350.78 60.05C348.53 62.12 345.9 63.62 343.93 64.46C342.15 65.3 340.27 65.87 338.02 65.87C327.69 65.87 323.47 59.02 323.47 49.82V34.33H323.46Z" fill="url(#payoffdesktop_8_linear_56_3)"/>
      <path d="M400.83 55.45C401.21 57.89 400.83 61.18 399.14 62.68C396.79 64.74 392.66 65.87 388.16 65.87C378.02 65.87 375.02 59.39 375.02 53.1V31.51C371.36 31.51 369.29 29.54 369.29 28.6C370.89 25.5 379.24 17.99 381.03 16.68C382.81 15.27 384.79 13.96 389.01 13.58C388.73 16.4 388.45 20.34 388.45 23.25H401.78C400.56 30.67 398.49 30.86 396.52 31.04C394.92 31.23 390.7 31.51 388.16 31.51C387.78 38.27 387.88 47 388.25 50.85C388.72 54.98 390.97 56.76 394.16 56.76C396.98 56.76 399.6 55.73 400.54 55.26L400.83 55.45Z" fill="url(#payoffdesktop_9_linear_56_3)"/>
      <path d="M405.99 27.57C406.83 25.13 410.78 22.13 413.78 22.13C418.57 22.13 420.26 26.26 420.35 31.99C422.98 25.51 426.92 22.13 432.09 22.13C434.34 22.13 436.5 23.26 437.44 24.38C437.53 26.63 436.5 31.05 434.62 33.58C433.78 34.61 432.65 35.27 430.96 34.8C429.46 34.24 428.52 34.14 427.11 34.14C425.8 34.14 423.26 34.98 421.95 38.55C421.39 40.05 421.2 40.9 421.2 42.96V62.11C421.2 63.61 421.01 64.46 418.95 64.74C415.19 65.21 412 65.3 407.87 65.3C408.06 59.57 408.15 53.75 408.15 48.31V32.35C408.15 30.57 407.59 28.88 405.99 28.03V27.57Z" fill="url(#payoffdesktop_10_linear_56_3)"/>
      <path d="M456.41 62.5C456.41 64.19 455.66 64.66 453.22 64.94C451.15 65.22 447.21 65.31 443.08 65.31C443.17 61.46 443.36 56.02 443.36 50.48V34.43C443.36 28.61 443.17 25.7 442.98 23.91C445.33 23.25 452.27 22.69 454.43 22.69C456.03 22.69 456.4 23.16 456.4 28.6V62.5H456.41ZM456.59 8.97998C456.59 13.02 453.68 16.12 449.74 16.12C446.64 16.12 443.07 14.34 443.07 9.35999C443.07 4.09999 447.48 2.21997 450.11 2.21997C454.15 2.21997 456.59 5.03998 456.59 8.97998Z" fill="url(#payoffdesktop_11_linear_56_3)"/>
      <path d="M504.29 56.86C504.29 62.49 501.57 65.87 495.46 65.87C491.61 65.87 488.89 64.18 487.29 60.89C484.94 63.43 481 65.87 476.77 65.87C470.67 65.87 464.66 61.18 464.66 53.67C464.66 44.28 474.99 41.65 482.4 40.06C484.09 39.68 486.16 39.31 487.19 39.12V37.99C487.19 34.33 484.56 31.61 480.24 31.61C477.05 31.61 473.76 33.11 471.98 33.96C467.38 35.27 466.07 31.24 466.16 28.23C470.57 25.13 477.43 22.13 483.81 22.13C491.79 22.13 499.68 27.2 499.68 38.09C499.68 41.94 499.3 48.14 499.3 54.43C499.3 55.37 500.43 56.59 502.68 56.59C503.15 56.59 503.81 56.59 504.18 56.5L504.29 56.86ZM482.13 56.77C484.01 56.77 484.95 56.39 486.17 55.36C486.73 54.7 487.02 53.76 487.02 52.07V45.69C478.66 46.53 476.88 49.73 476.88 51.79C476.87 54.98 479.22 56.77 482.13 56.77Z" fill="url(#payoffdesktop_12_linear_56_3)"/>
      <path d="M525.32 62.03C525.32 63.72 525.13 64.47 523.07 64.75C519.31 65.22 516.12 65.31 511.99 65.31C512.18 59.58 512.27 53.76 512.27 48.32V32.73C512.27 30.48 511.89 29.16 510.49 28.22V27.56C511.52 24.74 515.09 22.12 518.19 22.12C522.7 22.12 523.92 25.22 524.67 28.03C528.43 25.03 533.4 22.12 538.1 22.12C544.3 22.12 547.49 25.03 549.46 28.79C553.12 25.5 558.66 22.12 563.64 22.12C574.72 22.12 577.25 31.98 577.25 38.27V50.57C577.25 55.17 577.34 60.9 577.53 64.56C575.28 64.75 570.96 65.31 566.26 65.31C564.85 65.31 564.19 64.75 564.19 63.34V39.12C564.19 34.99 562.03 31.7 557.8 31.7C555.27 31.7 553.11 32.92 551.32 34.52V62.31C551.32 63.91 551.13 64.47 548.97 64.75C545.21 65.22 542.02 65.31 537.99 65.31C538.18 59.58 538.27 53.76 538.27 48.32V39.12C538.27 34.8 536.02 31.7 532.07 31.7C529.54 31.7 527.28 32.73 525.31 34.42V62.03H525.32Z" fill="url(#payoffdesktop_13_linear_56_3)"/>
      <path d="M628.5 43.34C628.5 56.86 619.67 65.87 606.44 65.87C595.08 65.87 585.5 58.83 585.5 44.28C585.5 31.32 594.79 22.12 607.47 22.12C621.27 22.12 628.5 31.89 628.5 43.34ZM599.02 42.97C599.02 53.39 602.96 58.09 607.56 58.09C613.38 58.09 614.98 51.8 614.98 44.57C614.98 36.96 612.63 29.92 606.53 29.92C600.99 29.92 599.02 35.93 599.02 42.97Z" fill="url(#payoffdesktop_14_linear_56_3)"/>
      <path d="M677.65 27.1C675.77 40.71 673.99 54.42 673.05 60.81C672.77 62.59 672.3 63.35 671.55 63.81C670.61 64.47 666.01 65.41 661.69 65.5C662.63 59.49 664.32 49.45 665.54 40.71C666.85 30.76 667.42 25.88 667.32 24.28C668.45 23.72 675.77 22.31 676.9 22.31C678.03 22.31 678.21 23.06 677.65 27.1ZM679.99 7.84998C680.18 12.36 676.7 14.7 674.08 14.7C671.92 14.7 669.1 13.67 668.82 9.34998C668.63 4.08998 672.67 2.5 674.64 2.5C677.37 2.5 679.71 3.62998 679.99 7.84998Z" fill="url(#payoffdesktop_15_linear_56_3)"/>
      <path d="M700.65 60.62C700.37 62.31 699.9 63.25 698.87 63.81C697.18 64.66 693.14 65.5 689.29 65.5C690.79 56.49 695.58 24.38 696.52 15.93C697.74 6.34999 698.12 3.63 698.21 2.13C699.71 1.66 705.53 0.909973 707.5 0.909973C708.63 0.909973 708.91 1.65998 708.53 4.28998C707.13 16.12 702.34 49.26 700.65 60.62Z" fill="url(#payoffdesktop_16_linear_56_3)"/>
      <path d="M791.94 54.23C787.53 61.65 781.33 65.87 774.29 65.87C765.75 65.87 765.37 57.42 766.5 50.47C767.16 46.15 768.1 41.46 768.75 36.95C769.88 30 770.35 26.15 770.53 24.37C771.56 23.81 778.98 22.3 780.29 22.3C781.14 22.3 781.42 23.33 781.04 25.68C780.01 31.41 778.22 42.11 776.81 50.75C775.96 55.35 777 57.23 779.53 57.23C783.94 57.23 792.67 49.44 796.05 27.66C796.33 25.22 796.8 24.37 798.02 23.81C799.9 22.96 805.44 22.12 807.22 22.31C805.25 29.63 803.09 45.88 802.24 53.1C801.96 55.63 801.49 61.08 801.58 64.27C798.86 65.49 793.51 65.87 792 65.87C791.34 65.87 791.34 61.74 792.19 54.32L791.94 54.23Z" fill="url(#payoffdesktop_17_linear_56_3)"/>
      <path d="M839.82 61.27C836.91 64.18 832.97 65.87 827.99 65.87C822.45 65.87 819.16 61.93 820.76 51.6C821.7 44.93 823.01 37.42 824.05 31.23H817.85C817.85 28.88 819.45 24.28 821.51 24.28H825.17C826.58 16.96 828.17 14.61 829.77 13.67C831.08 12.92 834.93 11.98 837.47 11.98C836.81 15.45 835.97 19.96 835.12 24.28C841.22 24.28 844.23 24.28 846.11 24C845.64 26.72 844.7 29.26 842.92 30.39C841.42 31.23 838.51 31.23 834 31.23C832.87 37.99 831.28 48.69 830.71 52.17C830.15 56.02 831.09 57.71 832.96 57.71C835.4 57.71 838.69 55.93 841.69 53.3C842.45 55.27 841.61 59.59 839.82 61.27Z" fill="url(#payoffdesktop_18_linear_56_3)"/>
      <path d="M880.48 54.23C876.07 61.65 869.87 65.87 862.83 65.87C854.29 65.87 853.91 57.42 855.04 50.47C855.7 46.15 856.64 41.46 857.29 36.95C858.42 30 858.89 26.15 859.07 24.37C860.1 23.81 867.52 22.3 868.83 22.3C869.68 22.3 869.96 23.33 869.58 25.68C868.55 31.41 866.76 42.11 865.35 50.75C864.5 55.35 865.54 57.23 868.07 57.23C872.48 57.23 881.21 49.44 884.59 27.66C884.87 25.22 885.34 24.37 886.56 23.81C888.44 22.96 893.98 22.12 895.76 22.31C893.79 29.63 891.63 45.88 890.78 53.1C890.5 55.63 890.03 61.08 890.12 64.27C887.4 65.49 882.05 65.87 880.54 65.87C879.88 65.87 879.88 61.74 880.73 54.32L880.48 54.23Z" fill="url(#payoffdesktop_19_linear_56_3)"/>
      <path d="M920.38 36.4C925.36 25.51 931.18 22.32 935.21 22.32C936.9 22.32 938.21 23.07 938.97 23.92C939.35 26.64 937.19 31.9 935.31 33.87C934.28 33.4 933.06 32.84 931.46 32.84C928.46 32.84 921.32 38.1 917.28 60.72C916.9 62.69 916.34 63.44 915.4 64.01C913.9 64.76 908.55 65.42 905.92 65.51C907.42 57.9 909.68 43.07 910.71 33.96C910.99 31.24 911.18 26.45 910.9 24.38C912.5 23.63 918.6 22.31 920.01 22.31C920.76 22.31 921.51 27.19 920.1 36.39H920.38V36.4Z" fill="url(#payoffdesktop_20_linear_56_3)"/>
      <path d="M983.66 38.84C983.66 54.05 974.08 65.88 959.72 65.88C949.67 65.88 944.23 60.72 944.23 49.64C944.23 35.37 952.87 22.32 968.08 22.32C979.06 22.31 983.66 29.45 983.66 38.84ZM955.49 50.57C955.49 55.83 957.37 59.11 961.12 59.11C967.41 59.11 972.57 49.72 972.57 37.14C972.57 33.38 971.26 28.97 966.94 28.97C960.19 28.98 955.49 39.49 955.49 50.57Z" fill="url(#payoffdesktop_21_linear_56_3)"/>
      <path d="M735.33 60.99C734.83 64.32 734.27 67.04 733.61 69.25H743.73C744.3 67.22 744.79 64.95 745.18 62.4C746.59 52.64 747.62 44.47 749.31 31.23C753.82 31.23 755.98 31.14 757.2 30.29C759.08 29.07 759.74 26.72 760.3 24C758.14 24.38 753.92 24.28 750.25 24.28C752.03 10.29 754.76 7.65997 758.7 7.65997C759.83 7.65997 761.14 7.93997 761.89 8.21997C764.05 7.84997 764.99 4.36999 764.71 2.48999C763.68 1.54999 761.05 0.52002 758.04 0.52002C750.15 0.52002 742.74 4.37002 740.01 24.27L735.88 24.36C734.19 24.55 732.59 28.77 732.5 31.21H739.26C738.42 37.99 736.92 49.82 735.33 60.99Z" fill="url(#payoffdesktop_22_linear_56_3)"/>
      <defs>
      <linearGradient id="payoffdesktop_0_linear_56_3" x1="1008.05" y1="35.5495" x2="26.9548" y2="35.5495" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffdesktop_1_linear_56_3" x1="1008.06" y1="44.0935" x2="26.9549" y2="44.0935" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffdesktop_2_linear_56_3" x1="1008.06" y1="44.3754" x2="26.9549" y2="44.3754" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffdesktop_3_linear_56_3" x1="1008.05" y1="34.7984" x2="26.9551" y2="34.7984" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffdesktop_4_linear_56_3" x1="1008.06" y1="44.3754" x2="26.9548" y2="44.3754" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffdesktop_5_linear_56_3" x1="1008.06" y1="44.0935" x2="26.9549" y2="44.0935" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffdesktop_6_linear_56_3" x1="1008.06" y1="44.3754" x2="26.9546" y2="44.3754" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffdesktop_7_linear_56_3" x1="1008.06" y1="43.718" x2="26.9546" y2="43.718" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffdesktop_8_linear_56_3" x1="1008.06" y1="44.2812" x2="26.9551" y2="44.2812" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffdesktop_9_linear_56_3" x1="1008.06" y1="39.7276" x2="26.9543" y2="39.7276" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffdesktop_10_linear_56_3" x1="1008.06" y1="43.718" x2="26.9547" y2="43.718" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffdesktop_11_linear_56_3" x1="1008.06" y1="33.7654" x2="26.9534" y2="33.7654" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffdesktop_12_linear_56_3" x1="1008.06" y1="43.9999" x2="26.9547" y2="43.9999" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffdesktop_13_linear_56_3" x1="1008.06" y1="43.718" x2="26.9547" y2="43.718" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffdesktop_14_linear_56_3" x1="1008.06" y1="43.9999" x2="26.9549" y2="43.9999" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffdesktop_15_linear_56_3" x1="1008.06" y1="34.0008" x2="26.9532" y2="34.0008" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffdesktop_16_linear_56_3" x1="1008.06" y1="33.2026" x2="26.9554" y2="33.2026" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffdesktop_17_linear_56_3" x1="1005.61" y1="44.0803" x2="24.5036" y2="44.0803" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffdesktop_18_linear_56_3" x1="1005.61" y1="38.9294" x2="24.5026" y2="38.9294" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffdesktop_19_linear_56_3" x1="1005.61" y1="44.0803" x2="24.5033" y2="44.0803" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffdesktop_20_linear_56_3" x1="1005.61" y1="43.9057" x2="24.5049" y2="43.9057" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffdesktop_21_linear_56_3" x1="1005.61" y1="44.0935" x2="24.5016" y2="44.0935" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      <linearGradient id="payoffdesktop_22_linear_56_3" x1="1005.61" y1="34.8923" x2="24.5037" y2="34.8923" gradientUnits="userSpaceOnUse">
      <stop stopColor="#45A9ED"/>
      <stop offset="0.4913" stopColor="#0065C9"/>
      <stop offset="1" stopColor="#004494"/>
      </linearGradient>
      </defs>
    </IconBase>
  )
}

export default PayoffDesktop
