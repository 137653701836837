import React from 'react'
import Select from 'react-select'
import { ChevronDown } from 'components/atoms/Icons'
import { FormError } from 'components/atoms'
import styles from './FormSelect.module.sass'

interface Option {
  label: string
  value: any
  selected?: boolean
}
export interface Props {
  placeholder?: string
  name?: string
  value?: any
  className?: string
  disabled?: boolean
  status?: '' | 'success' | 'error'
  errorMessage?: string
  onBlur?: (e: React.FocusEvent<HTMLElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLElement>) => void
  onChange?: (e: Option) => void
  isSearchable?: boolean
  options: Option[]
}

const FormSelectDropdownIndicator = () => (
  <div className="react-select__dropdown-indicator">
    <ChevronDown />
  </div>
)

const FormSelect = (props: Props) => {
  const {
    placeholder = '',
    name = '',
    value = null,
    className = '',
    disabled = false,
    status = '',
    errorMessage = '',
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
    options = [],
    isSearchable = false,
  } = props

  const classNames = ` 
    ${styles.formSelect}
    ${status ? styles[`formSelect--${status}`] : ''}
    ${className}`

  const reactSelectClassNames = `${styles.select} 
    react-select
    ${status ? `react-select--${status}` : ''}
  `
  return (
    <div className={classNames}>
      <Select
        className={reactSelectClassNames}
        classNamePrefix="react-select"
        closeMenuOnSelect
        isClearable={false}
        isSearchable={isSearchable}
        isDisabled={disabled || false}
        instanceId={name}
        name={name}
        placeholder={placeholder}
        components={{ DropdownIndicator: FormSelectDropdownIndicator }}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        options={options}
        value={
          options?.find((option) => option.selected) ||
          options?.find((option) => option.value === value)
        }
      />
      <FormError className={styles.error} message={errorMessage} visible={status === 'error'} />
    </div>
  )
}

export default FormSelect
