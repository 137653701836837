import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function Feedback({ className }: Props) {
  return (
    <IconBase name="feedback" w={120} className={className}>
      <path d="M60.3048 115.123C87.5993 115.123 109.726 93.0188 109.726 65.7524C109.726 38.486 87.5993 16.3822 60.3048 16.3822C33.0103 16.3822 10.8838 38.486 10.8838 65.7524C10.8838 93.0188 33.0103 115.123 60.3048 115.123Z" fill="white" stroke="#164194" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
      <path d="M38.8921 51.4448L35.7388 14.6865C35.4972 11.7308 37.6544 9.12835 40.6072 8.80451C43.5542 8.48656 46.2242 10.565 46.625 13.5031L51.1987 46.6874L53.4325 10.0292C53.7037 7.07935 56.2734 4.87727 59.2322 5.06568C62.191 5.2541 64.4661 7.76235 64.36 10.724L62.8099 39.1862L85.3131 51.9159C85.3131 51.9159 85.3131 67.6719 85.3131 75.7089C85.3131 83.7459 78.0929 89.1687 78.0929 89.1687V111.831C72.5762 113.957 66.5761 115.123 60.3049 115.123C54.1044 115.123 48.1692 113.98 42.6996 111.896V94.1793V88.5623L41.957 88.0971C35.6386 83.8343 31.4893 76.6157 31.4893 68.4315C31.4893 61.0657 35.0021 54.6537 40.1298 50.1318C41.3086 49.0897 43.6957 48.2477 43.6957 48.2477L53.2675 46.3518C56.7567 45.6629 60.1458 47.9297 60.8354 51.4154C61.525 54.901 59.2558 58.2866 55.7666 58.9755L46.1947 60.8714L44.4324 61.154C51.4757 61.154 56.5799 66.6474 56.5799 73.6835" fill="#D3DFF0" />
      <path d="M38.8921 51.4448L35.7388 14.6865C35.4972 11.7308 37.6544 9.12835 40.6072 8.80451C43.5542 8.48656 46.2242 10.565 46.625 13.5031L51.1987 46.6874L53.4325 10.0292C53.7037 7.07935 56.2734 4.87727 59.2322 5.06568C62.191 5.2541 64.4661 7.76235 64.36 10.724L62.8099 39.1862L85.3131 51.9159C85.3131 51.9159 85.3131 67.6719 85.3131 75.7089C85.3131 83.7459 78.0929 89.1687 78.0929 89.1687V111.831C72.5762 113.957 66.5761 115.123 60.3049 115.123C54.1044 115.123 48.1692 113.98 42.6996 111.896V94.1793V88.5623L41.957 88.0971C35.6386 83.8343 31.4893 76.6157 31.4893 68.4315C31.4893 61.0657 35.0021 54.6537 40.1298 50.1318C41.3086 49.0897 43.6957 48.2477 43.6957 48.2477L53.2675 46.3518C56.7567 45.6629 60.1458 47.9297 60.8354 51.4154C61.525 54.901 59.2558 58.2866 55.7666 58.9755L46.1947 60.8714L44.4324 61.154C51.4757 61.154 56.5799 66.6474 56.5799 73.6835" stroke="#164194" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M68.4332 59.8233C65.3271 59.8233 62.8044 57.3092 62.8044 54.2004V39.1862C62.8044 36.0833 65.3212 33.5632 68.4332 33.5632C71.5393 33.5632 74.0619 36.0774 74.0619 39.1862V54.2004C74.0619 57.3033 71.5393 59.8233 68.4332 59.8233Z" fill="#D3DFF0" stroke="#164194" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M79.6844 57.533C76.5783 57.533 74.0557 55.0188 74.0557 51.91V40.5404C74.0557 37.4375 76.5724 34.9175 79.6844 34.9175C82.7905 34.9175 85.3131 37.4316 85.3131 40.5404V51.9159C85.3131 55.0188 82.7964 57.533 79.6844 57.533Z" fill="#D3DFF0" stroke="#164194" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M90.5237 16.5767C90.5237 16.5767 91.2309 23.5362 93.6239 26.1857C96.0169 28.8353 103.496 29.536 103.496 29.536C103.496 29.536 96.2585 30.2249 93.9245 32.5918C91.5905 34.9587 90.5237 42.5012 90.5237 42.5012C90.5237 42.5012 89.7162 34.317 86.9283 32.421C84.1405 30.5251 77.551 29.5419 77.551 29.5419C77.551 29.5419 84.4823 28.9884 87.0521 26.5508C89.9578 23.7952 90.5237 16.5767 90.5237 16.5767Z" fill="#F7C5D7" stroke="#E23B77" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
      <path d="M79.4721 4.88318C79.4721 4.88318 79.9554 9.67594 81.6057 11.5012C83.256 13.3265 88.4015 13.8093 88.4015 13.8093C88.4015 13.8093 83.4211 14.2862 81.812 15.9112C80.203 17.5363 79.4721 22.7295 79.4721 22.7295C79.4721 22.7295 78.9122 17.0947 76.9966 15.7876C75.0752 14.4805 70.5427 13.8034 70.5427 13.8034C70.5427 13.8034 75.3168 13.4207 77.085 11.7426C79.0831 9.85258 79.4721 4.88318 79.4721 4.88318Z" fill="#F7C5D7" stroke="#E23B77" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
    </IconBase>
  )
}

export default Feedback
