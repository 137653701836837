import { useRouter } from 'next/router'
import sortedUniqBy from 'lodash/sortedUniqBy'
import sortBy from 'lodash/sortBy'
import { toggle } from 'utils'
import { useEffect, useState } from 'react'

export default function useListing(items, setItems, pageSize) {
  const router = useRouter()

  const [selected, setSelected] = useState(router.query.filtri?.split('/').map(Number) || [])

  useEffect(() => {
    if (router.query?.filtri) {
      changeFiltri(router.query.filtri)
    } else {
      filter([])
    }
  }, [router.query?.filtri])

  useEffect(() => {
    filter(selected)
  }, [selected])

  function filter(attivi) {
    let filteredItems = []

    filteredItems =
      attivi.length > 0
        ? items.filter((item) => item.temi.find((t) => attivi.includes(t.pk)))
        : items

    setItems([...filteredItems])
  }

  function toggleFilter(item) {
    const tmp = selected

    toggle(tmp, item.pk)

    const baseUrl = router.asPath.split('?')[0]

    const filtri = tmp.length > 0 ? `?filtri=${tmp.join('/')}` : ''
    const url = `${baseUrl}${filtri}`

    router.push(url, undefined, { shallow: true, scroll: false }) // client side transition
  }

  function changeFiltri(filtri) {
    setSelected(filtri?.split('/').map(Number))
  }

  function getAttributi(attributi) {
    return attributi.map((item) => ({ ...item, url: null, selected: selected.includes(item.pk) }))
  }

  function getUniqueFilters() {
    return sortedUniqBy(sortBy(items.map((item) => item.temi).flat(), ['nome']), 'pk')
  }

  function paginatedItems(array, page) {
    return array.slice((page - 1) * pageSize, page * pageSize)
  }

  return {
    toggleFilter,
    getAttributi,
    getUniqueFilters,
    paginatedItems,
  }
}
