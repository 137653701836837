import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function LaureaIllustration({ className }: Props) {
  return (
    <IconBase name="laurea-illustration" w={68} h={80} className={className}>
      <g clipPath="url(#laureaillustration_clip0_2976_25574)">
      <path d="M23.0147 43.4546L8.59872 38.2666C6.95872 37.6746 6.95471 35.3546 8.59072 34.7586L39.5907 23.4786C39.9987 23.3306 40.4507 23.3306 40.8587 23.4786L71.6187 34.5466C73.2587 35.1386 73.2627 37.4586 71.6267 38.0546L57.1267 43.3306L23.0147 43.4546Z" fill="#D3DFF0"/>
      <path d="M22.8945 44.1546L8.3625 38.9266C7.3345 38.5546 6.6665 37.6106 6.6665 36.5186C6.6665 35.4266 7.3265 34.4786 8.3545 34.1026L39.3505 22.8186C39.9145 22.6146 40.5345 22.6146 41.0945 22.8186L71.8545 33.8866C72.8825 34.2546 73.5505 35.2026 73.5505 36.2946C73.5505 37.3866 72.8905 38.3346 71.8625 38.7106L57.2505 44.0306L22.8945 44.1586V44.1546ZM40.2305 24.0666C40.0945 24.0666 39.9625 24.0906 39.8305 24.1386L8.8345 35.4186C8.2665 35.6226 8.0665 36.1186 8.0665 36.5146C8.0665 36.9106 8.2705 37.4066 8.8385 37.6106L23.1385 42.7586L57.0065 42.6346L71.3905 37.3986C71.9585 37.1946 72.1585 36.6986 72.1585 36.3026C72.1585 35.9066 71.9545 35.4146 71.3865 35.2106L40.6225 24.1346C40.4945 24.0906 40.3625 24.0666 40.2265 24.0666H40.2305Z" fill="#164194"/>
      <path d="M29.7827 22.9666C29.8147 22.9826 29.8467 22.9946 29.8787 23.0146C29.8467 23.0026 29.8147 22.9866 29.7827 22.9706V22.9666Z" fill="#D3DFF0"/>
      <path d="M52.1745 22.9626V22.9706C52.1305 22.9906 52.0865 23.0146 52.0425 23.0306C52.0865 23.0066 52.1305 22.9866 52.1745 22.9626Z" fill="#D3DFF0"/>
      <path d="M40.2265 32.5747C40.1505 32.5587 40.0745 32.5427 39.9985 32.5307C40.0745 32.5307 40.1505 32.5467 40.2265 32.5747Z" fill="#8C6361"/>
      <path d="M40.0707 56.6707C36.9467 56.6707 33.7867 56.4627 30.6067 56.0507C28.7267 55.8067 26.5307 55.4307 24.4787 54.4947L24.3827 54.4507C23.7547 54.1667 22.8947 53.7787 22.6467 52.7227L22.6307 52.6227C22.5027 51.1347 23.7507 50.5907 24.3507 50.3267C26.7667 49.2707 29.2907 48.8667 31.6547 48.5867C33.0307 48.4227 34.4267 48.3467 35.7747 48.2747C36.5387 48.2347 37.2987 48.1907 38.0627 48.1347C39.7547 48.0107 41.4387 48.0867 43.0667 48.1667C43.5347 48.1907 44.0027 48.2107 44.4707 48.2307C47.8707 48.3587 50.8747 48.7987 53.6587 49.5827C54.5427 49.8307 55.4787 50.1387 56.3387 50.7187C56.6187 50.9107 57.4787 51.4867 57.3587 52.6347L57.3467 52.7147C57.1267 53.7267 56.3187 54.1147 55.7267 54.3947L55.6467 54.4347C53.8467 55.2987 51.9307 55.6667 50.1307 55.9387C46.8427 56.4307 43.4827 56.6787 40.0747 56.6787L40.0707 56.6707ZM24.0187 52.4467C24.1147 52.7627 24.3867 52.9187 24.9587 53.1747L25.0587 53.2187C26.9467 54.0787 29.0147 54.4307 30.7907 54.6627C37.3107 55.5107 43.7507 55.4747 49.9227 54.5467C51.6187 54.2907 53.4107 53.9507 55.0387 53.1667L55.1227 53.1267C55.6427 52.8787 55.8907 52.7347 55.9667 52.4467C55.9787 52.2787 55.9307 52.1307 55.5507 51.8747C54.8587 51.4067 54.0467 51.1427 53.2747 50.9267C50.5987 50.1747 47.6987 49.7507 44.4107 49.6267C43.9387 49.6107 43.4667 49.5867 42.9907 49.5627C41.4027 49.4867 39.7627 49.4067 38.1587 49.5307C37.3867 49.5867 36.6147 49.6307 35.8427 49.6707C34.5147 49.7427 33.1467 49.8187 31.8107 49.9747C29.5507 50.2427 27.1507 50.6227 24.9027 51.6067C24.1907 51.9187 23.9987 52.0907 24.0147 52.4467H24.0187Z" fill="#164194"/>
      <path d="M39.9947 56.6707C33.9867 56.6707 22.6187 55.7707 22.6187 52.3667C22.6187 48.9627 33.9827 48.0627 39.9947 48.0627C46.0067 48.0627 57.3707 48.9627 57.3707 52.3667C57.3707 55.7707 46.0067 56.6707 39.9947 56.6707ZM39.9947 49.4627C29.6587 49.4627 24.0187 51.3827 24.0187 52.3667C24.0187 53.3507 29.6587 55.2707 39.9947 55.2707C50.3307 55.2707 55.9707 53.3507 55.9707 52.3667C55.9707 51.3827 50.3307 49.4627 39.9947 49.4627Z" fill="#164194"/>
      <path d="M56.667 52.3667C56.667 50.3747 49.2029 48.7627 39.9909 48.7627C30.7789 48.7627 23.3149 50.3747 23.3149 52.3667V39.2227C23.3149 37.2307 30.7789 35.6187 39.9909 35.6187C49.2029 35.6187 56.667 37.2307 56.667 39.2227V52.3707V52.3667Z" fill="#D3DFF0"/>
      <path d="M57.3668 52.3667H55.9668C55.9668 51.3827 50.3268 49.4627 39.9907 49.4627C29.6547 49.4627 24.0147 51.3827 24.0147 52.3667H22.6147V39.2227C22.6147 35.8187 33.9787 34.9187 39.9907 34.9187C46.0028 34.9187 57.3668 35.8187 57.3668 39.2227V52.3707V52.3667ZM39.9907 48.0627C44.7148 48.0627 52.7428 48.6187 55.9668 50.5067V39.2187C55.9668 38.2347 50.3268 36.3147 39.9907 36.3147C29.6547 36.3147 24.0147 38.2347 24.0147 39.2187V50.5067C27.2387 48.6187 35.2667 48.0627 39.9907 48.0627Z" fill="#164194"/>
      <path d="M62.6348 45.1747C61.8068 45.1747 61.1348 44.5027 61.1348 43.6747V30.6467C61.1348 29.9227 61.6588 29.1507 62.6348 29.1507C63.6108 29.1507 64.1348 29.8947 64.1348 30.6107V43.6747C64.1348 44.5027 63.4628 45.1747 62.6348 45.1747Z" fill="#F7C5D7"/>
      <path d="M62.6346 45.8747C61.4226 45.8747 60.4346 44.8867 60.4346 43.6747V30.6467C60.4346 29.5667 61.2586 28.4507 62.6346 28.4507C64.0106 28.4507 64.8346 29.5507 64.8346 30.6107V43.6747C64.8346 44.8867 63.8466 45.8747 62.6346 45.8747ZM62.6346 29.8507C62.0026 29.8507 61.8346 30.3707 61.8346 30.6467V43.6747C61.8346 44.1147 62.1946 44.4747 62.6346 44.4747C63.0746 44.4747 63.4346 44.1147 63.4346 43.6747V30.6107C63.4346 30.3187 63.2186 29.8507 62.6346 29.8507Z" fill="#E23B77"/>
      <path d="M62.6348 42.8267C64.2588 42.8267 65.5748 44.1427 65.5748 45.7667V50.5707C65.5748 51.5147 64.8068 52.2827 63.8628 52.2827H61.4068C60.4628 52.2827 59.6948 51.5147 59.6948 50.5707V45.7667C59.6948 44.1427 61.0108 42.8267 62.6348 42.8267Z" fill="#F7C5D7"/>
      <path d="M63.8626 52.9827H61.4066C60.0746 52.9827 58.9946 51.8987 58.9946 50.5707V45.7667C58.9946 43.7587 60.6266 42.1267 62.6346 42.1267C64.6426 42.1267 66.2746 43.7587 66.2746 45.7667V50.5707C66.2746 51.9027 65.1906 52.9827 63.8626 52.9827ZM62.6346 43.5267C61.3986 43.5267 60.3946 44.5307 60.3946 45.7667V50.5707C60.3946 51.1307 60.8506 51.5827 61.4066 51.5827H63.8626C64.4226 51.5827 64.8746 51.1267 64.8746 50.5707V45.7667C64.8746 44.5307 63.8706 43.5267 62.6346 43.5267Z" fill="#E23B77"/>
      </g>
      <defs>
      <clipPath id="laureaillustration_clip0_2976_25574">
      <rect width="66.888" height="34.004" fill="white" transform="translate(6.6665 22.6667)"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default LaureaIllustration
