import React, { ReactNode } from 'react'
import { WImage } from 'components/atoms'

interface Props {
  src?: string
  alt?: string
  title?: string
  classPrefix?: string
  maxWidth: number
  maxHeight: number
  lazyLoad?: boolean
  children?: ReactNode
}

const ImageBox = (props: Props) => {
  const {
    src = '',
    alt = '',
    title = '',
    classPrefix = '',
    maxWidth = 0,
    maxHeight = 0,
    lazyLoad = false,
    children = null,
  } = props

  return (
    <div className={`image-box${classPrefix ? ` ${classPrefix}__image-box` : ''}`}>
      <WImage
        src={src}
        alt={alt}
        title={title}
        classPrefix={classPrefix}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        lazyLoad={lazyLoad}
      />
      {children}
    </div>
  )
}

export default ImageBox
