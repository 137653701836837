import React from 'react'
import Link from 'next/link'
import styles from './LandingProdottoButton.module.sass'

interface Props {
  label?: string
  variant?: '' | 'white'
  href?: string
  onClick?: () => void
  className?: string
}

const LandingProdottoButton = (props: Props) => {
  const { label = '', variant = '', href = '', onClick = undefined, className = '' } = props

  return onClick ? (
    <button
      type="button"
      onClick={onClick}
      className={`${styles.landingProdottoButton} ${
        variant ? styles[`landingProdottoButton--${variant}`] : ''
      } ${className}`}
    >
      {label}
    </button>
  ) : (
    <Link legacyBehavior href={href || '#'}>
      <a
        className={`${styles.landingProdottoButton} ${
          variant ? styles[`landingProdottoButton--${variant}`] : ''
        } ${className}`}
      >
        {label}
      </a>
    </Link>
  )
}

export default LandingProdottoButton
