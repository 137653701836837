import React, { ReactNode } from 'react'
import styles from './FormStep.module.sass'

interface ContentProps {
  children?: ReactNode
}

interface ButtonsProps {
  children?: ReactNode
}

interface Props {
  title?: string
  children?: ReactNode
  buttons?: ReactNode[]
}

const FormStepContent = (props: ContentProps) => {
  const { children = null } = props

  return <div className={styles.content}>{children}</div>
}

const FormStepButtons = (props: ButtonsProps) => {
  const { children = null } = props

  return <div className={styles.buttons}>{children}</div>
}

const FormStep = (props: Props) => {
  const { title = '', children = [], buttons = [] } = props

  return (
    <div className={styles.formStep}>
      {title && <p className={styles.title}>{title}</p>}
      {children && <FormStepContent>{children}</FormStepContent>}
      {buttons && <FormStepButtons>{buttons}</FormStepButtons>}
    </div>
  )
}

FormStep.Content = FormStepContent
FormStep.Buttons = FormStepButtons

export default FormStep
