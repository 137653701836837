import React from 'react'
import { Loader } from 'components/atoms/Icons'
import styles from './LoaderOverlay.module.sass'


interface Props {
  className?: string
}

const LoaderOverlay = (props: Props) => {

  const { 
    className = '',
  } = props

  return (
    <div className={`${styles.loaderOverlay} ${className}`}>
      <Loader />
    </div>
  )
}

export default LoaderOverlay
