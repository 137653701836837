import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function ClessidraIllustration({ className }: Props) {
  return (
    <IconBase name="clessidra-illustration" w={80} className={className}>
      <g clipPath="url(#clessidraillustration_clip0_2976_25565)">
      <path d="M50.8757 63.7418C52.4797 63.7418 53.7077 62.5994 53.4557 61.3425C52.2477 55.3666 46.5797 51.2235 39.7717 51.2235C32.9637 51.2235 27.2957 55.3634 26.0877 61.3425C25.8317 62.6026 27.0637 63.7418 28.6677 63.7418H50.8757Z" fill="#EFC3D6"/>
      <path d="M50.876 64.2987H28.668C27.696 64.2987 26.776 63.9614 26.144 63.3727C25.516 62.7872 25.244 62.0139 25.396 61.2534C26.656 55.0197 32.564 50.6667 39.772 50.6667C46.98 50.6667 52.888 55.0197 54.148 61.2534C54.3 62.0139 54.028 62.784 53.4 63.3727C52.768 63.9614 51.848 64.2987 50.876 64.2987ZM39.772 51.7804C33.268 51.7804 27.924 55.7484 26.78 61.4284C26.692 61.8644 26.848 62.3099 27.212 62.6472C27.576 62.9877 28.108 63.1818 28.668 63.1818H50.876C51.436 63.1818 51.968 62.9877 52.332 62.6472C52.696 62.3099 52.852 61.8644 52.764 61.4284C51.616 55.7484 46.276 51.7804 39.772 51.7804Z" fill="#E23B77"/>
      <path d="M29.3438 21.4653C28.0958 21.4653 27.1718 22.6533 27.5078 23.8533C28.4438 27.1893 31.3878 29.3453 35.1918 31.9613C38.9958 34.5773 39.3398 38.3133 39.6238 38.2973C40.3638 38.2573 40.1358 34.5413 44.5278 31.8453C47.7718 29.8533 50.8798 27.2053 51.9958 23.9933C52.4238 22.7573 51.5118 21.4653 50.2038 21.4653H29.3438Z" fill="#EFC3D6"/>
      <path d="M39.6119 38.9973C39.0559 38.9973 38.8839 38.4893 38.6999 37.9533C38.3399 36.8893 37.4959 34.3973 34.7959 32.5373C30.7479 29.7533 27.8119 27.5173 26.8319 24.0413C26.6119 23.2653 26.7719 22.4453 27.2599 21.8013C27.7599 21.1453 28.5159 20.7653 29.3399 20.7653H50.1999C51.0519 20.7653 51.8239 21.1613 52.3159 21.8573C52.8039 22.5413 52.9319 23.4253 52.6519 24.2213C51.6399 27.1333 49.0279 29.8973 44.8919 32.4413C42.0959 34.1573 41.3279 36.2813 40.8679 37.5493C40.5919 38.3053 40.3559 38.9613 39.6599 38.9973C39.6439 38.9973 39.6239 38.9973 39.6079 38.9973H39.6119ZM29.3439 22.1653C28.9639 22.1653 28.6119 22.3413 28.3799 22.6453C28.1559 22.9413 28.0839 23.3133 28.1839 23.6613C29.0359 26.6933 31.6679 28.6853 35.5919 31.3813C38.0759 33.0893 39.1719 35.2333 39.7159 36.6333C40.2479 35.2293 41.2719 33.0253 44.1639 31.2453C48.0199 28.8773 50.4319 26.3573 51.3359 23.7573C51.4639 23.3853 51.4079 22.9853 51.1799 22.6613C50.9519 22.3413 50.5959 22.1573 50.2039 22.1573H29.3439V22.1653Z" fill="#E23B77"/>
      <path d="M59.72 7.36667H19.824C18.0986 7.36667 16.7 8.76533 16.7 10.4907C16.7 12.216 18.0986 13.6147 19.824 13.6147H59.72C61.4453 13.6147 62.844 12.216 62.844 10.4907C62.844 8.76533 61.4453 7.36667 59.72 7.36667Z" fill="#D3DFF0"/>
      <path d="M59.72 14.3147H19.824C17.716 14.3147 16 12.5987 16 10.4907C16 8.38266 17.716 6.66666 19.824 6.66666H59.72C61.828 6.66666 63.544 8.38266 63.544 10.4907C63.544 12.5987 61.828 14.3147 59.72 14.3147ZM19.824 8.06666C18.488 8.06666 17.4 9.15466 17.4 10.4907C17.4 11.8267 18.488 12.9147 19.824 12.9147H59.72C61.056 12.9147 62.144 11.8267 62.144 10.4907C62.144 9.15466 61.056 8.06666 59.72 8.06666H19.824Z" fill="#1E438E"/>
      <path d="M59.72 66.4467H19.824C18.0986 66.4467 16.7 67.8453 16.7 69.5707C16.7 71.296 18.0986 72.6947 19.824 72.6947H59.72C61.4453 72.6947 62.844 71.296 62.844 69.5707C62.844 67.8453 61.4453 66.4467 59.72 66.4467Z" fill="#D3DFF0"/>
      <path d="M59.72 73.3946H19.824C17.716 73.3946 16 71.6786 16 69.5706C16 67.4626 17.716 65.7466 19.824 65.7466H59.72C61.828 65.7466 63.544 67.4626 63.544 69.5706C63.544 71.6786 61.828 73.3946 59.72 73.3946ZM19.824 67.1466C18.488 67.1466 17.4 68.2346 17.4 69.5706C17.4 70.9066 18.488 71.9946 19.824 71.9946H59.72C61.056 71.9946 62.144 70.9066 62.144 69.5706C62.144 68.2346 61.056 67.1466 59.72 67.1466H19.824Z" fill="#1E438E"/>
      <path d="M58.588 66.4467H57.188C57.188 57.6946 53.08 49.6147 46.724 45.8587L45.932 45.4147C44.008 44.1907 42.948 42.3187 42.948 40.1427C42.948 37.9667 43.984 36.1467 45.788 34.9267L46.14 34.7107C52.864 31.0707 57.188 22.8666 57.188 13.7906H58.588C58.588 23.3746 53.976 32.0627 46.836 35.9227L46.54 36.1026C45.136 37.0546 44.344 38.4946 44.344 40.1387C44.344 41.7827 45.168 43.2586 46.664 44.2186L47.42 44.6387C54.212 48.6507 58.588 57.2067 58.588 66.4427V66.4467Z" fill="#164194"/>
      <path d="M22.3561 66.4467H20.9561C20.9561 57.2107 25.3321 48.6547 32.1081 44.6507L32.8761 44.2227C34.3721 43.2627 35.1961 41.8147 35.1961 40.1427C35.1961 38.4707 34.4081 37.0547 32.9721 36.0867L32.6761 35.9067C25.5721 32.0627 20.9561 23.3746 20.9561 13.7906H22.3561C22.3561 22.8666 26.6801 31.0746 33.3721 34.6947L33.7281 34.9107C35.5601 36.1467 36.5961 38.0467 36.5961 40.1427C36.5961 42.2387 35.5361 44.1867 33.6121 45.4147L33.5761 45.4347L32.8041 45.8666C26.4641 49.6147 22.3561 57.6987 22.3561 66.4467Z" fill="#164194"/>
      </g>
      <defs>
      <clipPath id="clessidraillustration_clip0_2976_25565">
      <rect width="47.544" height="66.728" fill="white" transform="translate(16 6.66666)"/>
      </clipPath>
      </defs>
    </IconBase>
  )
}

export default ClessidraIllustration
