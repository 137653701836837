import React from 'react'
import IconBase from 'components/atoms/IconBase/IconBase'

interface Props {
  className?: string
}

function ShoppingBag({ className }: Props) {
  return (
    <IconBase name="shopping-bag" w={20} className={className}>
      <path d="M15 5.00008H13.3333C13.3333 3.15841 11.8416 1.66675 9.99998 1.66675C8.15831 1.66675 6.66665 3.15841 6.66665 5.00008H4.99998C4.08331 5.00008 3.33331 5.75008 3.33331 6.66675V16.6667C3.33331 17.5834 4.08331 18.3334 4.99998 18.3334H15C15.9166 18.3334 16.6666 17.5834 16.6666 16.6667V6.66675C16.6666 5.75008 15.9166 5.00008 15 5.00008ZM9.99998 3.33341C10.9166 3.33341 11.6666 4.08341 11.6666 5.00008H8.33331C8.33331 4.08341 9.08331 3.33341 9.99998 3.33341ZM15 16.6667H4.99998V6.66675H6.66665V8.33342C6.66665 8.79175 7.04165 9.16675 7.49998 9.16675C7.95831 9.16675 8.33331 8.79175 8.33331 8.33342V6.66675H11.6666V8.33342C11.6666 8.79175 12.0416 9.16675 12.5 9.16675C12.9583 9.16675 13.3333 8.79175 13.3333 8.33342V6.66675H15V16.6667Z" fill="currentColor"/>
    </IconBase>
  )
}

export default ShoppingBag
