/* eslint-disable unicorn/no-object-as-default-parameter */
import { api } from 'utils'
import { TRADUZIONI } from 'utils/endpoint'

// se client-side -> prendo da localStorage
export const fetchTraduzioni = (lang = 'it', isServer = true) => {
  let payload = null
  const key = `traduzioni-${lang}`

  payload =
    !isServer && localStorage.getItem(key)
      ? { data: JSON.parse(localStorage.getItem(key)) }
      : api.get(TRADUZIONI, { params: { lang } }).catch((error) => console.log(error))

  return {
    type: 'FETCH_TRADUZIONI',
    payload,
  }
}

export default function reducer(
  state = {
    traduzioni: [],
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case 'FETCH_TRADUZIONI_PENDING': {
      return { ...state, fetching: true }
    }
    case 'FETCH_TRADUZIONI_REJECTED': {
      return { ...state, fetching: false, error: action.payload }
    }
    case 'FETCH_TRADUZIONI_FULFILLED': {
      const lang = action.payload.config.params?.lang

      const traduzioni = action.payload.data
      const key = `traduzioni-${lang}`

      if (typeof window !== 'undefined') localStorage.setItem(key, JSON.stringify(traduzioni))

      return {
        ...state,
        fetching: false,
        fetched: true,
        traduzioni,
        lang,
      }
    }

    default: {
      return state
    }
  }
}
